const koolhaasSlides = [
  {
    id: 1,
    imageUrl: "rem01.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 12,
    imageUrl: "rem12.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 2,
    imageUrl: "rem02.png",
    hasMask: false,
    hasQuote: false,
    variantFont: false,
  },
  {
    id: 14,
    imageUrl: "rem14.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 3,
    imageUrl: "rem03.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 4,
    imageUrl: "rem04.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 15,
    imageUrl: "rem15.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 5,
    imageUrl: "rem05.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 6,
    imageUrl: "rem06.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 16,
    imageUrl: "rem16.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 7,
    imageUrl: "rem07.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 11,
    imageUrl: "rem11.png",
    hasMask: false,
    hasQuote: false,
  },
];

export default koolhaasSlides;
