// const isProd = process.env.REACT_APP_CONFIG_ENV === "production";

export const availableLocales = { "en-FI": "English (Finland)" };

export const availableLocaleKeys = Object.keys(availableLocales);

export const liveLocaleKeys = ["en-FI"];

/* Static feature flags set by locale
Will be merged with general flags in the appConfig.js (if any)
Will return for the current locale by the mapStateToProps
In components use: features.getIn(['hiddenSections', 'wearable'])
Locale-specific 'season' config is in ./static/living/seasons.js
*/

const allFeaturesBlocked = {
  home: true,
  koolhaas: true,
  wiley: true,
  art: true,
  card: true,
  video: true,
  wearable: true,
  living: true,
  viewmono: true,
  privacy: true,
  info: true,
  howtowear: true,
  benefits: true,
  magazine: true,
  finewine: true,
  heroVideo: true,
  archive: true,
};

export const features = {
  "en-FI": {
    hiddenSections: {
      ...allFeaturesBlocked,
      wearable: false,
      howtowear: false,
      viewmono: false,
      info: false,
      archive: false,
    }
  },
};

export default availableLocales;
