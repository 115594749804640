const images = () => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ''}assets`;
  const introductions1Image = `${assetPath}/home/introductions1.jpg`;
  const introductions2Image = `${assetPath}/home/introductions2.jpg`;
  const introductions3Image = `${assetPath}/home/introductions3.jpg`;
  const introductions4Image = `${assetPath}/home/introductions4.jpg`;
  const introductions5Image = `${assetPath}/home/introductions5.png`;
  const introductions6Image = `${assetPath}/home/introductions6.jpg`;
  const introductions7Image = `${assetPath}/home/introductions7.png`;
  const introductions8Image = `${assetPath}/home/introductions8.jpg`;
  const introductions9Image = `${assetPath}/home/introductions9.jpg`;

  return [
    {
      ratio: 0.67,
      right: "0%",
      src: introductions1Image,
      top: "23.5%",
      width: "23.75%",
      mixBlendMode: "multiply",
    }, // rushdi
    {
      ratio: 0.81,
      right: "10%",
      src: introductions2Image,
      top: "7.5%",
      width: "26.2%",
      mixBlendMode: "multiply",
    }, // gwyneth
    {
      ratio: 1.9,
      right: "-15%",
      src: introductions8Image,
      top: "20.5%",
      width: "33.262%",
      mixBlendMode: "normal",
    }, // salif
    {
      ratio: 1,
      right: "35.8%",
      src: introductions7Image,
      top: "35.75%",
      width: "24.566%",
      mixBlendMode: "normal",
    }, // vase
    {
      ratio: 0.8,
      right: "84%",
      src: introductions3Image,
      top: "37.5%",
      width: "25.35%",
      mixBlendMode: "multiply",
    }, // samurai
    {
      ratio: 0.75,
      right: "99.75%",
      src: introductions4Image,
      top: "9%",
      width: "15.5%",
      mixBlendMode: "multiply",
    }, // letter
    {
      ratio: 0.97,
      right: "111.5%",
      src: introductions5Image,
      top: "21.25%",
      width: "25.55%",
      mixBlendMode: "multiply",
    }, // dial
    {
      ratio: 0.85,
      right: "123.75%",
      src: introductions6Image,
      top: "3.75%",
      width: "24.5%",
      mixBlendMode: "multiply",
    }, // map
    {
      ratio: 1.51,
      right: "126.75%",
      src: introductions9Image,
      top: "35.5%",
      width: "23.525%",
      mixBlendMode: "multiply",
    }, // red
  ];
};
export default images;
