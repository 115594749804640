import React from "react";
import propTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from '@react-spring/web';
import * as easings from "d3-ease";

export default function InView(props) {
  const { children, y, duration, delay, triggerOnce, rootMargin } = props;
  const animConfig = (ref) => {
    if (!ref) {
      return {
        to: {
          opacity: 0,
        },
      };
    }
    return {
      from: {
        opacity: ref ? 0 : 1,
        transform: ref ? `translate3d(0,${y}px,0)` : "translate3d(0,0px,0)",
      },
      to: {
        opacity: ref ? 1 : 0,
        transform: ref ? "translate3d(0,0,0)" : `translate3d(0,${y}px,0)`,
      },
      delay,
      config: {
        duration,
        easing: easings.easePolyInOut.exponent(2),
      },
    };
  };

  const [el, inView] = useInView({
    triggerOnce,
    rootMargin,
  });
  const animation = useSpring(animConfig(inView));

  return (
    <div ref={el} className={props.className} style={props.style}>
      <animated.div style={animation}>{children}</animated.div>
    </div>
  );
}

InView.propTypes = {
  children: propTypes.any,
  y: propTypes.number,
  duration: propTypes.number,
  delay: propTypes.number,
  triggerOnce: propTypes.bool,
  rootMargin: propTypes.string,
};

InView.defaultProps = {
  y: 200,
  duration: 300,
  delay: 0,
  triggerOnce: false,
  rootMargin: "15% 0% 10% 0%",
};
