import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { useSpring, useTransition, animated } from '@react-spring/web';
import { useDrag } from "@use-gesture/react";
import clamp from "lodash.clamp";
import style from "./SlideshowAlt.module.scss";
import SlideNotes from "../SlideNotes/SlideNotes";
import Slide from "../Slideshow/Slide";

const SlideshowAlt = ({ intl, slides = [], title, rootModule }) => {
  const locale = intl.locale.toLowerCase();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMaskVisible, setIsMaskVisible] = useState(true);

  function buildContext() {
    const isZh = locale === "zh-hk";

    const current = currentSlide + 1;
    const total = slides.length;

    return isZh
      ? `第${current}頁，共${total}頁`
      : `${current} ${intl.messages[`${rootModule}.of`]} ${total}`;
  }

  function atStart() {
    const slide = slides[currentSlide];

    if (slide?.hasMask && !isMaskVisible) {
      return false;
    }

    return currentSlide < 1;
  }

  function atEnd() {
    const slide = slides[currentSlide];
    if (slide?.hasMask && isMaskVisible) {
      return false;
    }

    return currentSlide >= slides.length - 1;
  }

  function handlePreviousClick() {
    if (atStart()) {
      return;
    }

    const slide = slides[currentSlide];
    if (slide.hasMask && !isMaskVisible) {
      setIsMaskVisible(true);
      return;
    }

    setIsMaskVisible(true);
    setCurrentSlide(currentSlide - 1);
  }

  function handleNextClick() {
    if (atEnd()) {
      return;
    }

    const slide = slides[currentSlide];
    if (slide.hasMask && isMaskVisible) {
      setIsMaskVisible(false);
      return;
    }

    setIsMaskVisible(true);
    setCurrentSlide(currentSlide + 1);
  }

  const slideSpring = useSpring({
    transform: `translateX(-${currentSlide * 100}%)`,
  });

  const bind = useDrag(
    ({ down, direction: [xDir], distance: [dx], cancel }) => {
      if (down && dx > 400) {
        cancel(
          setCurrentSlide(
            clamp(currentSlide + (xDir > 0 ? -1 : 1), 0, slides.length - 1)
          )
        );
      }
    }
  );

  const fadeTransition = useTransition([currentSlide], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, position: "absolute" },
    config: { tension: 280, friction: 60 },
  });

  const slideRefs = [];

  return (
    <div
      aria-label={title}
      aria-roledescription="carousel"
      className={`position-relative ${style.wrapper}`}
      role="group"
    >
      <div className={`${style.titleWrapper}`}>
        <div className="container">
          <div className="row">
            <div className={`col-xs-9 pad-0-lr pad-1-lr-md`}>
              <h2
                data-localeid="remKoolhaasWork"
                className={`${style.slideshowTitle} bigcaslonregular`}
              >
                <FormattedMessage
                  id={title}
                  values={{
                    i: (str) => <i className={`bigcaslonitalic`}>{str}</i>,
                  }}
                />
              </h2>
            </div>
            <div className={`col-xs-3 pad-0-lr pad-1-lr-md text-align-right`}>
              <p className={`${style.context} bentonsansthin margin-0`}>
                {buildContext()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container pad-0">
        <div className="row">
          <div className={`col-md-9 pad-0 ${style.main}`}>
            <div className={`no-scroll ${style.slides}`}>
              <animated.div
                {...bind()}
                aria-atomic="false"
                aria-live="polite"
                className={`flex flex-align-items-center ${style.slideContainer}`}
                style={slideSpring}
              >
                {slides.map((slide) => {
                  const newRef = React.createRef();
                  slideRefs.push(newRef);
                  return (
                    <Slide
                      ref={newRef}
                      key={slide.id}
                      isMaskVisible={isMaskVisible}
                      slide={slide}
                      currentSlide={currentSlide}
                      rootModule={rootModule}
                    />
                  );
                })}
              </animated.div>
            </div>
          </div>

          <div className={`col-md-3 pad-0-lr pad-1-lr-md ${style.aside}`}>
            <div className={`flex flex-column ${style.sidebarWrapper}`}>
              <div className={style.sidebar}>
                { fadeTransition((styles, item) => (
                  <animated.div
                    className={style.animatedNotes}
                    style={styles}
                  >
                    <div className={`${style.notes}`}>
                      <SlideNotes
                        slide={slides[item]}
                        isMaskVisible={isMaskVisible}
                        currentSlide={currentSlide}
                        nSlides={slides.length}
                        rootModule={rootModule}
                      />
                    </div>
                  </animated.div>
                ))}
              </div>
              <div
                className={`${style.nav} flex flex-align-center flex-justify-between`}
              >
                <nav className="flex">
                  <button
                    aria-label={intl.formatMessage({
                      id: `${rootModule}.previousSlide`,
                    })}
                    className={`dls-black ${style.moveButtons} margin-1-r transparent`}
                    data-test="previous-slide"
                    disabled={atStart()}
                    type="button"
                    onClick={handlePreviousClick}
                  >
                    <span className="dls-icon-left-filled icon icon-lg" />
                  </button>
                  <button
                    aria-label={intl.formatMessage({
                      id: `${rootModule}.nextSlide`,
                    })}
                    className={`dls-black ${style.moveButtons} transparent`}
                    data-test="next-slide"
                    disabled={atEnd()}
                    type="button"
                    onClick={handleNextClick}
                  >
                    <span className="dls-icon-right-filled icon icon-lg" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SlideshowAlt.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      hasMask: PropTypes.bool.isRequired,
      hasQuote: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      imageUrl: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string,
};

SlideshowAlt.defaultProps = {
  title: "",
};

export default injectIntl(SlideshowAlt);
