/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import * as Sentry from "@sentry/react";
import smoothscroll from "smoothscroll-polyfill";
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import { routes } from "./childRoutes";
import { setHasTouch } from "./features/browser/browserSlice";
import "./App.scss";
import "./App.module.scss";

const App = ({
  languageData,
  localeName,
  registerTouch,
}) => {
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter (
    createRoutesFromElements(routes)
  );

  useEffect(() => {
    if (!window) {
      return;
    }
    const registerTouchStart = () => {
      registerTouch(true);
      window.removeEventListener("touchstart", registerTouchStart);
    };
    window.addEventListener("touchstart", registerTouchStart);
    return () => {
      window.removeEventListener("touchstart", registerTouchStart);
    };
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  if (!languageData.centurion) {
    return null;
  }
  return (
    <>
      <IntlProvider
        wrapRichTextChunksInFragment={true}
        locale={localeName}
        messages={languageData}
      >
        <RouterProvider router={router} />
      </IntlProvider>
    </>
  );
};

App.propTypes = {
  languageData: PropTypes.object.isRequired,
  localeName: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const localeName = state.getIn(["intl", "activeLocale"]);
  const languagePack = state
    .getIn(["intl", "languagePacks", localeName, "axp-centurion"], fromJS({}))
    .toJS();

  // refactor so that the other slices return Immutable.Map (?)
  const config = state.get("config");
  const env = config?.env;

  return {
    languageData: languagePack?.data || {},
    localeName,
    env,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerTouch: (hasTouch) => dispatch(setHasTouch(hasTouch)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
