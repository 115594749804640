import classNames from "classnames";
import style from "../Calendar.module.scss";

export default function CalendarTabs({ options, month, handleClick }) {
  return (
    <ul className={style.calendarTabs}>
      {options.map(({ id, name, past }) => {
        const isActive = id === month;

        const buttonClasses = classNames({
          [style.tabButton]: true,
          [style.isActive]: isActive,
          // [style.isPast]: past,
        });

        return (
          <li key={id}>
            <button onClick={() => handleClick(id)} className={buttonClasses}>
              {name}
            </button>
          </li>
        );
      })}
    </ul>
  );
}
