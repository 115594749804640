const wearableImages = ({ assetPath }) => [
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-1.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-2.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-3.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-4.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-5.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-6.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-7.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-8.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-9.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-10.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-11.jpg`,
  },
  {
    alt: "",
    ratio: 1,
    src: `${assetPath}/wearable/wearable-grid-image-12.jpg`,
  },
];

export default wearableImages;
