import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { animated, useSpring } from '@react-spring/web';
import { useDrag } from "@use-gesture/react";
import debounce from "lodash.debounce";
import { useMeasure } from "react-use";
import PropTypes from "prop-types";
import style from "./Carousel.module.scss";
import CarouselSlide from "./CarouselSlide";

const Carousel = ({
  intl,
  items,
  toggleButtonId,
  carouselContainerId,
  buttonTextId,
  buttonTextIdSmall,
  defaultOpen,
}) => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const faqsImage = `${assetPath}/privacy/faqsImage.svg`;

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [pageRes, setPageRes] = useState("small"); // 'small' or 'large'
  const [contentHeight, setContentHeight] = useState("0");
  const [transformAmount, setTransformAmount] = useState(0);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [maxPagination, setMaxPagination] = useState(null);

  const [measureRef, { height }] = useMeasure();

  const slide = useSpring({
    transform: `translateX(-${transformAmount}%)`,
  });

  const resetPageRes = () => {
    let res = window.innerWidth;
    setPageRes(res < 768 ? "small" : "large"); // catch state change with useEffect (below)
  };

  const calculateAvailableHeight = () => {
    const wh = window.innerHeight;
    const th = window.innerWidth >= 1024 ? 107 : 93; // toggle
    const fh = 75; // footer
    if (wh - (th + fh) > parseInt(contentHeight)) {
      return "" + (wh - (th + fh));
    } else {
      return contentHeight;
    }
  };

  const wearableFaqsToggleAnim = useSpring({
    height: isOpen
      ? `${pageRes === "small" ? contentHeight : calculateAvailableHeight()}px`
      : "0px",
    onRest: () => {
      if (isOpen) {
        setTimeout(() => {
          document
            .getElementById("faqs")
            .scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    },
  });

  const handleResize = useCallback(() => {
    const slidesInView = window.innerWidth < 768 ? 1 : 3;
    setMaxPagination(Math.ceil(items.length / slidesInView) - 1);
  }, [items.length]);

  const handlePrev = () => {
    const adj = pagination - 1;
    setPagination(adj < 0 ? 0 : adj);
  };

  const handleNext = () => {
    const adj = pagination + 1;
    setPagination(adj > maxPagination ? maxPagination : adj);
  };

  const bind = useDrag(({ down, event, direction: [xDir] }) => {
    if (down) {
      return;
    }
    if (xDir < 0 && atEnd) {
      return;
    }
    if (xDir > 0 && atStart) {
      return;
    }
    if (xDir === 0) {
      return;
    }
    xDir < 0 && handleNext();
    xDir > 0 && handlePrev();
    event.stopPropagation();
  });

  useEffect(() => {
    if (!window) {
      return;
    }
    const { hash } = window.location;
    if (hash === "#faqs") {
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!window) {
      return;
    }
    const debFunc = debounce(() => {
      resetPageRes();
    }, 25);
    resetPageRes(); // initial call...
    window.addEventListener("resize", debFunc);
    return () => {
      window.removeEventListener("resize", debFunc);
    };
  }, []);

  useEffect(() => {
    setContentHeight(height);
  }, [height]);

  useEffect(() => {
    if (!window) {
      return;
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (maxPagination === null) {
      return;
    }
    setAtStart(pagination <= 0);
    setAtEnd(pagination >= maxPagination);
    setTransformAmount(pagination * 100);
  }, [pagination, maxPagination]);

  useEffect(() => {
    if (maxPagination === null) {
      return;
    }
    if (maxPagination <= 1) {
      setAtEnd(true);
    }
  }, [maxPagination]);

  return (
    <div id="faqs" className={`position-relative ${style.accordionEnabled}`}>
      <div role="heading" aria-level="1">
        <div className={style.accordionToggleWrapper}>
          <div
            id={toggleButtonId}
            tabIndex="0"
            role="button"
            className={style.accordionToggle}
            aria-controls={carouselContainerId}
            aria-expanded={isOpen}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13 || e.keyCode === 32) {
                setIsOpen(!isOpen);
              }
            }}
          >
            <div className={`position-relative container`}>
              <span className={style.accordionToggleIcon}>
                <svg xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="none"
                    stroke="#000"
                    strokeWidth=".25"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    d="M28 0l21 9.5L70 0"
                  />
                </svg>
              </span>
              <span className={`${style.accordionToggleText} bigcaslonregular`}>
                <FormattedMessage
                  id={
                    pageRes === "small" && buttonTextIdSmall
                      ? buttonTextIdSmall
                      : buttonTextId
                  }
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        id={carouselContainerId}
        className={`position-relative ${style.slidesWrapper}`}
        role="region"
        aria-labelledby={toggleButtonId}
      >
        <animated.div
          aria-roledescription="carousel"
          aria-label={intl.formatMessage({ id: buttonTextId })}
          className={`no-scroll ${style.carousel}`}
          data-test="carousel"
          style={wearableFaqsToggleAnim}
        >
          <div ref={measureRef} className="container">
            <img className={style.faqsBG} src={faqsImage} alt="" />
            <div {...bind()} className="no-scroll">
              <animated.div
                className={`flex-no-wrap row ${style.carouselCols}`}
                style={slide}
                aria-atomic="false"
                aria-live="polite"
              >
                {items.map((item) => (
                  <div
                    key={item.id}
                    className={`${item.lastItem ? "col-md-6" : "col-md-4"} ${
                      style.slideWrapper
                    }`}
                  >
                    <div className={`pad-responsive-tb ${style.slide}`}>
                      <CarouselSlide slide={item} />
                    </div>
                  </div>
                ))}
              </animated.div>
            </div>
            <div className="pad-4-t">
              <nav className="flex flex-justify-center">
                <button
                  aria-label={intl.formatMessage({ id: "previousFaq" })}
                  className={`dls-black margin-1-r transparent ${style.navButton}`}
                  data-test="previous-slide"
                  disabled={atStart}
                  type="button"
                  onClick={handlePrev}
                >
                  <span className="dls-icon-left-filled icon icon-lg" />
                </button>
                <button
                  aria-label={intl.formatMessage({ id: "nextFaq" })}
                  className={`dls-black transparent ${style.navButton}`}
                  data-test="next-slide"
                  disabled={atEnd}
                  type="button"
                  onClick={handleNext}
                >
                  <span className="dls-icon-right-filled icon icon-lg" />
                </button>
              </nav>
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  toggleButtonId: PropTypes.string.isRequired,
  carouselContainerId: PropTypes.string.isRequired,
  buttonTextId: PropTypes.string.isRequired,
  buttonTextIdSmall: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool.isRequired,
};

Carousel.defaultProps = {
  defaultOpen: false,
};

export default injectIntl(Carousel);
