import { createSlice } from "@reduxjs/toolkit";

const popupSlice = createSlice({
  name: "popup",
  initialState: {
    isOpen: false,
  },
  reducers: {
    setIsOpen: (state, { payload: isOpen }) => {
      state.isOpen = isOpen;
    },
  },
});

export const { setIsOpen } = popupSlice.actions;
export default popupSlice.reducer;
