import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import Logotype from "../../components/Logotype/Logotype";
import style from "./Video.module.scss";

const Video = ({ cdnUrl }) => {
  const s3Url = `axp-centurion-video/assets`;

  const { locale: localeName, messages: languageData } = useIntl();

  const marketsMap = ["fi", "de"];
  let videoLocale = localeName.toLocaleLowerCase().split("-")[1];

  // override for no, se, ch, nl
  if (
    videoLocale === "no" ||
    videoLocale === "se" ||
    videoLocale === "ch" ||
    videoLocale === "nl"
  ) {
    videoLocale = "fi";
  }

  // fallback to gb
  videoLocale = marketsMap.indexOf(videoLocale) !== -1 ? videoLocale : "gb";

  if (!languageData.centurion) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{languageData.title}</title>
      </Helmet>
      <div className="container">
        <main>
          <div className="row">
            <div className="col-md-12">
              <header className={`position-relative ${style.header}`}>
                <div
                  className={`flex flex-justify-between ${style.titleWrapper}`}
                >
                  <Logotype id="newCenturion" title="title" rootModule="home" />
                </div>
              </header>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className={`no-scroll position-relative ${style.videoContainer}`}
              >
                <video
                  className={`position-absolute ${style.video}`}
                  controls
                  controlsList="nodownload"
                  poster={`${cdnUrl}${s3Url}/poster-frame.jpg`}
                >
                  <source
                    src={`${cdnUrl}static/${videoLocale}_centurion_50_rev01_subs.mp4`}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

Video.propTypes = {
  cdnUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  cdnUrl: state.get("config")["assets"],
});

export default connect(mapStateToProps)(Video);
