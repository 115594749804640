import React from "react";
import PropTypes from "prop-types";
import Logotype from "../Logotype/Logotype";
import style from "./CardHeader.module.scss";

const CardHeader = ({ id }) => {
  return (
    <div className={`${style.wrapper} row`}>
      <div className={`${style.titleWrapper} col-sm-12`}>
        <Logotype id={id} title="title" rootModule="card" />
      </div>
    </div>
  );
};

CardHeader.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CardHeader;
