import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { connect } from "react-redux";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import TopBar from "../../components/TopBar/TopBar";
import HowToWearIntro from "../../components/HowToWearIntro/HowToWearIntro";
import Tiles from "../../components/Tiles/Tiles";
import Steps from "../../components/Steps/Steps";
import Examples from "../../components/Examples/Examples";
import SiteFooter from "../../components/SiteFooter/SiteFooter";

const HowToWear = ({ updateRoot }) => {
  const { messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();

  useEffect(() => {
    updateRoot("howtowear");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  if (!languageData.centurion) {
    return null;
  }

  return (
    <Fragment>
      <div>
        <TopBar rootModule="howtowear" />
        <HowToWearIntro />
        <Tiles />
        <Steps />
        <Examples />
      </div>
      <SiteFooter />
    </Fragment>
  );
};

HowToWear.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(null, mapDispatchToProps)(HowToWear);
