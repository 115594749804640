// import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import { Helmet } from "react-helmet-async";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import CardIntro from "../../components/Cards/CardIntro";
import Cards from "../../components/Cards/Cards";
import Modal from "../../components/Modal/Modal";
import HomeLink from "../../components/HomeLink/HomeLink";
import Logotype from "../../components/Logotype/Logotype";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import offset from "../../services/elementOffset";
import style from "./Card.module.scss";

const Card = ({ updateRoot }) => {
  const intl = useIntl();
  const { handleRouteChange } = useOutletContext();
  const location = useLocation();
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState("image");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [winHeight, setWinHeight] = useState(0);

  const hashTimeoutId = useRef(-1);
  const hashLinkScroll = useCallback((hash) => {
    // const { hash } = location;
    // if (!hash) {
    //   return;
    // }
    hashTimeoutId.current = setTimeout(() => {
      const id = hash.replace("#", "");
      const filtered = ["classicBlack", "remKoolhaas", "kehindeWiley"];
      if (!filtered.includes(id)) {
        return;
      }
      const card = document.getElementById(id);
      if (!card) {
        return;
      }
      let p = 50; // mobile
      const ch = card.querySelectorAll('[class*="ImageContainer"]')[0]
        .offsetHeight;
      // Work out vertically centered position
      if (window.innerWidth >= 768) {
        p = winHeight / 2 - ch / 2 - 100;
      }
      if (window.innerWidth >= 1024) {
        p = winHeight / 2 - ch / 2 - 50;
      }
      // Add card/breakpoint specific tweaks
      if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
        p -= 48;
      }
      if (window.innerWidth >= 1024) {
        p -= window.innerHeight < 800 && id === "remKoolhaas" ? 64 : 32;
      }
      if (id === "classicBlack" && window.innerWidth < 768) {
        p = 18; // don't change
      }
      if (id === "remKoolhaas" && window.innerWidth < 768) {
        p = 34; // 90;
      }
      if (id === "kehindeWiley" && window.innerWidth < 768) {
        p = 64; // 70;
      }
      card.style.paddingTop = `${p}px`;
      card.style.marginTop = `${-p}px`;
      window.scrollTo({
        top: offset(card).top,
        left: 0,
        behavior: "smooth",
      });
    }, 750);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winHeight]);

  useEffect(() => {
    updateRoot("card");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  // Always force to scroll to hash link from the top...
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!window) {
      return;
    }
    const updateWinHeight = () => {
      if (window.innerHeight !== winHeight) {
        setWinHeight(window.innerHeight);
      }
    };
    const debFunc = debounce(() => {
      updateWinHeight();
    }, 25);
    updateWinHeight();
    window.addEventListener("orientationchange", debFunc);
    return () => {
      window.removeEventListener("orientationchange", debFunc);
    };
  }, [winHeight]);

  useEffect(() => {
    if (winHeight !== 0) {
      clearInterval(hashTimeoutId.current);
      hashLinkScroll(location.hash);
    }
    return () => clearInterval(hashTimeoutId.current);
  }, [hashLinkScroll, winHeight, location.pathname, location.hash]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: `card.title` })}</title>
      </Helmet>
      <div data-module="card" className={`${style.card} container`}>
        <section className={style.introWrapper}>
          <HomeLink rootModule="card" />
          <div className="row">
            <div className="col-sm-12 col-md-6 col-md-offset-3">
              <div className={`${style.titleWrapper}`}>
                <Logotype
                  id="newCenturionCard"
                  title="title"
                  rootModule="card"
                />
              </div>
            </div>
          </div>
          <CardIntro />
        </section>
        <Cards
          handleModalData={(data) => {
            setModalData(data);
          }}
          handleModalType={(type) => {
            setModalType(type);
          }}
          handleIsModalOpen={(isOpen) => {
            setIsModalOpen(isOpen);
          }}
        />
        <SiteFooter />
      </div>
      {isModalOpen && (
        <Modal
          data={modalData}
          type={modalType}
          handleIsModalOpen={(isOpen) => {
            setIsModalOpen(isOpen);
          }}
        />
      )}
    </>
  );
};

// Card.propTypes = {
//   location: PropTypes.object.isRequired,
// };

const mapDispatchToProps = (dispatch) => {
  return {
    updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
  };
};

export default connect(null, mapDispatchToProps)(Card);
