const wearableSlides = [
  {
    id: 1,
    imageUrl: "prada-01.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 2,
    imageUrl: "prada-02.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 3,
    imageUrl: "prada-03.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 4,
    imageUrl: "prada-04.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 5,
    imageUrl: "prada-05.png",
    hasMask: false,
    hasQuote: false,
  },
];

export default wearableSlides;
