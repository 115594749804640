import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import FocusTrap from "focus-trap-react";
// import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import style from "./Modal.module.scss";
// import { setIsModalOpen } from '../../features/modal/modalSlice';
import ModalImage from "./ModalImage";

const IMAGE = "image";

function getModalContentComponent(type) {
  const map = {
    [IMAGE]: ModalImage,
  };

  return map[type] || type;
}

const Modal = ({
  // data, dispatchSetIsModalOpen, intl, type
  data,
  intl,
  type,
  handleIsModalOpen,
}) => {
  const ref = useRef(null);

  function toggleScrollLock() {
    document.querySelector("html").classList.toggle("no-scroll");
  }
  useEffect(() => {
    toggleScrollLock();

    return () => {
      document.querySelector("html").classList.remove("no-scroll");
    };
  }, []);

  function closeModal() {
    // dispatchSetIsModalOpen(false);
    handleIsModalOpen(false);
    toggleScrollLock();
  }

  function handleCloseClick() {
    closeModal();
  }

  function handleModalClick(event) {
    if (ref.current.contains(event.target)) {
      return;
    }
    closeModal();
  }

  function handleModalKeyDown(event) {
    if (event.keyCode === 27) {
      closeModal();
    }
  }

  const ModalContentComponent = getModalContentComponent(type);

  return ReactDOM.createPortal(
    <FocusTrap>
      <div
        aria-modal="true"
        className={`position-fixed ${style.wrapper}`}
        role="dialog"
        tabIndex="-1"
        onClick={(event) => handleModalClick(event)}
        onKeyDown={(event) => handleModalKeyDown(event)}
      >
        <div
          ref={ref}
          className={`position-relative flex flex-column ${style.content}`}
          data-imagekey={data.imageKey}
        >
          <ModalContentComponent data={data} />
        </div>
        <button
          aria-label={intl.formatMessage({ id: "closeModal" })}
          className={`dls-black position-absolute transparent ${style.close}`}
          type="button"
          onClick={() => handleCloseClick()}
        >
          <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25.18 12L20 17.18 14.82 12 12 14.82 17.18 20 12 25.18 14.82 28 20 22.82 25.18 28 28 25.18 22.82 20 28 14.82 25.18 12zM20 0C8.94 0 0 8.94 0 20s8.94 20 20 20 20-8.94 20-20S31.06 0 20 0zm0 36c-8.82 0-16-7.18-16-16S11.18 4 20 4s16 7.18 16 16-7.18 16-16 16z"
              fill="currentColor"
              fillRule="nonzero"
            ></path>
          </svg>
        </button>
      </div>
    </FocusTrap>,
    document.body
  );
};

Modal.propTypes = {
  data: PropTypes.shape({}),
  // dispatchSetIsModalOpen: PropTypes.func.isRequired,
  handleIsModalOpen: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  data: null,
};

// const mapDispatchToProps = (dispatch) => ({
//   dispatchSetIsModalOpen: (value) => dispatch(setIsModalOpen(value)),
// });

// export default injectIntl(connect(null, mapDispatchToProps)(Modal));
export default injectIntl(Modal);
