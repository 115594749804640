import React, { lazy, Suspense } from "react";
import { Route, redirect } from "react-router-dom";
import { getConfigForEnv } from "./appConfig";
import Home from "./routes/Home/Home";
import Koolhaas from "./routes/Koolhaas/Koolhaas";
import Card from "./routes/Card/Card";
import Wearable from "./routes/Wearable/Wearable";
import IntroductionsLoader from "./routes/Introductions/IntroductionsLoader";
import PerformancesLoader from "./routes/Performances/PerformancesLoader";
import Art from "./routes/Art/Art";
import Wiley from "./routes/Wiley/Wiley";
import Video from "./routes/Video/Video";
import Viewmono from "./routes/Viewmono/Viewmono";
import Privacy from "./routes/Privacy/Privacy";
import Info from "./routes/Info/Info";
import Howtowear from "./routes/HowToWear/HowToWear";
import Finewine from "./routes/FineWine/FineWine";
import Magazine from "./routes/Magazine/Magazine";
import Calendar from "./routes/Calendar/Calendar";
import Archive from "./routes/Archive/Archive";
import Notfound from "./routes/NotFound/NotFound";
// import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import AppRoutes from "./components/AppRoutes/AppRoutes";

import { availableLocaleKeys } from "./locales";
import { availableLocaleKeys as archiveAvailableLocaleKeys } from "./locales-archive";

const Living = lazy(() => import("./routes/Living/Living"));
const Introductions = lazy(() => import("./routes/Introductions/Introductions"));
const Performances = lazy(() => import("./routes/Performances/Performances"));
const BenefitsLanding = lazy(() => import("./routes/Benefits/Landing"));
const Benefit = lazy(() => import("./routes/Benefits/Benefit"));

const basePath = ":locale/:card/:centurion";
const { features, env } = getConfigForEnv();
const isArchive = env === "archive";
const localeKeys = isArchive ? archiveAvailableLocaleKeys : availableLocaleKeys;

const loader = (feature) => ({ params }) => {
  const { locale, card, centurion } = params;
  const localeCased =
    `${locale.split('-')[0]}-${locale.split('-')[1].toUpperCase()}`;
  if (
    features[localeCased]?.hiddenSections?.[feature] ||
    !localeKeys.includes(localeCased)
  ) {
    return redirect(`/${locale}/${card}/${centurion}/404`);
  }
  return null;
};

export const routesConfig = [
  {
    id: 'home',
    path: "/",
    pattern: `${basePath}/`,
    feature: isArchive ? 'archive' : 'home',
    element: isArchive ? <Archive /> : <Home />,
    loader: loader(isArchive ? 'archive' : 'home'),
  },
  {
    id: "koolhaas",
    path: "rem-koolhaas",
    pattern: `${basePath}/:page`,
    feature: 'koolhaas',
    element: <Koolhaas />,
    loader: loader('koolhaas'),
  },
  {
    id: "wiley",
    path: "kehinde-wiley",
    pattern: `${basePath}/:page`,
    feature: 'wiley',
    element: <Wiley />,
    loader: loader('wiley'),
  },
  {
    id: "art",
    path: "art",
    pattern: `${basePath}/:page`,
    feature: 'art',
    element: <Art />,
    loader: loader('art'),
  },
  {
    id: "card",
    path: "card",
    pattern: `${basePath}/:page`,
    feature: 'card',
    element: <Card />,
    loader: loader('card'),
  },
  {
    id: "video",
    path: "video",
    pattern: `${basePath}/:page`,
    feature: 'video',
    element: <Video />,
    loader: loader('video'),
  },
  {
    id: "wearable",
    path: "wearable",
    pattern: `${basePath}/:page`,
    feature: 'wearable',
    element: <Wearable />,
    loader: loader('wearable'),
  },
  {
    id: "introductions",
    path: "living/introductions/:season/:id",
    pattern: `${basePath}/:page/:subPage/:pageId/:id`,
    feature: "living",
    element: <Introductions />,
    lazy: true,
    loader: IntroductionsLoader,
  },
  {
    id: "performances",
    path: "living/performances/:season/:id",
    pattern: `${basePath}/:page/:subPage/:pageId/:id`,
    feature: "living",
    element: <Performances />,
    lazy: true,
    loader: PerformancesLoader,
  },
  {
    id: "calendar",
    path: "living/calendar",
    pattern: `${basePath}/:page/:subPage`,
    feature: "calendar",
    element: <Calendar />,
    loader: loader('living'),
  },
  {
    id: "living",
    path: "living",
    pattern: `${basePath}/:page`,
    feature: "living",
    element: <Living />,
    loader: loader('living'),
    lazy: true,
  },
  {
    id: "viewmono",
    path: "monogramming-viewings/:subpage",
    pattern: `${basePath}/:page/:subPage`,
    feature: "viewmono",
    element: <Viewmono />,
    loader: loader('viewmono'),
  },
  {
    id: "viewmono-landing",
    path: "monogramming-viewings",
    pattern: `${basePath}/:page`,
    feature: "viewmono",
    element: <Viewmono />,
    loader: loader('viewmono'),
  },
  {
    id: "privacy",
    path: "privacy-policy",
    pattern: `${basePath}/:page`,
    feature: "privacy",
    element: <Privacy />,
    loader: loader('privacy'),
  },
  {
    id: "info",
    path: "faqs/:subpage",
    pattern: `${basePath}/:page/:subPage`,
    feature: "info",
    element: <Info />,
    loader: loader('info'),
  },
  {
    id: "info-landing",
    path: "faqs",
    pattern: `${basePath}/:page`,
    feature: "info",
    element: <Info />,
    loader: loader('info'),
  },
  {
    id: "howtowear",
    path: "howtowear",
    pattern: `${basePath}/:page`,
    feature: "howtowear",
    element: <Howtowear />,
    loader: loader('howtowear'),
  },
  {
    id: "benefits",
    path: "benefits/:id",
    pattern: `${basePath}/:page/:subPage`,
    feature: "benefits",
    element: <Benefit />,
    loader: loader('benefits'), // TODO: comment out check with redirect on benefits landing?
    lazy: true,
  },
  {
    id: "benefits-landing",
    path: "benefits",
    pattern: `${basePath}/:page`,
    feature: "benefits",
    element: <BenefitsLanding />,
    loader: loader('benefits'),
    lazy: true,
  },
  {
    id: "magazine",
    path: "centurionliving",
    pattern: `${basePath}/:page`,
    feature: "magazine",
    element: <Magazine />,
    loader: loader('magazine'),
  },
  {
    id: "finewine",
    path: "living/finewine",
    pattern: `${basePath}/:page/:subPage`,
    feature: "finewine",
    element: <Finewine />,
    loader: loader('finewine'),
  },
  {
    id: "archive",
    path: "archive",
    pattern: `${basePath}/:page`,
    feature: "archive",
    element: <Archive />,
    loader: loader('archive'),
  },
  {
    id: "notfound",
    path: "*",
    pattern: `${basePath}/:page`,
    element: <Notfound />,
  },
];

export const routes = (
  <Route path="/" element={<AppRoutes />}>
    {
      routesConfig.map((route) => {
        return (
          <Route
            key={route.id}
            path={`/:locale/:card/:centurion/${route.path === '/' ? '' : route.path}`}
            element={
              route.lazy ? (
                <Suspense fallback={<></>}>
                  {route.element}
                </Suspense>
              ) : (
                route.element
              )
            }
            loader={route?.loader || null}
            // errorElement={<ErrorBoundary />}
          />);
      })
    }
  </Route>
);
