import {
  availableLocaleKeys,
  liveLocaleKeys,
  features as localeFeatures,
} from "./locales";

import {
  liveLocaleKeys as archiveLiveLocaleKeys,
  features as archiveLocaleFeatures,
} from "./locales-archive";

// If there are feature flags that don't need a locale, merge them into features here
const features = localeFeatures;

const productionLocaleKeys = availableLocaleKeys.filter((loc) =>
  liveLocaleKeys.includes(loc)
);

const provideStateConfig = {
  env: {
    development: "dev",
    dev: "dev",
    uat: "uat",
    production: "prod",
    archive: "archive",
  },
  locales: {
    development: availableLocaleKeys,
    dev: availableLocaleKeys,
    uat: availableLocaleKeys,
    production: productionLocaleKeys,
    archive: archiveLiveLocaleKeys,
  },
  assets: {
    development: "https://d1kmaacr1eciff.cloudfront.net/",
    dev: "https://d1kmaacr1eciff.cloudfront.net/",
    uat: "https://d3oj15hhh2vwel.cloudfront.net/",
    production: "https://d27kagfz5r40ly.cloudfront.net/",
    archive: "https://d27kagfz5r40ly.cloudfront.net/",
  },
  features: {
    development: features,
    dev: features,
    uat: features,
    production: features,
    archive: archiveLocaleFeatures
  },
  trackingLink: {
    development: "https://www.americanexpress.com/qa/adobedtm-acq",
    dev: "https://www.americanexpress.com/qa/adobedtm-acq",
    uat: "https://www.americanexpress.com/qa/adobedtm-acq",
    production: "https://www.americanexpress.com/adobedtm-acq",
    archive: null,
  },
};

// returns the config object above, with only the values for the currently set environment
export const getConfigForEnv = () => {
  const env = process.env.REACT_APP_CONFIG_ENV || "development"; // this needs to be set (export REACT_APP_CONFIG_ENV=development|dev|uat|production)
  const config = {};
  for (const value in provideStateConfig) {
    config[value] = provideStateConfig[value][env]; // safe to access as we'll always have value and env
  }
  return config;
};
