import { createSlice } from "@reduxjs/toolkit";

const baseUrlSlice = createSlice({
  name: "baseUrl",
  initialState: {
    path: null, // `${locale}/${card}/${centurion}`
  },
  reducers: {
    setBasePath: (state, action) => {
      state.path = action.payload;
    },
  },
});

export const { setBasePath } = baseUrlSlice.actions;
export default baseUrlSlice.reducer;