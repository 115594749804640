import React from "react";
import { FormattedMessage } from "react-intl";
import sharedStyle from "../HowToWearIntro/SketchContainer.module.scss";
import style from "./Examples.module.scss";

const Examples = () => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const ExampleLeftImg = `${assetPath}/howtowear/exampleleft.png`;
  const ExampleRightImg = `${assetPath}/howtowear/exampleright.png`;

  const Example = ({ side }) => {
    const imgs = {
      LEFT: ExampleLeftImg,
      RIGHT: ExampleRightImg,
    };

    return (
      <div className={style.example}>
        <h2 className={`bigcaslonitalic ${style.title}`}>
          <FormattedMessage id={`howtowear.exampleTitle${side}`} />
        </h2>

        <img className={style.image} src={imgs[side.toUpperCase()]} alt="" />
      </div>
    );
  };

  return (
    <div className={`${sharedStyle.sketchContainer} ${style.examples}`}>
      <Example side="Left" />
      <Example side="Right" />
    </div>
  );
};

export default Examples;
