import React from 'react';
import PropTypes from 'prop-types';
import style from './ImageContainer.module.scss';

const ImageContainer = ({
  alt, children, imageStyle, ratio, src, round, maxWidth, type
}) => {
  const ratioStyle = { paddingBottom: `${100 / ratio}%` };

  // console.log(alt);

  return (
    <div>
      <div aria-label={alt} style={{ maxWidth }} className={`${style.imageContainer} no-scroll position-relative valign-middle`} role="img">
        <div data-test="ratio" style={ratioStyle} />
        <div
          className={`position-absolute ${style.image} ${round ? style.round : undefined}`}
          style={{ ...imageStyle, backgroundImage: `url('${src}')` }}
          data-type={type ? type : ''}
        />
      </div>
      {children}
    </div>
  );
};

ImageContainer.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  imageStyle: PropTypes.shape({
    mixBlendMode: PropTypes.string,
  }),
  ratio: PropTypes.number,
  src: PropTypes.string.isRequired,
  round: PropTypes.bool,
};

ImageContainer.defaultProps = {
  alt: '',
  children: null,
  imageStyle: {},
  ratio: 1,
  round: false,
  maxWidth: 'none',
  type: null,
};

export default ImageContainer;
