import { useParams, Link } from "react-router-dom";
import { useIntl } from "react-intl";

import style from "../Calendar.module.scss";

import Overlay from "../../../components/Overlay/Overlay";

export default function CalendarItem({
  year,
  name,
  path,
  section,
  city,
  image,
  past = false,
}) {
  const { locale, card, centurion } = useParams();

  const { messages: languageData } = useIntl();

  return (
    <li>
      <Link
        to={`/${locale}/${card}/${centurion}/living/${section}/${year}/${path}`}
        className={style.calendarItem}
      >
        <div className={style.imageWrapper}>
          <img alt={name} src={image} className={style.itemImage} />

          {past && <Overlay />}
        </div>

        <div className={style.itemDetail}>
          <p className={`bigcaslonitalic ${style.detailSection}`}>
            {languageData[`general.${section}`]}
          </p>

          <p className={`bentonsanslight ${style.detailName}`}>{name}</p>

          <address className={`bentonsanslight ${style.detailCity}`}>
            {city}
          </address>
        </div>
      </Link>
    </li>
  );
}
