import { benefitsMenuItems } from "./benefits";

export const menuItems = ({ basePath, features, localeName }) => {
  const hasLiving = !features?.hiddenSections?.living;
  const hasBenefits = !features?.hiddenSections?.benefits;

  return [
    {
      title: "cardTitle",
      id: "menu_card",
      to: `/${basePath}/card`,
      hidden: false,
      children: [
        {
          title: "cardIntroduction",
          to: {
            pathname: `/${basePath}/card`,
            hash: "#introduction", // should be ignored but forces scroll update
          },
        },
        {
          title: "cardBlack",
          to: {
            pathname: `/${basePath}/card`,
            hash: "#classicBlack",
          },
        },
        {
          title: "cardKoolhaas",
          to: {
            pathname: `/${basePath}/card`,
            hash: "#remKoolhaas",
          },
        },
        {
          title: "cardWiley",
          to: {
            pathname: `/${basePath}/card`,
            hash: "#kehindeWiley",
          },
        },
      ],
    },
    {
      title: "artTitle",
      id: "menu_art",
      to: `/${basePath}/art`,
      hidden: false,
      children: [
        {
          title: "artIntroduction",
          to: {
            pathname: `/${basePath}/art`,
            hash: "#introduction", // should be ignored but forces scroll update
          },
        },
        {
          title: "artRemProfile",
          to: {
            pathname: `/${basePath}/rem-koolhaas`,
          },
        },
        {
          title: "artKehindeProfile",
          to: {
            pathname: `/${basePath}/kehinde-wiley`,
          },
        },
      ],
    },
    {
      title: "pradaTitle",
      to: `/${basePath}/wearable`, // isProd ? null : `/${basePath}/wearable`,
      hash: "#",
      id: "menu_wearable",
      hidden: features?.hiddenSections?.wearable || false,
      children: [
        {
          title: "pradaWearable",
          to: `/${basePath}/wearable`, // isProd ? null : `/${basePath}/wearable`,
        },
      ],
    },
    hasBenefits
      ? {
          title: "benefitsTitle",
          id: "centurionBenefits",
          to: `/${basePath}/benefits`,
          hidden: false,
          children: benefitsMenuItems({ basePath, localeName }),
        }
      : hasLiving
      ? {
          title: "livingTitle",
          id: "menu_living",
          to: `/${basePath}/living`,
          hidden: false,
          children: [
            {
              title: "livingIntro",
              to: {
                pathname: `/${basePath}/living`,
                hash: "#introduction", // should be ignored but forces scroll update
              },
            },
            {
              title: "livingExperiences",
              to: {
                pathname: `/${basePath}/living`,
                hash: "#performancesAndExperiences",
              },
              // "secondary": "menuLivingExperiencesSecondary"
            },
            {
              title: "livingIntroductions",
              to: {
                pathname: `/${basePath}/living`,
                hash: "#introductions",
              },
              // "secondary": "menuLivingIntroductionsSecondary"
            },
            {
              title: "livingCalendar",
              to: {
                pathname: `/${basePath}/living/calendar`,
              },
            },
          ],
        }
      : false,
  ];
};
