import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTransition, animated } from '@react-spring/web';
import MenuToggle from "../Menu/MenuToggle";
import Menu from "../Menu/Menu";
import style from "./Header.module.scss";

const Header = ({
  isMenuVisible,
  isMenuOpen,
  handleMenuToggle,
  location,
  basePath,
  hasTouch,
  isPopupOpened,
  isProd,
}) => {
  const transition = useTransition(isMenuOpen ? ["one"] : [], {
    from: { height: "0%" },
    enter: { height: "100%" },
    leave: { height: "0%" },
  });

  const [hasSmallHeight, setHasSmallHeight] = useState(false);
  const [clipBackground, setClipBackground] = useState(false);

  useEffect(() => {
    const handleMenuLayout = () => {
      const w = window.innerWidth;
      const h = window.innerHeight;
      setHasSmallHeight(h < 500 && w > h);
      setClipBackground(h >= 650); //  setClipBackground((h >= 850) && (w/h <= 2));
    };

    window.addEventListener("resize", handleMenuLayout);

    handleMenuLayout();

    return () => {
      window.removeEventListener("resize", handleMenuLayout);
    };
  }, []);

  return (
    <div className="relative-position">
      <div
        id="header"
        className={`${style.siteHeader} ${
          isPopupOpened ? style.isPopupOpened : ""
        }`}
        data-location={location.pathname}
      >
        <div
          className={`flex flex-align-center ${
            location.pathname.includes("living")
              ? "flex-justify-between"
              : "flex-justify-end"
          }`}
        >
          <div className="flex flex-align-center">
            <MenuToggle
              isMenuVisible={isMenuVisible}
              isMenuOpen={isMenuOpen}
              onMenuToggle={handleMenuToggle}
              location={location}
            />
          </div>
        </div>
        { transition((styles) => (
          <animated.div
              style={styles}
              className={`position-fixed no-scroll ${style.menuWrapper}`}
            >
              <Menu
                isMenuOpen={isMenuOpen}
                hasTouch={hasTouch}
                handleMenuToggle={handleMenuToggle}
                hasSmallHeight={hasSmallHeight}
                clipBackground={clipBackground}
                basePath={basePath}
                isProd={isProd}
              />
            </animated.div>
          ))
        }
      </div>
    </div>
  );
};

Header.propTypes = {
  isMenuVisible: PropTypes.bool.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  handleMenuToggle: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  basePath: PropTypes.string,
  hasTouch: PropTypes.bool.isRequired,
  isProd: PropTypes.bool.isRequired,
};

export default Header;
