import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import style from "./ArtistLink.module.scss";

const ArtistLink = ({ imageUrl, text, to }) => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const imageSrc = `${assetPath}/art/${imageUrl}`;
  return (
    <Link className={`display-block dls-black ${style.artistLink}`} to={to}>
      <div className={`${style.imageWrapper}`}>
        <img alt="" src={imageSrc} />
      </div>
      <p
        className={`bentonsansthin text-align-center ${style.artistLinkText} ${style.content}`}
      >
        {text}
      </p>
    </Link>
  );
};

ArtistLink.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  to: PropTypes.string.isRequired,
};

export default ArtistLink;
