import {
  configureStore,
  // getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux-immutable';

import basePathReducer from './features/baseUrl/baseUrlSlice';
import modalReducer from "./features/modal/modalSlice";
import configReducer from './features/config/configSlice';
import intlReducer from './features/intl/intl';
import rootModuleReducer from './features/rootModule/rootModuleSlice';
import browserReducer from './features/browser/browserSlice';
import menuReducer from './features/menu/menuSlice';
import popupReducer from "./features/popup/popupSlice";

const rootReducer = combineReducers({
  basePath: basePathReducer,
  modal: modalReducer,
  config: configReducer,
  intl: intlReducer,
  rootModule: rootModuleReducer,
  browser: browserReducer,
  menu: menuReducer,
  popup: popupReducer,
});

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});
