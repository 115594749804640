import React, { useRef, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import style from "./BespokeAudio.module.scss";
import AudioPlaylist from "../AudioPlaylist/AudioPlaylist";
import { useMeasure } from "react-use";
import { animated, useSpring } from '@react-spring/web';

const BespokeAudio = ({ tracks, rootModule, cdnUrl }) => {
  const intl = useIntl();

  const audioElement = useRef(null);
  const volumeRef = useRef(null);
  const [isIos, setIsIos] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [savedVolume, setSavedVolume] = useState(0);
  const [currentAudioSrcIdx, setCurrentAudioSrcIdx] = useState(0);
  const [currentAudioSrc, setAudioSrc] = useState(null);
  const [useAnim, setUseAnim] = useState(0);
  const [resetTrackAnim, setResetTrackAnim] = useState(false);

  useEffect(() => {
    const src = tracks[currentAudioSrcIdx].src;
    setAudioSrc(`${cdnUrl}static/${src}`);
  }, [currentAudioSrcIdx, cdnUrl, tracks]);

  const playText = intl.formatMessage({ id: `${rootModule}.audioStatePlay` });
  const playingText = intl.formatMessage({
    id: `${rootModule}.audioStatePlaying`,
  });
  const audioTitleText = intl.formatMessage({
    id: `${rootModule}.${tracks[currentAudioSrcIdx].title}`,
  });

  const maxTitleWidth = 258; // Max width for the container of the title
  const scrollWidthMargin = 8; // Margin on scroll so that looping looks smooth

  const setAnimationFlagAndWidth = (width) => {
    setTitleWidth(width);
    // Only set the scrolling animation if the title is larger than the container
    if (width + scrollWidthMargin > maxTitleWidth) setUseAnim(1);
  };

  const [measureRef, { width }] = useMeasure();
  const [titleWidth, setTitleWidth] = useState(0);

  useEffect(() => {
    setAnimationFlagAndWidth(width);
    const onResize = () => {
      setAnimationFlagAndWidth(width);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [width]);

  /* End state = -1 * (container width) + (amount title is larger than container) + (play text width) + (margin)
   ** End state is either 0 if no animation OR
   ** some negative value i.e -275px to scroll to
   */
  const formatScrollAnimationEndState = useCallback(
    (ptW, tW) =>
      `-${
        useAnim *
        (maxTitleWidth + (tW - maxTitleWidth) + ptW + scrollWidthMargin)
      }`,
    [useAnim]
  );

  const [playTextWidth, setPlayTextWidth] = useState(0);
  useEffect(() => {
    const setScrollAnimationState = (w) => {
      setPlayTextWidth(w);
      setScrollEndState(formatScrollAnimationEndState(w, titleWidth));
      setResetTrackAnim((state) => !state);
    };
    const playTextWidth = isPlaying ? 42 : 26;
    setScrollAnimationState(playTextWidth);
  }, [isPlaying, titleWidth, formatScrollAnimationEndState]);

  const { x } = useSpring({
    from: { x: 0 },
    x: useAnim ? 1 : 0,
    onRest: () => setResetTrackAnim((state) => !state), // reset to start when animation finishes
    reset: resetTrackAnim,
    immediate: !useAnim, // if no animation needed, make sure it does not animate
    config: { duration: 10000 },
  });

  const scrollStartState = useAnim * 258;
  const [scollEndState, setScrollEndState] = useState(
    formatScrollAnimationEndState(playTextWidth, titleWidth)
  );

  const secondsToDuration = (timeInSeconds) => {
    const pad = (num, size) => {
        return ("000" + num).slice(size * -1);
      },
      time = parseFloat(timeInSeconds).toFixed(3),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.ceil(time - minutes * 60);

    return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
  };

  const handlePlay = () => {
    const audioEl = audioElement.current;
    if (audioEl.paused) {
      audioEl.play();
    }
    return;
  };

  const handlePause = () => {
    const audioEl = audioElement.current;
    if (!audioEl.paused) {
      audioEl.pause();
    }
    return;
  };

  const handleSkipForward = () => {
    const audioEl = audioElement.current;
    audioEl.currentTime += 10;
  };

  const handleSkipBack = () => {
    const audioEl = audioElement.current;
    audioEl.currentTime -= 10;
  };

  const timelineCurrentWidth = () => {
    if (duration > 0) {
      return `${(currentTime / duration) * 100}%`;
    }

    return "0%";
  };

  const handleVolumeChange = (newVolume) => {
    // save it for unmute later
    setSavedVolume(volume);
    // set volume in state (for slider)
    setVolume(newVolume);

    // Set the new volume on the audio
    const audioEl = audioElement.current;
    audioEl.volume = newVolume;
    // Set the new volume on the volume slider
    const volumeEl = volumeRef.current;
    volumeEl.style.setProperty("--value", newVolume);
  };

  const setMediaVolume = (event) => {
    const newVolume = event.target.value;
    handleVolumeChange(newVolume);
  };

  const toggleMute = () => {
    // Mute if currently unmuted, or revert to previous volume
    const newVolume = volume > 0 ? 0 : savedVolume;
    handleVolumeChange(newVolume);
  };

  const setCurrentTrack = (i) => {
    setCurrentAudioSrcIdx(i);

    const audioEl = audioElement.current;
    audioEl.currentTime = 0;
    audioEl.pause();
  };

  const detectIos = () => {
    const { navigator } = window;

    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  // Controls the volume sliders progress line (black part)
  useEffect(() => {
    let e = volumeRef.current;
    e.style.setProperty("--value", e.value);
    e.style.setProperty("--min", e.min === "" ? "0" : e.min);
    e.style.setProperty("--max", e.max === "" ? "100" : e.max);
    e.addEventListener("input", () => e.style.setProperty("--value", e.value));

    setIsIos(detectIos());
  }, []);

  return (
    <div className={`${style.audioWrapper} flex flex-justify-center`}>
      <figure className={`${style.audioFigure} flex`}>
        <div className={`${style.audioElement} flex flex-align-center`}>
          <audio
            className="display-none"
            ref={audioElement}
            controls
            src={currentAudioSrc}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
            onLoadedMetadata={(e) => setDuration(e.target.duration)}
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          <button
            onClick={handleSkipBack}
            className={`dls-black transparent ${style.skipButton}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g fill="none" fillRule="evenodd">
                <g stroke="#020203">
                  <path d="M3.70925217 4.07092174C5.79829565 1.8890087 8.74090435.5304 11.999687.5304c6.3401739 0 11.4782608 5.13913043 11.4782608 11.4782609 0 6.3401739-5.1380869 11.4782608-11.4782608 11.4782608-6.33913048 0-11.47826091-5.1380869-11.47826091-11.4782608" />
                  <path d="M3.7250087.00907826L3.65196522 4.1829913l4.17391304.07304348" />
                </g>
                <text className={`${style.skipText} bentonsans`}>
                  <tspan x="5.412" y="16">
                    10
                  </tspan>
                </text>
              </g>
            </svg>
          </button>
          {isPlaying ? (
            <button
              id="pButton"
              className={`dls-black transparent btn-block ${style.playPause} ${style.pause}`}
              onClick={handlePause}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g fill="#000" fillRule="evenodd" transform="translate(11 5)">
                  <rect width="2" height="22" rx="1" />
                  <rect width="2" height="22" x="8" rx="1" />
                </g>
              </svg>
            </button>
          ) : (
            <button
              id="pButton"
              className={`dls-black transparent btn-block ${style.playPause} ${style.play}`}
              onClick={handlePlay}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path
                  fill="#000"
                  fillRule="nonzero"
                  d="M8 6.66609326c0-1.57772882 1.74230083-2.53388394 3.073751-1.68684l14.6666667 9.33066184c1.2349986.7856833 1.2349986 2.5879967 0 3.37368L11.073751 27.0142568C9.74230083 27.8613008 8 26.9051457 8 25.3274168V6.66609326zm2 0V25.3274168l14.6666667-9.3306617L10 6.66609326z"
                />
              </svg>
            </button>
          )}
          <button
            onClick={handleSkipForward}
            className={`dls-black transparent ${style.skipButton}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <defs />
              <g fill="none" fillRule="evenodd">
                <g stroke="#020203">
                  <path d="M20.29074783 4.07092174C18.20170435 1.8890087 15.25909565.5304 12.000313.5304 5.6601391.5304.5220522 5.66953043.5220522 12.0086609c0 6.3401739 5.1380869 11.4782608 11.4782608 11.4782608 6.33913048 0 11.47826091-5.1380869 11.47826091-11.4782608" />
                  <path d="M20.2749913.00907826l.07304348 4.17391304-4.17391304.07304348" />
                </g>
                <text className={`${style.skipText} bentonsans`}>
                  <tspan x="5.412" y="16">
                    10
                  </tspan>
                </text>
              </g>
            </svg>
          </button>
        </div>
        <figcaption className={`${style.audioCaption} flex flex-column`}>
          <div className={style.scrollTextContainer}>
            <animated.div
              style={{
                transform: x
                  .to({
                    range: [0, 1],
                    output: [scrollStartState, scollEndState],
                  })
                  .to((x) => `translate3d(${x}px, 0, 0)`),
              }}
              className={`${style.title}`}
            >
              <span className="bentonsanslight">
                {isPlaying ? playingText : playText}
              </span>
              <div ref={measureRef} className={`bentonsansthin`}>
                &nbsp;{audioTitleText}
              </div>
            </animated.div>
          </div>
          <div className={`${style.timeline}`}>
            <div
              className={`${style.timelineCurrentTime}`}
              style={{ width: timelineCurrentWidth() }}
            />
          </div>
        </figcaption>
        <div className={`${style.volumeAndTime} flex flex-column`}>
          <div className={`${style.duration} bentonsansthin`}>
            {secondsToDuration(currentTime)}
          </div>

          <div className={`${style.volumeControls} ${isIos ? style.ios : ""}`}>
            <button
              className={`dls-black transparent ${style.volumeButton}`}
              onClick={toggleMute}
            >
              {volume > 0 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    fill="#000"
                    fillRule="nonzero"
                    d="M16.368487 17.1769561c-.2407842.3451842-.7112168.4265629-1.0507405.1817645-.3395238-.2447985-.419568-.723074-.1787838-1.0682582.8740827-1.2530705 1.3537091-2.7356912 1.3537091-4.2793918 0-1.5644858-.4773544-3.05398705-1.3522602-4.29946524-.2419086-.34437067-.1634246-.82291335.175299-1.068855.3387235-.24594165.8094189-.16614917 1.0513275.1782215C17.4230334 8.32424128 18 10.1245653 18 12.0110706c0 1.8664594-.5794564 3.6576746-1.631513 5.1658855zm3.464631 2.5369541c-.252824.3232342-.7210082.3812447-1.0457186.1295702-.3247104-.2516746-.3829859-.7177302-.1301619-1.0409644 1.5162363-1.9385006 2.352481-4.3195049 2.352481-6.8292722 0-2.45903388-.842438-4.82237499-2.3550708-6.77908724-.2509762-.32465796-.1900434-.7903767.1360973-1.04021191.3261407-.24983521.7939865-.18917939 1.0449627.13547857C21.5448449 6.50032983 22.5 9.17988265 22.5 11.9732438c0 2.8428731-.9486443 5.543908-2.666882 7.7406664zM6.72255694 7.87210637l5.03934956-4.19532955C12.2484115 3.27175456 13 3.60750371 13 4.22985717V19.7701204c0 .6306708-.7690816.963349-1.2517235.5414525L6.962198 16.1278712H1.75c-.41421356 0-.75-.3261381-.75-.7284498V8.60055621c0-.40231174.33578644-.72844984.75-.72844984h4.97255694z"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    fill="#000"
                    fillRule="nonzero"
                    d="M8.19935526 6.64265095l3.56255124-2.96587413C12.2484115 3.27175456 13 3.60750371 13 4.22985717v7.21343853l3.0610728 3.0610728c.2828282-.7927512.431599-1.6335516.431599-2.4932979 0-1.5644858-.4773544-3.05398705-1.3522602-4.29946524-.2419086-.34437067-.1634246-.82291335.175299-1.068855.3387235-.24594165.8094189-.16614917 1.0513275.1782215C17.4230334 8.32424128 18 10.1245653 18 12.0110706c0 1.2754277-.2705798 2.5157198-.7776428 3.6545823l2.134302 2.134302c1.0718614-1.7315558 1.6530593-3.7343877 1.6530593-5.8267111 0-2.45903388-.842438-4.82237499-2.3550708-6.77908724-.2509762-.32465796-.1900434-.7903767.1360973-1.04021191.3261407-.24983521.7939865-.18917939 1.0449627.13547857C21.5448449 6.50032983 22.5 9.17988265 22.5 11.9732438c0 2.4917411-.7287764 4.8745181-2.0657666 6.9042853l1.8911307 1.8911307c.2928932.2928932.2928932.7677669 0 1.0606602-.2928932.2928932-.767767.2928932-1.0606602 0L2.52637422 3.09099026c-.29289322-.29289322-.29289322-.76776696 0-1.06066017.29289322-.29289322.76776696-.29289322 1.06066017 0l4.61232087 4.61232086zM13 15.6859364v4.084184c0 .6306708-.7690816.963349-1.2517235.5414525L6.962198 16.1278712H1.75c-.41421356 0-.75-.3261381-.75-.7284498V8.60055621c0-.40231174.33578644-.72844984.75-.72844984h3.43616999L13 15.6859364z"
                  />
                </svg>
              )}
            </button>
            <input
              onChange={setMediaVolume}
              type="range"
              min="0"
              max="1"
              step="0.05"
              value={volume}
              ref={volumeRef}
              className="styled-slider slider-progress"
            />
          </div>
        </div>
      </figure>
      {tracks.length > 1 && (
        <AudioPlaylist
          setCurrentTrack={setCurrentTrack}
          currentTrackIdx={currentAudioSrcIdx}
          tracks={tracks}
        />
      )}
    </div>
  );
};

BespokeAudio.propTypes = {
  tracks: PropTypes.array,
  rootModule: PropTypes.string.isRequired,
  cdnUrl: PropTypes.string.isRequired,
};

BespokeAudio.defaultProps = {
  tracks: [],
  rootModule: "",
};

const mapStateToProps = (state) => ({
  cdnUrl: state.get("config")["assets"],
});

export default connect(mapStateToProps)(BespokeAudio);
