import React from "react";
import PropTypes from "prop-types";
import style from "./Hero.module.scss";
// import HeroVideo from "../HeroVideo/HeroVideo";
import Logotype from "../Logotype/Logotype";
import HomeLink from "../HomeLink/HomeLink";

const Hero = ({ showVideo }) => (
  <div>
    <div className={`position-relative ${style.wrapper}`}>
      <HomeLink rootModule="home" />
      <div className="row">
        <div className="col-sm-12 col-md-6 col-md-offset-3">
          <div className={`${style.titleWrapper}`}>
            <Logotype id="newCenturion" title="heroTitle" rootModule="home" />
          </div>
        </div>
      </div>
    </div>
    {/* {showVideo && <HeroVideo rootModule="home" />} */}
  </div>
);

Hero.propTypes = {
  showVideo: PropTypes.bool.isRequired,
};

export default Hero;
