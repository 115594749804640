import React from "react";

import { FormattedMessage, useIntl } from "react-intl";
import sharedStyle from "./SketchContainer.module.scss";
import style from "./HowToWearIntro.module.scss";

const HowToWearIntro = () => {
  const intl = useIntl();
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const IntroWearableImg = `${assetPath}/howtowear/introwearable.png`;
  const bulletIds = [1, 2, 3, 4, 5, 6];

  const IntroBullet = ({ id }) => {
    const msg = `howtowear.introBullet${id}`;
    if (!intl.messages[msg]) {
      return null;
    }
    return (
      <li key={id} className={`bentonsansthin ${style.bullet}`}>
        <FormattedMessage id={msg} />
      </li>
    );
  };

  const IntroBullets = () => (
    <ul className={`${style.bullets}`}>
      {bulletIds.map((id) => (
        <IntroBullet key={id} id={id} />
      ))}
    </ul>
  );

  return (
    <div className={`${sharedStyle.sketchContainer} ${style.intro}`}>
      <div className="row">
        <div className="col-md-5 col-md-offset-1 col-lg-4 col-lg-offset-2">
          <img className={style.image} src={IntroWearableImg} alt="" />
        </div>

        <div className="col-md-5 col-md-offset-1 col-lg-offset-1">
          <h2 className={`bigcaslonitalic ${style.title}`}>
            <FormattedMessage id="howtowear.introTitle" />
          </h2>
          <IntroBullets />
        </div>
      </div>
    </div>
  );
};

export default HowToWearIntro;
