// const isProd = process.env.REACT_APP_CONFIG_ENV === "production";

export const availableLocales = {
  "en-GB": "English (United Kingdom)", // Fallback locale, DO NOT REMOVE (or update all fallbacks)
  "en-US": "English (United States)",
  "en-AU": "English (Australia)",
  "de-DE": "German (Deutschland)",
  "en-FI": "English (Finland)",
  "en-NO": "English (Norway)",
  "en-SE": "English (Sweden)",
  "en-CH": "English (Switzerland)",
  "en-NL": "English (Netherlands)",
  "en-HK": "English (Hong Kong)",
  "zh-HK": "Cantonese (Hong Kong)",
  "it-IT": "Italian (Italy)",
  "en-BH": "English (Bahrain)",
  "en-GL": "English (Global)", // really Saudi Arabia
  "fr-FR": "French (France)",
  "en-BE": "English (Belgium)",
  "es-ES": "Spanish (Spain)",
  "zh-TW": "Cantonese (Taiwan)",
  "en-SG": "English (Singapore)",
  "ja-JP": "Japanese (Japan)",
  "en-IN": "English (India)",
  "de-AT": "German (Austria)",
  "en-ID": "English (IDC)",
  "es-ID": "Spanish (IDC)",
};

export const availableLocaleKeys = Object.keys(availableLocales);

export const liveLocaleKeys = [
  // Edit this to enable/disable specific locales in production
  "de-DE",
  "en-BE",
  "en-BH",
  "en-CH",
  "en-FI",
  "en-GB",
  "en-GL",
  "en-HK",
  "en-NL",
  "en-NO",
  "en-SE",
  "en-US",
  "fr-FR",
  "it-IT",
  "zh-HK",
  "zh-TW",
  "es-ES",
  "en-AU",
  "en-SG",
  "de-AT",
  "ja-JP",
  "en-ID",
  "es-ID",
  "en-IN",
];

/* Static feature flags set by locale
Will be merged with general flags in the appConfig.js (if any)
Will return for the current locale by the mapStateToProps
In components use: features.getIn(['hiddenSections', 'wearable'])
Locale-specific 'season' config is in ./static/living/seasons.js
*/
export const features = {
  "de-DE": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      // magazine: true,
      video: true,
      wearable: true,
      howtowear: true,
      archive: true,
    },
  },
  "en-AU": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      living: true,
      magazine: true,
      finewine: true,
      video: true,
      archive: true,
    },
  },
  "en-BE": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      video: true,
      wearable: true,
      howtowear: true,
      archive: true,
    },
  },
  "en-BH": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      video: true,
      archive: true,
    },
  },
  "en-CH": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      wearable: true,
      info: true,
      viewmono: true,
      benefits: true,
      magazine: true,
      video: true,
      archive: true,
    },
  },
  "en-FI": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      video: true,
      wearable: true,
      howtowear: true,
      archive: true,
    },
  },
  "en-GB": {
    hiddenSections: {
      heroVideo: true,
      living: true,
      magazine: true,
      // home: isProd,
      // art: isProd,
      // card: isProd,
      // wearable: isProd,
      // benefits: isProd,
      // monogramming: isProd,
      // koolhaas: isProd,
      // wiley: isProd,
      finewine: true,
      video: true,
      archive: true,
    },
  },
  "en-GL": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      finewine: true,
      video: true,
      archive: true,
    },
  },
  "en-HK": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      video: true,
      wearable: true,
      howtowear: true,
      archive: true,
    },
  },
  "en-NL": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      video: true,
      // magazine: true, // TEMP
      wearable: true,
      howtowear: true,
      archive: true,
    },
  },
  "en-NO": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      video: true,
      wearable: true,
      howtowear: true,
      archive: true,
    },
  },
  "en-SE": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      video: true,
      wearable: true,
      howtowear: true,
      archive: true,
    },
  },
  "en-US": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      wearable: true,
      benefits: true,
      magazine: true,
      finewine: true,
      archive: true,
    },
  },
  "es-ES": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      living: true,
      magazine: true,
      finewine: true,
      video: true,
      archive: true,
    },
  },
  "fr-FR": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      video: true,
      wearable: true,
      howtowear: true,
      archive: true,
    },
  },
  "it-IT": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      video: true,
      wearable: true,
      howtowear: true,
      archive: true,
    },
  },
  "zh-HK": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      video: true,
      wearable: true,
      howtowear: true,
      archive: true,
    },
  },
  "zh-TW": {
    hiddenSections: {
      privacy: true,
      living: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      finewine: true,
      video: true,
      archive: true,
    },
  },
  "en-SG": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      // living: true,
      benefits: true,
      magazine: true,
      video: true,
      archive: true,
    },
  },
  "ja-JP": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      // living: true,
      benefits: true,
      magazine: true,
      video: true,
      archive: true,
    },
  },
  "en-IN": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      living: true,
      magazine: true,
      finewine: true,
      video: true,
      archive: true,
      wearable: true,
      howtowear: true,
    },
  },
  "de-AT": {
    hiddenSections: {
      privacy: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      video: true,
      archive: true,
    },
  },
  "en-ID": {
    hiddenSections: {
      privacy: true,
      // living: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      finewine: true,
      video: true,
      archive: true,
    },
  },
  "es-ID": {
    hiddenSections: {
      privacy: true,
      // living: true,
      heroVideo: true,
      benefits: true,
      magazine: true,
      finewine: true,
      video: true,
      archive: true,
    },
  },
};

export default availableLocales;
