import React, { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import style from "./CardCarouselSlide.module.scss";

const CardCarouselSlide = ({ slide }) => {
  const intl = useIntl();

  const uuid = () => {
    return Math.floor(Date.now() * Math.random()).toString(32);
  };

  const formatLink = (linktext) => {
    return linktext.indexOf("http") === 0 ? linktext : `http://${linktext}`;
  };

  const mailtoLink = (email) => {
    return (email = `mailto:${email}`);
  };

  const formatTel = (str) => {
    if (!str[0]) {
      return "";
    }
    let s = str[0].toString();
    let n = s.replace(/\s+/g, "");
    n = n.replace(/-/g, "");
    return n;
  };

  const formatHTMLMessage = {
    h4: (...str) => <h4>{str}</h4>,
    p: (...str) => <p>{str}</p>,
    b: (...str) => <strong className="bentonsanslight">{str}</strong>,
    bold: (...str) => <strong className="bentonsanslight">{str}</strong>,
    italic: (...str) => <em>{str}</em>,
    subheading: (...str) => <span>{str}</span>,
    link: (...str) => (
      <a
        href={formatLink(str)}
        target="_blank"
        rel="noopener noreferrer"
        className="bentonsanslight"
      >
        {str}
      </a>
    ),
    usviewingslink: (...str) => (
      <a
        href="http://centurion.com/viewings"
        target="_blank"
        rel="noopener noreferrer"
        className="bentonsanslight"
      >
        {str}
      </a>
    ),
    pradaLink: (...str) => (
      <a
        href="https://www.prada.com"
        target="_blank"
        rel="noopener noreferrer"
        className="bentonsanslight"
      >
        {str}
      </a>
    ),
    tel: (str) => (
      <a
        href={`tel:${formatTel(str)}`}
        rel="noopener noreferrer"
        key={uuid()}
        className="bentonsanslight"
      >
        {str}
      </a>
    ),
    mailto: (str) => (
      <a
        href={mailtoLink(str)}
        target="_blank"
        rel="noopener noreferrer"
        className="bentonsanslight"
      >
        {str}
      </a>
    ),
    lineBreak: (
      <span>
        <br />
      </span>
    ),
    // The above line break would not work for me
    // regardless how I attempted to impliment it,
    // I had to add an anonymous function to get
    // it to work (did not want to break the above)
    lineBreakFn: () => (
      <span>
        <br />
      </span>
    ),
  };

  const textClassName = (slide) => {
    // if (typeof slide.title == 'undefined') {
    //   return `${style.slideText} ${style.slidePadding} bentonsansthin`
    // }
    // return `${style.slideText} bentonsansthin`
    if (typeof slide.title === "undefined") {
      return `${style.slideText} ${style.slidePadding} ${
        slide.subtitleAsTitle ? style.subtitleAsTitle : ""
      } bentonsansthin`;
    }
    return `${style.slideText} bentonsansthin`;
  };

  return (
    <Fragment>
      {slide && (
        <div
          className={`${style.slide} ${
            slide.subtitleAsTitle ? style.subtitleAsTitle : ""
          }`}
          data-slide-type="card"
          data-slide-id={slide.id}
          role="group"
          aira-roledescription="slide"
          aria-label={
            slide.title ? intl.formatMessage({ id: slide.title }) : ""
          }
        >
          {slide.title && (
            <h3
              className={`${style.title} ${
                slide.titleCleared && !slide.lastItem ? style.titleCleared : ""
              } 
              ${slide.lastItem ? style.lastItem : ""} 
              bigcaslonitalic`}
            >
              <FormattedMessage id={slide.title} values={formatHTMLMessage} />
            </h3>
          )}
          {slide.sectionTitle && (
            <h3
              className={`${style.sectionTitle} ${
                slide.subtitleAsTitle ? style.subtitleAsTitle : ""
              } bigcaslonitalic`}
            >
              <FormattedMessage
                id={slide.sectionTitle}
                values={formatHTMLMessage}
              />
            </h3>
          )}
          <div className={textClassName(slide)}>
            <FormattedMessage id={slide.text} values={formatHTMLMessage} />
          </div>
        </div>
      )}
    </Fragment>
  );
};

CardCarouselSlide.propTypes = {
  slide: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default CardCarouselSlide;
