import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { animated, useSpring, useTrail } from '@react-spring/web';
import style from "./FeatureArt.module.scss";
import ArtistProfile from "../ArtistProfile/ArtistProfile";
import LineSet from "../LineSet/LineSet";

const FeatureArt = ({ intl }) => {
  const params = useParams();
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const artist1ProfileImage = `${assetPath}/home/kehinde-wiley.png`;
  const artist1ArtworkImage = `${assetPath}/home/kehinde-wiley-artwork.jpg`;
  const artist2ProfileImage = `${assetPath}/home/rem-koolhaas.jpg`;
  const artist2ArtworkImage = `${assetPath}/home/rem-koolhaas-artwork.png`;

  const cols = [
    {
      children: (
        <ArtistProfile
          artist={{
            name: intl.formatMessage({ id: `home.artist1Name` }),
            quote: intl.formatMessage(
              { id: `home.artist1Quote` },
              {
                em: (str) => (
                  <em key="artist1QuoteEm">
                    {str}
                  </em>
                ),
                lineBreak: <br key="artist1Break" />,
              }
            ),
            profileImageUrl: artist1ProfileImage,
            artworkImageUrl: artist1ArtworkImage,
          }}
        />
      ),
    },
    {
      children: (
        <ArtistProfile
          artist={{
            name: intl.formatMessage({ id: `home.artist2Name` }),
            quote: intl.formatMessage(
              { id: `home.artist2Quote` },
              {
                em: (str) => (
                  <em key="artist2QuoteEm">
                    {str}
                  </em>
                ),
                lineBreak: <br key="artist2Break" />,
              }
            ),
            profileImageUrl: artist2ProfileImage,
            artworkImageUrl: artist2ArtworkImage,
          }}
        />
      ),
    },
  ];

  const [colsRef, colsInView] = useInView();
  const colsAnim = useTrail(cols.length, {
    from: {
      opacity: colsInView ? 0 : 1,
      transform: colsInView ? "translate3d(0,64px,0)" : "translate3d(0,0px,0)",
    },
    to: {
      opacity: colsInView ? 1 : 0,
      transform: colsInView ? "translate3d(0,0,0)" : "translate3d(0,64px,0)",
    },
    delay: 300,
  });

  const [moreRef, moreInView] = useInView();
  const animMore = useSpring({
    from: {
      opacity: moreInView ? 0 : 1,
      transform: moreInView ? "translate3d(0,32px,0)" : "translate3d(0,0px,0)",
    },
    to: {
      opacity: moreInView ? 1 : 0,
      transform: moreInView ? "translate3d(0,0,0)" : "translate3d(0,32px,0)",
    },
  });

  return (
    <div className={style.wrapper}>
      <div className="row">
        <div className="col-xs-10 col-xs-offset-1 col-md-6 col-md-offset-3">
          <p className={`heading-5 ${style.blurb} text-justify bentonsansthin`}>
            <LineSet
              id={`home.artBlurb`}
              desktop={[]}
              tablet={[]}
              mobile={[]}
              values={{
                em: (str) => <em>{str}</em>,
                bold: (str) => <>{str}</>,
                lineBreak: <br />,
                artLink: <FormattedMessage id={`home.artLinkText`} />,
              }}
            />
          </p>
        </div>
      </div>
      <div ref={colsRef} className={`row ${style.colsWrapper}`}>
        {colsAnim.map((props, index) => {
          return (
            <animated.div
              key={index}
              style={props}
              className="col-xs-6 col-lg-6"
            >
              {cols[index].children}
            </animated.div>
          );
        })}
      </div>
      <div ref={moreRef} className="text-align-center">
        <animated.div className="text-align-center" style={animMore}>
          <Link
            className={`btn btn-centurion-black moreButton bentonsanslight`}
            to={{
              pathname: `/${params.locale}/${params.card}/${params.centurion}/art`,
            }}
          >
            <FormattedMessage id={`home.findOutMore`} />
          </Link>
        </animated.div>
      </div>
    </div>
  );
};

FeatureArt.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(FeatureArt);
