import React, { useEffect /*, useState */ } from "react";
import PropTypes from "prop-types";
// import { FormattedMessage, useIntl } from "react-intl";
import { Link, /*useParams,*/ useOutletContext } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
// import debounce from "lodash.debounce";
// import gsap from "gsap/dist/gsap";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
// import HomeLink from "../../components/HomeLink/HomeLink";
// import ArtistLink from "../../components/Art/ArtistLink";
// import InView from "../../components/InView/InView";
// import Logotype from "../../components/Logotype/Logotype";
// import LineSet from "../../components/LineSet/LineSet";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import style from "./Archive.module.scss";

const Archive = ({ updateRoot }) => {
  // const { messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();
  // const params = useParams();

  useEffect(() => {
    updateRoot("archive");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  // const links = [
  //   {
  //     id: "remKoolhaas",
  //     classes: `${style.profileFirst}`,
  //     imageUrl: "rem-koolhaas.png",
  //     textId: "viewProfileKoolhaas",
  //     to: `/${params.locale}/${params.card}/${params.centurion}/rem-koolhaas`,
  //   },
  // ];

  return (
    <>
      <Helmet>
        <title>Centurion Archive</title>
      </Helmet>

      <div data-module="notfound" className={`${style.main} container`}>
        <div className={`position-relative ${style.wrapper}`}>
          <h2
            className={`bentonsansthin text-align-center ${style.statusCode}`}
            data-localeid="statusCode"
          >
            Archived Pages
          </h2>
          <div className={`${style.bodyCopy} row`}>
            <div
              className={`${style.intro} bentonsanslight col-xs-10 col-xs-offset-1 col-md-7 col-lg-6`}
            >
             <ul className={style.links}>
              <li>
                <Link to="/en-fi/benefits/centurion/wearable">Prada Centurion Wearable</Link>
              </li>
              <li>
                <Link to="/en-fi/benefits/centurion/howtowear">Prada x Centurion – How To Wear</Link>
              </li>
              <li>
                <Link to="/en-fi/benefits/centurion/monogramming-viewings/monogramming">Wearable Monogramming</Link>
              </li>
              <li>
                <Link to="/en-fi/benefits/centurion/faqs/info">Standalone FAQs</Link>
              </li>
             </ul>
            </div>
          </div>
        </div>

        <SiteFooter />
      </div>
    </>
  );
};

Archive.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
  };
};

export default connect(null, mapDispatchToProps)(Archive);
