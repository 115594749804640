const cardFaqs = ({ locale, pageRes }) => {
  const data = {
    "de-DE": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
    ],
    "en-CH": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
    ],
    "en-FI": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 6,
        title: "card.faq7Title",
        text: "card.faq7Text",
        // lastItem: true,
      },
      // {
      //   id: 7,
      //   title: "card.faq8Title",
      //   text: "card.faq8Text",
      // },
    ],
    "en-GL": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      // {
      //   id: 2,
      //   title: "card.faq3Title",
      //   text: "card.faq3Text",
      // },
      // {
      //   id: 3,
      //   title: "card.faq4Title",
      //   titleCleared: true,
      //   subtitleAsTitle: true,
      //   text: "card.faq4Text",
      // },
      // {
      //   id: 4,
      //   sectionTitle: "card.faq4Title",
      //   text: "card.faq5Text",
      // },
      // {
      //   id: 5,
      //   sectionTitle: "card.faq4Title",
      //   subtitleAsTitle: true,
      //   text: "card.faq6Text",
      // },
      {
        id: 6,
        title: "card.faq7Title",
        text: "card.faq7Text",
        // lastItem: true,
      },
    ],
    "en-BH": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq7Title",
        text: "card.faq7Text",
      },
    ],
    "en-NL": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      // {
      //   id: 2,
      //   title: "card.faq3Title",
      //   text: "card.faq3Text",
      // },
      // {
      //   id: 3,
      //   title: "card.faq4Title",
      //   titleCleared: true,
      //   subtitleAsTitle: true,
      //   text: "card.faq4Text",
      // },
      // {
      //   id: 4,
      //   sectionTitle: "card.faq4Title",
      //   subtitleAsTitle: true,
      //   text: "card.faq5Text",
      // },
      // {
      //   id: 5,
      //   sectionTitle: "card.faq4Title",
      //   subtitleAsTitle: true,
      //   text: "card.faq6Text",
      // },
      {
        id: 6,
        title: "card.faq7Title",
        text: "card.faq7Text",
        // lastItem: true,
      },
      // {
      //   id: 7,
      //   title: "card.faq8Title",
      //   text: "card.faq8Text",
      // },
    ],
    "en-NO": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      // {
      //   id: 2,
      //   title: "card.faq3Title",
      //   text: "card.faq3Text",
      // },
      // {
      //   id: 3,
      //   title: "card.faq4Title",
      //   titleCleared: true,
      //   subtitleAsTitle: true,
      //   text: "card.faq4Text",
      // },
      // {
      //   id: 4,
      //   sectionTitle: "card.faq4Title",
      //   subtitleAsTitle: true,
      //   text: "card.faq5Text",
      // },
      // {
      //   id: 5,
      //   sectionTitle: "card.faq4Title",
      //   subtitleAsTitle: true,
      //   text: "card.faq6Text",
      // },
      {
        id: 6,
        title: "card.faq7Title",
        text: "card.faq7Text",
        // lastItem: true,
      },
      // {
      //   id: 7,
      //   title: "card.faq8Title",
      //   text: "card.faq8Text",
      // },
    ],
    "en-SE": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      // {
      //   id: 2,
      //   title: "card.faq3Title",
      //   text: "card.faq3Text",
      // },
      // {
      //   id: 3,
      //   title: "card.faq4Title",
      //   titleCleared: true,
      //   subtitleAsTitle: true,
      //   text: "card.faq4Text",
      // },
      // {
      //   id: 4,
      //   sectionTitle: "card.faq4Title",
      //   subtitleAsTitle: true,
      //   text: "card.faq5Text",
      // },
      // {
      //   id: 5,
      //   sectionTitle: "card.faq4Title",
      //   subtitleAsTitle: true,
      //   text: "card.faq6Text",
      // },
      {
        id: 6,
        title: "card.faq7Title",
        text: "card.faq7Text",
        // lastItem: true,
      },
      // {
      //   id: 7,
      //   title: "card.faq8Title",
      //   text: "card.faq8Text",
      // },
    ],
    "en-BE": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
    ],
    "en-US": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
        lastItem: true,
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
        lastItem: true,
      },
    ],
    "en-HK": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq7Title",
        text: "card.faq7Text",
      },
    ],
    "zh-HK": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq7Title",
        text: "card.faq7Text",
      },
    ],
    "it-IT": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
    ],
    "fr-FR": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        // title: "card.faq3Title",
        // text: "card.faq3Text",
        title: "card.faq7Title",
        text: "card.faq7Text",
      },
      {
        id: 3,
        title: "card.faq4Title",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "card.faq4Text",
      },
      {
        id: 4,
        sectionTitle: "card.faq4Title",
        subtitleAsTitle: true,
        text: "card.faq5Text",
      },
      // {
      //   id: 5,
      //   sectionTitle: "card.faq4Title",
      //   subtitleAsTitle: true,
      //   text: "card.faq6Text",
      // },
      // {
      //   id: 6,
      //   title: "card.faq7Title",
      //   text: "card.faq7Text",
      // },
      {
        id: 5,
        title: "card.faq8Title",
        text: "card.faq8Text",
      },
      // {
      //   id: 8,
      //   sectionTitle: "card.faq9Title",
      //   text: "card.faq9Text",
      // },
    ],
    "en-GB": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
      {
        id: 3,
        title: "card.faq4Title",
        titleCleared: true,
        lastItem: true,
        subtitleAsTitle: true,
        text: "card.faq4Text",
      },
    ],
    "es-ES": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
    ],
    "zh-TW": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
      {
        id: 3,
        title: "card.faq4Title",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "card.faq4Text",
      },
      {
        id: 4,
        sectionTitle: "card.faq4Title",
        subtitleAsTitle: true,
        text: "card.faq5Text",
      },
      {
        id: 5,
        sectionTitle: "card.faq4Title",
        subtitleAsTitle: true,
        text: "card.faq6Text",
      },
      {
        id: 6,
        title: "card.faq7Title",
        text: "card.faq7Text",
      },
      {
        id: 7,
        title: "card.faq8Title",
        // titleCleared: true,
        // lastItem: true,
        text: "card.faq8Text",
      },
    ],
    "en-AU": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
      {
        id: 3,
        title: "card.faq4Title",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "card.faq4Text",
      },
      {
        id: 4,
        sectionTitle: "card.faq4Title",
        subtitleAsTitle: true,
        text: "card.faq5Text",
      },
      {
        id: 5,
        sectionTitle: "card.faq4Title",
        subtitleAsTitle: true,
        text: "card.faq6Text",
      },
      {
        id: 6,
        title: "card.faq7Title",
        titleCleared: true,
        lastItem: true,
        text: "card.faq7Text",
      },
    ],
    "en-SG": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
    ],
    "de-AT": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
      {
        id: 3,
        title: "card.faq4Title",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "card.faq4Text",
      },
      {
        id: 4,
        sectionTitle: "card.faq4Title",
        subtitleAsTitle: true,
        text: "card.faq5Text",
      },
      {
        id: 5,
        sectionTitle: "card.faq6Title",
        subtitleAsTitle: true,
        text: "card.faq6Text",
      },
      {
        id: 6,
        title: "card.faq7Title",
        titleCleared: true,
        lastItem: true,
        text: "card.faq7Text",
      },
    ],
    "ja-JP": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
    ],
    "en-ID": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
      {
        id: 3,
        title: "card.faq4Title",
        text: "card.faq4Text",
      },
      {
        id: 4,
        // lastItem: true,
        titleCleared: true,
        title: "card.faq5Title",
        text: "card.faq5Text",
      },
      {
        id: 5,
        // lastItem: true,
        title: "card.faq6Title",
        text: "card.faq6Text",
      },
    ],
    "es-ID": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
      {
        id: 3,
        title: "card.faq4Title",
        text: "card.faq4Text",
      },
      {
        id: 4,
        // lastItem: true,
        titleCleared: true,
        title: "card.faq5Title",
        text: "card.faq5Text",
      },
      {
        id: 5,
        // lastItem: true,
        title: "card.faq6Title",
        text: "card.faq6Text",
      }
    ],
    "en-IN": [
      {
        id: 0,
        title: "card.faq1Title",
        text: "card.faq1Text",
      },
      {
        id: 1,
        title: "card.faq2Title",
        text: "card.faq2Text",
      },
      {
        id: 2,
        title: "card.faq3Title",
        text: "card.faq3Text",
      },
      {
        id: 3,
        title: "card.faq4Title",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "card.faq4Text",
      },
      {
        id: 5,
        sectionTitle: "card.faq4Title",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "card.faq5Text",
      },
      {
        id: 6,
        sectionTitle: "card.faq4Title",
        titleCleared: true,
        subtitleAsTitle: true,
        // lastItem: true,
        text: "card.faq6Text",
      },
    ],
  };
  const items = data[locale] || data["en-GB"];

  // eslint-disable-next-line
  // console.log('Card FAQs:', locale, items);

  return pageRes === "large" &&
    ![
      "en-GL",
      "en-BE",
      "en-HK",
      "zh-HK",
      "en-FI",
      "en-NL",
      "en-NO",
      "en-SE",
      "en-BH",
      "it-IT",
      "en-CH",
      "en-US",
      "de-DE",
      "en-GB",
      "es-ES",
      "zh-TW",
      "en-AU",
      "en-SG",
      "de-AT",
      "ja-JP",
      "en-ID",
      "es-ID",
      "en-IN",
      "fr-FR",
    ].includes(locale)
    ? items.slice(0, items.length - 1)
    : items;
};

export default cardFaqs;
