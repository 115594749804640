import style from "../Calendar.module.scss";

import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { default as introductionsData } from "../../../static/living/introductions";
// import { default as artistData } from "../../static/living/artists";

export default function CalendarTalent({ season, options, handleSelect }) {
  const { messages: languageData, locale } = useIntl();
  const [disabledEvents, setDisabledEvents] = useState([]);

  useEffect(() => {
    const disabledEvents = introductionsData
      .find((seasonConfig) => seasonConfig.season === season.toString())
      ?.items.filter((item) => item?.disabledLocales) || [];

    setDisabledEvents(disabledEvents);
  }, [season]);

  return (
    <div className={style.calendarCountry}>
      <label
        htmlFor="FilterTalent"
        className={`bentonsanslight ${style.countryLabel}`}
      >
        {languageData['general.talent']}:
      </label>

      <div className={`position-relative ${style.countrySelectWrapper}`}>
        <select
          id="FilterTalent"
          onInput={(e) => handleSelect(e.target.value)}
          className={style.countrySelect}
        >
          <option value="">{languageData['general.showall']}</option>

          {options.map(({ id, name }) => {
            const disabledLocales = disabledEvents.find((item) => item.id === id)?.disabledLocales;
            const opt = (
              <option key={id} value={id}>
                {name}
              </option>
            );
            return disabledLocales
              ? disabledLocales.includes(locale.toLowerCase())
                ? null : opt
              : opt;
          })}
        </select>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={style.countrySelectIcon}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </div>
    </div>
  );
}
