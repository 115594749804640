import style from "./Overlay.module.scss";

import { FormattedMessage } from "react-intl";

export default function Overlay({ fontSize = 16 }) {
  return (
    <div
      className={style.imageOverlay}
      style={{
        "--restrictSize": `${fontSize}px`,
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.179 142.299">
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth=".5"
          d="m.175 35.096 58.053 22.168m81.052 30.951 49.724 18.988"
        />
      </svg>

      <span
        className="bigcaslonitalic"
        style={{
          fontSize,
        }}
      >
        <FormattedMessage id={`performances.pastEvent`} />
      </span>
    </div>
  );
}
