import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from '@react-spring/web';
import style from "./FeatureWearable.module.scss";
import ImageList from "../ImageList/ImageList";
import LineSet from "../LineSet/LineSet";

const FeatureWearable = ({ localeName }) => {
  const params = useParams();
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const images = [
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-1.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-2.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-3.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-4.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-5.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-6.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-7.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-8.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-9.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-10.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-11.jpg`,
    },
    {
      alt: "",
      ratio: 1,
      src: `${assetPath}/home/wearable-grid-image-12.jpg`,
    },
  ];

  const [moreRef, moreInView] = useInView({
    triggerOnce: true,
    rootMargin: "20% 0% 30% 0%",
  });
  const animMore = useSpring({
    from: {
      opacity: moreInView ? 0 : 1,
      transform: moreInView ? "translate3d(0,32px,0)" : "translate3d(0,0px,0)",
    },
    to: {
      opacity: moreInView ? 1 : 0,
      transform: moreInView ? "translate3d(0,0,0)" : "translate3d(0,32px,0)",
    },
  });

  return (
    <div className={style.wrapper}>
      <div className="row">
        <div
          className={`col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3 ${style.blurbContainer}`}
        >
          { localeName.toLowerCase() === 'en-au' &&
            <p className={`${style.blurb} text-justify bentonsanslight`}>
              Available until 31 December 2024, order before this date. Wearables will work until October 2026.
            </p>
          }
          <p
            className={`${style.blurb} text-justify bentonsansthin`}
            data-localeid="wearableBlurb1"
          >
            <LineSet
              // id="wearableBlurb1"
              id="home.wearableBlurb1"
              desktop={
                ['en-US','en-ID'].includes(localeName) ? [] : [9, 11]
              }
              tablet={[]}
              mobile={
                ['en-US','en-ID'].includes(localeName) ? [] : [8]
              }
              values={{
                em: (str) => <em>{str}</em>,
                bold: (str) => <>{str}</>,
                lineBreak: <br />,
                wearableLink: <FormattedMessage id={`home.wearableLinkText`} />,
              }}
            />
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <div className={style.imageList}>
            <ImageList items={images} />
          </div>
        </div>
      </div>
      <div ref={moreRef} className="text-align-center">
        <animated.div className="text-align-center" style={animMore}>
          <Link
            className={`btn btn-centurion-black moreButton bentonsanslight`}
            to={{
              pathname: `/${params.locale}/${params.card}/${params.centurion}/wearable`,
            }}
          >
            <FormattedMessage id="home.findOutMore" />
          </Link>
        </animated.div>
      </div>
    </div>
  );
};

export default FeatureWearable;
