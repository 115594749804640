// For use with components/Markets for FAQs dropdown
const markets = ({ formatMessage }) => [
  {
    val: "",
    text: formatMessage({ id: "info.pleaseSelect" }),
  },
  {
    val: "en-us",
    text: formatMessage({ id: "info.unitedstates" }),
  },
  {
    val: "en-gb",
    text: formatMessage({ id: "info.unitedkingdom" }),
  },
  {
    val: "de-de",
    text: formatMessage({ id: "info.germany" }),
  },
  {
    val: "de-at",
    text: formatMessage({ id: "info.austria" }),
  },
  {
    val: "en-be",
    text: formatMessage({ id: "info.belgium" }),
  },
  {
    val: "fr-fr",
    text: formatMessage({ id: "info.france" }),
  },
  {
    val: "en-fi",
    text: formatMessage({ id: "info.finland" }),
  },
  {
    val: "it-it",
    text: formatMessage({ id: "info.italy" }),
    // hiddenFor: ['en-gb', 'de-de']
  },
  {
    val: "es-es",
    text: formatMessage({ id: "info.spain" }),
    // hiddenFor: ['en-gb', 'de-de']
  },
  {
    val: "en-nl",
    text: formatMessage({ id: "info.netherlands" }),
  },
  {
    val: "en-no",
    text: formatMessage({ id: "info.norway" }),
  },
  {
    val: "en-se",
    text: formatMessage({ id: "info.sweden" }),
  },
  {
    val: "en-sg",
    text: formatMessage({ id: "info.singapore" }),
    // hiddenFor: ['en-gb', 'de-de', 'it-it'],
  },
  {
    val: "en-hk",
    text: formatMessage({ id: "info.hongkong" }),
    // hiddenFor: ['en-gb', 'de-de', 'it-it'],
  },
  {
    val: "zh-hk",
    text: formatMessage({ id: "info.hongkongZH" }),
    // hiddenFor: ['en-gb', 'de-de', 'it-it'],
  },
  {
    val: "zh-tw",
    text: formatMessage({ id: "info.taiwan" }),
    // hiddenFor: [],
  },
  {
    val: "en-gl",
    text: formatMessage({ id: "info.saudiarabia" }),
  },
  {
    val: "en-bh",
    text: formatMessage({ id: "info.middleeast" }),
  },
  {
    val: "en-au",
    text: formatMessage({ id: "info.australia" }),
  },
  {
    val: "en-in",
    text: formatMessage({ id: "info.india" }),
  },
  {
    val: "ja-jp",
    text: formatMessage({ id: "info.japan" }),
  },
  {
    val: "en-id",
    text: formatMessage({ id: "info.idcEn" }),
  },
  {
    val: "es-id",
    text: formatMessage({ id: "info.idcEs" }),
  },
];

export default markets;
