import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import style from "../Calendar.module.scss";

export default function CalendarRegion({ options, handleSelect }) {
  const params = useParams();

  const [groupedOptions, setGroupedOptions] = useState([]);

  const { messages: languageData } = useIntl();

  useEffect(() => {
    const groupedOptions = options.reduce((optionsByRegion, optionItem) => {
      const { region, regionTranslations, cityTranslations } = optionItem;

      const regionName = regionTranslations[params.locale] || region;
      const regionOptions = optionsByRegion[regionName] || [];

      const newOptionItem = {
        ...optionItem,
        name: cityTranslations[params.locale] || optionItem.name,
      };

      return {
        ...optionsByRegion,
        [regionName]: [...regionOptions, newOptionItem],
      };
    }, {});

    const groupedOptionsArray = Object.entries(groupedOptions).map(
      ([region, options]) => ({
        region,
        options,
      })
    );

    setGroupedOptions(groupedOptionsArray);
  }, [options, params.locale]);

  return (
    <div className={style.calendarCountry}>
      <label
        htmlFor="FilterCountry"
        className={`bentonsanslight ${style.countryLabel}`}
      >
        {languageData['general.locations']}:
      </label>

      <div className={`position-relative ${style.countrySelectWrapper}`}>
        <select
          id="FilterCountry"
          onInput={(e) => handleSelect(e.target.value)}
          className={style.countrySelect}
        >
          <option value="">{languageData['general.showall']}</option>

          {groupedOptions.map(({ region, options }) => (
            <optgroup key={region} label={region}>
              {/*
                We need to set the value to the `name` as we filter by
                city and there's no `id` for a city.
              */}
              {options.map(({ name }, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </optgroup>
          ))}
        </select>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={style.countrySelectIcon}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </div>
    </div>
  );
}
