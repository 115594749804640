import { createSlice } from "@reduxjs/toolkit";
// import { fromJS } from "immutable";
import { getConfigForEnv } from "../../appConfig";

const configSlice = createSlice({
  name: "config",
  initialState: getConfigForEnv(),
  reducers: {}, // We only care about initial state for config vars
});

export default configSlice.reducer;