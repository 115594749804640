import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import style from "./CardArtistLink.module.scss";

const CardArtistLink = ({ imageUrl, name, to, flipHorizontal }) => (
  <Link
    className={`dls-black flex flex-column flex-align-center ${style.link} bentonsansthin`}
    to={to}
  >
    <div
      className={`${style.imageWrapper} ${
        flipHorizontal ? style.noPadLeft : undefined
      }`}
      data-name={name}
    >
      <img alt={name} src={imageUrl} />
    </div>
    <p className={`${style.content} bentonsansthin`}>
      <FormattedMessage
        id="card.visitGallery"
        values={{
          name,
          b: (str) => <span className="bentonsanslight">{str}</span>,
        }}
      />
    </p>
  </Link>
);

CardArtistLink.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CardArtistLink;
