import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import CardCarousel from "../CardCarousel/CardCarousel";
import WearableCarousel from "../WearableCarousel/WearableCarousel";
import cardFaqs from "../../static/cardFaqs";
import wearableFaqs from "../../static/wearableFaqs";
import style from "./MainInfo.module.scss";
import { getConfigForEnv } from "../../appConfig";


const MainInfo = ({ params, basePath, hasWearable }) => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const intl = useIntl();
  const { locale } = intl;
  const staticParams = `${params.card}/${params.centurion}/faqs/market`; // benefits/new-centurion/faqs/market

  const [cardFaqsOpen, setCardFaqsIsOpen] = useState(false);
  const [wearableFaqsOpen, setWearableFaqsIsOpen] = useState(false);
  const [pageRes, setPageRes] = useState(null);

  const localisedCardFaqs = cardFaqs({ locale, pageRes });
  const localisedWearableFaqs = wearableFaqs(locale);

  const cardImageMap = {
    "de-DE": `${assetPath}/info/kehinde-wiley-card-de.png`,
    "fr-FR": `${assetPath}/info/kehinde-wiley-card-fr.png`,
    "es-ES": `${assetPath}/info/kehinde-wiley-card-es.png`,
    default: `${assetPath}/info/kehinde-wiley-card.png`, // default for all en-* and locales without
  };
  const cardImage =
    locale in cardImageMap
      ? cardImageMap[locale]
      : cardImageMap.default;

      const { env } = getConfigForEnv();
      const isArchive = env === "archive";

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const { hash } = window.location;
  //     if (hash === "#cardFaqs") {
  //       setCardFaqsIsOpen(true);
  //     } else if (hash === "#wearableFaqs") {
  //       setWearableFaqsIsOpen(true);
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const { hash } = window.location;
  //     if (hash === "#cardFaqs") {
  //       setCardFaqsIsOpen(true);
  //     } else if (hash === "#wearableFaqs") {
  //       setWearableFaqsIsOpen(true);
  //     }
  //   }
  // }, [location.pathname, location.hash]);

  const resetPageRes = () => {
    let res = window.innerWidth;
    setPageRes(res < 768 ? "small" : "large"); // catch state change with useEffect (below)
  };

  useEffect(() => {
    if (!window) {
      return;
    }
    const debFunc = debounce(() => {
      resetPageRes();
    }, 25);
    resetPageRes(); // initial call...
    window.addEventListener("resize", debFunc);
    return () => {
      window.removeEventListener("resize", debFunc);
    };
  }, []);

  const resetCardIsOpen = () => {
    setCardFaqsIsOpen(false);
  };

  const resetWearableIsOpen = () => {
    setWearableFaqsIsOpen(false);
  };

  return (
    <div className="container position-relative">
         {!isArchive && ( 
         <div className={style.backButton}>
        <Link
          className={`${style.backLink} flex flex-align-items-center`}
          to={{ pathname: `/${params.locale}/${staticParams}` }}
        >
          <span className="backLinkIcon icon dls-icon-left" />
          <span className={style.backText}>
            <FormattedMessage id="info.back" />
          </span>
        </Link>
      </div>
         )}
      <div className={`${style.intro} ${hasWearable ? style.hasWearable : ''}`}>
        <h1
          className={`${style.mainTitle} bigcaslonitalic`}
          data-localeid="infoAndFaqs"
        >
          <FormattedMessage id="info.infoAndFaqs" />
        </h1>
        <div className={`${style.diagonalLine}`}>
          <svg
            className={`${style.diagonalLineSvg}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 85 36"
          >
            <path
              fill="none"
              fillRule="evenodd"
              stroke="#000"
              strokeWidth=".5"
              d="M0 1l85 34"
            />
          </svg>
        </div>
        <p
          data-localeid="selectLink"
          className={`${style.selectLink} bentonsansthin`}
        >
          <FormattedMessage id="info.selectLink" />
        </p>
        <div className={`flex flex-justify-center ${style.faqsLinks}`}>
          {/* <Link
            to={{ pathname: location.pathname, hash: "#cardFaqs" }}
            className={`flex flex-column ${style.cardLink}`}
          > */}
          <div
            tabIndex="0"
            role="button"
            onClick={() => {
              if (!cardFaqsOpen) {
                setCardFaqsIsOpen(true);
              }
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13 || e.keyCode === 32) {
                if (!cardFaqsOpen) {
                  setCardFaqsIsOpen(true);
                }
              }
            }}
            className={`flex flex-column ${style.cardLink}`}
          >
            <p
              className={`${style.faqLinkText} bentonsanslight text-align-center`}
              data-localeid="centurionCard"
            >
              <FormattedMessage id={`info.centurionCard`} />
            </p>
            <img
              className={style.wileyCardImage}
              alt={intl.formatMessage({ id: `info.pradaImageAlt` })}
              src={cardImage}
              // src={`${assetPath}/info/kehinde-wiley-card.png`}
            />
          </div>
          {/* </Link> */}
          {/* <Link
            to={{ pathname: location.pathname, hash: "#wearableFaqs" }}
            className={`flex flex-column ${style.wearableLink}`}
          > */}
          { hasWearable &&
            <div
              tabIndex="0"
              role="button"
              onClick={() => {
                if (!wearableFaqsOpen) {
                  setWearableFaqsIsOpen(true);
                }
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  if (!wearableFaqsOpen) {
                    setWearableFaqsIsOpen(true);
                  }
                }
              }}
              className={`flex flex-column ${style.wearableLink}`}
            >
              <p
                className={`${style.faqLinkText} bentonsanslight text-align-center`}
                data-localeid="pradaCenturionWearable"
              >
                <FormattedMessage id={`info.pradaCenturionWearable`} />
              </p>
              <img
                src={`${assetPath}/info/wearableImage.png`}
                className={style.wearableCardImage}
                alt={intl.formatMessage({
                  id: `info.wileyCardImageAlt`,
                })}
              />
            </div>
          }
          {/* </Link> */}
        </div>
      </div>
      <CardCarousel
        defaultOpen={cardFaqsOpen}
        items={localisedCardFaqs}
        toggleButtonId="cardFaqs"
        carouselContainerId="cardFaqsContainer"
        buttonTextId="cardFaqs"
        toggleCallback={resetCardIsOpen}
        rootModule="info"
      />
      { hasWearable &&
        <WearableCarousel
          defaultOpen={wearableFaqsOpen}
          items={localisedWearableFaqs}
          toggleButtonId="wearableFaqs"
          carouselContainerId="wearableFaqsContainer"
          buttonTextId="pradaFaqs"
          buttonTextIdSmall="wearableCardFaqs_mobile"
          toggleCallback={resetWearableIsOpen}
          rootModule="info"
          basePath={basePath}
        />
      }
    </div>
  );
};

MainInfo.propTypes = {
  params: PropTypes.shape({}).isRequired,
  basePath: PropTypes.string.isRequired,
  hasWearable: PropTypes.bool.isRequired,
};

export default MainInfo;
