import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useTransition, animated } from '@react-spring/web';
import { FormattedMessage } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { menuItems } from "../../static/menu";
import Logotype from "../Logotype/Logotype";
import MenuItem from "./MenuItem";
import style from "./Menu.module.scss";

const Menu = ({
  hasSmallHeight,
  clipBackground,
  isMenuOpen,
  hasTouch,
  handleMenuToggle,
  basePath,
  features,
}) => {
  const body = document.querySelector("body");
  const location = useLocation();
  const { locale } = useIntl();
  const localeName = locale.toLowerCase();

  const [hasMoved, setHasMoved] = useState(false);

  const navigate = useNavigate();

  const items = useMemo(() =>
    menuItems({ basePath, features, localeName }),
    [basePath, features, localeName]
  );

  const transitions = useTransition(items, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    trail: 180,
  });

  const handleTouchEnd = (to) => {
    if (hasMoved) {
      setHasMoved(false);
      return;
    }
    // handleMenuToggle();
    return navigate(to);
  };

  const handleMoved = () => {
    setHasMoved(true);
  };

  const numSectionsVisible = items.reduce(
    (sum, item) => (!item.hidden ? sum + 1 : sum),
    0
  );
  const toggleScrollLock = () => {
    document.querySelector("body").classList.toggle(style.noScroll);
  };

  useEffect(() => {
    toggleScrollLock();
    return () => {
      document.querySelector("body").classList.remove(style.noScroll);

      // eslint-disable-next-line no-console
      // console.log(history.location.pathname, location.pathname);

      // if (history.location.pathname === location.pathname) {
      //   return;
      // }
      // if (!history.location.hash) {
      //   window.scrollTo(0, 0);
      // }
      if (!location.hash) {
        window.scrollTo(0, 0);
      }
    };
  }, [
    body,
    // history.location.pathname,
    location.pathname,
    // history.location.hash,
    location.hash,
    isMenuOpen,
  ]);

  if (!items.length) {
    return null;
  }

  return (
    <nav
      className={`dls-white position-absolute ${style.wrapper} ${
        hasSmallHeight ? "" : style.isStaggered
      } ${clipBackground ? style.isClipped : ""}`}
    >
      <div className={`container ${style.containerOverride}`}>
        <div className={`position-relative ${style.content}`}>
          <div className={`${style.homeLinkWrapper}`}>
            <div className="row">
              <div
                className={`col-md-8 col-lg-3 col-lg-offset-9 ${style.homeLinkRow}`}
              >
                <MenuItem
                  // onClick={hasTouch ? () => false : handleMenuToggle}
                  // onTouchStart={hasTouch ? () => handleClick({pathname: ''}) : () => false}
                  onTouchEnd={
                    hasTouch
                      ? () => handleTouchEnd({ pathname: `/${basePath}/` })
                      : () => false
                  }
                  onTouchMove={hasTouch ? () => handleMoved() : () => false}
                  to={hasTouch ? null : { pathname: `/${basePath}/` }}
                  className={`
                      ${style.homeLink}
                      ${isMenuOpen ? "" : `${style.hiddenHome} invisible`}
                    `}
                >
                  <div className={style.homeLinkIcon} />
                  <div>
                    <span className="sr-only">Centurion</span>
                    <p
                      className={`${style.homeLinkText} dls-white bentonsansthin`}
                    >
                      <FormattedMessage id="menu.homepage" />
                    </p>
                  </div>
                </MenuItem>
              </div>
            </div>
          </div>
          <div
            className={`row ${style.logotypeContainer}`}
            data-numsectionsvisible={numSectionsVisible}
          >
            { transitions((styles, item) => (
                item &&
                // !(item.id === "centurionBenefits" && localeName === "en-in") && // TEMP!
                !item.hidden && (
                  <animated.div
                    className={`col-lg-3 col-lg-offset-0 ${style.col}`}
                    style={styles}
                    data-section={item.id}
                  >
                    <MenuItem
                      className={`display-block ${style.logotypeLink} ${
                        !item.to ? style.itemDisabled : ""
                      }`}
                      // onClick={hasTouch ? () => false : handleMenuToggle}
                      // onTouchStart={hasTouch ? () => handleClick(item.to) : () => false}
                      onTouchEnd={
                        hasTouch ? () => handleTouchEnd(item.to) : () => false
                      }
                      onTouchMove={hasTouch ? () => handleMoved() : () => false}
                      to={hasTouch ? null : item.to}
                    >
                      <Logotype
                        id={item.id}
                        title={item.title}
                        rootModule="menu"
                      />
                    </MenuItem>
                    {item.children
                      ? item.children.map(
                          ({ title, to, secondary }) =>
                            title && (
                              <MenuItem
                                className={`display-block dls-white ${
                                  style.linkChild
                                } ${
                                  !to ? style.itemDisabled : ""
                                } bentonsansthin`}
                                key={title}
                                // onClick={
                                //   hasTouch ? () => false : handleMenuToggle
                                // }
                                // onTouchStart={hasTouch ? () => handleClick(to) : () => false}
                                onTouchEnd={
                                  hasTouch
                                    ? () => handleTouchEnd(to)
                                    : () => false
                                }
                                onTouchMove={
                                  hasTouch ? () => handleMoved() : () => false
                                }
                                to={hasTouch ? null : to}
                              >
                                <FormattedMessage
                                  id={`menu.${title}`}
                                  values={{ lineBreak: <br /> }}
                                />
                                {/* {secondary && (
                                <p className={style.linkChildSecondary}>
                                  <FormattedMessage id={secondary} />
                                </p>
                              )} */}
                              </MenuItem>
                            )
                        )
                      : null}
                  </animated.div>
                )
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

Menu.propTypes = {
  hasSmallHeight: PropTypes.bool.isRequired,
  clipBackground: PropTypes.bool.isRequired,
  features: PropTypes.shape({}).isRequired,
  basePath: PropTypes.string,
  isMenuOpen: PropTypes.bool.isRequired,
  hasTouch: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const localeName = state.getIn(["intl", "activeLocale"]);
  const config = state.get("config");
  const features = config?.features[localeName] || {};
  const basePath = state.get("basePath").path;

  return {
    features,
    basePath,
  };
};

export default connect(mapStateToProps)(Menu);
