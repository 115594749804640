// For use with components/Monogramming
// const isProd = process.env.REACT_APP_CONFIG_ENV === "production";

const stores = {
  dates: [
    "availableFromFeb",
    // 'availableFromMarch',
  ],
  countries: [
    {
      countryId: "europe",
      locales: ["de-de", "fr-fr", "en-gb", "en-ch", "en-fi", "en-nl", "es-es", "de-at"],
      from: "availableFromFeb",
      items: [
        {
          id: "store_1",
          countryId: "italy",
          hidden: false,
        },
        {
          id: "store_2",
          countryId: "italy",
          hidden: false,
        },
        {
          id: "store_3",
          countryId: "france",
          hidden: false,
        },
        {
          id: "store_4",
          countryId: "germany",
          hidden: false,
        },
        {
          id: "store_26",
          countryId: "austria",
          hidden: false, // isProd,
        },
        {
          id: "store_5",
          countryId: "switzerland",
          hidden: false,
        },
        {
          id: "store_6",
          countryId: "uk",
          hidden: false,
        },
        {
          id: "store_23",
          countryId: "spain", // Barcelona
          hidden: false,
        },
        // {
        //   id: "store_15",
        //   countryId: "uk",
        // },
        // {
        //   id: "store_16",
        //   countryId: "uk",
        // },
      ],
    },
    {
      countryId: "na",
      locales: ["en-us"],
      from: "availableFromFeb",
      items: [
        // {
        //   id: "store_7",
        //   countryId: "usa",
        //   hidden: false,
        // },
        {
          id: "store_8",
          countryId: "usa",
          hidden: false,
        },
        {
          id: "store_27",
          countryId: "usa",
          hidden: false,
        },
        {
          id: "store_9",
          countryId: "canada",
          hidden: false,
        },
        {
          id: "store_12",
          countryId: "mexico",
          hidden: false,
        },
      ],
    },
    {
      countryId: "me",
      locales: ["en-bh", "en-gl", "en-se", "en-hk"],
      from: "availableFromFeb",
      items: [
        {
          id: "store_13",
          countryId: "uae",
          hidden: false,
        },
        {
          id: "store_14",
          countryId: "kuwait",
          hidden: false,
        },
      ],
    },
    {
      countryId: "asia",
      locales: [],
      from: "availableFromFeb",
      items: [
        {
          id: "store_11",
          countryId: "macau",
          hidden: false,
        },
        {
          id: "store_10",
          countryId: "hongkong",
          hidden: false,
        },
        // {
        //   id: "store_17", (Tokyo Aoyama)
        //   countryId: "",
        // },
        // {
        //   id: "store_18", (Tokyo Ginza)
        //   countryId: "",
        // },
        {
          id: "store_19", // Singapore
          countryId: "",
          hidden: false,
        },
        {
          id: "store_20", // Melbourne
          countryId: "australia",
          hidden: false,
        },
        {
          id: "store_21", // Sydney
          countryId: "australia",
          hidden: false,
        },
        { // Taipei
          id: "store_22",
          countryId: "",
          hidden: false, // isProd,
        },
        { // JP
          id: "store_24",
          countryId: "japan",
          hidden: false, // isProd,
        },
        { // JP
          id: "store_25",
          countryId: "japan",
          hidden: false, // isProd,
        },
      ]
    },
  ],
};

export default stores;
