import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useParams, useOutletContext } from "react-router-dom";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import Viewings from "../../components/Viewings/Viewings";
import Monogramming from "../../components/Monogramming/Monogramming";
import ViewmonoLanding from "../../components/Monogramming/ViewmonoLanding";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import style from "./Viewmono.module.scss";

const Viewmono = ({ location, basePath, updateRoot, features }) => {
  const { locale: localeName, messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();

  const params = useParams();
  const subpage = params.subpage || "landing";

  const hasWearable = !features?.hiddenSections?.wearable;

  useEffect(() => {
    updateRoot(subpage === "landing" ? "viewmono-landing" : "viewmono");
    handleRouteChange();
  }, [subpage, updateRoot, handleRouteChange]);

  const Subpage = () => {
    if (subpage === "viewings") {
      return (
        <Viewings
          location={location}
          basePath={basePath}
          languageData={languageData}
          localeName={localeName}
        />
      );
    } else if (subpage === "monogramming") {
      return (
        <Monogramming
          basePath={basePath}
          languageData={languageData}
          localeName={localeName}
        />
      );
    } else {
      return (
        <ViewmonoLanding
          params={params}
          basePath={basePath}
          localeName={localeName}
          hasWearable={hasWearable}
        />
      );
    }
  };

  if (!languageData.centurion) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{languageData.pageTitle}</title>
      </Helmet>
      <div
        data-module="viewmono"
        data-subpage={subpage}
        className={`${style.container}`}
      >
        <Subpage />
      </div>
      <SiteFooter />
    </>
  );
};

Viewmono.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const basePath = state.get("basePath")?.path;
  const localeName = state.getIn(["intl", "activeLocale"]);
  const config = state.get("config");
  const features = config?.features[localeName];
  return {
    basePath,
    features
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Viewmono);
