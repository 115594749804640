import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useLocation, useOutletContext } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import HomeLink from "../../components/HomeLink/HomeLink";
import ImageList from "../../components/ImageList/ImageList";
import Logotype from "../../components/Logotype/Logotype";
import Slideshow from "../../components/Slideshow/SlideshowAlt";
import InViewProfile from "../../components/InView/InViewProfile";
import ChevronDown from "../../components/ChevronDown/ChevronDown";
import WearableCarousel from "../../components/WearableCarousel/WearableCarousel";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import LineSet from "../../components/LineSet/LineSet";
import slidesData from "../../static/wearableSlides";
import wearableFaqs from "../../static/wearableFaqs";
import wearableImages from "../../static/wearableImages";
import style from "./Wearable.module.scss";

const Wearable = ({ updateRoot, basePath }) => {
  const { locale, messages } = useIntl();
  const { handleRouteChange } = useOutletContext();

  useEffect(() => {
    updateRoot("wearable");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  const { pathname } = useLocation();

  // Force scroll to top before scrolling to anchor IDs
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const bigTileImg = `${assetPath}/wearable/prada-bigtile.png`;

  const gridImages = wearableImages({ assetPath });

  const bigTileLink = {
    "it-IT": "https://www.pradagroup.com/it/brands/prada.html",
    "en-SE": "https://prada.com/se",
    "en-NO": "https://prada.com/no",
    "en-FI": "https://prada.com/fi",
    "en-NL": "https://prada.com/nl",
    "de-DE": "https://prada.com/de",
    "en-CH": "https://prada.com/ch",
    "en-GL": "https://prada.com/ae",
    "en-BH": "https://prada.com/ww",
    "es-ES": "https://www.prada.com/es/es.html",
    "en-AU": "https://www.prada.com/au/en.html",
    "de-AT": "https://www.prada.com/at/de.html",
  };

  const pradaLink = bigTileLink[locale] || "https://prada.com";

  const questions = wearableFaqs(locale);

  const wearableRef = useRef();
  const gridRef = useRef();
  const worldOfPradaRef = useRef();
  const refMap = {
    worldOfPrada: worldOfPradaRef,
    grid: gridRef,
    wearable: wearableRef,
  };

  function handleChevronClick(id) {
    const element = refMap[id] && refMap[id].current;

    if (!element) {
      return;
    }
    element.scrollIntoView({
      behavior: "smooth",
    });
  }

  const duration = 300;

  if (!messages.centurion) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{messages["wearable.title"]}</title>
      </Helmet>
      <div data-module="wearable" id="pageTop">
        <div className={style.pageBackground}>
          <div className={`container ${style.notFullpage}`}>
            <header className={`position-relative ${style.header}`}>
              <HomeLink rootModule="wearable" />
              <div className="row">
                <div
                  className={`col-xs-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 ${style.titleWrapper}`}
                >
                  <Logotype
                    id="wearablexcenturion"
                    title="title"
                    rootModule="wearable"
                  />
                </div>
              </div>
              { locale.toLowerCase() === 'en-au' &&
                <div className={`row`}>
                  <div className={`col-xs-12 col-md-10 col-md-offset-1 col-lg-6 col-lg-offset-3`}>
                    <InViewProfile y={0} duration={duration}>
                      <p className={`${style.content} ${style.auSentence} text-center bentonsans text-justify`}>
                        Available until 31 December 2024, order before this date. Wearables will work until October 2026.
                      </p>
                    </InViewProfile>
                  </div>
                </div>
              }
              <div className={`row`}>
                <div className={`col-xs-12 ${style.pradaImageMob}`}>
                  <InViewProfile y={0} duration={duration}>
                    <img
                      className={`${style.pradaImageMob} ${style.pradaImageMobImage}`}
                      alt={messages.wearableImageAlt}
                      src={`${assetPath}/wearable/wearable.png`}
                    />
                  </InViewProfile>
                </div>
                <div
                  className={`col-lg-4 col-lg-offset-1 ${style.pradaImageLg}`}
                >
                  <InViewProfile y={0} duration={duration}>
                    <img
                      className={`${style.pradaImageLg} ${style.pradaImageLgImage}`}
                      alt={messages.wearableImageAlt}
                      src={`${assetPath}/wearable/wearable.png`}
                    />
                  </InViewProfile>
                </div>
                <div className="col-xs-12 col-md-offset-1 col-lg-6 col-lg-offset-0">
                  <InViewProfile y={0} duration={duration}>
                    <p
                      className={`${style.summary} bigcaslonregular text-justify`}
                    >
                      <FormattedMessage id="wearable.summary" />
                    </p>
                  </InViewProfile>
                </div>
              </div>
              <InViewProfile y={0} duration={duration} delay={0}>
                <div>
                  <div className={`${style.diagonalLine}`}>
                    <svg
                      className={`${style.largeLine}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 60.2 23.1"
                    >
                      <path
                        fill="none"
                        stroke="#000"
                        strokeWidth=".1"
                        d="M0 0l60.2 23.1"
                      />
                    </svg>
                    <svg
                      className={`${style.mediumLine}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 36.8 14.1"
                    >
                      <path
                        fill="none"
                        stroke="#000"
                        strokeWidth=".1"
                        d="M0 0l36.8 14.1"
                      />
                    </svg>
                  </div>
                </div>
              </InViewProfile>
            </header>
            <div className="row">
              <div className={`${style.pradaImageMd} col-md-3 col-md-offset-1`}>
                <InViewProfile y={0} duration={duration} delay={0}>
                  <img
                    className={`${style.pradaImage}`}
                    alt={messages.wearableImageAlt}
                    src={`${assetPath}/wearable/wearable.png`}
                  />
                </InViewProfile>
              </div>
              <div
                className={`col-sm-12 col-sm-offset-0 col-md-7 col-md-offset-1 col-lg-6 col-lg-offset-0 ${style.contentWrapper}`}
              >
                <InViewProfile y={0} duration={duration} delay={0}>
                  <>
                    <p
                      className={`${style.content} bentonsansthin text-justify`}
                    >
                      <FormattedMessage
                        id="wearable.content1"
                        values={{ lineBreak: <br /> }}
                      />
                    </p>
                    <p
                      className={`${style.content} ${style.content2} bentonsansthin text-justify`}
                    >
                      <FormattedMessage
                        id="wearable.content2"
                        values={{ lineBreak: <br /> }}
                      />
                    </p>
                    <p
                      className={`${style.content} ${style.content3} bentonsansthin text-justify`}
                    >
                      <FormattedMessage
                        id="wearable.content3"
                        values={{ lineBreak: <br /> }}
                      />
                    </p>
                  </>
                </InViewProfile>
              </div>
              <div className={`${style.rings} col-lg-5 col-lg-offset-1`}>
                <InViewProfile y={0} duration={duration} delay={0}>
                  <img
                    src={`${assetPath}/wearable/wearableRings.png`}
                    alt={messages.wearableImageAlt}
                  />
                </InViewProfile>
              </div>
            </div>
            <ChevronDown
              onClick={() => handleChevronClick("wearable")}
              id="chevronToSlideshow"
              rootModule="wearable"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <section
          ref={wearableRef}
          id="wearable"
          className={style.slideshowWrapper}
        >
          <InViewProfile y={0} duration={duration} delay={0}>
            <Slideshow
              slides={slidesData}
              title="wearable.centurionWearableGallery"
              rootModule="wearable"
            />
          </InViewProfile>
        </section>
      </div>
      <div id="pageBottom" className={style.pageBottom}>
        <div className="container">
          <ChevronDown
            onClick={() => handleChevronClick("grid")}
            id="chevronToGrid"
            rootModule="wearable"
          />
          <div ref={gridRef} className={style.imageListWrapper}>
            <div className="row">
              <div className={`col-xs-12 ${style.imageListContainer}`}>
                <ImageList items={gridImages} />
              </div>
            </div>
          </div>
          <ChevronDown
            onClick={() => handleChevronClick("worldOfPrada")}
            id="chevronToWorld"
            rootModule="wearable"
          />
          <div className={`position-relative ${style.gradWrapper}`}>
            <section
              ref={worldOfPradaRef}
              id="worldOfPrada"
              className={style.sectionWorld}
            >
              <div className="container">
                <InViewProfile y={400} duration={duration}>
                  <>
                    <header className="row">
                      <div
                        className={`col-xs-10 col-xs-offset-1 col-md-6 col-md-offset-3 ${style.titleWrapper} ${style.worldOfPrada}`}
                      >
                        <a href={pradaLink}>
                          <Logotype
                            id="worldofprada"
                            title="worldOfPradaTitle"
                            rootModule="wearable"
                          />
                        </a>
                      </div>
                    </header>

                    <div className={`${style.bigTile}`}>
                      <div className={`row ${style.card}`}>
                        <div className={`row ${style.wrapper}`}>
                          <div className={style.copy}>
                            <p className="bentonsansthin">
                              <LineSet
                                id="wearable.bigtileCopy"
                                desktop={[6, 5, 5, 7, 4, 6, 7, 5]}
                                tablet={
                                  locale === "en-US"
                                    ? [5, 5, 4, 6, 5, 4, 7, 6, 4]
                                    : [5, 5, 4, 6, 5, 4, 6, 6]
                                }
                                values={{
                                  link: (str) => (
                                    <a
                                      className="bentonsanslight"
                                      href={pradaLink}
                                    >
                                      {str}
                                    </a>
                                  ),
                                }}
                              />
                            </p>
                          </div>
                          <a href={pradaLink} className={`${style.imageLink}`}>
                            <img
                              src={bigTileImg}
                              className={`${style.image}`}
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                </InViewProfile>
              </div>
            </section>
          </div>
          <section>
            <h2 className="sr-only">
              <FormattedMessage id="wearable.wearableCardFaqs" />
            </h2>
            <WearableCarousel
              items={questions}
              toggleButtonId="carousel1Toggle"
              carouselContainerId="carousel1Container"
              buttonTextId="wearableCardFaqs"
              buttonTextIdSmall="wearableCardFaqs_mobile"
              rootModule="wearable"
              basePath={basePath}
            />
          </section>
        </div>
      </div>
      <div className="container">
        <SiteFooter />
      </div>
    </>
  );
};

Wearable.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  basePath: state.get("basePath")?.path,
});

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wearable);
