import React from "react";
import PropTypes from "prop-types";
import style from "./ArtistProfile.module.scss";

const ArtistProfile = ({ artist }) => (
  <div className={`${style.wrapper}`} data-artist={artist.name}>
    <div className="flex height-full">
      <div>
        <div className={`${style.artworkImage}`}>
          <img id="artwork-image" alt="" src={artist.artworkImageUrl} />
        </div>
        <div
          className={`col-md-offset-2 col-lg-8 ${
            artist.name === "Rem Koolhaas"
              ? `col-lg-offset-4`
              : `col-lg-offset-2`
          }`}
        >
          <blockquote className={style.blockquote}>
            <p className="body-3">{artist.quote}</p>
          </blockquote>
        </div>
      </div>
      <div>
        <div
          className={`${
            style.profileImage
          } col-xs-8 col-xs-offset-2 col-md-8 col-md-offset-2 col-lg-6 ${
            artist.name === "Rem Koolhaas"
              ? `col-lg-offset-4`
              : `col-lg-offset-2`
          }`}
        >
          <div>
            <img id="profile-image" alt="" src={artist.profileImageUrl} />
          </div>
          <p className={`bentonsansthin text-align-center ${style.name}`}>
            — {artist.name}
          </p>
        </div>
      </div>
    </div>
  </div>
);

ArtistProfile.propTypes = {
  artist: PropTypes.shape({
    name: PropTypes.node.isRequired,
    quote: PropTypes.node.isRequired,
    profileImageUrl: PropTypes.string.isRequired,
    artworkImageUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default ArtistProfile;
