import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Rellax from "rellax";
import debounce from "lodash.debounce";
import ImageContainer from "./ImageContainer";
import { blends } from "../../static/benefits.js";
import style from "./BenefitsBlend.module.scss";

const BenefitsBlend = ({
  id,
  containerRatio,
  path = "benefits",
  data,
  imageContainerProps,
  clickHandler,
  // module,
}) => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const { locale } = useIntl();
  const localeName = locale.toLowerCase();

  const [pageId, setPageId] = useState(null);
  const [pageRes, setPageRes] = useState(null);
  const [images, setImages] = useState(null);
  const [rellax, setRellax] = useState(null);

  const calculateImageSpeed = ({ width, top }) =>
    ((100 - width) / 20 + (isNaN(top) ? 50 : top) / 10) *
    (pageRes === "small" ? 0.25 : 0.5);

  useEffect(() => {
    const resetPageRes = () => {
      const width = window.innerWidth;
      const res = width < 768 ? "small" : "large";
      const changed = pageRes !== res && pageId;
      setPageRes(res);
      if (changed) {
        setImages([]);
        setRellax(null);
        setPageId(null);
      }
    };
    const debFunc = debounce(() => {
      resetPageRes();
    }, 125);
    resetPageRes(); // initial call...
    window.addEventListener("resize", debFunc);
    return () => {
      window.removeEventListener("resize", debFunc);
    };
  }, [pageId, pageRes]);

  useEffect(() => {
    if (!id || !pageRes || images?.length) {
      return;
    }
    const config = blends[localeName]?.[id];
    const blendImages = config?.[pageRes] || config || data;
    if (!blendImages) {
      return;
    }
    // console.log("set images", blendImages); // eslint-disable-line no-console
    setImages(blendImages);
  }, [localeName, id, pageRes, images, data]);

  useEffect(() => {
    if (!images?.length || rellax) {
      return;
    }
    setTimeout(() => {
      setRellax(
        new Rellax(".rellax", {
          center: true,
        })
      );
    }, 1000); // 200);
  }, [images, rellax]);

  useEffect(() => {
    if (!rellax || !id || id === pageId) {
      return;
    }
    if (id && pageId && id !== pageId) {
      if (rellax?.destroy) {
        rellax.destroy();
      }
      setImages([]);
      setRellax(null);
      setPageId(null);
      return;
    }
    setPageId(id);
  }, [id, pageId, rellax]);

  if (!pageRes) {
    return null;
  }

  if (!images) {
    return null;
  }

  const showGrid = process.env.NODE_ENV === "development";
  // const showGrid = false;

  return (
    <div className={style.wrapper}>
      {/* <pre>{JSON.stringify(images, null, 2)}</pre> */}
      <div className={`row ${style.imagesWrapper}`}>
        <div className={style.images}>
          <div
            className={`${style.imagesContent} ${showGrid ? style.devEnv : ""}`}
            style={
              containerRatio
                ? {
                    paddingBottom: `${containerRatio}%`,
                  }
                : {}
            }
          >
            {images.map((image, index) => (
              <div
                key={index}
                data-id={image.src}
                className={style.thumbnailWrapper}
                tabIndex={clickHandler && image.src ? "0" : null}
                role={clickHandler && image.src ? "button" : null}
                aria-label={
                  clickHandler && image.src
                  ? `${index} of ${images.length - 1}`
                  : null
                }
                onClick={
                  clickHandler && image.src
                    ? (event) => {
                        clickHandler(image.src);
                      }
                    : null
                }
                style={{
                  maxWidth: `${image.width}%`,
                  ...(image.centerVertically && image.centerHorizontally
                    ? {
                        top: `${50 + (image.top || 0)}%`,
                        left: `${50 + (image.left || 0)}%`,
                        transform: "translate(-50%,-50%)",
                      }
                    : {
                        top: `${image.top}%`,
                        ...(image.left
                          ? {
                              left: `${image.left}%`,
                            }
                          : {
                              right: `${image.right}%`,
                            }),
                        ...(image.centerVertically
                          ? {
                              top: `${50 + (image.top || 0)}%`,
                              transform: "translateY(-50%)",
                            }
                          : image.centerHorizontally
                          ? {
                              left: `${50 + (image.left || 0)}%`,
                              transform: "translateX(-50%)",
                            }
                          : false),
                      }),
                  ...(!image.halo // dont'apply multiply blend mode to central image
                    ? { mixBlendMode: image.blend || "multiply" }
                    : false),
                  ...(image.opacity ? { opacity: image.opacity } : false),
                }}
              >
                <div
                  className={`rellax ${style.parallaxWrapper}`}
                  data-rellax-speed={image.speed || calculateImageSpeed(image)}
                  data-rellax-min={pageRes === "small" ? -100 : -240}
                  data-rellax-max={pageRes === "small" ? 100 : 240}
                >
                  <div
                    className={`${style.thumbnail}`}
                    tabIndex={image.clickable ? "0" : null}
                    role={image.clickable ? "button" : null}
                    style={{
                      opacity: `${image.opacity ? image.opacity : 1}`,
                      ...(image.rotate
                        ? {
                            transform: `rotate(${image.rotate})`,
                          }
                        : false),
                    }}
                  >
                    <ImageContainer
                      id={id}
                      halo={image.halo}
                      src={
                        path === "benefits"
                        ? `${assetPath}/${path}/${localeName}/${id}/${image.src}`
                        : `${assetPath}/${path}/${id}/${image.src}`
                      }
                      {...(imageContainerProps &&
                      typeof imageContainerProps === "function"
                        ? imageContainerProps(image)
                        : imageContainerProps || false)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

BenefitsBlend.propTypes = {
  id: PropTypes.string.isRequired,
  containerRatio: PropTypes.number,
};

export default BenefitsBlend;
