import { format } from "date-fns";
import { enGB, enUS, de, deAT, fr, it, zhHK, ja, es } from "date-fns/locale";

export function getDate(dateValue, dateFormat, dateLocale = "en-fi") {
  return format(new Date(dateValue), dateFormat, {
    locale: dateFnsLocales[dateLocale],
  });
}

const dateFnsLocales = {
  "de-at": deAT,
  "de-de": de,
  "en-be": enGB,
  "en-bh": enGB,
  "en-fi": enGB,
  "en-gb": enGB,
  "en-gl": enGB,
  "en-hk": enGB,
  "en-id": enUS,
  "en-nl": enGB,
  "en-no": enGB,
  "en-se": enGB,
  "en-sg": enGB,
  "en-us": enUS,
  "es-id": es,
  "fr-fr": fr,
  "it-it": it,
  "ja-jp": ja,
  "zh-hk": zhHK,
};
