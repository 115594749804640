import React, { Fragment, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Carousel from "../Carousel/Carousel";
import style from "./MainPrivacy.module.scss";

const formatLink = (linktext) => {
  return linktext.indexOf("http") === 0 ? linktext : `http://${linktext}`;
};

const mailtoLink = (email) => {
  return (email = `mailto:${email}`);
};

const MainPrivacy = ({ params, location }) => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const intl = useIntl();
  const userLocale = intl.locale.toLocaleLowerCase();
  const staticParams = `${params.card}/${params.centurion}/faqs/market`; // benefits/new-centurion/faqs/market

  const italic = (str) => <em>{str}</em>;
  const subheading = (str) => <span>{str}</span>;
  const link = (str) => (
    <a
      href={formatLink(str)}
      target="_blank"
      rel="noopener noreferrer"
      className="bentonsanslight"
    >
      {str}
    </a>
  );
  const mailto = (str) => (
    <a
      href={mailtoLink(str)}
      target="_blank"
      rel="noopener noreferrer"
      className="bentonsanslight"
    >
      {str}
    </a>
  );

  const nbsp = String.fromCharCode(160);

  const common = {
    break: <br />,
    lineBreak: (
      <Fragment>
        <br />
        <br />
      </Fragment>
    ),
    italic,
    subheading,
    link,
    mailto,
  };

  const values = {
    ...common,
    bold: (str) => <strong className="bentonsanslight">{str}</strong>,
  };

  const answerValues = {
    ...common,
    bold: (str) => <strong>{str}</strong>,
  };

  const localisedCardFaqs = {
    "en-gb": [
      {
        id: 0,
        title: <FormattedMessage id="faq1Title" />,
        text: <FormattedMessage id="faq1Text" values={values} />,
      },
      {
        id: 1,
        title: <FormattedMessage id="faq2Title" />,
        text: <FormattedMessage id="faq2Text" values={values} />,
      },
      {
        id: 2,
        title: <FormattedMessage id="faq3Title" />,
        text: <FormattedMessage id="faq3Text" values={values} />, // viewingsLink: viewingsLink()
      },
      {
        id: 3,
        title: <FormattedMessage id="faq4Title" />,
        text: <FormattedMessage id="faq4Text" values={values} />,
      },
      {
        id: 4,
        text: <FormattedMessage id="faq5Text" values={values} />,
      },
      {
        id: 5,
        text: <FormattedMessage id="faq6Text" values={values} />,
      },
    ],
    "en-ch": [
      {
        id: 0,
        title: <FormattedMessage id="faq1Title" />,
        text: <FormattedMessage id="faq1Text" values={values} />,
      },
      {
        id: 1,
        title: <FormattedMessage id="faq2Title" />,
        text: <FormattedMessage id="faq2Text" values={values} />,
      },
      {
        id: 2,
        title: <FormattedMessage id="faq3Title" />,
        text: <FormattedMessage id="faq3Text" values={values} />, // viewingsLink: viewingsLink()
      },
      {
        id: 3,
        title: <FormattedMessage id="faq4Title" />,
        text: <FormattedMessage id="faq4Text" values={values} />,
      },
      {
        id: 4,
        text: <FormattedMessage id="faq5Text" values={values} />,
      },
    ],
  };

  const faqsWearable = {
    "en-GB": [
      {
        id: 1,
        title: <FormattedMessage id="faq1Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq1Answer" values={answerValues} />,
      },
      {
        id: 2,
        title: <FormattedMessage id="faq2Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq2Answer" values={answerValues} />,
      },
      {
        id: 3,
        title: <FormattedMessage id="faq3Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq3Answer" values={answerValues} />,
      },
      {
        id: 4,
        text: <FormattedMessage id="faq4Answer" values={answerValues} />,
      },
      {
        id: 5,
        text: <FormattedMessage id="faq5Answer" values={answerValues} />,
      },
      {
        id: 6,
        title: <FormattedMessage id="faq6Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq6Answer" values={answerValues} />,
      },
      {
        id: 7,
        title: <FormattedMessage id="faq7Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq7Answer" values={answerValues} />,
      },
      {
        id: 8,
        text: <FormattedMessage id="faq8Answer" values={answerValues} />,
      },
      {
        id: 9,
        text: <FormattedMessage id="faq9Answer" values={answerValues} />,
      },
      {
        id: 10,
        title: <FormattedMessage id="faq10Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq10Answer" values={answerValues} />,
      },
    ],
    "en-gl": [
      {
        id: 0,
        title: <FormattedMessage id="faq1Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq1Answer" values={answerValues} />,
      },
      {
        id: 1,
        title: <FormattedMessage id="faq2Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq2Answer" values={answerValues} />,
      },
      {
        id: 2,
        title: <FormattedMessage id="faq3Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq3Answer" values={answerValues} />,
      },
      {
        id: 3,
        text: <FormattedMessage id="faq4Answer" values={answerValues} />,
      },
      {
        id: 4,
        text: <FormattedMessage id="faq5Answer" values={answerValues} />,
      },
      {
        id: 5,
        title: <FormattedMessage id="faq6Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq6Answer" values={answerValues} />,
      },
      {
        id: 6,
        title: <FormattedMessage id="faq7Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq7Answer" values={answerValues} />,
      },

      {
        id: 7,
        text: <FormattedMessage id="faq9Answer" values={answerValues} />,
      },
      {
        id: 8,
        title: <FormattedMessage id="faq10Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq10Answer" values={answerValues} />,
      },
    ],
    "en-bh": [
      {
        id: 0,
        title: <FormattedMessage id="faq1Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq1Answer" values={answerValues} />,
      },
      {
        id: 1,
        title: <FormattedMessage id="faq2Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq2Answer" values={answerValues} />,
      },
      {
        id: 2,
        title: <FormattedMessage id="faq3Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq3Answer" values={answerValues} />,
      },
      {
        id: 3,
        text: <FormattedMessage id="faq4Answer" values={answerValues} />,
      },
      {
        id: 4,
        text: <FormattedMessage id="faq5Answer" values={answerValues} />,
      },
      {
        id: 5,
        title: <FormattedMessage id="faq6Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq6Answer" values={answerValues} />,
      },
      {
        id: 6,
        title: <FormattedMessage id="faq7Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq7Answer" values={answerValues} />,
      },

      {
        id: 7,
        text: <FormattedMessage id="faq9Answer" values={answerValues} />,
      },
      {
        id: 8,
        title: <FormattedMessage id="faq10Question" values={{ nbsp }} />,
        text: <FormattedMessage id="faq10Answer" values={answerValues} />,
      },
    ],
  };
  const wearableFaqs = faqsWearable[userLocale] || faqsWearable["en-GB"];

  const cardFaqs = localisedCardFaqs[userLocale] || localisedCardFaqs["en-gb"];

  const [cardFaqsOpen, setCardFaqsIsOpen] = useState(false);
  const [wearableFaqsOpen, setWearableFaqsIsOpen] = useState(false);

  useEffect(() => {
    if (!window) {
      return;
    }
    const { hash } = window.location;
    if (hash === "#cardFaqs") {
      setCardFaqsIsOpen(true);
    } else if (hash === "#wearableFaqs") {
      setWearableFaqsIsOpen(true);
    }
  }, []);

  return (
    <div className="container position-relative">
      <div className={style.backButton}>
        <Link
          className={`${style.backLink} flex flex-align-items-center`}
          to={{ pathname: `/${params.locale}/${staticParams}` }}
        >
          <span className="backLinkIcon icon dls-icon-left" />
          <span className={style.backText}>
            <FormattedMessage id="back" />
          </span>
        </Link>
      </div>
      <h1
        className={`${style.mainTitle} bigcaslonitalic`}
        data-localeid="infoAndFaqs"
      >
        <FormattedMessage id="infoAndFaqs" />
      </h1>
      <div className={`${style.diagonalLine}`}>
        <svg
          className={`${style.diagonalLineSvg}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 85 36"
        >
          <path
            fill="none"
            fillRule="evenodd"
            stroke="#000"
            strokeWidth=".5"
            d="M0 1l85 34"
          />
        </svg>
      </div>
      <p
        data-localeid="selectLink"
        className={`${style.selectLink} bentonsansthin`}
      >
        <FormattedMessage id="selectLink" />
      </p>
      <div className={`flex flex-justify-center ${style.faqsLinks}`}>
        <Link
          to={{ pathname: location.pathname, hash: "#cardFaqs" }}
          className={`flex flex-column ${style.cardLink}`}
        >
          <p
            className={`${style.faqLinkText} bentonsanslight text-align-center`}
            data-localeid="centurionCard"
          >
            <FormattedMessage id="centurionCard" />
          </p>
          <img
            className={style.wileyCardImage}
            alt={intl.formatMessage({ id: "pradaImageAlt" })}
            src={`${assetPath}/privacy/kehinde-wiley-card.png`}
          />
        </Link>
        <Link
          to={{ pathname: location.pathname, hash: "#wearableFaqs" }}
          className={`flex flex-column ${style.wearableLink}`}
        >
          <p
            className={`${style.faqLinkText} bentonsanslight text-align-center`}
            data-localeid="pradaCenturionWearable"
          >
            <FormattedMessage id="pradaCenturionWearable" />
          </p>
          <img
            src={`${assetPath}/privacy/wearableImage.png`}
            className={style.wearableCardImage}
            alt={intl.formatMessage({ id: "wileyCardImageAlt" })}
          />
        </Link>
      </div>
      <Carousel
        defaultOpen={cardFaqsOpen}
        items={cardFaqs}
        toggleButtonId="cardFaqs"
        carouselContainerId="cardFaqsContainer"
        buttonTextId="cardFaqs"
      />
      <Carousel
        defaultOpen={wearableFaqsOpen}
        items={wearableFaqs.items}
        toggleButtonId="wearableFaqs"
        carouselContainerId="wearableFaqsContainer"
        buttonTextId="pradaFaqs"
      />
    </div>
  );
};

export default MainPrivacy;
