// Cookie banner setup

const setup = () => {
  if (
    !window.MutationObserver ||
    !process.env.REACT_APP_COOKIE_URL ||
    !process.env.REACT_APP_ASSETS_URL
  ) {
    return false;
  }
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type !== "attributes") {
        return;
      }
      if (
        !window.euLocale &&
        document.documentElement.lang &&
        document.documentElement.lang.includes("-")
      ) {
        const docLang = (
          document.documentElement.lang ||
          window.location.pathname.split("/").filter((s) => s)[0]
        ).toLowerCase();
        if (docLang.length < 5) {
          return;
        }
        const splitLang = docLang.split("-");
        window.euLocale = `${splitLang[1].toUpperCase()}_${splitLang[0]}`;
        if (window.euLocale === "FI_en") {
          window.euLocale = "FI_fi";
        }
        if (window.euLocale === "SE_en") {
          window.euLocale = "SE_sv";
        }
        if (window.euLocale === "NO_en") {
          window.euLocale = "NO_no";
        }
        if (window.euLocale === "NL_en") {
          window.euLocale = "NL_nl";
        }
        observer.disconnect();
        var s = document.createElement("script");
        s.src = process.env.REACT_APP_COOKIE_URL;
        document.body.appendChild(s);
      }
    });
  });

  observer.observe(document.documentElement, {
    attributes: true,
  });
};

setup();
