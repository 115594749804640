import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, useOutletContext } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import Logotype from "../../components/Logotype/Logotype";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import style from "./Magazine.module.scss";

const Magazine = ({ updateRoot }) => {
  const { messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();

  useEffect(() => {
    updateRoot("magazine");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  const intl = useIntl();
  const marketLocale = intl.locale.toLowerCase();

  const [market, setMarket] = useState("");

  const navigate = useNavigate();

  const defaultOpt = [{
    val: "",
    text: intl.formatMessage({ id: "magazine.pleaseSelect" }),
  }];
  const marketOptions = {
    "en-no": [
      {
        market: "finland",
        val: "en-fi",
        text: intl.formatMessage({ id: "magazine.finland" }),
      },
      {
        market: "norway",
        val: "en-no",
        text: intl.formatMessage({ id: "magazine.norway" }),
      },
      {
        market: "sweden",
        val: "en-se",
        text: intl.formatMessage({ id: "magazine.sweden" }),
      },
    ],
    "en-nl": [
      {
        market: "netherlands",
        val: "en-nl",
        text: intl.formatMessage({ id: "magazine.netherlands" }),
      },
      {
        market: "belgium",
        val: "en-be",
        text: intl.formatMessage({ id: "magazine.belgium" }),
      },
    ],
    "de-de": [
      {
        market: "germany",
        val: "de-de",
        text: intl.formatMessage({ id: "magazine.germany" }),
      },
      {
        market: "austria",
        val: "de-at",
        text: intl.formatMessage({ id: "magazine.austria" }),
      },
    ],
  };

  const localOptions = marketOptions[marketLocale || "en-no"] || [];
  const filteredOptions = [...defaultOpt, ...localOptions];

  function handleChange(event) {
    const market = event.target.value;
    const marketData = marketOptions[marketLocale || "en-no"].find((d) => d.market === market);
    const val = (marketData && marketData.market) || market;
    setMarket(val);
  }

  function marketPath(marketLocale) {
    const paths = {
      "default": "benefits/new-centurion",
      "de-de": "vorteile/neue-centurion",
      "it-it": "benefit/nuova-centurion",
    }
    return paths[marketLocale] || paths["default"];
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (market) {
      const marketData = marketOptions[marketLocale || "en-no"].find((d) => d.market === market);
      if (marketData && marketData.val) {
        const path = `/${marketData.val}/${marketPath(marketData.val)}/living`;
        navigate({ pathname: path });
      }
    }
  }

  if (!languageData.centurion) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{languageData["magazine.title"]}</title>
      </Helmet>
      <div className={`${style.container}`}>
        <div className={`flex flex-justify-between ${style.titleWrapper}`}>
          <Logotype
            id="CenturionLiving"
            title="logoTitle"
            rootModule="magazine"
          />
        </div>

        <div className="container position-relative">
          <h1
            className={`${style.marketsTitle} bigcaslonregular col-md-10 col-md-offset-1`}
            data-localeid="marketsTitle"
          >
            <span>
              <FormattedMessage id="magazine.marketsTitle" />
            </span>
          </h1>
          <form
            className={`flex flex-align-items-center flex-justify-center ${style.marketForm}`}
            onSubmit={handleSubmit}
          >
            <fieldset>
              <div className="position-relative">
                <label
                  className="sr-only"
                  data-localeid="marketsTitle"
                  htmlFor="market"
                >
                  <FormattedMessage id="magazine.marketsTitle" />
                </label>
                <select
                  className={`${style.marketSelector} bentonsanslight`}
                  value={market}
                  name="market"
                  onChange={handleChange}
                >
                  {filteredOptions.map((opt) => (
                    <option value={opt.market} key={opt.text}>
                      {opt.text}
                    </option>
                  ))}
                </select>
                <div className={`position-absolute ${style.selectChevronWrapper}`}>
                  <span className={`icon dls-icon-down ${style.selectChevron}`} />
                </div>
              </div>
            </fieldset>
            <input
              type="submit"
              className={`${style.goButton} bentonsanslight`}
              value={intl.formatMessage({ id: "magazine.go" })}
            />
          </form>
        </div>

      </div>
      <SiteFooter />
    </>
  );
};

Magazine.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(null, mapDispatchToProps)(Magazine);
