import React from "react";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from '@react-spring/web';
import blends from "../../static/living/livingBlends";
import BenefitsBlend from "../../components/Benefits/BenefitsBlend";
import LineSet from "../LineSet/LineSet";
import CalendarCta from "../Living/CalendarCta";
import style from "./FeatureLivingNew.module.scss";

const FeatureLivingNew = ({ currentSeason, hasBrochure = true }) => {
  const intl = useIntl();
  const params = useParams();

  const [moreRef, moreInView] = useInView();
  const animMore = useSpring({
    from: {
      opacity: moreInView ? 0 : 1,
      transform: moreInView ? "translate3d(0,32px,0)" : "translate3d(0,0px,0)",
    },
    to: {
      opacity: moreInView ? 1 : 0,
      transform: moreInView ? "translate3d(0,0,0)" : "translate3d(0,32px,0)",
    },
  });

  const overrides = blends[currentSeason]?.overrides;

  const livingBlends = overrides
    ? overrides.find(item => item.id === intl.locale.toLowerCase())?.config || blends[currentSeason]
    : blends[currentSeason];

  return (
    <div className={style.wrapper}>
      <div className="row">
        <div className="col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3">
          <p
            className={`heading-5 ${style.blurb} text-justify bentonsansthin`}
            data-localeid="livingBlurb"
          >
            <LineSet
              id={`home.livingBlurb`}
              desktop={intl.locale !== "en-US" ? [12, 10, 11] : []}
              tablet={[]}
              mobile={[]}
              values={{
                em: (str) => <em>{str}</em>,
                bold: (str) => <>{str}</>,
                lineBreak: <br />,
                livingLink: <FormattedMessage id={`home.livingTitle`} />,
              }}
            />
          </p>
        </div>
      </div>

      <div
        className={`${style.benefitBlendsContainer} ${
          hasBrochure ? style.hasBrochure : null
        }`}
      >
        <div ref={moreRef} className="pad-2-t pad-4-b text-align-center">
          <animated.div className="text-align-center" style={animMore}>
            <Link
              className={`btn btn-centurion-black moreButton bentonsanslight`}
              to={{
                pathname: `/${params.locale}/${params.card}/${params.centurion}/living`,
              }}
            >
              <FormattedMessage id={`living.findOutMore`} />
            </Link>
          </animated.div>
        </div>

        <BenefitsBlend
          id="living"
          path={`home/${currentSeason}`}
          imageContainerProps={(image) => ({
            isRect: true,
            width: image.width,
            height: image.height,
            aspectRatio: image.aspectRatio * 100,
          })}
          data={livingBlends.items}
          containerRatio={livingBlends.containerRatio * 100}
          className="margin-4-tb"
        />

        <div className="pad-4-tb">
          <CalendarCta text="living.viewCalendar" season={currentSeason} />
        </div>
      </div>
    </div>
  );
};

export default FeatureLivingNew;
