import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import style from "./CardDetail.module.scss";
import ImageContainer from "../ImageContainer/ImageContainer";

const CardDetail = ({
  cardId,
  cardImageUrl,
  cardImageUrlLarge,
  children,
  flipHorizontal,
  signatureImageUrl,
  handleModalData,
  handleModalType,
  handleIsModalOpen,
}) => {
  const intl = useIntl();

  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const zoomIn = `${assetPath}/card/zoomIn.svg`;
  const zoomInWhite = `${assetPath}/card/zoomInWhite.svg`;

  const section = useRef(null);

  const handleImageClick = ({ ratio, src }) => {
    handleModalData({
      src,
      ratio,
    });
    handleModalType("image");
    handleIsModalOpen(true);
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const parallax = gsap.timeline({
      scrollTrigger: {
        start: "0 75%",
        end: "100% 100%",
        trigger: section.current,
        scrub: 5,
        // markers: true, // Enable for debug
      },
    });

    parallax
      .to(section.current.querySelector(`.${style.mobileChildren}`), {
        opacity: 1,
        y: 0,
        ease: "power4.inOut",
        // duration: 1.25
      })
      .to(section.current.querySelector(`.${style.cardImage}`), {
        opacity: 1,
        y: 0,
        ease: "power4.inOut",
        // duration: 0.8,
        delay: 0.2
      },
      '<' // Commence at the same time as the previous tween
      );

    return () => {
      parallax.kill();
      ScrollTrigger.getAll().forEach(t => t.kill());
    };
  }, []);

  return (
    <section ref={section}>
      <div id={cardId} className={`${cardId} row ${style.cardInner}`}>
        <div
          className={`${style.cardImage} col-md-5 col-lg-6 ${
            flipHorizontal ? "col-md-push-7 col-lg-push-6" : undefined
          }`}
        >
          <div
            className={style.noFocus}
            role="button"
            tabIndex="0"
            onClick={() =>
              handleImageClick({
                src: cardImageUrlLarge,
                ratio: 1.12,
              })
            }
          >
            <ImageContainer alt="" ratio={1.58} src={cardImageUrl} />
          </div>
          <div
            className={`flex flex-justify-between flex-align-start ${style.enlargeWrapper}`}
          >
            <button
              className={`btn transparent ${style.enlarge}`}
              type="button"
              onClick={() =>
                handleImageClick({
                  src: cardImageUrlLarge,
                  ratio: 1.12,
                })
              }
            >
              <img
                className={style.enlargeIcon}
                alt={intl.formatMessage({ id: `card.zoomIn` })}
                src={cardId === "classicBlack" ? zoomInWhite : zoomIn}
              />
            </button>
            {signatureImageUrl && (
              <div className={style.signature}>
                <img id="signature-image" alt="" src={signatureImageUrl} />
              </div>
            )}
          </div>
        </div>
        <div
          className={`${style.mobileChildren} col-md-6 col-lg-5 ${
            flipHorizontal
              ? "col-md-pull-5 col-lg-pull-6"
              : "col-md-offset-1  col-lg-offset-1"
          }`}
        >
          <div className={style.mobileCardImageWrapper}>
            <div
              role="button"
              tabIndex="0"
              className={(style.cardImageMob, style.noFocus)}
              onClick={() =>
                handleImageClick({
                  src: cardImageUrlLarge,
                  ratio: 1.12,
                })
              }
            >
              <ImageContainer
                alt=""
                ratio={1.58}
                src={cardImageUrl}
                maxWidth="351px"
              />
            </div>
            <div
              className={`flex flex-justify-between flex-align-start ${
                style.enlargeWrapper
              } ${
                cardId === "classicBlack"
                  ? style.enlargeWrapperNoSignature
                  : undefined
              }`}
            >
              <button
                className={`btn transparent ${style.enlarge}`}
                type="button"
                onClick={() =>
                  handleImageClick({
                    src: cardImageUrlLarge,
                    ratio: 1.12,
                  })
                }
              >
                <img
                  className={style.enlargeIcon}
                  alt={intl.formatMessage({ id: `card.zoomIn` })}
                  src={cardId === "classicBlack" ? zoomInWhite : zoomIn}
                />
              </button>
              {signatureImageUrl && (
                <div className={style.signature}>
                  <img id="signature-image" alt="" src={signatureImageUrl} />
                </div>
              )}
            </div>
          </div>
          {children}
        </div>
      </div>
    </section>
  );
};

CardDetail.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardImageUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  // dispatchSetIsModalOpen: PropTypes.func.isRequired,
  // dispatchSetModalData: PropTypes.func.isRequired,
  // dispatchSetModalType: PropTypes.func.isRequired,
  handleModalData: PropTypes.func.isRequired,
  handleModalType: PropTypes.func.isRequired,
  handleIsModalOpen: PropTypes.func.isRequired,
  flipHorizontal: PropTypes.bool,
  signatureImageUrl: PropTypes.string,
};

CardDetail.defaultProps = {
  flipHorizontal: false,
  signatureImageUrl: "",
};

export default CardDetail;
