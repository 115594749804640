/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from '@react-spring/web';
import * as easings from "d3-ease";

export default function InView(props) {
  const { children, y = 0, duration = 1000, delay = 0, triggerOnce } = props;
  // 0: view loaded, 1: initial delayed animation, 2: in view animation? - i dunno... it sort of works shut up
  const [loadedState, setLoadedState] = useState(0);
  const animConfig = (ref) => {
    if (!ref || loadedState === 0) {
      return {
        to: {
          opacity: 0,
        },
      };
    }

    if (loadedState <= 2) {
      return {
        from: { opacity: 0, transform: `translate3d(0,${y}px,0)` },
        to: { opacity: 1, transform: `translate3d(0,0,0)` },
        delay,
        config: {
          duration,
          easing: easings.easePolyInOut.exponent(2),
        },
      };
    }

    return {
      from: {
        opacity: !ref ? 1 : 0,
        transform: ref ? `translate3d(0,${y}px,0)` : "translate3d(0,0px,0)",
      },
      to: {
        opacity: !ref ? 0 : 1,
        transform: ref ? "translate3d(0,0,0)" : `translate3d(0,${y}px,0)`,
      },
      config: {
        duration,
        easing: easings.easePolyInOut.exponent(2),
      },
    };
  };

  const [el, inView] = useInView({
    triggerOnce,
    rootMargin: "10% 0% 10% 0%",
  });
  const animation = useSpring(animConfig(inView));

  useEffect(() => {
    if (loadedState <= 2) {
      setLoadedState(loadedState + 1);
    }
  }, [inView]);

  return (
    <div ref={el} className={props.className} style={props.style}>
      <animated.div style={animation}>{children}</animated.div>
    </div>
  );
}

InView.propTypes = {
  children: propTypes.any,
  y: propTypes.number,
  duration: propTypes.number,
  delay: propTypes.number,
  triggerOnce: propTypes.bool,
};

InView.defaultProps = {
  y: 200,
  duration: 300,
  delay: 0,
  triggerOnce: false,
};
