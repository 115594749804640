import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import style from "./Logotype.module.scss";

const Logotype = ({ id, title, rootModule }) => {
  /* Warning: if the locale the user is in doesn't have an image
   if will not currently serve a fallback image, it will just fail.
   This means if a logotype has been translated the locale *must* be added to the localeMap
  */
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const localeMap = [
    "en-gb",
    "en-au",
    "en-us",
    "de-de",
    "it-it",
    "zh-hk",
    "fr-fr",
    "es-es",
    "zh-tw",
    "en-sg",
    "de-at",
    "es-id",
    'en-in',
    "ja-jp",
  ]; // ! add all locales that have a sub-folder in assets
  const intl = useIntl();
  const userLocale = intl.locale.toLocaleLowerCase();
  const locale = localeMap.indexOf(userLocale) > -1 ? userLocale : "en-gb";
  const logotypeUrl = `${assetPath}/${rootModule}/${locale}/${id}.svg`;

  return (
    <div className={style.logotypeContainer}>
      <img
        className={style.logotypeImage}
        id={id}
        alt={intl.formatMessage({ id: `${rootModule}.${title}` })}
        src={logotypeUrl}
      />
    </div>
  );
};

Logotype.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  rootModule: PropTypes.string.isRequired,
};

export default Logotype;
