import React, { useEffect, useState } from "react";
import { animated, useSpring } from '@react-spring/web';
import { useMeasure } from "react-use";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import style from "./ViewingSubAccordion.module.scss";

const ViewingSubAccordion = ({ item }) => {
  const subs = item.subRegions;

  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const [measureRef, { height }] = useMeasure();

  const [contentHeight, setContentHeight] = useState("0px");

  useEffect(() => {
    setContentHeight(height);
    const onResize = () => {
      setContentHeight(height);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [height]);

  function handleToggle(e) {
    setIsOpen(!isOpen);
  }

  const toggle = useSpring({
    height: isOpen ? `${contentHeight}px` : "0px",
    overflow: "hidden",
  });

  return (
    <div
      key={item.id}
      className={style.region}
      // className={`${style.country} ${
      //   venues.length === 2 ? style.twoItems : ""
      // }`}
    >
      <div className="flex flex-align-center flex-justify-center">
        <span className={`${style.toggle} ${isOpen && style.open}`} />
        <h4
          className={`text-align-center ${style.regionTitle} bentonsanslight`}
          tabIndex="0"
          role="button"
          id={`${item.id}-toggle`}
          aria-expanded={isOpen}
          aria-controls={`${item.id}-container`}
          onClick={handleToggle}
          onKeyPress={(e) => {
            if (e.charCode === 13 || e.charCode === 32) {
              e.preventDefault();
              handleToggle();
            }
          }}
        >
          <span>
            <FormattedMessage id={`viewmono.${item.id}`} />
          </span>
        </h4>
      </div>
      <animated.div style={toggle}>
        <div
          ref={measureRef}
          id={`${item.id}-container`}
          role="region"
          aria-labelledby={`${item.id}-toggle`}
        >
          <>
            {subs.map((sub) => (
              <div key={sub.id}>
                <h4
                  className={`text-align-center ${style.subRegionTitle} bentonsanslight`}
                >
                  <span>
                    <FormattedMessage id={`viewmono.${sub.id}`} />
                  </span>
                </h4>
                <div
                  className={`flex flex-wrap text-align-center ${style.venuesWrapper}`}
                >
                  {sub.items.map((venue) => (
                    <div key={venue.id} className={style.venue}>
                      <p
                        className="bentonsansthin"
                        data-localeid={`viewmono.${venue.id}address`}
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: `viewmono.${venue.id}address`,
                          }),
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        </div>
      </animated.div>
    </div>
  );
};

ViewingSubAccordion.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ViewingSubAccordion;
