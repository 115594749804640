const wileySlides = [
  {
    id: 1,
    imageUrl: "kehinde-01.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 14,
    imageUrl: "kehinde-14.png",
    hasMask: false,
    hasQuote: false,
    variantWidth: false,
  },
  {
    id: 2,
    imageUrl: "kehinde-02.png",
    hasMask: false,
    hasQuote: false,
    variantWidth: true,
  },
  {
    id: 15,
    imageUrl: "kehinde-15.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 3,
    imageUrl: "kehinde-03.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 4,
    imageUrl: "kehinde-04.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 5,
    imageUrl: "kehinde-05.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 6,
    imageUrl: "kehinde-06.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 7,
    imageUrl: "kehinde-07.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 16,
    imageUrl: "kehinde-16.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 8,
    imageUrl: "kehinde-08.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 17,
    imageUrl: "kehinde-17.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 9,
    imageUrl: "kehinde-09.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 18,
    imageUrl: "kehinde-18.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 10,
    imageUrl: "kehinde-10.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 19,
    imageUrl: "kehinde-19.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 11,
    imageUrl: "kehinde-11.png",
    hasMask: false,
    hasQuote: false,
  },
  {
    id: 13,
    imageUrl: "kehinde-13.png",
    hasMask: false,
    hasQuote: false,
  },
];

export default wileySlides;
