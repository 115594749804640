import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { getConfigForEnv } from "../../appConfig";
import markets from "../../static/markets";
import style from "./Market.module.scss";

const Market = ({ params }) => {
  const staticParams = `${params.card}/${params.centurion}/faqs`; // benefits/new-centurion/faqs
  const { locale, formatMessage } = useIntl();
  const marketLocale = locale.toLocaleLowerCase();
  const [market, setMarket] = useState("");
  const marketOptions = markets({ formatMessage });

  const { locales } = getConfigForEnv(); // All enabled locales
  const availableMarkets = marketOptions.filter(item => {
    if (item.val === "") {
      return false;
    }
    const split = item.val.split("-");
    return locales.includes(`${split[0]}-${split[1].toUpperCase()}`);
  });

  const filteredOptions = availableMarkets.filter(
    (opt) => !opt.hiddenFor || !opt.hiddenFor.includes(marketLocale)
  );

  const navigate = useNavigate();

  const handleChange = (event) => {
    setMarket(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!market) {
      return false;
    }
    const path = `/${market}/${staticParams}/info`;
    if (market === marketLocale) {
      return navigate({ pathname: path });
    }
    window.location.href = path; // Force reload if switching locales
  };

  return (
    <div className="container position-relative">
      <div className={style.backButton}>
        <Link
          className={`${style.backLink} flex flex-align-items-center`}
          to={{ pathname: `/${params.locale}/${staticParams}` }}
        >
          <span className="backLinkIcon icon dls-icon-left" />
          <span className={style.backText}>
            <FormattedMessage id="info.back" />
          </span>
        </Link>
      </div>
      <h1
        className={`${style.marketsTitle} bigcaslonregular col-md-8 col-md-offset-2`}
        data-localeid="marketsTitle"
      >
        <FormattedMessage id="info.marketsTitle" />
      </h1>
      <form
        className={`flex flex-align-items-center flex-justify-center ${style.marketForm}`}
        onSubmit={handleSubmit}
      >
        <fieldset>
          <div className="position-relative">
            <label
              className="sr-only"
              data-localeid="marketsTitle"
              htmlFor="market"
            >
              <FormattedMessage id="info.marketsTitle" />
            </label>
            <select
              className={`${style.marketSelector} bentonsanslight`}
              value={market}
              name="market"
              onChange={handleChange}
            >
              {filteredOptions.map((opt) => (
                <option value={opt.val} key={opt.val}>
                  {opt.text}
                </option>
              ))}
            </select>
            <div className={`position-absolute ${style.selectChevronWrapper}`}>
              <span className={`icon dls-icon-down ${style.selectChevron}`} />
            </div>
          </div>
        </fieldset>
        <input
          type="submit"
          className={`${style.goButton} bentonsanslight`}
          value={formatMessage({ id: "info.go" })}
        />
      </form>
      <p
        className={`bentonsansthin ${style.notListed}`}
        data-localeid="notListed"
      >
        <FormattedMessage id="info.notListed" />
      </p>
      <p
        className={`bentonsansthin col-md-8 col-md-offset-2 ${style.notListedDetail}`}
        data-localeid="notListedDetail"
      >
        <FormattedMessage id="info.notListedDetail" />
      </p>
    </div>
  );
};

Market.propTypes = {
  params: PropTypes.shape({
    card: PropTypes.string,
    centurion: PropTypes.string,
  }),
};

// export default withRouter(Market);
export default Market;
