import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ViewingAccordion from "../Viewings/ViewingAccordion";
import stores from "../../static/stores";
import style from "./Monogramming.module.scss";
import { getConfigForEnv } from "../../appConfig";


const Monogramming = ({ basePath, localeName }) => {
  const { dates, countries } = stores;
  const { env } = getConfigForEnv();
  const isArchive = env === "archive";



  return (
    <div>
      {/* stores by country */}
      <section id="mono" className={style.monogrammingWrapper}>
        <div className="container position-relative">
          {!isArchive && (
          <div className={style.backButton} >
            <Link
              className={`${style.backLink} flex flex-align-items-center`}
              to={{ pathname: `/${basePath}/monogramming-viewings/` }}
            >
              <span className="backLinkIcon icon dls-icon-left" />
              <span className={style.backText}>
                <FormattedMessage id="viewmono.back" />
              </span>
            </Link>
          </div>
)}
          <h1
            className={`text-align-center bigcaslonitalic ${style.sectionTitle}`}
          >
            <FormattedMessage id="viewmono.monogrammingTitle" />
          </h1>

          {dates.map((date) => {
            const items = countries.filter((country) => country.from === date);
            return (
              <div key={date}>
                <h3
                  className={`text-align-center ${style.titleFrom} ${style.titleFromBottom} bentonsansthin`}
                >
                  <FormattedMessage
                    id="viewmono.availableNow"
                    values={{ i: (str) => <i>{str}</i>, lineBreak: <br /> }}
                  />
                </h3>
                <div
                  className={`flex flex-column ${style.viewingsContainer}`}
                  data-locale={localeName}
                >
                  {items.map((item) => <ViewingAccordion key={item.countryId} item={item} />)}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

Monogramming.propTypes = {
  localeName: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const localeName = state.getIn(["intl", "activeLocale"]);

  return {
    localeName,
  };
};

export default connect(mapStateToProps)(Monogramming);
