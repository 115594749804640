import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useParams, useOutletContext } from "react-router-dom";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import Logotype from "../../components/Logotype/Logotype";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import InfoLanding from "../../components/InfoLanding/InfoLanding";
import Market from "../../components/Market/Market.jsx";
import MainInfo from "../../components/MainInfo/MainInfo";
import style from "./Info.module.scss";

const Info = ({ location, basePath, updateRoot, features }) => {
  const { messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();

  const params = useParams();
  const subpage = params.subpage || "landing";
  const hasWearable = !features?.hiddenSections?.wearable;

  useEffect(() => {
    if (subpage) {
      updateRoot(subpage === "landing" ? "info-landing" : "info");
      handleRouteChange();
    }
  }, [subpage, updateRoot, handleRouteChange]);

  const Subpage = () => {
    if (params.subpage && params.subpage === "market") {
      return <Market params={params} />;
    }
    if (params.subpage && params.subpage === "info") {
      return (
        <MainInfo params={params} location={location} basePath={basePath} hasWearable={hasWearable} />
      );
    }
    return <InfoLanding params={params} />;
  };

  if (!languageData.centurion) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{languageData.title}</title>
      </Helmet>

      <div className={`${style.faqsContainer}`} data-subpage={subpage}>
        <div className={`flex flex-justify-between ${style.titleWrapper}`}>
          <Logotype id="newCenturion" title="logoTitle" rootModule="info" />
        </div>

        <Subpage />
      </div>
      <SiteFooter />
    </>
  );
};

Info.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const basePath = state.get("basePath")?.path;
  const localeName = state.getIn(["intl", "activeLocale"]);
  const config = state.get("config");
  const features = config?.features[localeName];
  return {
    basePath,
    features
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Info);
