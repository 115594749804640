import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    activeModal: null,
  },
  reducers: {
    setActiveModal: (state, { payload: activeModal }) => {
      state.activeModal = activeModal;
    },
  },
});

export const { setActiveModal } = modalSlice.actions;
export default modalSlice.reducer;
