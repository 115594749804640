import { createSlice } from "@reduxjs/toolkit";

const rootModuleSlice = createSlice({
  name: "rootModule",
  initialState: {
    name: null
  },
  reducers: {
    updateRootModule: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { updateRootModule } = rootModuleSlice.actions;
export default rootModuleSlice.reducer;
