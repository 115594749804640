import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { getConfigForEnv } from "../../appConfig";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import style from "./NotFound.module.scss";
import Logotype from "../../components/Logotype/Logotype";
import HomeLink from "../../components/HomeLink/HomeLink";
import SiteFooter from "../../components/SiteFooter/SiteFooter";

const Notfound = ({ basePath, updateRoot }) => {
  const { messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();
  const params = useParams();

  useEffect(() => {
    updateRoot("notfound");
    handleRouteChange();
    // Leave this in so we can use it as an attribute selector
    document.body.setAttribute("data-module-id", "notfound");
    return () => {
      document.body.removeAttribute("data-module-id");
    };
  }, [updateRoot, handleRouteChange]);

  const lowerCaseLocale = params.locale.toLowerCase();
  const { locales } = getConfigForEnv(); // All enabled locales
  const localeIsAvailable = locales.some(
    (key) => key.toLowerCase() === lowerCaseLocale
  );

  if (!languageData.centurion) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{languageData.title}</title>
      </Helmet>
      <>
        <div data-module="notfound" className={`${style.main} container`}>
          <div className={`position-relative ${style.wrapper}`}>
            <HomeLink rootModule="notfound" />
            <div className={`${style.titleWrapper}`}>
              <Logotype
                id="newCenturion"
                title="heroTitle"
                rootModule="notfound"
              />
            </div>
          </div>
          <h2
            className={`bentonsansthin text-align-center ${style.statusCode}`}
            data-localeid="statusCode"
          >
            <FormattedMessage id="notfound.statusCode" />
          </h2>
          <div className={`${style.bodyCopy} row`}>
            <p
              className="bentonsansthin col-md-8 col-md-offset-2 text-align-center"
              data-localeid="bodyCopy"
            >
              <FormattedMessage
                id="notfound.bodyCopy"
                values={{
                  centurionLink: (str) => (
                    <Link
                      className={style.homeLink}
                      to={{
                        pathname: localeIsAvailable ? `/${basePath}/` : "/",
                      }}
                    >
                      {str}
                    </Link>
                  ),
                }}
              />
            </p>
          </div>
        </div>
        <SiteFooter />
      </>
    </>
  );
};

Notfound.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  basePath: state.get("basePath")?.path || "/",
});

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notfound);
