import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { animated, useSpring } from '@react-spring/web';
import { useInView } from "react-intersection-observer";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import HomeLink from "../../components/HomeLink/HomeLink";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import FineWineBlend from "../../components/FineWine/FineWineBlend";
import Logotype from "../../components/Logotype/Logotype";
import style from "./FineWine.module.scss";

const FineWine = ({ cdnUrl, updateRoot }) => {
  const intl = useIntl();
  const { messages: languageData, formatMessage } = intl;
  const { handleRouteChange } = useOutletContext();
  const isFrance = intl.locale === "fr-FR";
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const pdfPath = `${cdnUrl}static/Introduction-to-Fine_Wine.pdf`;

  const [moreRef, moreInView] = useInView();
  const animMore = useSpring({
    from: {
      opacity: moreInView ? 0 : 1,
      transform: moreInView ? "translateY(32px)" : "translateY(0)",
    },
    to: {
      opacity: moreInView ? 1 : 0,
      transform: moreInView ? "translateY(0)" : "translateY(32px)",
    },
  });

  useEffect(() => {
    updateRoot("finewine");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  useEffect(() => {
    document.body.setAttribute("data-module-id", "finewine");
    return () => {
      document.body.removeAttribute("data-module-id");
    };
  }, []);

  if (!languageData.centurion) {
    return null;
  }

  const formatLink = (link) =>
    link.startsWith("http") ? link : `http://${link}`;

  const formattingValues = {
    link: ([str]) => (
      <a href={formatLink(str)} target="_blank" rel="noopener noreferrer">
        {str}
      </a>
    ),
    i: (str) => <i>{str}</i>,
    strong: (str) => <strong>{str}</strong>,
    sup: (str) => <sup>{str}</sup>,
    center: (str) => <span className="centeredInlineBlock">{str}</span>,
    lineBreak: <br />,
    spacer: <span className={style.spacer} />,
  };

  const title = intl.formatMessage({
    id: "finewine.title",
  });

  const subtitle = (
    <FormattedMessage id="finewine.subtitle" values={formattingValues} />
  );

  const copyMessage = intl.formatMessage({
    id: "finewine.legal",
  });

  const introContent = [
    "finewine.content1",
    "finewine.content2",
    "finewine.content3",
  ];

  if (languageData.centurion) {
    return (
      <>
        <Helmet>
          <title>{title.replace(/\u00a0/, " ")}</title>
        </Helmet>
        <div data-module="finewine">
          <div className={style.overflowContainer}>
            <div className="container">
              <header className="position-relative">
                <HomeLink rootModule="finewine" />

                <div className="row">
                  <div
                    className={`col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 ${style.titleWrapperContainer}`}
                  >
                    <div className={style.titleWrapper}>
                        <Logotype
                          title="title"
                          rootModule="finewine"
                          id="logo" 
                        />
                      <img
                        src={`${assetPath}/finewine/logos/pfv.svg`}
                        alt={formatMessage({
                          id: "finewine.introduction.brand",
                        })}
                        className={style.introLogo}
                      />
                      <h1 className={`${style.subtitle} bigcaslonregular`}>
                        {subtitle}
                      </h1>
                    </div>
                  </div>
                </div>
              </header>
            </div>

            <div className={style.diagBgWrapper}>
              <div className={`${style.diagBg} ${style.diagBgFw}`}>
                <div className="row">
                  {introContent.map((messageId, index) => (
                    <div
                      key={index}
                      className="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 margin-3-b"
                    >
                      <p
                        className={`bentonsansthin text-justify ${style.textWrapper}`}
                      >
                        <FormattedMessage
                          key={`introContent${index}`}
                          id={messageId}
                          values={formattingValues}
                        />
                      </p>
                    </div>
                  ))}
              {!isFrance && (
                  <div
                    ref={moreRef}
                    className="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 margin-4-t"
                  >
                    <animated.div className="text-align-center" style={animMore}>
                      <a
                        className={`btn bentonsanslight ${style.findOutMoreBtn}`}
                        href={pdfPath}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          {intl.formatMessage({
                            id: "finewine.button",
                          })}
                        </span>
                        <img
                          src={`${
                            process.env.REACT_APP_ASSETS_URL || ""
                          }assets/icons/external.svg`}
                          alt=""
                        />
                      </a>
                    </animated.div>
                    {copyMessage && 
                    <p className={`bentonsansthin text-align-center ${style.paddingTop}`}> {copyMessage}</p>
                    } 
                    </div>
                    )}
                </div>
              </div>

              <div className={`container ${style.benefitsContainer}`}>
                <div id="blends">
                  <FineWineBlend
                    id="finewine"
                    path="benefits"
                    className="margin-4-tb"
                    imageContainerProps={{ aspectRatio: 100 }}
                  />
                </div>
              </div>
            </div>
          </div>

          <SiteFooter />
        </div>
      </>
    );
  } else {
    return null;
  }
};

FineWine.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cdnUrl: state.get("config")["assets"],
});

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FineWine);
