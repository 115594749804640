import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { default as introductionsData } from "../../../static/living/introductions";
// import { default as artistData } from "../../static/living/artists";

import CalendarGroup from "./CalendarGroup";

import style from "../Calendar.module.scss";

export default function CalendarView({
  season,
  events,
  months,
  country,
  talent,
  error = [],
}) {
  const { messages: languageData } = useIntl();

  const [filteredEvents, setFilteredEvents] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [disabledEvents, setDisabledEvents] = useState([]);

  useEffect(() => {
    let errorMessage = languageData["calendar.error"];

    const [months] = error;

    const presentMonths = months.filter(({ past }) => !past);
    const monthNames = presentMonths.map(({ name }) => name);

    const monthNamesSentence = `${monthNames.slice(0, -1).join(", ")} ${
      languageData["general.or"]
    } ${monthNames.slice(-1)}`;

    errorMessage = `${errorMessage}: ${monthNamesSentence}`;

    setErrorMessage(errorMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const disabledEvents = introductionsData
      .find((seasonConfig) => seasonConfig.season === season.toString())
      ?.items.filter((item) => item?.disabledLocales) || [];

    setDisabledEvents(disabledEvents);
  }, [season]);

  useEffect(() => {
    const filteredEvents = events.filter(({ id, events }) => {
      const hasCountry = !!country;
      const hasTalent = !!talent;

      const inMonth = months.includes(id);

      if (!hasCountry && !hasTalent) {
        return inMonth;
      }

      const inFilters = events.reduce((inFilters, { events }) => {
        let filteredEvents = events;

        if (hasTalent) {
          filteredEvents = filteredEvents.filter(({ slug }) => slug === talent);
        }

        if (hasCountry) {
          filteredEvents = filteredEvents.filter(
            ({ city }) => city === country
          );
        }

        return inFilters || !!filteredEvents.length;
      }, false);

      return inMonth && inFilters;
    });

    setFilteredEvents(filteredEvents);
  }, [events, months, country, talent]);

  return (
    <>
      {!filteredEvents.length && (
        <div className="container">
          <p className={`bentonsansbold ${style.noEvents}`}>{errorMessage}</p>
        </div>
      )}

      {filteredEvents.map(({ id, name, events }) => {
        const parentKey = `${name}-${id}`;
        const childKey = `${name}-${id}-events`;

        return (
          <div key={parentKey}>
            <div key={childKey}>
              <h2 className={`bigcaslonitalic ${style.calendarMonth}`}>
                {name}
              </h2>

              <ul className={style.calendarGrid}>
                {events.map(({ id, name, weekday, events }) => {
                  const filteredEvents = !!talent
                    ? events.filter(({ slug }) => slug === talent)
                    : events;

                  return (
                    <CalendarGroup
                      key={id}
                      name={name}
                      weekday={weekday}
                      events={filteredEvents}
                      disabled={disabledEvents}
                      country={country}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        );
      })}
    </>
  );
}
