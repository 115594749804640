import React from "react";
import { FormattedMessage } from "react-intl";
import LineSet from "../LineSet/LineSet";
import sharedStyle from "../HowToWearIntro/SketchContainer.module.scss";
import style from "./Steps.module.scss";

const Steps = () => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const Step1 = `${assetPath}/howtowear/step1.png`;
  const Step2 = `${assetPath}/howtowear/step2.png`;
  const Step3 = `${assetPath}/howtowear/step3.png`;
  const Step4 = `${assetPath}/howtowear/step4.png`;

  const diagramIds = [1, 2, 3, 4];

  function Diagram({ id, index }) {
    const imgs = {
      STEP1: Step1,
      STEP2: Step2,
      STEP3: Step3,
      STEP4: Step4,
    };

    const titleMsg = `howtowear.stepTitle${id}`;
    const copyMsg = `howtowear.stepCopy${id}`;

    return (
      <li className={`col-md-3 ${style.diagram}`}>
        <h4 className={`bigcaslonitalic ${style.diagramTitle}`}>
          <FormattedMessage id={titleMsg} />
        </h4>
        <img src={imgs[`STEP${id}`]} alt="" />
        <p className={`bentonsansthin ${style.diagramCopy}`}>
          <LineSet id={copyMsg} desktop={index === 3 ? [] : []} />
        </p>
      </li>
    );
  }

  function Diagrams() {
    return (
      <ol className={`row ${style.diagrams}`}>
        {diagramIds.map((id, index) => (
          <Diagram key={id} id={id} index={index} />
        ))}
      </ol>
    );
  }

  return (
    <div className={`${sharedStyle.sketchContainer}`}>
      <header className={style.titles}>
        <h2 className={`bigcaslonregular ${style.title}`}>
          <FormattedMessage id="howtowear.stepsTitle" />
        </h2>
        <h3 className={`bigcaslonitalic ${style.subtitle}`}>
          <FormattedMessage id="howtowear.stepsSubtitle" />
        </h3>
      </header>
      <Diagrams />
    </div>
  );
};

export default Steps;
