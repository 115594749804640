import { useIntl } from "react-intl";
import style from "../Calendar.module.scss";

import { assetPath } from "../helpers/assets";

import CalendarItem from "./CalendarItem";

export default function CalendarGroup({ name, weekday, events, country, disabled }) {
  const { locale } = useIntl();
  let filteredEvents = !!country
    ? events.filter(({ city }) => city === country)
    : events;

  filteredEvents = filteredEvents.filter(({ slug }) => {
    const disabledLocales = disabled
      .find((item) => item.id === slug)?.disabledLocales;
    return disabledLocales
      ? disabledLocales.includes(locale.toLowerCase())
        ? false : true
      : true;
  });

  const hasEvents = filteredEvents.length > 0;

  return (
    hasEvents && (
      <li className={style.calendarGroup}>
        <p className={`bigcaslonitalic ${style.calendarDay}`}>
          <span className={style.calendarDayDesktop}>
            {weekday?.long} {name}
          </span>

          <span className={style.calendarDayMobile}>
            {weekday?.short}
            <br />
            {name}
          </span>
        </p>

        <ul>
          {filteredEvents.map(
            ({ year, name, slug, path, section, city, past }, index) => {
              const showEvent = !!country ? city === country : true;
              const imageUrl = `${assetPath}/${section}/${year}/${slug}.webp`;

              return (
                showEvent && (
                  <CalendarItem
                    key={index}
                    year={year}
                    name={name}
                    slug={slug}
                    path={path}
                    section={section}
                    city={city}
                    image={imageUrl}
                    past={past}
                  />
                )
              );
            }
          )}
        </ul>
      </li>
    )
  );
}
