import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Logo from "../Logotype/Logotype";
import style from "./ViewmonoLanding.module.scss";

const ViewmonoLanding = ({ basePath, localeName, hasWearable }) => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const intl = useIntl();
  const cardImageMap = {
    "de-DE": `${assetPath}/viewmono/kehinde-wiley-card-de.png`,
    "fr-FR": `${assetPath}/viewmono/kehinde-wiley-card-fr.png`,
    "es-ES": `${assetPath}/viewmono/kehinde-wiley-card-es.png`,
    default: `${assetPath}/viewmono/kehinde-wiley-card.png`, // default for all en-* and locales without
  };
  const cardImage =
    localeName in cardImageMap
      ? cardImageMap[localeName]
      : cardImageMap.default;

  const hasMonoOverride = ['en-FI', 'en-NO', 'en-SE', 'de-DE', 'en-NL', 'en-BE', 'en-HK', 'zh-HK', 'fr-FR', 'it-IT', 'en-in'].includes(localeName);

  return (
    <div>
      <div className={style.logo}>
        <Logo id="newCenturion" title="logoTitle" rootModule="viewmono" />
      </div>

      <div className={`container ${style.fauxPadding}`}>
        <div className={`flex flex-justify-center ${style.links}`}>
          {!hasMonoOverride &&
            <Link
              to={{ pathname: `/${basePath}/monogramming-viewings/viewings` }}
              className={`flex flex-column ${style.cardLink}`}
            >
              <p
                className={`${style.linkText} bigcaslonitalic text-align-center`}
                data-localeid="centurionCard"
              >
                <FormattedMessage
                  id="viewmono.centurionCard"
                  values={{ lineBreak: <br /> }}
                />
              </p>
              <img
                className={style.wileyCardImage}
                alt={intl.formatMessage({ id: "viewmono.pradaImageAlt" })}
                src={cardImage}
              />
            </Link>
          }
          { (hasWearable || hasMonoOverride) &&
            <Link
              to={{ pathname: `/${basePath}/monogramming-viewings/monogramming` }}
              className={`flex flex-column ${style.wearableLink}`}
            >
              <p
                className={`${style.linkText} bigcaslonitalic text-align-center`}
                data-localeid="pradaCenturionWearable"
              >
                <FormattedMessage
                  id="viewmono.pradaCenturionWearable"
                  values={{ lineBreak: <br /> }}
                />
              </p>
              <img
                src={`${assetPath}/viewmono/wearableImage.png`}
                className={style.wearableCardImage}
                alt={intl.formatMessage({
                  id: "viewmono.wileyCardImageAlt",
                })}
              />
            </Link>
          }
        </div>
      </div>
    </div>
  );
};

ViewmonoLanding.propTypes = {
  localeName: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  hasWearable: PropTypes.bool.isRequired,
};

export default ViewmonoLanding;
