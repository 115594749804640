import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { animated, useSpring } from '@react-spring/web';
import style from "./Slide.module.scss";

const Slide = ({
  intl,
  isMaskVisible,
  slide: { hasMask, hasQuote, id, imageUrl },
  rootModule,
}) => {
  const props = useSpring({
    opacity: hasMask && isMaskVisible ? 1 : 0,
  });

  const locale = intl.locale.toLocaleLowerCase();
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const imageSrc = `${assetPath}/${rootModule}/${locale.toLowerCase()}/${imageUrl}`;

  return (
    <div
      aria-label={intl.formatMessage({
        id: `${rootModule}.slide${id}ImageAlt`,
      })}
      aria-roledescription="slide"
      className={`position-relative ${style.wrapper}`}
      role="group"
    >
      <div className={`position-absolute ${style.inner}`}>
        <div>
          <img
            alt={intl.formatMessage({ id: `${rootModule}.slide${id}ImageAlt` })}
            className={style.image}
            src={imageSrc}
          />
        </div>
        {hasQuote && (
          <animated.div
            style={props}
            className={`position-absolute ${style.mask} ${
              !isMaskVisible && style.inactive
            }`}
          />
        )}
        <animated.div
          className={`pad-responsive position-absolute flex flex-column flex-align-center flex-justify-center ${style.quote} `}
          style={props}
        >
          {hasQuote && (
            <Fragment>
              <blockquote className={style.blockquote}>
                <q
                  className={`dls-white heading-5 ${style.blockquoteText} bentonsansthin`}
                >
                  <FormattedMessage id={`${rootModule}.slide${id}Quote`} />
                </q>
              </blockquote>
              <p
                className={`dls-white text-align-center ${style.attribution} bentonsansthin`}
              >
                <FormattedMessage
                  id={`${rootModule}.slide${id}QuoteAttribution`}
                />
              </p>
            </Fragment>
          )}
        </animated.div>
      </div>
    </div>
  );
};

Slide.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isMaskVisible: PropTypes.bool,
  slide: PropTypes.shape({
    hasMask: PropTypes.bool.isRequired,
    hasQuote: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }).isRequired,
};

Slide.defaultProps = {
  isMaskVisible: false,
};

const IntlWrapper = injectIntl(Slide);

export default React.forwardRef((props, ref) => (
  <IntlWrapper {...props} innerRef={ref} />
));
