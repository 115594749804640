import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    isOpen: false,
    showMenuMap: {
      // "home": false, // test
      "video": false,
      "viewmono": false,
      "viewmono-landing": false,
      "info-landing": false,
      "info": false,
      "privacy": false,
      "howtowear": false,
      "magazine": false,
    },
  },
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setIsOpen } = menuSlice.actions;
export default menuSlice.reducer;
