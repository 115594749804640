import { Link } from "react-router-dom";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { getConfigForEnv } from "../../appConfig";
import style from "./InfoLanding.module.scss";

const InfoLanding = ({ params }) => {
  const [languageLinks] = useState([
    {
      to: { pathname: `/en-gb/benefits/new-centurion/faqs/market` },
      locale: "en-GB",
      text: "View in English",
      circleText: "En",
    },
    {
      to: { pathname: `/es-es/benefits/new-centurion/faqs/market` },
      locale: "es-ES",
      text: "Ver en español",
      circleText: "Es",
    },
    {
      to: { pathname: `/de-de/vorteile/neue-centurion/faqs/market` },
      locale: "de-DE",
      text: "Ansicht auf Deutsch",
      circleText: "De",
    },
    {
      to: { pathname: `/fr-fr/benefits/new-centurion/faqs/market` },
      locale: "fr-FR",
      text: "Voir en français",
      circleText: "Fr",
    },
    {
      to: { pathname: `/it-it/benefit/nuova-centurion/faqs/market` },
      locale: "it-IT",
      text: "Visualizza in italiano",
      circleText: "It",
    },
    {
      to: { pathname: `/zh-hk/benefits/new-centurion/faqs/market` },
      locale: "zh-HK",
      text: "查看中文版",
      circleText: "中文",
    },
    {
      to: { pathname: `/ja-jp/benefits/new-centurion/faqs/market` },
      locale: "ja-JP",
      text: "日本語で見る",
      circleText: "日本",
    },
  ]);

  const { locales } = getConfigForEnv(); // All enabled locales

  const filteredLanguageLinks = languageLinks.filter(item => {
    const split = item.locale.split("-");
    return locales.includes(`${split[0]}-${split[1].toUpperCase()}`);
  });

  const handleClick = (e) => {
    const currentLoclale = window.location.href
      .split("/")[3]
      .toLocaleLowerCase();
    const targetLocale = e.target.href.split("/")[3].toLocaleLowerCase();

    if (currentLoclale === targetLocale) {
      return true;
    }

    e.preventDefault();
    // refresh window location to force language pack update
    window.location.href = e.target.href;
  };

  return (
    <div className={`flex flex-column container ${style.links}`}>
      <div className={`flex flex-column ${style.linksWrapper}`}>
        {filteredLanguageLinks.map((link) => (
          <Link
            to={link.to}
            onClick={handleClick}
            key={link.text}
            className={`bentonsansthin ${style.linkAnchor}`}
            data-locale={link.locale}
          >
            <p data-circletext={link.circleText} className={style.linkText}>
              {link.text}
            </p>
            <div
              className={`flex flex-align-items-center flex-justify-center ${style.circleText}`}
            >
              {link.circleText}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

InfoLanding.propTypes = {
  params: PropTypes.shape({
    card: PropTypes.string,
    centurion: PropTypes.string,
  }),
};

export default InfoLanding;
