// Generate names, category etc (as translations) based on ID

const defaultTalent = {
  avatar: "psychoanalysis.png",
  hasQuoteImage: true,
  hasImageSignature: false,
  hasSubtitle: true,
  hasCarouselSubtitle: true,
  showAudioPlayer: false,
  audioSrc: [],
  showArticle: false,
  articleData: {},
  hasGuides: false,
  showGuides: false,
  showVideo: false,
  bookingOpens: false, // hides buttons where true (&& !tbc)
  events: [],
};

const introductions = [
  {
    season: "2022",
    items: [
      {
        id: "wellbeing",
        path: "wellbeing",
        categoryColour: "gold",
        avatar: "wellbeing.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: false,
        articleData: {},
        hasGuides: false,
        showGuides: false,
        // showCitiesAndDates: true,
        showVideo: false,
        // citiesAndDates: [
        //   {
        //     id: 1,
        //     venueId: 'LA'
        //   },
        //   {
        //     id: 2,
        //     venueId: 'LA'
        //   },
        // ],
        events: [
          { id: "wellbeing_event1", map: "" },
          { id: "wellbeing_event2", map: "" },
        ],
        hasBlends: true, // 'Old' blends rendering
      },
      {
        id: "classicalmusic",
        path: "classical-music",
        avatar: "classicalMusic.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: true,
        audioSrc: [
          {
            player: "spotify",
            type: "track",
            src: "7DbbSnZeKYKu5lRpvOcjRk",
            title: "classicalmusic_listenTo1",
          },
        ],
        showArticle: false,
        articleData: {},
        hasGuides: true,
        showGuides: false,
        // showCitiesAndDates: true,
        showVideo: false,
        posterSrc: "section-video-poster-ma.jpg",
        // citiesAndDates: [
        //   {
        //     id: 1,
        //     venueId: 'NYC'
        //   },
        //   {
        //     id: 2,
        //     venueId: 'NYC'
        //   },
        // ],
        events: [
          { id: "classicalmusic_event1", map: "" },
          { id: "classicalmusic_event2", map: "" },
        ],
        hasBlends: true, // 'Old' blends rendering
      },
      {
        id: "ceremonialjapan",
        path: "ceremonial-japan",
        categoryColour: "gold",
        avatar: "ceremonialJapan.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: true,
        audioSrc: [
          {
            player: "spotify",
            type: "track",
            src: "56TYvI2rgYT3tdH77D44jZ",
            title: "ceremonialjapan_listenTo1",
            description: "ceremonialjapan_listenTo1Description",
          },
        ],
        showArticle: false,
        articleData: {},
        hasGuides: true,
        showGuides: false,
        // showCitiesAndDates: true,
        showVideo: true,
        videoSrc: "static/ceremonial_japan_video.mp4",
        posterSrc: "ceremonialjapan_poster.png",
        // citiesAndDates: [
        //   {
        //     id: 1,
        //     venueId: 'Tokyo'
        //   },
        //   {
        //     id: 2,
        //     venueId: 'Tokyo'
        //   },
        // ],
        events: [
          { id: "ceremonialjapan_event1", map: "" },
          // { id: "ceremonialjapan_event2", map: "" },
        ],
        hasBlends: true, // 'Old' blends rendering
      },
      {
        id: "autobiography",
        path: "autobiography",
        categoryColour: "gold",
        avatar: "autobiography.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: false,
        articleData: {},
        hasGuides: false,
        showGuides: false,
        // showCitiesAndDates: true,
        showVideo: true,
        videoSrc: "static/autobiography_video_edit.mp4",
        posterSrc: "autobiography_poster.png",
        // citiesAndDates: [
        //   {
        //     id: 1,
        //     venueId: 'Dubai'
        //   },
        //   {
        //     id: 2,
        //     venueId: 'Amsterdam'
        //   },
        // ],
        events: [
          { id: "autobiography_event1", map: "" },
          { id: "autobiography_event2", map: "" },
        ],
        hasBlends: true, // 'Old' blends rendering
      },
      {
        id: "highlands",
        path: "highlands",
        categoryColour: "gold",
        avatar: "highlands.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: true,
        audioSrc: [
          {
            player: "spotify",
            type: "track",
            src: "5r3a8zt9tB7bfcEod5xD2o",
            title: "highlands_listenTo1",
          },
        ],
        showArticle: false,
        articleData: {},
        hasGuides: false,
        showGuides: false,
        // showCitiesAndDates: true,
        showVideo: true,
        posterSrc: "highlands_poster.png",
        videoSrc: "static/scottishhighlands_video.mp4",
        events: [
          { id: "highlands_event1", map: "" },
          { id: "highlands_event2", map: "" },
          { id: "highlands_event3", map: "" },
          { id: "highlands_event4", map: "" },
          { id: "highlands_event5", map: "" },
          { id: "highlands_event6", map: "" },
          { id: "highlands_event7", map: "" },
          { id: "highlands_event8", map: "" },
          { id: "highlands_event9", map: "" },
          { id: "highlands_event10", map: "" },
        ],
        hasBlends: true, // 'Old' blends rendering
      },
      {
        id: "ikebana",
        path: "ikebana",
        categoryColour: "gold",
        avatar: "ikebana.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: true,
        audioSrc: [
          {
            player: "spotify",
            type: "track",
            src: "533TZP8RSOvLW5aydnvfRp",
            title: "ikebana_listenTo1",
          },
        ],
        showArticle: true,
        articleData: {
          id: "arithmeticOfBeauty",
          logo: "nyt",
          hasImage: true,
          imageLeft: false,
          background: true,
        },
        hasGuides: false,
        showGuides: false,
        showVideo: false,
        // showCitiesAndDates: true,
        // citiesAndDates: [
        //   {
        //     id: 1,
        //     venueId: 'HK'
        //   },
        //   {
        //     id: 2,
        //     venueId: 'HK'
        //   },
        //   {
        //     id: 3,
        //     venueId: 'NYC'
        //   },
        //   {
        //     id: 4,
        //     venueId: 'NYC'
        //   },
        // ],
        events: [
          { id: "ikebana_event1", map: "" },
          // { id: "ikebana_event2", map: "" },
        ],
        hasBlends: true, // 'Old' blends rendering
      },
      {
        id: "finewine",
        path: "fine-wine",
        categoryColour: "gold",
        avatar: "fineWine.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: true,
        articleData: {
          id: "worldsBestSommelier",
          logo: "forbes",
          hasImage: true,
          imageLeft: true,
          background: true,
        },
        hasGuides: true,
        showGuides: false,
        // showCitiesAndDates: true,
        showVideo: false,
        events: [
          { id: "finewine_event1", map: "" },
          { id: "finewine_event2", map: "" },
          { id: "finewine_event3", map: "" },
          { id: "finewine_event4", map: "" },
          { id: "finewine_event5", map: "" },
          { id: "finewine_event6", map: "" },
          { id: "finewine_event7", map: "" },
          { id: "finewine_event8", map: "" },
          { id: "finewine_event9", map: "", tbc: true },
        ],
        hasBlends: true, // 'Old' blends rendering
      },
    ],
  },
  {
    season: "2023",
    items: [
      // {
      //   id: "oceans",
      //   path: "oceans",
      //   categoryColour: "gold",
      //   avatar: "oceans.png",
      //   hasQuoteImage: false,
      //   hasImageSignature: false,
      //   hasSubtitle: true,
      //   hasCarouselSubtitle: true,
      //   showAudioPlayer: false,
      //   audioSrc: [],
      //   showArticle: false,
      //   articleData: {},
      //   hasGuides: false,
      //   showGuides: false,
      //   showVideo: false,
      //   bookingOpens: false, // hides buttons where true (&& !tbc)
      //   events: [
      //     { id: "oceans_event1", map: "" },
      //     { id: "oceans_event2", map: "" },
      //   ],
      //   disabledLocales: ['en-id'],
      //   disabledLocales: ['es-id'],
      // },
      {
        id: "psychoanalysis",
        path: "psychoanalysis",
        categoryColour: "gold",
        avatar: "psychoanalysis.png",
        hasQuoteImage: true,
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: false,
        articleData: {},
        hasGuides: false,
        showGuides: false,
        showVideo: false,
        videoOverrides: {
          "en-no": {
            showVideo: true,
          },
          "en-se": {
            showVideo: true,
          },
          "en-fi": {
            showVideo: true,
          },
          "en-nl": {
            showVideo: true,
          },
          "en-be": {
            showVideo: true,
          },
          "en-hk": {
            showVideo: true,
          },
          "en-sg": {
            showVideo: true,
          },
          "en-ch": {
            showVideo: true,
          },
          "de-at": {
            showVideo: true,
          },
          "de-de": {
            showVideo: true,
          },
          "ja-jp": {
            showVideo: true,
          },
          "en-bh": {
            showVideo: true,
          },
          "it-it": {
            showVideo: true,
          },
          "zh-hk": {
            showVideo: true,
          },
          "fr-fr": {
            showVideo: true,
          },
        },
        videoSrc: "static/psychoanalysis-2023.mp4",
        captionsSrc: "static/psychoanalysis_",
        captionsLocales: ["fr"],
        posterSrc: "psychoanalysis_poster.jpg",
        // bookingOpens: false, // hides buttons where true (&& !tbc)
        // events: [
        //   { id: "psychoanalysis_event1", map: "" },
        //   { id: "psychoanalysis_event2", map: "" },
        //   { id: "psychoanalysis_event3", map: "" },
        //   { id: "psychoanalysis_event4", map: "" },
        //   { id: "psychoanalysis_event5", map: "" },
        //   { id: "psychoanalysis_event6", map: "" },
        //   { id: "psychoanalysis_event7", map: "" },
        //   { id: "psychoanalysis_event8", map: "" },
        //   { id: "psychoanalysis_event9", map: "" },
        //   { id: "psychoanalysis_event10", map: "" },
        //   { id: "psychoanalysis_event11", map: "" },
        // ],
      },
      {
        id: "selflove",
        path: "self-love",
        avatar: "selflove.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: false,
        articleData: {},
        hasGuides: false,
        showGuides: false,
        showVideo: false,
        // bookingOpens: false, // hides buttons where true (&& !tbc)
        // events: [
        //   { id: "selflove_event1", map: "" },
        //   { id: "selflove_event2", map: "" },
        // ],
      },
      {
        id: "shakespeare",
        path: "shakespeare",
        categoryColour: "gold",
        avatar: "shakespeare.png",
        hasQuoteImage: true,
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: false,
        articleData: {},
        hasGuides: false,
        showGuides: false,
        showVideo: false,
        // bookingOpens: false, // hides buttons where true (&& !tbc)
        // events: [
        //   { id: "shakespeare_event1", map: "", },
        //   { id: "shakespeare_event2", map: "", },
        // ],
        eventsOverrides: {
          "en-ID": ["shakespeare_event2"],
          "es-ID": ["shakespeare_event2"],
        },
      },
      {
        id: "middleeasternart",
        path: "middle-eastern-art",
        categoryColour: "gold",
        avatar: "middleeasternart.png",
        hasQuoteImage: true,
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: false,
        articleData: {},
        hasGuides: false,
        showGuides: false,
        showVideo: false,
        // bookingOpens: false, // hides buttons where true (&& !tbc)
        // events: [
        //   { id: "middleeasternart_event1", map: "" },
        // ],
      },
      {
        id: "africanart",
        path: "african-art",
        categoryColour: "gold",
        avatar: "africanart.png",
        hasQuoteImage: true,
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: false,
        articleData: {},
        hasGuides: false,
        showGuides: false,
        showVideo: false,
        // bookingOpens: false, // hides buttons where true (&& !tbc)
        // events: [
        //   { id: "africanart_event1", map: "" },
        //   { id: "africanart_event2", map: "" },
        //   { id: "africanart_event3", map: "" },
        //   { id: "africanart_event4", map: "" },
        // ],
      },
      {
        id: "finewine",
        path: "fine-wine",
        categoryColour: "gold",
        avatar: "finewine.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: true,
        articleData: {
          id: "worldsBestSommelier",
          logo: "forbes",
          hasImage: true,
          imageLeft: true,
          background: true,
        },
        hasGuides: false,
        showGuides: false,
        showVideo: false,
        // bookingOpens: false, // hides buttons where true (&& !tbc)
        // events: [
        //   { id: "finewine_event1", map: "" },
        //   { id: "finewine_event2", map: "" },
        //   { id: "finewine_event3", map: "" },
        // ],
        eventsOverrides: {
          "en-ID": ["finewine_event2", "finewine_event3"],
          "es-ID": ["finewine_event2", "finewine_event3"],
        },
        hasBlends: true, // 'Old' blends rendering
      },
      {
        id: "ceremonialjapan",
        path: "ceremonial-japan",
        categoryColour: "gold",
        avatar: "ceremonialjapan.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: false,
        articleData: {},
        hasGuides: false,
        showGuides: false,
        showVideo: false,
        // bookingOpens: false, // hides buttons where true (&& !tbc)
        // events: [
        //   { id: "ceremonialjapan_event1", map: "" },
        //   // { id: "ceremonialjapan_event2", map: "" },
        // ],
        hasBlends: true, // 'Old' blends rendering
      },
    ],
  },
  {
    season: "2024",
    items: [
      {
        ...defaultTalent,
        id: "photography",
        path: "photography",
        categoryColour: "gold",
        avatar: "photography.png",
      },
      {
        ...defaultTalent,
        id: "southeastasianart",
        path: "southeast-asian-art",
        categoryColour: "gold",
        avatar: "southeastasianart.png",
        showVideo: true,
        captionsSrc: "static/southeastasianart_",
        captionsLocales: ["fr"],
        videoSrc: "static/dr-cleo-edit-v7.mp4",
        posterSrc: "southeastasianart_poster.jpg",
        videoOverrides: {
          "fr-fr": {
            showVideo: true,
            videoSrc: "static/dr-cleo-v8-fr-no-subtitles.mp4",
            posterSrc: "southeastasianart_poster-fr.jpg",
          },
        },
      },
      {
        ...defaultTalent,
        id: "italianfoodandwine",
        path: "italian-food-and-wine",
        hasSubtitle: true,
        categoryColour: "gold",
        avatar: "italianfoodandwine.png",
        disabledLocales: ["en-gl"],
      },
      {
        ...defaultTalent,
        id: "finejewellery",
        path: "fine-jewellery",
        avatar: "finejewellery.png",
        showVideo: true,
        captionsSrc: "static/finejewellery_",
        captionsLocales: ["fr"],
        videoSrc: "static/mel-grant-edit-v4a.mp4",
        posterSrc: "finejewellery_poster.jpg",
        videoOverrides: {
          "fr-fr": {
            showVideo: true,
            videoSrc: "static/mel-grant-v5-fr-no-subtitles.mp4",
            posterSrc: "finejewellery_poster-fr.jpg",
          },
        },
      },
      {
        id: "finewine",
        path: "fine-wine",
        categoryColour: "gold",
        avatar: "finewine.png",
        hasImageSignature: false,
        hasSubtitle: true,
        hasCarouselSubtitle: true,
        showAudioPlayer: false,
        audioSrc: [],
        showArticle: true,
        articleData: {
          id: "worldsBestSommelier",
          logo: "forbes",
          hasImage: true,
          imageLeft: true,
          background: true,
        },
        hasGuides: false,
        showGuides: false,
        showVideo: false,
        bookingOpens: false, // hides buttons where true (&& !tbc)
        events: [
          { id: "finewine_event1", map: "" },
          { id: "finewine_event2", map: "" },
          { id: "finewine_event3", map: "" },
        ],
        disabledLocales: ["en-gl"],
        // eventsOverrides: {
        //   "en-ID": [
        //     { id: "finewine_event2", map: "" },
        //     { id: "finewine_event3", map: "" },
        //   ],
        //   "es-ID": [
        //     { id: "finewine_event1", map: "" },
        //     { id: "finewine_event2", map: "" },
        //   ],
        // },
        hasBlends: true, // 'Old' blends rendering
      },
    ],
  },
];

export default introductions;
