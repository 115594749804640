/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useLocation, matchPath, useNavigate, useBlocker } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
// import "intersection-observer";
import { getConfigForEnv } from "../../appConfig";
import { routesConfig } from "../../childRoutes";
import { setBasePath } from "../../features/baseUrl/baseUrlSlice";
import { setIsOpen } from "../../features/menu/menuSlice";
import Header from "../Header/Header";
import LangSelect from "../LangSelect/LangSelect";
import offset from "../../services/elementOffset";
import style from "./AppRoutes.module.scss";

const RootLayout = ({
  localeName,
  env,
  basePath,
  rootModule,
  hasTouch,
  setBaseUrl,
  isMenuOpen,
  setIsMenuOpen,
  showMenuMap,
}) => {
  const { version } = require('../../../package.json');

  const isProd = env === "prod";
  const isArchive = env === "archive";
  const location = useLocation();
  const navigate = useNavigate();
  const search = location?.search || '';

  const [routeParams, setRouteParams] = useState(null);

  const [initialised, setInitialised] = useState(false);
  const [trackingPath, setTrackingPath] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState(true);
  const [prevPosition, setPrevPosition] = useState(0);

  const haveSticky = [
    "viewmono",
    "viewmono-landing",
    "info",
    "info-landing",
    "privacy",
    "magazine"
  ];

  const blocker = useBlocker(({ nextLocation }) => {
    if (isArchive && (nextLocation.pathname.includes('/archive') || nextLocation.pathname === '/en-fi/benefits/new-centurion/')) {
      return false;
    }
    if (isArchive && rootModule !== "archive") {
      setTimeout(() => {
        if (blocker) {
          blocker.reset?.();
        }
      }, 50);
      return true;
    }
    return false;
  });

  function registerPath() {
    document.body.setAttribute("data-path", location.pathname);
  }

  // Passed into <Outlet> as context prop: called when route changes
  const handleRouteChange = () => {
    if (location?.pathname) {
      if (trackingPath !== location.pathname || trackingPath === null) {
        // Reset route params
        setRouteParams(null);
        // This will trigger routeParams to be (re)populated
        setTrackingPath(location.pathname);
      }
    }
  };

  function handleMenuToggle() {
    setIsMenuOpen(!isMenuOpen);
  }

  const handleScroll = () => {
    if (!showMenu) {
      return;
    }
    const position = window.scrollY;
    const menu = document.getElementById("header");
    const menuH = menu.offsetHeight;

    const isVisible = position < prevPosition || position < menuH;

    setMenuVisible(isVisible);
    setPrevPosition(position);
  };

  useEffect(() => {
    // Initialise basePath (should only happen once)
    if (!basePath && localeName) {
      const lang = localeName.toLowerCase(); // (localeName || locale).toLowerCase();
      const { langCard, langCenturion } =
        {
          "de-de": {
            langCard: "vorteile",
            langCenturion: "neue-centurion",
          },
          "it-it": {
            langCard: "benefit",
            langCenturion: "nuova-centurion",
          },
        }[lang] || {};

      const baseUrl =
        // `${lang || "en-gb"}` +
        `${lang}` +
        `/${langCard || "benefits"}` +
        `/${langCenturion || "new-centurion"}`;

      // eslint-disable-next-line no-console
      // console.log("setBaseUrl:", baseUrl);

      setBaseUrl(baseUrl);
    }
  }, []);

  useEffect(() => {

    if (!initialised && basePath && routeParams?.locale) {
      const { hash } = location;
      const query = Object.fromEntries(new URLSearchParams(search));
      const queryString = Object.keys(query).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
      }).join('&');
      const { page, subPage, pageId, id } = routeParams;

      const to = `/${basePath}${
        page
          ? `/${page}${
              subPage
                ? ["performances", "introductions"].includes(subPage)
                  ? `/${subPage}${
                      pageId ? `/${pageId}${id ? `/${id}` : ""}` : ""
                    }`
                  : `/${subPage}${pageId ? `/${pageId}` : ""}`
                : ""
            }`
          : ""
      }${ hash ? hash : "" }${ query ? `?${ queryString }` : null }`;

      setInitialised(true);
      return navigate(to);
    }
  }, [basePath, routeParams?.locale, initialised]);

  useEffect(() => {
    if (rootModule) {
      // const { locales, features } = getConfigForEnv();

      const route = routesConfig.find(route => route.id === rootModule);

      /*
      N.B. 'pattern' property of routes defined in childRoutes.js
      Must be configured carefully or matchPath will fail
      Params used in 'pattern' may differ from those used in 'path' property
      */
      const {
        params: {
          locale: matchedLocale,
          // card: matchedCard,
          // centurion: matchedCenturion,
          page: matchedPage,
          subPage: matchedSubPage,
          pageId: matchedPageId,
          id: matchedId,
        } = {}
      } = matchPath(
        {
          path: route.pattern,
          caseSensitive: true,
          end: true,
        },
        location.pathname,
      ) || {};


      if (!matchedLocale) {
        return;
      }

      // const localeCased =
      //   `${matchedLocale.split('-')[0]}-${matchedLocale.split('-')[1].toUpperCase()}`;

      // if (
      //   !locales.includes(localeCased) ||
      //   (!matchedPage && route.id !== "home") ||
      //   (route.feature && features[localeCased]?.hiddenSections?.[route.feature])
      // ) {
      //   // eslint-disable-next-line no-console
      //   console.log(
      //     `Route not found: ${localeCased} ${route.id}`,
      //     `/${matchedLocale}/${matchedCard}/${matchedCenturion}/404`
      //   );
      //   navigate(`/${matchedLocale}/${matchedCard}/${matchedCenturion}/404`);
      //   return;
      // }

      // eslint-disable-next-line no-console
      // console.log(
      //   route.feature, matchedPage
      // );

      // Set props for template logic
      setRouteParams({
        locale: matchedLocale || null,
        page: matchedPage || null,
        subPage: matchedSubPage || null,
        pageId: matchedPageId || null,
        id: matchedId || null,
      });

    }
  }, [trackingPath]);

  // Update tracking data object based on path
  // Assumes window.digitalData has been initialised as a global variable
  useEffect(() => {
    if (
      !window._satellite || !routeParams?.locale
    ) {
      return;
    }

    const [language, country] = localeName.split("-");
    const { page, subPage, pageId, id } = routeParams;

    const infoProps = {
      country,
      language,
      pageName: rootModule,
    };

    // Override pageName for talent and benefits pages only (for now)
    if (
      ["performances", "introductions", "benefits"].includes(rootModule) &&
      subPage
    ) {
      if (pageId) {
        infoProps.pageName = id
          ? `${subPage}:${pageId}:${id}`
          : `${subPage}:${pageId}`; // e.g. "performances:2022:alicia-keys"
      } else {
        infoProps.pageName = `${rootModule}:${subPage}`; // e.g. "benefits:harvey-nichols" (new)
      }
    }

    const categoryProps = {
      businessUnit: "Loyalty",
      primaryCategory: "Centurion",
      subCategory1: page || "home",
      ...(subPage
        ? {
            subCategory2: subPage,
            ...(pageId
              ? {
                  subCategory3: pageId && id ? `${pageId}:${id}` : pageId,
                }
              : false),
          }
        : false),
    };

    // Update global data object
    window.digitalData.page.pageInfo = infoProps;
    window.digitalData.page.category = categoryProps;

    /* eslint-disable no-console */
    // Leave in for debugging
    // console.log(
    //   "Route change, track page data:",
    //   window.digitalData,
    //   location.pathname,
    //   page,
    //   subPage,
    //   pageId,
    //   id,
    // );
    /* eslint-enable no-console */

    window._satellite.track("page");
  }, [routeParams]);

  useEffect(() => {
    if (!routeParams?.locale) {
      return
    }
    const { locale, page } = routeParams;
    if (rootModule && locale) {
      const showMenuForModule = isArchive ?
        false : showMenuMap[rootModule] !== undefined ?
          showMenuMap[rootModule] : true;
      const { locales } = getConfigForEnv();
      const localeChunks = locale.split("-");
      if (
        locales.includes(`${localeChunks[0]}-${localeChunks[1].toUpperCase()}`)
      ) {
        setShowMenu(showMenuForModule);
      }

      if (isMenuOpen) {
        handleMenuToggle();
      }
      registerPath();

      const { hash } = location;

      // eslint-disable-next-line no-console
      // console.log(hash);

      if (hash) {
        // Push onto callback queue so it runs after the DOM is updated,
        // this is required when navigating from a different page so that
        // the element is rendered on the page before trying to getElementById.
        const id = hash.replace("#", "");
        const reservedHashes = {
          card: ["classicBlack", "remKoolhaas", "kehindeWiley"],
          wearable: ["faqs"],
          benefits: ["introduction"],
          living: ["citiesAndDates"]
        };

        if (reservedHashes[page] && reservedHashes[page].indexOf(id) >= 0) {
          // eslint-disable-next-line no-console
          // console.log("reserved hash");
          return;
        }

        const offsetKey = `${page}${hash}`;
        const offsetY =
          {
            "benefits#lifestyle": -30,
            "benefits#travel": -30,
          }[offsetKey] || 0;

        const element = document.getElementById(id);
        if (element) {
          window.scrollTo({
            top: offset(element).top + offsetY,
            left: 0,
            behavior: "smooth",
          });
          return;
        }
      }
      window.scrollTo({
        left: 0,
        top: 0,
      });
    }
  }, [rootModule, location.pathname, location.hash, routeParams?.locale]);

  useEffect(() => {
    registerPath();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevPosition]);

  return (
    <>
      <Helmet defaultTitle="American Express Centurion">
        <html
          lang={localeName}
          data-sticky={haveSticky.includes(rootModule)}
          data-page={routeParams?.page || ''}
          data-sub-page={routeParams?.subPage || ''}
          data-module={rootModule}
        />
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="stylesheet" href="//cloud.typenetwork.com/projects/4493/fontface.css/" />
        {localeName === 'ja-JP' &&
          <link rel="stylesheet" href="//fastly-cloud.typenetwork.com/projects/7769/fontface.css" />
        }
      </Helmet>
      <div
        data-package-version={version}
        data-module="container"
        data-env={env}
        className={`app ${style.mask} ${isMenuOpen ? style.maskActive : ""}`}
      >
        <div className={`${style.headerDefault} hiddenHeader`}>
          {showMenu && (
            <Header
              isProd={isProd}
              isMenuVisible={isMenuVisible}
              isMenuOpen={isMenuOpen}
              hasTouch={hasTouch}
              handleMenuToggle={handleMenuToggle}
              location={location}
              basePath={basePath}
            />
          )}
        </div>
        { basePath &&
          <main
            className={style.main}
            style={{ pointerEvents: isMenuOpen ? "none" : "all" }}
          >
            <Outlet context={{ handleRouteChange }} />
          </main>
        }
        {rootModule && !isArchive && <LangSelect module={rootModule} />}
      </div>
      {isArchive && rootModule !== "archive" &&
        <Link to="/en-fi/benefits/new-centurion/">
          <div tabIndex="0" className={`${style.archiveBanner} bentonsanslight`}>You are viewing the Centurion website archive</div>
        </Link>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  const localeName = state.getIn(["intl", "activeLocale"]);
  const config = state.get("config");
  const env = config?.env;
  // const cdnUrl = config?.assets;
  // const trackingLink = config?.trackingLink;
  const basePath = state.get("basePath")?.path;

  const rootModule = state.get("rootModule")?.name;
  const hasTouch = state.get("browser")?.hasTouch;

  const menu = state.get("menu");
  const isMenuOpen = menu.isOpen;
  const showMenuMap = menu.showMenuMap;

  return {
    localeName,
    env,
    // cdnUrl,
    // trackingLink,
    basePath,
    rootModule,
    hasTouch,
    isMenuOpen,
    showMenuMap,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBaseUrl: (path) => dispatch(setBasePath(path)),
    setIsMenuOpen: (isMenuOpen) => dispatch(setIsOpen(isMenuOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RootLayout);
