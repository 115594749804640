import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getDate } from "../helpers/dates";

import CalendarArchive from "./CalendarArchive";

export default function CalendarArchives() {
  const params = useParams();

  const archivedSeasons = [2023]; // [2024, 2023];

  const [calendarArchives, setCalendarArchives] = useState([]);

  useEffect(() => {
    const { locale } = params;

    const calendarArchivedItems = archivedSeasons.map((archivedSeason) => {
      const talentData = require(`../data/archive/calendar-${archivedSeason}.json`);

      const eventsData = talentData.flatMap((talentItem) => {
        const { year, nameTranslations, slug, section, type, events } =
          talentItem;

        return events.flatMap((eventItem) => {
          const { date, cityTranslations, location } = eventItem;

          const datePassed = new Date(date) < new Date();

          return {
            year,
            name: nameTranslations[locale],
            slug,
            section,
            type,
            date,
            city: cityTranslations[locale],
            location,
            past: datePassed,
          };
        });
      });

      const eventsGroupedByMonth = eventsData.reduce(
        (calendarEvents, eventData) => {
          const eventDate = new Date(eventData.date);
          const eventMonth = eventDate.getMonth() + 1;

          if (!calendarEvents[eventMonth]) {
            calendarEvents[eventMonth] = [];
          }

          calendarEvents[eventMonth].push(eventData);

          return calendarEvents;
        },
        {}
      );

      const eventsGroupedByDay = Object.entries(eventsGroupedByMonth).map(
        ([itemMonth, itemEvents]) => {
          const monthLabel = getLocaleMonth(itemMonth, "MMMM");

          const sortedEvents = itemEvents.sort((eventA, eventB) => {
            const dateA = new Date(eventA.date);
            const dateB = new Date(eventB.date);

            return dateA - dateB;
          });

          const groupedEvents = sortedEvents.reduce(
            (groupedEvents, eventItem) => {
              const eventDate = new Date(eventItem.date);
              const eventDay = eventDate.getDate();

              if (!groupedEvents[eventDay]) {
                groupedEvents[eventDay] = {
                  id: eventDay,
                  name: getLocaleDay(eventDate, "d"),
                  weekday: {
                    long: getLocaleDay(eventDate, "EEEE"),
                    short: getLocaleDay(eventDate, "EEE"),
                  },
                  events: [],
                };
              }

              groupedEvents[eventDay].events.push(eventItem);

              return groupedEvents;
            },
            []
          );

          return {
            id: Number(itemMonth),
            name: monthLabel,
            events: groupedEvents.filter(Boolean),
          };
        }
      );

      return {
        year: archivedSeason,
        events: eventsGroupedByDay,
      };
    });

    setCalendarArchives(calendarArchivedItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getLocaleMonth(monthId, monthFormat) {
    const fakeDate = new Date(1970, monthId - 1, 1);

    return getDate(fakeDate, monthFormat, params.locale);
  }

  function getLocaleDay(fullDate, dayFormat) {
    const fakeDate = new Date(fullDate);

    return getDate(fakeDate, dayFormat, params.locale);
  }

  return (
    <div>
      {calendarArchives.map(({ year, events }) => (
        <CalendarArchive key={year} year={year} events={events} />
      ))}
    </div>
  );
}
