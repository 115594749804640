import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import style from "./LineSet.module.scss";

export default function LineSet({
  id,
  mobile = [],
  tablet = [],
  desktop = [],
  values = {},
}) {
  const intl = useIntl();

  const en = intl.locale.substring(0, 2) === "en";

  const [pageRes, setPageRes] = useState("DESKTOP");

  const parse = (msg) => {
    // !DEV - Second replace removes dom, potentally adjust this
    // const clean = msg.replace(/\s+/g, " ").replace(/(<([^>]+)>)/gi, "");
    const clean = msg.replace(/\s+/g, " ");

    const exploded = clean.split(" ");

    return exploded.length ? exploded : null;
  };

  const composeMessage = (device, arr) => {
    const workingArr = [...arr];
    const paddedDevice = [...device, -1];

    const res = paddedDevice.reduce((acc, count) => {
      const c = count > 0 ? count : workingArr.length;

      const line = workingArr.splice(0, c);

      return [...acc, line.join(" ")];
    }, []);

    return res;
  };

  // Mount/unmount window resize event
  useEffect(() => {
    const handleWindowResize = () => {
      const w = window.innerWidth;

      setPageRes(w < 768 ? "MOBILE" : w < 1024 ? "TABLET" : "DESKTOP");
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // Extract messages from intl
  const { messages } = intl;

  // Setup override keys
  const keyMobile = `${id}_mobile`;
  const keyTablet = `${id}_tablet`;
  const keyDesktop = `${id}_desktop`;

  // Set original message (for non-en)
  const original = id in messages ? messages[id] : null;

  // Get default message
  const parsedMessage = id in messages ? parse(original) : [];

  // Get override messages
  const messageMobile =
    keyMobile in messages ? parse(messages[keyMobile]) : null;
  const messageTablet =
    keyTablet in messages ? parse(messages[keyTablet]) : null;
  const messageDesktop =
    keyDesktop in messages ? parse(messages[keyDesktop]) : null;

  // Compose each line per device
  const mobileLines = composeMessage(mobile, messageMobile || parsedMessage);
  const tabletLines = composeMessage(tablet, messageTablet || parsedMessage);
  const desktopLines = composeMessage(desktop, messageDesktop || parsedMessage);

  const formatted = ({ message, values }) => {
    if (!message) return null;

    return (
      <FormattedMessage
        id="PurposelyFallingBack"
        defaultMessage={message}
        values={values}
      />
    );
  };

  // Set each line (span)
  const lineSetLines = ({ lines, values }) => {
    if (lines.length === 1) {
      return formatted({ message: lines[0], values });
    }
    return lines.map((message, index) => (
      <span className={style.lineSetRow} key={index}>
        {formatted({ message, values })}
      </span>
    ));
  };

  if (!en) {
    return formatted({ message: original, values });
  }

  if (pageRes === "MOBILE" && mobileLines) {
    return lineSetLines({ lines: mobileLines, values });
  }

  if (pageRes === "TABLET" && tabletLines) {
    return lineSetLines({ lines: tabletLines, values });
  }

  if (pageRes === "DESKTOP" && desktopLines) {
    return lineSetLines({ lines: desktopLines, values });
  }

  return null;
}
