import React, { useEffect, useState } from "react";
import { animated, useSpring } from '@react-spring/web';
import { useMeasure } from "react-use";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import ViewingSubAccordion from "./ViewingSubAccordion";
import style from "./ViewingAccordion.module.scss";

const ViewingAccordion = ({ item }) => {
  const hasRegions = item.regions ? true : false;
  const regions = hasRegions ? item.regions : null;

  const venues = item.items;
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const [measureRef, { height }] = useMeasure();

  const [contentHeight, setContentHeight] = useState("0px");
  useEffect(() => {
    setContentHeight(height);

    const onResize = () => {
      setContentHeight(height);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [height]);

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  const toggle = useSpring({
    height: isOpen ? `${contentHeight}px` : "0px",
    overflow: "hidden",
  });

  return (
    <div
      key={item.countryId}
      data-country={item.countryId}
      className={`${style.country} ${
        !hasRegions ? (venues.length === 2 ? style.twoItems : "") : ""
      }`}
    >
      <div className="flex flex-align-center flex-justify-center">
        <span className={`${style.toggle} ${isOpen && style.open}`} />
        <h4
          className={`text-align-center ${style.countryTitle}`}
          tabIndex="0"
          role="button"
          id={`${item.countryId}-toggle`}
          aria-expanded={isOpen}
          aria-controls={`${item.countryId}-container`}
          onClick={handleToggle}
          onKeyPress={(e) => {
            if (e.charCode === 13 || e.charCode === 32) {
              e.preventDefault();
              handleToggle();
            }
          }}
        >
          <FormattedMessage id={`viewmono.${item.countryId}`} />
        </h4>
      </div>
      <animated.div style={toggle}>
        <div
          ref={measureRef}
          id={`${item.countryId}-container`}
          role="region"
          aria-labelledby={`${item.countryId}-toggle`}
          className={`flex flex-wrap text-align-center ${style.venuesWrapper}`}
        >
          <>
            {hasRegions ? (
              <div>
                {regions.map((region) => {
                  return <ViewingSubAccordion key={region.id} item={region} />;
                })}
              </div>
            ) : (
              venues
                // .sort((a, b) => (a.address > b.address ? 1 : -1))
                .map((venue) => (
                  venue.hidden ? null : (
                  <div key={venue.id} className={style.venue}>
                    <p
                      className="bentonsansthin"
                      data-localeid={`${venue.id}address`}
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: `viewmono.${venue.id}address`,
                        }),
                      }}
                    />
                  </div>
                )))
            )}
          </>
        </div>
      </animated.div>
    </div>
  );
};

ViewingAccordion.propTypes = {
  item: PropTypes.any.isRequired,
};

export default ViewingAccordion;
