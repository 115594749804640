import { smallGutterWidth, largeColumns } from "./benefits";

export const blend = {
  large: [
    {
      width: 40,
      top: 30,
      left: 30,
      src: "1.png", // Bottle - Center
      halo: true,
    },
    {
      width: 16.66,
      top: 47.5,
      left: 0,
      src: "2.png", // Sign - Middle Left
    },
    {
      width: 20,
      top: 26.5,
      left: 12.5,
      src: "3.png", // Hill - Top Left
    },
    {
      width: 20,
      top: 65,
      right: largeColumns(1.25),
      src: "4.png", // Group of Bottles - Bottom Right
    },
    {
      width: 28,
      top: 28,
      right: largeColumns(1.35),
      src: "5.png", // Single Bottle - Top Right
    },
    {
      width: 30,
      top: 50,
      left: 8.33,
      src: "6.png", // Tree Graphic - Bottom Left
    },
  ],
  small: [
    {
      width: 60,
      top: 25,
      left: 20,
      src: "1.png",
      halo: true, // Bottle - Center
    },
    {
      width: 25,
      top: 22,
      left: 8.33,
      src: "2.png", // Sign - Middle Left
    },
    {
      width: 25 - smallGutterWidth,
      top: 64,
      right: 8.33,
      src: "4.png", // Group of Bottles - Bottom Right
    },
    {
      width: 33.33,
      top: 15,
      right: 0,
      src: "5.png", // Single Bottle - Top Right
    },
    {
      width: 36,
      top: 60,
      left: 0,
      src: "6.png", // Tree Graphic - Bottom Left
    },
  ],
};
