import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import style from "./MenuToggle.module.scss";

const MenuToggle = ({ isMenuOpen, onMenuToggle, location, isMenuVisible }) => {
  const routeChunks = location.pathname.split("/");

  const isDarkPage =
    routeChunks[routeChunks.length - 1] === "living" &&
    !document.querySelector('[data-module-id="notfound"]');

  const className = classNames("btn", style.button, style.buttonWithIcon, {
    "btn-centurion-black": !isMenuOpen || isDarkPage,
    "btn-centurion-white": isMenuOpen || isDarkPage,
  });

  const Burger = () => {
    return (
      <svg className={style.menuBurger} viewBox="0 0 44 33">
        <title>
          <FormattedMessage id="menu.toggleClose" />
        </title>
        <defs>
          <path
            id="a"
            d="M41.25 27.5c1.5187831 0 2.75 1.2312169 2.75 2.75S42.7687831 33 41.25 33H2.75C1.23121694 33 0 31.7687831 0 30.25s1.23121694-2.75 2.75-2.75h38.5zm0-13.75c1.5187831 0 2.75 1.2312169 2.75 2.75s-1.2312169 2.75-2.75 2.75H2.75C1.23121694 19.25 0 18.0187831 0 16.5s1.23121694-2.75 2.75-2.75h38.5zm0-13.75C42.7687831 0 44 1.23121694 44 2.75S42.7687831 5.5 41.25 5.5H2.75C1.23121694 5.5 0 4.26878306 0 2.75S1.23121694 0 2.75 0h38.5z"
          />
        </defs>
        <g fill="none">
          <mask id="b" fill="#fff">
            <use href="#a" />
          </mask>
          <use fill="#FFF" href="#a" />
          <g fill="currentColor" mask="url(#b)">
            <path d="M-2-8h48v48H-2z" />
          </g>
        </g>
      </svg>
    );
  };

  const Close = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        className={style.menuBurger}
      >
        <title>
          <FormattedMessage id="menu.toggleOpen" />
        </title>
        <g fill="none">
          <g fill="currentColor" transform="translate(-279 -19)">
            <g transform="translate(270 10)">
              <g transform="rotate(90 11 20)">
                <path d="M13.0344797,10.9999574 L20.6637122,3.37072487 C21.223207,2.81122936 21.223207,1.89578425 20.6637122,1.336303 C20.1042174,0.776821742 19.1887715,0.776807337 18.6292904,1.336303 L11.0000441,8.96552056 L3.3707243,1.3362883 C2.81122878,0.776792788 1.89578396,0.776792788 1.33630271,1.3362883 C0.776821454,1.89578382 0.776807193,2.81131521 1.33630271,3.37071018 L8.96562182,10.9999427 L1.3362883,18.6292752 C0.776792788,19.1887715 0.776792788,20.1042173 1.3362883,20.6636971 C1.89578382,21.2231769 2.81122893,21.2231933 3.37070989,20.6636971 L11.000029,13.0343779 L18.6292603,20.663697 C19.1887565,21.2231932 20.1042876,21.2231932 20.6636821,20.663697 C21.2231769,20.1042022 21.2231769,19.1887576 20.6636821,18.6292751 L13.0344797,10.9999574 Z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  };

  return (
    <button
      id="menu-toggle"
      className={`${className} ${!isMenuVisible ? style.hiddenBurger : ""}`}
      type="button"
      onClick={() => onMenuToggle()}
    >
      {isMenuOpen ? <Close /> : <Burger />}

      <span className="sr-only">
        <FormattedMessage id="menu.toggleClose" />
      </span>
    </button>
  );
};

MenuToggle.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
};

export default MenuToggle;
