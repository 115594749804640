import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { animated, useSpring, useTrail } from '@react-spring/web';
import { useMeasure } from "react-use";
import PropTypes from "prop-types";
import style from "./AudioPlaylist.module.scss";

const AudioPlaylist = ({ tracks, currentTrackIdx, setCurrentTrack }) => {
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [contentHeight, setContentHeight] = useState("0");
  const [measureRef, { height }] = useMeasure();

  const toggle = useSpring({
    from: { height: showPlaylist ? "0px" : `${contentHeight}px` },
    to: { height: showPlaylist ? `${contentHeight}px` : "0px" },
  });

  useEffect(() => {
    setContentHeight(height);
    const onResize = () => {
      setContentHeight(height);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [height]);

  const tracksAnim = useTrail(tracks.length, {
    from: { opacity: 0 },
    opacity: showPlaylist ? 1 : 0,
    delay: 100,
  });

  return (
    <div className={`${style.playlistContainer} bg-white`}>
      <animated.div
        style={toggle}
        className={`flex flex-column ${style.tracklist}`}
      >
        <div ref={measureRef}>
          {tracksAnim.map((props, idx) => {
            const track = tracks[idx];
            return (
              <animated.div
                style={props}
                className={`${style.track} ${
                  currentTrackIdx === idx && style.selected
                }`}
                key={idx}
                onClick={() => setCurrentTrack(idx)}
              >
                <div
                  className={`${style.trackTitle} bentonsansthin`}
                  data-localeid={track.title}
                >
                  <FormattedMessage id={track.title} />
                </div>
              </animated.div>
            );
          })}
        </div>
      </animated.div>
      {
        <button
          className={`button transparent dls-black flex ${style.playlistToggle}`}
          onClick={() => setShowPlaylist(!showPlaylist)}
        >
          <div className={`${style.playlistIconContainer}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path
                fill="#4D6571"
                fillRule="evenodd"
                d="M15.341 4.027c.152-.053.32-.025.45.069.131.093.208.245.208.405V11.5L16 12c0 1.104-.896 2-2 2-1.105 0-2-.896-2-2s.895-2 2-2c.366 0 .704.105.999.277V5.195L10 6.861V14c0 1.104-.896 2-2 2-1.105 0-2-.896-2-2s.895-2 2-2c.366 0 .705.105 1 .277V6.501c0-.216.138-.406.342-.475l5.999-1.999zM6.5 8c.276 0 .5.224.5.5s-.224.5-.5.5h-6C.224 9 0 8.776 0 8.5S.224 8 .5 8h6zm0-4c.276 0 .5.224.5.5s-.224.5-.5.5h-6C.224 5 0 4.776 0 4.5S.224 4 .5 4h6zm9-4c.276 0 .5.224.5.5s-.224.5-.5.5H.5C.224 1 0 .776 0 .5S.224 0 .5 0h15z"
              />
            </svg>
          </div>
          <span
            data-localeid={showPlaylist ? "close" : "open"}
            className={`${style.playlistTitle} bentonsansthin`}
          >
            <FormattedMessage id="audioPlaylistTitle" />
          </span>
        </button>
      }
    </div>
  );
};

AudioPlaylist.propTypes = {
  tracks: PropTypes.array,
};

AudioPlaylist.defaultProps = {
  tracks: [],
};

export default AudioPlaylist;
