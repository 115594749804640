import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { animated, useSpring, useTrail } from '@react-spring/web';
import InViewProfile from "../InView/InViewProfile";
import LineSet from "../LineSet/LineSet";
import style from "./FeatureCard.module.scss";
import ImageContainer from "../ImageContainer/ImageContainer";

const animConfig = (ref) => {
  return {
    from: {
      opacity: ref ? 0 : 1,
      transform: ref ? "translate3d(0,32px,0)" : "translate3d(0,0px,0)",
    },
    to: {
      opacity: ref ? 1 : 0,
      transform: ref ? "translate3d(0,0,0)" : "translate3d(0,32px,0)",
    },
    delay: 80,
  };
};

const FeatureCard = ({ intl }) => {
  const params = useParams();

  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const blackCardImage = `${assetPath}/home/black-card-rounded.webp`;
  const cardsArtwork = `${assetPath}/home/cards-redesign.webp`;
  const artist1CardImage = `${assetPath}/home/kehinde-wiley-card-rounded.webp`;
  const artist2CardImage = `${assetPath}/home/rem-koolhaas-card-rounded.webp`;

  const blurbs = [
    {
      id: `home.cardBlurbIntro`,
      // style: style.cardBlurbIntro,
      style: `${style.cardBlurbIntro} bigcaslonregular`,
      classes:
        "col-sm-10 col-sm-offset-1 col-md-5 col0-md-offset-1 col-lg-6 col-lg-offset-0",
    },
    {
      id: `home.cardBlurb1`,
      style: `${style.blurb} ${style.cardBlurb1} bentonsansthin`,
      classes:
        "col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-3 col-lg-5 col-lg-offset-3",
    },
    {
      id: `home.cardBlurb2`,
      style: `${style.blurb} bentonsansthin`,
      classes: `col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-5 col-lg-5 col-lg-offset-6 ${style.lastRow}`,
    },
  ];

  const cards = [
    {
      id: 0,
      className: "",
      to: {
        pathname: `/${params.locale}/${params.card}/${params.centurion}/card`,
        hash: "#classicBlack",
      },
      alt: intl.formatMessage({ id: `home.blackCard` }),
      src: blackCardImage,
    },
    {
      id: 1,
      className: "",
      to: {
        pathname: `/${params.locale}/${params.card}/${params.centurion}/card`,
        hash: "#kehindeWiley",
      },
      alt: intl.formatMessage({ id: `home.artist1Card` }),
      src: artist1CardImage,
    },
    {
      id: 2,
      className: "",
      to: {
        pathname: `/${params.locale}/${params.card}/${params.centurion}/card`,
        hash: "#remKoolhaas",
      },
      alt: intl.formatMessage({ id: `home.artist2Card` }),
      src: artist2CardImage,
    },
  ];

  const [blurbRef, blurbsInView] = useInView();
  const blurbAnim = useTrail(blurbs.length, animConfig(blurbsInView));

  const [cardsRef, cardsInView] = useInView();
  const animCards = useTrail(cards.length, {
    from: {
      opacity: cardsInView ? 0 : 1,
      transform: cardsInView ? "translate3d(0,64px,0)" : "translate3d(0,0px,0)",
    },
    to: {
      opacity: cardsInView ? 1 : 0,
      transform: cardsInView ? "translate3d(0,0,0)" : "translate3d(0,64px,0)",
    },
    delay: 300,
  });

  const [moreRef, moreInView] = useInView();
  const animMore = useSpring({
    from: {
      opacity: moreInView ? 0 : 1,
      transform: moreInView ? "translate3d(0,32px,0)" : "translate3d(0,0px,0)",
    },
    to: {
      opacity: moreInView ? 1 : 0,
      transform: moreInView ? "translate3d(0,0,0)" : "translate3d(0,32px,0)",
    },
  });

  return (
    <>
      <div className={`position-relative ${style.artwork}`}>
        <div className="container">
          <InViewProfile y={0} duration={1000}>
            <img src={cardsArtwork} alt="" />
          </InViewProfile>
        </div>
      </div>
      <div className={style.wrapper} ref={blurbRef}>
        {blurbAnim.map((blurb, index) => (
          <animated.div key={blurbs[index].id} className="row" style={blurb}>
            <div className={`${blurbs[index].classes}`}>
              {index === 2 ? (
                <p className={`${blurbs[index].style} text-justify`}>
                  <LineSet
                    id={blurbs[index].id}
                    desktop={
                      ['en-US','en-ID'].includes(intl.locale) ? [8, 6] : [9, 6]
                    }
                    tablet={[]}
                    mobile={[]}
                    values={{
                      em: (str) => <em>{str}</em>,
                      bold: (str) => <>{str}</>,
                      lineBreak: <br />,
                      classicBlackLink: (
                        <FormattedMessage id={`home.classicBlackLinkText`} />
                      ),
                      artCardLink: (
                        <FormattedMessage id={`home.artCardLinkText`} />
                      ),
                    }}
                  />
                </p>
              ) : (
                <p className={`${blurbs[index].style} text-justify`}>
                  <FormattedMessage
                    id={blurbs[index].id}
                    values={{
                      em: (str) => <em>{str}</em>,
                      bold: (str) => <>{str}</>,
                      lineBreak: <br />,
                      classicBlackLink: (
                        <FormattedMessage id={`home.classicBlackLinkText`} />
                      ),
                      artCardLink: (
                        <FormattedMessage id={`home.artCardLinkText`} />
                      ),
                    }}
                  />
                </p>
              )}
            </div>
          </animated.div>
        ))}
        <div ref={cardsRef} className={style.cardsWrapper}>
          {animCards.map((props, index) => (
            <animated.div
              key={cards[index].id}
              style={props}
              className={cards[index].className}
            >
              <Link
                className={`display-block no-scroll ${style.card}`}
                to={cards[index].to}
              >
                <ImageContainer
                  alt={cards[index].alt}
                  ratio={1}
                  src={cards[index].src}
                />
                <span className="sr-only">{cards[index].alt}</span>
              </Link>
            </animated.div>
          ))}
        </div>
        <div ref={moreRef} className="text-align-center">
          <animated.div className="text-align-center" style={animMore}>
            <Link
              className={`btn btn-centurion-black moreButton bentonsanslight`}
              to={`/${params.locale}/${params.card}/${params.centurion}/card`}
            >
              <FormattedMessage id={`home.findOutMore`} />
            </Link>
          </animated.div>
        </div>
      </div>
    </>
  );
};

FeatureCard.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(FeatureCard);
