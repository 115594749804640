import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import ImageContainer from "../../components/ImageContainer/ImageContainer";
import Modal from "../../components/Modal/Modal";
import { Helmet } from "react-helmet-async";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import slidesData from "../../static/koolhaasSlides";
import HomeLink from "../../components/HomeLink/HomeLink";
import Slideshow from "../../components/Slideshow/Slideshow";
import InViewProfile from "../../components/InView/InViewProfile";
import ChevronDown from "../../components/ChevronDown/ChevronDown";
import Logotype from "../../components/Logotype/Logotype";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import LineSet from "../../components/LineSet/LineSet";
import BespokeAudio from "../../components/BespokeAudio/BespokeAudio";
import style from "./Koolhaas.module.scss";

const BOOMPJES = "boompjes";
const BOOMPJESLG = "boompjesLG";
const BOOMPJESDETAIL = "boompjesdetail";
const BOOMPJESDETAILLG = "boompjesdetailLG";
const BUTTERFLY = "butterfly";
const BUTTERFLYLG = "butterflyLG";

const Koolhaas = ({ updateRoot }) => {
  const { locale: localeName, messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();

  useEffect(() => {
    updateRoot("koolhaas");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;

  const butterflyImage = `${assetPath}/koolhaas/butterfly.png`;
  const butterflyImageLG = `${assetPath}/koolhaas/butterfly-LG.png`;
  const butterflyBgImage = `${assetPath}/koolhaas/butterfly-bg.png`;
  const boompjesImage = `${assetPath}/koolhaas/boompjes.jpg`;
  const boompjesImageLG = `${assetPath}/koolhaas/boompjes-LG.png`;
  const boompjesImageRot = `${assetPath}/koolhaas/boompjes-rotated.png`;
  const boompjesdetailImage = `${assetPath}/koolhaas/boompjes-detail.png`;
  const boompjesdetailImageLG = `${assetPath}/koolhaas/boompjes-detail-LG.png`;
  const profileImage = `${assetPath}/koolhaas/rem-koolhaas.png`;
  const zoomIn = `${assetPath}/koolhaas/zoomIn.svg`;

  const [pageRes, setPageRes] = useState("small"); // 'small' or 'large'
  const [pageHeight, setPageHeight] = useState(null);

  const resetPageRes = () => {
    let res = window.innerWidth;
    setPageRes(res < 768 ? "small" : res > 1024 ? "large" : "m+"); // catch state change with useEffect (below)

    setPageHeight(window.innerHeight > 830 ? 900 : 50);
  };

  useEffect(() => {
    if (!window) {
      return;
    }
    const debFunc = debounce(function () {
      resetPageRes();
    }, 25);
    resetPageRes(); // initial call...
    window.addEventListener("resize", debFunc);
    return () => {
      window.removeEventListener("resize", debFunc);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--butterflyBg",
      `url(${butterflyBgImage})`
    );
  });

  function handleImageClick({ description, imageKey, ratio, title }) {
    const map = {
      [BOOMPJES]: boompjesImage,
      [BOOMPJESDETAIL]: boompjesdetailImage,
      [BOOMPJESDETAILLG]: boompjesdetailImageLG,
      [BUTTERFLY]: butterflyImage,
      [BOOMPJESLG]: boompjesImageLG,
      [BUTTERFLYLG]: butterflyImageLG,
    };

    setModalData({
      src: map[imageKey],
      ratio,
      description,
      title,
      imageKey,
    });
    setModalType("image");
    setIsModalOpen(true);
  }

  const slideshowRef = useRef();
  const diagonalRef = useRef();
  const boompjesRef = useRef();
  const refMap = {
    slideshow: slideshowRef,
    boompjes: boompjesRef,
    diagonal: diagonalRef,
  };

  function handleChevronClick(id) {
    const element = refMap[id] && refMap[id].current;

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  const koolhaasTracks = [
    {
      id: 0,
      title: "audioTitle0",
      src: "rem.mp3",
    },
  ];

  // replace mapped modal props with consts passed to children
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState("image");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIsModalOpen = (isOpen) => {
    setIsModalOpen(isOpen);
  };

  if (!languageData.centurion) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{languageData["koolhaas.title"]}</title>
      </Helmet>
      <div className={style.koolhaas}>
        <div id="pageTop" data-module="koolhaas" className="position-relative">
          <div
            className={`${style.cssDiagonal} ${style.cssDiagonalTop}`}
            style={{
              background: `linear-gradient(0deg, #d3e0e6 0%, #EEF5F8 100%), url("${butterflyBgImage}")`,
              backgroundPosition: "62% 86%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          />

          <div className={`container ${style.topContainer}`}>
            <header className={`position-relative ${style.header}`}>
              <HomeLink rootModule="koolhaas" />
              <div className="row">
                <div className="col-sm-12 col-sm-offset-0 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                  <div className={`${style.titleWrapper}`}>
                    <Logotype
                      id="artRemProfile"
                      title="title"
                      rootModule="koolhaas"
                    />
                  </div>
                </div>
              </div>
              <div className={`row`}>
                <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                  <InViewProfile y={0} duration={800}>
                    <img
                      className={`${style.profileImageMobile}`}
                      src={profileImage}
                      alt={languageData["koolhaas.title"]}
                    />
                  </InViewProfile>
                  <InViewProfile y={0} duration={800} delay={250}>
                    <p
                      data-localeid="summary"
                      data-profile-image={profileImage}
                      className={`${style.summary} text-justify bigcaslonregular`}
                    >
                      {pageRes === "large" && (
                        <LineSet
                          id="koolhaas.summaryLarge"
                          desktop={[12, 11, 10]}
                          tablet={[12, 10, 9]}
                          mobile={[7, 7, 7, 6, 6]}
                        />
                      )}

                      {pageRes !== "large" && (
                        <LineSet
                          id="koolhaas.summary"
                          desktop={[12, 11, 8]}
                          tablet={[12, 10, 9]}
                          mobile={[7, 7, 7, 6, 6]}
                        />
                      )}
                    </p>
                  </InViewProfile>
                </div>
              </div>
              {/* <div className={`${style.diagonalLine} row`}>
                  <svg className={`col-xs-2 col-xs-offset-5 col-md-1 col-md-offset-3 col-lg-offset-4`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 36">
                    <path fill="none" fillRule="evenodd" stroke="#000" strokeWidth=".5" d="M0 1l85 34"/>
                  </svg>
                </div> */}
            </header>
            <div className={`row ${style.profileAndCopy}`}>
              <div
                className={`col-md-3 col-md-offset-1 col-lg-3 col-lg-offset-2`}
              >
                <InViewProfile y={0} duration={1000} delay={600}>
                  <img
                    className={`${style.profileImage}`}
                    src={profileImage}
                    alt={languageData["koolhaas.title"]}
                  />
                </InViewProfile>
              </div>
              <div
                className={`col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-1 col-lg-5 col-lg-offset-1 lineSetOverride`}
              >
                <div className={style.strokeTop}>
                  <svg
                    width="60"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 1l60 22.841" stroke="#000" strokeWidth=".1" />
                    </g>
                  </svg>
                </div>

                <InViewProfile y={0} duration={1000} delay={900}>
                  <>
                    <p
                      data-localeid="content1"
                      className={`${style.content} ${style.contentFirst} bentonsansthin text-justify`}
                    >
                      <LineSet
                        id="koolhaas.content1"
                        desktop={[12, 10, 10]}
                        tablet={[12, 10, 10, 9]}
                        mobile={[8, 7, 8, 7, 8, 9]}
                        values={{ lineBreak: <br /> }}
                      />
                    </p>
                    <p
                      data-localeid="content2"
                      className={`${style.content} ${style.contentSecond} bentonsansthin text-justify`}
                    >
                      <LineSet
                        id="koolhaas.content2"
                        desktop={[10, 10, 8]}
                        tablet={[10, 9, 9, 5]}
                        mobile={[7, 8, 6, 7, 6]}
                        values={{ lineBreak: <br /> }}
                      />
                    </p>
                  </>
                </InViewProfile>
              </div>
            </div>
            {pageHeight && (
              <div className={style.spotifyWrapper}>
                <InViewProfile y={0} duration={1000} delay={pageHeight}>
                  {/* <iframe
                      src={`https://open.spotify.com/embed/track/21guRcOnbejctLBqlTMgfj`}
                      className={style.spotifyEmbed}
                      width="300"
                      height="80"
                      frameBorder="0"
                      allowtransparency="true"
                      allow="encrypted-media"
                    ></iframe>
                    <p
                      data-localeid="spotifyIntro"
                      className={`${style.spotifyTitle} bentonsansthin text-justify`}
                    >
                      <FormattedMessage id="spotifyIntro" />
                    </p> */}
                  <BespokeAudio tracks={koolhaasTracks} rootModule="koolhaas" />
                </InViewProfile>
              </div>
            )}
          </div>
          <ChevronDown
            onClick={() => handleChevronClick("slideshow")}
            id="chevronToSlideshow"
            rootModule="koolhaas"
          />
        </div>
        <div className="container">
          <section
            ref={slideshowRef}
            id="slideshow"
            className={style.slideshowWrapper}
          >
            <InViewProfile duration={1000}>
              <Slideshow
                slides={slidesData}
                title="koolhaas.insideTheStudio"
                rootModule="koolhaas"
              />
            </InViewProfile>
          </section>
        </div>
        <div className="container">
          <ChevronDown
            onClick={() => handleChevronClick("boompjes")}
            id="chevronToBoompjes"
            rootModule="koolhaas"
          />
          <InViewProfile y={400} duration={1500} className="boompjesAnim1">
            <>
              <div
                ref={boompjesRef}
                id="boompjes"
                className={style.titleBoompjesWrapper}
              >
                <h2 className={`${style.titleBoompjes} bigcaslonregular`}>
                  <FormattedMessage
                    id="koolhaas.behindTheCard"
                    values={{
                      lineBreak: <br />,
                      cite: (str) => (
                        <cite className="bigcaslonitalic">{str}</cite>
                      ),
                    }}
                  />
                </h2>
              </div>
              <div className={style.boompjesImageContainer}>
                <img
                  className={style.boompjesTabletUp}
                  src={boompjesImageLG}
                  alt={languageData["koolhaas.image2LGTitle"]}
                />
                <img
                  className={style.boompjesMobileOnly}
                  src={boompjesImageRot}
                  alt={languageData["koolhaas.image2LGTitle"]}
                />
              </div>
              <ChevronDown
                onClick={() => handleChevronClick("diagonal")}
                id="chevronToBottom"
                rootModule="koolhaas"
              />
            </>
          </InViewProfile>
        </div>

        <div id="pageBottom" className={`${style.pageBottom}`}>
          <div className="container">
            <div ref={diagonalRef} id="diagonal" className={style.diagonal}>
              <div className={`position-relative ${style.boompjesContainer}`}>
                <InViewProfile
                  y={400}
                  duration={1500}
                  className="boompjesAnim2"
                >
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className={`${style.titleStory} bigcaslonregular`}>
                          <FormattedMessage
                            id="koolhaas.theStoryofBoompjes"
                            values={{
                              lineBreak: <br />,
                              cite: (str) => (
                                <cite className="bigcaslonitalic">{str}</cite>
                              ),
                            }}
                          />
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-5 col-lg-6">
                        <p
                          data-localeid="content3"
                          className={`body-1 ${style.content} ${style.contentThree} text-justify bentonsansthin`}
                        >
                          <LineSet
                            id="koolhaas.content3"
                            desktop={[10, 11, 11, 12, 13, 11]}
                            tablet={[10, 10, 10, 12, 11, 12]}
                            mobile={
                              localeName === "en-US"
                                ? [7, 7, 5, 7, 6, 8, 6, 7, 8]
                                : []
                            }
                            values={{
                              i: (str) => <i>{str}</i>,
                            }}
                          />
                        </p>
                      </div>
                    </div>
                    <div className={style.butterflyContainer}>
                      <div
                        onClick={() =>
                          handleImageClick({
                            imageKey: "butterflyLG",
                            title: (
                              <FormattedMessage
                                id="koolhaas.image1Title"
                                values={{
                                  cite: (str) => <cite>{str}</cite>,
                                }}
                              />
                            ), // languageData.image1Title,
                            description:
                              languageData["koolhaas.image1Description"],
                          })
                        }
                        className={`${style.onClick}`}
                      >
                        <ImageContainer ratio={1.12} src={butterflyImage}>
                          <div className={`flex ${style.imageButtonContainer}`}>
                            <button
                              className={`btn transparent ${style.enlarge}`}
                              type="button"
                              onClick={() =>
                                handleImageClick({
                                  imageKey: "butterflyLG",
                                  title: (
                                    <FormattedMessage
                                      id="koolhaas.image1Title"
                                      values={{
                                        cite: (str) => <cite>{str}</cite>,
                                      }}
                                    />
                                  ), // languageData.image1Title,
                                  description:
                                    languageData["koolhaas.image1Description"],
                                })
                              }
                            >
                              <img
                                className={`${style.enlargeIcon}`}
                                alt={languageData["koolhaas.zoomIn"]}
                                src={zoomIn}
                              />
                            </button>
                            <div>
                              <h3
                                data-localeid="image1Title"
                                className={`${style.imageDescription} bentonsanslight`}
                              >
                                <FormattedMessage
                                  id="koolhaas.image1Title"
                                  values={{
                                    cite: (str) => <cite>{str}</cite>,
                                  }}
                                />
                              </h3>
                              <p
                                data-localeid="image1Description"
                                className={`${style.imageDescriptionTwo} bentonsansthin`}
                              >
                                <FormattedMessage id="koolhaas.image1Description" />
                              </p>
                            </div>
                          </div>
                        </ImageContainer>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-5 col-lg-6">
                        <p
                          data-localeid="content4"
                          className={`body-1 ${style.content} ${style.contentFour} text-justify bentonsansthin`}
                        >
                          <LineSet
                            id="koolhaas.content4"
                            desktop={[10, 14, 12, 12, 12]}
                            tablet={[10, 13, 11, 11, 11, 11]}
                            mobile={[5, 7, 9, 8, 8, 7, 7, 8, 6]}
                            values={{
                              i: (str) => <i>{str}</i>,
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </>
                </InViewProfile>

                <InViewProfile
                  y={400}
                  duration={1500}
                  delay={300}
                  className="boompjesAnim3"
                >
                  <>
                    <div className={style.boompjesDetailContainer}>
                      <div
                        className={`${style.onClick}`}
                        onClick={() =>
                          handleImageClick({
                            imageKey: "boompjesdetailLG",
                            title: (
                              <FormattedMessage
                                id="koolhaas.image3Title"
                                values={{
                                  cite: (str) => <cite>{str}</cite>,
                                }}
                              />
                            ), // languageData.image3Title
                            description:
                              languageData["koolhaas.image3Description"],
                          })
                        }
                      >
                        <ImageContainer
                          ratio={1}
                          src={boompjesdetailImage}
                          type="boompjes"
                        >
                          <div className={`flex ${style.imageButtonContainer}`}>
                            <button
                              className={`btn transparent ${style.enlarge}`}
                              type="button"
                              onClick={() =>
                                handleImageClick({
                                  imageKey: "boompjesdetailLG",
                                  title: (
                                    <FormattedMessage
                                      id="koolhaas.image3Title"
                                      values={{
                                        cite: (str) => <cite>{str}</cite>,
                                      }}
                                    />
                                  ), // languageData.image3Title
                                  description:
                                    languageData["koolhaas.image3Description"],
                                })
                              }
                            >
                              <img
                                className={`${style.enlargeIcon}`}
                                alt={languageData["koolhaas.zoomIn"]}
                                src={zoomIn}
                              />
                            </button>
                            <div>
                              <h3
                                data-localeid="image3Title"
                                className={`${style.imageDescription} bentonsanslight`}
                              >
                                <FormattedMessage
                                  id="koolhaas.image3Title"
                                  values={{
                                    cite: (str) => <cite>{str}</cite>,
                                  }}
                                />
                              </h3>
                              <p
                                data-localeid="image3Description"
                                className={`${style.imageDescriptionTwo} text-justify bentonsansthin`}
                              >
                                <FormattedMessage id="koolhaas.image3Description" />
                              </p>
                            </div>
                          </div>
                        </ImageContainer>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-10 col-sm-offset-1 col-md-5 col-md-offset-7 col-lg-5">
                        <p
                          data-localeid="content5"
                          className={`body-1 ${style.content} ${style.contentLastInRow} text-justify bentonsansthin`}
                        >
                          <FormattedMessage
                            id="koolhaas.content5"
                            values={{
                              i: (str) => <i>{str}</i>,
                            }}
                          />
                          {
                            // <LineSet id="content5" desktop={[6, 5, 9, 7, 7, 5, 9]} />
                          }
                        </p>

                        <div className={style.strokeBottom}>
                          <svg
                            width="106"
                            height="48"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="M0 1l106 46.676"
                                stroke="#000"
                                strokeWidth=".1"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className={`row ${style.beautyQuoteSwitch}`}>
                      <div className="col-md-4 col-md-offset-8 col-lg-3 col-lg-offset-8">
                        <div className={style.beautyQuote}>
                          <blockquote className="bigcaslonregular">
                            <p>
                              <FormattedMessage id="koolhaas.isBeautyQuote" />
                            </p>
                            <footer>
                              <cite>
                                <FormattedMessage id="koolhaas.isBeautyQuoteAttribution" />
                              </cite>
                            </footer>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </>
                </InViewProfile>
              </div>
            </div>
          </div>
        </div>

        <div className={`${style.cssDiagonal}`} />

        <div className={`${style.containerOverride} container`}>
          <SiteFooter />
        </div>
      </div>
      {isModalOpen && (
        <Modal
          data={modalData}
          type={modalType}
          handleIsModalOpen={handleIsModalOpen}
        />
      )}
    </>
  );
};

Koolhaas.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(null, mapDispatchToProps)(Koolhaas);
