import { createSlice } from "@reduxjs/toolkit";

const browserSlice = createSlice({
  name: "browser",
  initialState: {
    hasTouch: false,
    pageDimensions: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  },
  reducers: {
    setHasTouch: (state, action) => {
      state.hasTouch = action.payload;
    },
    setPageDimensions: (state, { payload: { width, height } }) => {
      state.pageDimensions.width = width;
      state.pageDimensions.height = height;
    },
  },
});

export const { setHasTouch, setPageDimensions } = browserSlice.actions;
export default browserSlice.reducer;
