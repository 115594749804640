const wearableFaqs = (locale) => {
  const data = {
    "en-GB": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 7,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
      {
        id: 8,
        title: "wearable.faq8Question",
        text: "wearable.faq8Answer",
      },
      {
        id: 9,
        title: "wearable.faq9Question",
        text: "wearable.faq9Answer",
      },
    ],
    "es-ES": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 7,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
        lastItem: true,
      },
    ],
    "en-BE": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 8,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 7,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 9,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
    ],
    "en-BH": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 5,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
      {
        id: 6,
        title: "wearable.faq8Question",
        text: "wearable.faq8Answer",
      },
    ],
    "fr-FR": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq8Question",
        text: "wearable.faq8Answer",
      },
      {
        id: 5,
        title: "wearable.faq9Question",
        text: "wearable.faq9Answer",
      },
      {
        id: 6,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 7,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 8,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 9,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
    ],
    "en-US": [
      // {
      //   id: 1,
      //   title: "wearable.faq1Question",
      //   text: "wearable.faq1Answer",
      // },
      // {
      //   id: 2,
      //   title: "wearable.faq2Question",
      //   text: "wearable.faq2Answer",
      // },
      // {
      //   id: 3,
      //   title: "wearable.faq3Question",
      //   text: "wearable.faq3Answer",
      // },
      // {
      //   id: 4,
      //   title: "wearable.faq4Question",
      //   titleCleared: true,
      //   subtitleAsTitle: true,
      //   text: "wearable.faq4Answer1",
      // },
      // {
      //   id: 2,
      //   title: "wearable.faq2Question",
      //   text: "wearable.faq2Answer",
      // },
      // {
      //   id: 3,
      //   title: "wearable.faq3Question",
      //   text: "wearable.faq3Answer",
      // },
      // {
      //   id: 4,
      //   title: "wearable.faq4Question",
      //   titleCleared: true,
      //   subtitleAsTitle: true,
      //   text: "wearable.faq4Answer1",
      // },
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 7,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 8,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 9,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
      {
        id: 10,
        title: "wearable.faq8Question",
        text: "wearable.faq8Answer",
      },
    ],
    "en-GL": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
    ],
    "en-FI": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
    ],
    "en-SE": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
    ],
    "en-NL": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
    ],
    "en-NO": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
    ],
    "en-HK": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
    ],
    "zh-HK": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 5,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 6,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
    ],
    "zh-TW": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 7,
        title: "wearable.faq7Question",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "wearable.faq7Answer1",
      },
      {
        id: 8,
        subtitleAsTitle: true,
        sectionTitle: "wearable.faq8Question",
        text: "wearable.faq7Answer2",
      },
      {
        id: 9,
        subtitleAsTitle: true,
        sectionTitle: "wearable.faq8Question",
        text: "wearable.faq7Answer3",
      },
      {
        id: 10,
        title: "wearable.faq8Question",
        text: "wearable.faq8Answer",
      },
      {
        id: 11,
        title: "wearable.faq9Question",
        text: "wearable.faq9Answer",
      },
      {
        id: 12,
        title: "wearable.faq10Question",
        text: "wearable.faq10Answer",
      },
      {
        id: 13,
        title: "wearable.faq11Question",
        text: "wearable.faq11Answer",
        lastItem: true,
        titleCleared: true,
      },
    ],
    "it-IT": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
    ],
    "de-DE": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
    ],
    "en-AU": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "wearable.faq4Answer1",
      },
      {
        id: 5,
        subtitleAsTitle: true,
        sectionTitle: "wearable.faq4Question",
        text: "wearable.faq4Answer2",
      },
      {
        id: 6,
        subtitleAsTitle: true,
        sectionTitle: "wearable.faq4Question",
        text: "wearable.faq4Answer3",
      },
      {
        id: 7,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 8,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 9,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
    ],
    "en-SG": [
      { // one
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      }, // two
      {
        id: 4,
        title: "wearable.faq4Question",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "wearable.faq4Answer1",
      },
      {
        id: 5,
        subtitleAsTitle: true,
        sectionTitle: "wearable.faq4Question",
        text: "wearable.faq4Answer2",
      },
      {
        id: 6,
        subtitleAsTitle: true,
        sectionTitle: "wearable.faq4Question",
        text: "wearable.faq4Answer3",
      }, // three
      {
        id: 7,
        title: "wearable.faq5Question",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "wearable.faq5Answer1",
      },
      {
        id: 8,
        subtitleAsTitle: true,
        sectionTitle: "wearable.faq5Question",
        text: "wearable.faq5Answer2",
      },
      {
        id: 10,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      { // four
        id: 11,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
    ],
    "de-AT": [
      { // one
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      }, // two
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 7,
        title: "wearable.faq7Question",
        titleCleared: true,
        subtitleAsTitle: true,
        text: "wearable.faq7Answer1",
      },
      {
        id: 8,
        subtitleAsTitle: true,
        sectionTitle: "wearable.faq7Question",
        text: "wearable.faq7Answer2",
      },
      {
        id: 9,
        subtitleAsTitle: true,
        sectionTitle: "wearable.faq7Question",
        text: "wearable.faq7Answer3",
      },
      { // four
        id: 10,
        title: "wearable.faq8Question",
        text: "wearable.faq8Answer",
      },
      {
        id: 11,
        title: "wearable.faq9Question",
        text: "wearable.faq9Answer",
      },
    ],
    "ja-JP": [
      { // one
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      }, // two
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
    ],
    "en-ID": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 7,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
      {
        id: 8,
        title: "wearable.faq8Question",
        text: "wearable.faq8Answer",
      },
      {
        id: 9,
        title: "wearable.faq9Question",
        text: "wearable.faq9Answer",
      },
      {
        id: 10,
        title: "wearable.faq10Question",
        text: "wearable.faq10Answer",
      },
      {
        id: 11,
        title: "wearable.faq11Question",
        text: "wearable.faq11Answer",
      },
    ],
    "es-ID": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 7,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
      {
        id: 8,
        title: "wearable.faq8Question",
        text: "wearable.faq8Answer",
      },
      {
        id: 9,
        title: "wearable.faq9Question",
        text: "wearable.faq9Answer",
      },
      {
        id: 10,
        title: "wearable.faq10Question",
        text: "wearable.faq10Answer",
      },
      {
        id: 11,
        title: "wearable.faq11Question",
        text: "wearable.faq11Answer",
      },
    ],
    "en-IN": [
      {
        id: 1,
        title: "wearable.faq1Question",
        text: "wearable.faq1Answer",
      },
      {
        id: 2,
        title: "wearable.faq2Question",
        text: "wearable.faq2Answer",
      },
      {
        id: 3,
        title: "wearable.faq3Question",
        text: "wearable.faq3Answer",
      },
      {
        id: 4,
        title: "wearable.faq4Question",
        text: "wearable.faq4Answer",
      },
      {
        id: 5,
        title: "wearable.faq5Question",
        text: "wearable.faq5Answer",
      },
      {
        id: 6,
        title: "wearable.faq6Question",
        text: "wearable.faq6Answer",
      },
      {
        id: 7,
        title: "wearable.faq7Question",
        text: "wearable.faq7Answer",
      },
      {
        id: 8,
        title: "wearable.faq8Question",
        text: "wearable.faq8Answer",
      },
      {
        id: 9,
        title: "wearable.faq9Question",
        text: "wearable.faq9Answer",
      },
      {
        id: 10,
        title: "wearable.faq10Question",
        text: "wearable.faq10Answer",
      },
      {
        id: 11,
        title: "wearable.faq11Question",
        text: "wearable.faq11Answer",
      },
    ],
  };
  return data[locale] || data["en-GB"];
};

export default wearableFaqs;
