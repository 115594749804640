// For use with components/Viewings
const viewings = {
  dates: [
    "availableFromFeb",
    // 'availableFromMarch',
  ],
  countries: [
    {
      countryId: "united-states",
      locales: ["en-us"],
      from: "availableFromFeb",
      regions: [
        {
          id: "us-north-east",
          subRegions: [
            {
              id: "new-york",
              items: [
                { id: "new-york-1" },
                { id: "new-york-2" },
                { id: "new-york-3" },
                { id: "new-york-4" },
                { id: "new-york-5" },
                { id: "new-york-6" },
                { id: "new-york-7" },
              ],
            },
            {
              id: "philadelphia",
              items: [{ id: "philadelphia-1" }],
            },
            {
              id: "washington-dc",
              items: [{ id: "washington-dc-1" }],
            },
            // {
            //   id: "chicago",
            //   items: [
            //     { id: "chicago-1" },
            //   ],
            // },
          ],
        },
        {
          id: "us-midwest",
          subRegions: [
            {
              id: "chicago",
              items: [{ id: "chicago-1" }],
            },
          ],
        },
        {
          id: "us-west",
          subRegions: [
            {
              id: "los-angeles",
              items: [
                { id: "los-angeles-1" },
                { id: "los-angeles-2" },
                { id: "los-angeles-3" },
                { id: "los-angeles-4" },
              ],
            },
            {
              id: "las-vegas",
              items: [
                { id: "las-vegas-1" },
                { id: "las-vegas-2" },
                { id: "las-vegas-3" },
              ],
            },
            {
              id: "san-francisco",
              items: [{ id: "san-francisco-1" }],
            },
            {
              id: "san-diego",
              items: [{ id: "san-diego-1" }],
            },
            {
              id: "san-jose",
              items: [{ id: "san-jose-1" }],
            },
            {
              id: "phoenix-scottsdale",
              items: [
                { id: "phoenix-scottsdale-1" },
                { id: "phoenix-scottsdale-2" },
              ],
            },
            {
              id: "washington",
              items: [{ id: "washington-1" }, { id: "washington-2" }],
            },
            {
              id: "denver",
              items: [{ id: "denver-1" }],
            },
            {
              id: "aspen",
              items: [{ id: "aspen-1" }],
            },
          ],
        },
        {
          id: "us-florida",
          subRegions: [
            {
              id: "miami",
              items: [{ id: "miami-1" }, { id: "miami-2" }, { id: "miami-3" }],
            },
            {
              id: "orlando",
              items: [{ id: "orlando-1" }],
            },
          ],
        },
        {
          id: "us-south",
          subRegions: [
            {
              id: "texas",
              items: [{ id: "texas-1" }, { id: "texas-2" }, { id: "texas-3" }],
            },
            {
              id: "atlanta",
              items: [{ id: "atlanta-1" }],
            },
            {
              id: "charlotte",
              items: [{ id: "charlotte-1" }],
            },
          ],
        },
      ],
    },
    {
      countryId: "uk",
      locales: ["en-gb"],
      from: "availableFromFeb",
      items: [
        {
          id: "uk-1",
          map: "#",
        },
        {
          id: "uk-2",
          map: "#",
        },
      ],
    },
    {
      countryId: "spain",
      locales: ["es-es"],
      from: "availableFromFeb",
      items: [
        {
          id: "spain-1",
        },
        {
          id: "spain-2",
        },
      ],
    },
    {
      countryId: "germany",
      locales: ["de-de"],
      from: "availableFromFeb",
      items: [
        {
          id: "germany_1",
          map: "#",
        },
        {
          id: "germany_2",
          map: "#",
        },
        {
          id: "germany_3",
          map: "#",
        },
        {
          id: "germany_4",
          map: "#",
        },
        {
          id: "germany_5",
          map: "#",
        },
        {
          id: "germany_6",
          map: "#",
        },
      ],
    },
    {
      countryId: "austria",
      locales: ["de-at"],
      from: "availableFromFeb",
      items: [
        {
          id: "austria-1",
          map: "#",
        },
      ],
    },
    {
      countryId: "sweden",
      locales: ["en-se"],
      from: "availableFromFeb",
      items: [
        {
          id: "sweden_1",
          map: "#",
        },
        {
          id: "sweden_2",
          map: "#",
        },
      ],
    },
    {
      countryId: "italy",
      locales: ["it-it"],
      from: "availableFromFeb",
      items: [
        {
          id: "italy_1",
          map: "#",
        },
        {
          id: "italy_2",
          map: "#",
        },
        {
          id: "italy_3",
          map: "#",
        },
        {
          id: "italy_4",
          map: "#",
        },
        {
          id: "italy_5",
          map: "#",
        },
        {
          id: "italy_6",
          map: "#",
        },
        {
          id: "italy_7",
          map: "#",
        },
        {
          id: "italy_8",
          map: "#",
        },
        {
          id: "italy_9",
          map: "#",
        },
        {
          id: "italy_10",
          map: "#",
        },
        {
          id: "italy_11",
          map: "#",
        },
        {
          id: "italy_12",
          map: "#",
        },
      ],
    },
    {
      countryId: "hongkong",
      locales: ["en-hk", "zh-hk"],
      from: "availableFromFeb",
      items: [
        {
          id: "hongkong_1",
          map: "#",
        },
        {
          id: "hongkong_2",
          map: "#",
        },
      ],
    },
    {
      countryId: "netherlands",
      locales: ["en-nl"],
      from: "availableFromFeb",
      items: [
        {
          id: "netherlands_1",
          map: "#",
        },
        {
          id: "netherlands_2",
          map: "#",
        },
      ],
    },
    {
      countryId: "middleeast",
      locales: ["en-gl", "en-bh"],
      from: "availableFromFeb",
      items: [
        {
          id: "me_1",
          map: "#",
        },
        {
          id: "me_2",
          map: "#",
        },
        {
          id: "me_3",
          map: "#",
        },
        {
          id: "me_4",
          map: "#",
        },
        {
          id: "me_5",
          map: "#",
        },
      ],
    },
    {
      countryId: "taiwan",
      locales: ["zh-tw"],
      from: "availableFromFeb",
      items: [
        {
          id: "taiwan-1",
          map: "#",
        },
        {
          id: "taiwan-2",
          map: "#",
        },
        {
          id: "taiwan-3",
          map: "#",
        },
      ],
    },
    {
      countryId: "australia",
      locales: ["en-au"],
      from: "availableFromFeb",
      items: [
        {
          id: "australia-1",
          map: "#",
        },
        {
          id: "australia-2",
          map: "#",
        },
      ],
    },
    {
      countryId: "singapore",
      locales: ["en-sg"],
      from: "availableFromFeb",
      items: [
        {
          id: "singapore-1",
          map: "#",
        },
      ],
    },
    {
      countryId: "japan",
      locales: ["ja-jp"],
      from: "availableFromFeb",
      items: [
        {
          id: "japan-1",
          map: "#",
        },
        {
          id: "japan-2",
          map: "#",
        },
        {
          id: "japan-3",
          map: "#",
        },
        {
          id: "japan-4",
          map: "#",
        },
        {
          id: "japan-5",
          map: "#",
        },
      ],
    },
  ],
};

export default viewings;
