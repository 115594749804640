import React from "react";
import PropTypes from "prop-types";
import style from "./ImageContainer.module.scss";

const ImageContainer = ({
  id,
  alt,
  children,
  imageStyle,
  src,
  halo,
  isRect = false,
  width = null,
  height = null,
  aspectRatio = null,
  backgroundColor = null,
  style: styles = {},
}) => {
  return (
    <div className={style.imageWrapper} style={styles}>
      <div
        aria-label={alt}
        className={`position-relative valign-middle ${style[id]} ${
          halo ? style.halo : ""
        }`}
        role="img"
      >
        <div
          style={{
            paddingBottom: `${
              aspectRatio ||
              (isRect && width && height ? 100 : (height * 100) / width)
            }%`,
          }}
        />
        <div
          className={`position-absolute ${style.image} ${
            isRect ? style.rect : ""
          }`}
          data-test="image"
          style={{
            ...imageStyle,
            ...(backgroundColor
              ? {
                  backgroundColor: `#${backgroundColor}`,
                }
              : src
              ? {
                  backgroundImage: `url('${src}')`,
                }
              : false),
          }}
        />
      </div>
      {children}
    </div>
  );
};

ImageContainer.propTypes = {
  id: PropTypes.string.isRequired,
  alt: PropTypes.string,
  children: PropTypes.node,
  imageStyle: PropTypes.shape({
    mixBlendMode: PropTypes.string,
  }),
  src: PropTypes.string.isRequired,
  halo: PropTypes.bool,
};

ImageContainer.defaultProps = {
  alt: "",
  children: null,
  imageStyle: {},
  halo: false,
};

export default ImageContainer;
