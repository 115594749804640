import React, { useEffect, useState, useMemo } from "react";
import { useParams, useLocation, useOutletContext, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

import PropTypes from "prop-types";

import { updateRootModule } from "../../features/rootModule/rootModuleSlice";

import { assetPath } from "./helpers/assets";
import { getDate } from "./helpers/dates";

import { default as introductionsData } from "../../static/living/introductions";
import { default as performancesData } from "../../static/living/artists";

import HomeLink from "../../components/HomeLink/HomeLink";
import SiteFooter from "../../components/SiteFooter/SiteFooter";

import CalendarTabs from "./components/CalendarTabs";
import CalendarView from "./components/CalendarView";
import CalendarSeasons from "./components/CalendarSeasons";
import CalendarRegion from "./components/CalendarRegion";
import CalendarTalent from "./components/CalendarTalent";
import CalendarArchives from "./components/CalendarArchives";
import PageHero from "./components/PageHero";

import style from "./Calendar.module.scss";
import seasonsForLocale from "../../static/living/seasons";
import { activeSeasons } from "../../static/living/calendar";

function useQuery() {
  const { search } = useLocation();

  return useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  );
}

const Calendar = ({ updateRoot }) => {
  const { messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();
  const params = useParams();
  const query = useQuery();
  const navigate = useNavigate();

  const initialMonthsFilterOptions = [
    {
      id: "JanMar",
      name: `${getLocaleMonth(1, "MMM")} - ${getLocaleMonth(3, "MMM")}`,
      months: [1, 2, 3],
      past: false,
    },
    {
      id: "AprJun",
      name: `${getLocaleMonth(4, "MMM")} - ${getLocaleMonth(6, "MMM")}`,
      months: [4, 5, 6],
      past: false,
    },
    {
      id: "JulSep",
      name: `${getLocaleMonth(7, "MMM")} - ${getLocaleMonth(9, "MMM")}`,
      months: [7, 8, 9],
      past: false,
    },
    {
      id: "OctDec",
      name: `${getLocaleMonth(10, "MMM")} - ${getLocaleMonth(12, "MMM")}`,
      months: [10, 11, 12],
      past: false,
    },
  ];

  const localeCased =
  `${params.locale.split('-')[0]}-${params.locale.split('-')[1].toUpperCase()}`;

  const { availableSeasons } = seasonsForLocale(localeCased);

  let defaultSeason = availableSeasons[availableSeasons.length - 1];
  if (
    query.season &&
    availableSeasons.includes(query.season) &&
    activeSeasons.includes(parseInt(query.season))
  ) {
    defaultSeason = query.season;
  }

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [calendarSeason, setCalendarSeason] = useState(defaultSeason || '2023');

  const [showArchive, setShowArchive] = useState(false);

  const [talentData, setTalentData] = useState([]);

  const [monthsExcluded, setMonthsExcluded] = useState([]);
  const [monthsIncluded, setMonthsIncluded] = useState([]);
  const [monthsFilter, setMonthsFilter] = useState("JanMar");
  const [monthsFilterOptions, setMonthsFilterOptions] = useState(
    initialMonthsFilterOptions
  );

  /*
    We're actually filtering by city, not country. However, we keep this
    variable name as the events have the key `city` so we clashes.
  */
  const [countryFilter, setCountryFilter] = useState("");
  const [countryFilterOptions, setCountryFilterOptions] = useState([]);

  const [talentFilter, setTalentFilter] = useState("");
  const [talentFilterOptions, setTalentFilterOptions] = useState([]);

  useEffect(() => {
    updateRoot("calendar");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  useEffect(() => {
    document.body.setAttribute("data-module-id", "calendar");

    return () => {
      document.body.removeAttribute("data-module-id");
    };
  }, []);

  useEffect(() => {
    if (
      query.season && (
        !availableSeasons.includes(query.season) ||
        !activeSeasons.includes(parseInt(query.season))
      )
    ) {
      navigate({to: '/living/calendar', params: ''});
    }
  }, [availableSeasons, navigate, query.season]);

  // TODO: check hook above this replicates behaviour with navigate
  // useEffect(() => {
  //   if (query.season && query.season !== calendarSeason) {
  //     history.replace({
  //       search: ''
  //     });
  //   }
  // }, [calendarSeason, history, query.season]);

  useEffect(() => {
    initMonthFiltersOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!calendarSeason) {
      return;
    }

    const talentData = require(`./data/calendar-${calendarSeason}.json`);

    talentData.forEach((talentItem) => {
      const { section, year, slug } = talentItem;
      const config = section === "performances"
      ? performancesData?.[year]
      : introductionsData.find(
        (item) => item.season === year
      ).items;
      talentItem.path = config.find((item) => item.id === slug).path || slug;
    });

    setTalentData(talentData);
  }, [calendarSeason]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();

    if (!calendarSeason) {
      setShowArchive(true);
    } else {
      setShowArchive(false);
    }

    if (Number(calendarSeason) !== Number(currentYear)) {
      setMonthsFilterOptions(initialMonthsFilterOptions);

      setMonthsFilter("JanMar");

      return;
    }

    const currentMonth = new Date().getMonth() + 1;

    initMonthFiltersOptions();

    const { id } = monthsFilterOptions.find(({ months }) =>
      months.includes(currentMonth)
    );

    setMonthsFilter(id);
    setTalentFilter("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarSeason]);

  useEffect(() => {
    const countryData = talentData.flatMap((talentItem) => {
      const { events } = talentItem;

      return events.flatMap(({ location, cityTranslations }) => {
        const newLocation = {
          ...location,
          cityTranslations,
        };

        return newLocation;
      });
    });

    const countryDataFormatted = countryData.map((countryItem) => {
      const { id, cityTranslations, ...restCountry } = countryItem;

      const cityNameAsId = cityTranslations["en-fi"]
        .toLowerCase()
        .replace(/ /g, "-");

      const cityId = `${id}-${cityNameAsId}`;

      return {
        id,
        cityId,
        cityTranslations,
        ...restCountry,
      };
    });

    const countriesUnique = countryDataFormatted.filter(
      (countryItem, index, self) =>
        index === self.findIndex((item) => item.cityId === countryItem.cityId)
    );

    const talentOptions = talentData
      .map((talentItem) => {
        const { slug, nameTranslations } = talentItem;

        return {
          id: slug,
          name: nameTranslations[params.locale],
        };
      })
      .sort((talentA, talentB) => talentA.name.localeCompare(talentB.name));

    setTalentFilterOptions(talentOptions);
    setCountryFilterOptions(countriesUnique);
  }, [talentData, params.locale]);

  useEffect(() => {
    const { locale } = params;

    const eventsData = talentData.flatMap((talentItem) => {
      const { year, nameTranslations, slug, path, section, type, events } =
        talentItem;

      return events.flatMap((eventItem) => {
        const { date, cityTranslations, location } = eventItem;

        if (!date) {
          return [];
        }

        const datePassed = new Date(date) < new Date();

        return {
          year,
          name: nameTranslations[locale],
          slug,
          path,
          section,
          type,
          date,
          city: cityTranslations[locale],
          location,
          past: datePassed,
        };
      });
    });

    const eventsGroupedByMonth = eventsData.reduce(
      (calendarEvents, eventData) => {
        const eventDate = new Date(eventData.date);
        const eventMonth = eventDate.getMonth() + 1;

        if (!calendarEvents[eventMonth]) {
          calendarEvents[eventMonth] = [];
        }

        calendarEvents[eventMonth].push(eventData);

        return calendarEvents;
      },
      {}
    );

    const eventsGroupedByDay = Object.entries(eventsGroupedByMonth).map(
      ([itemMonth, itemEvents]) => {
        const monthLabel = getLocaleMonth(itemMonth, "MMMM");

        const sortedEvents = itemEvents.sort((eventA, eventB) => {
          const dateA = new Date(eventA.date);
          const dateB = new Date(eventB.date);

          return dateA - dateB;
        });

        const groupedEvents = sortedEvents.reduce(
          (groupedEvents, eventItem) => {
            const eventDate = new Date(eventItem.date);
            const eventDay = eventDate.getDate();

            if (!groupedEvents[eventDay]) {
              groupedEvents[eventDay] = {
                id: eventDay,
                name: getLocaleDay(eventDate, "d"),
                weekday: {
                  long: getLocaleDay(eventDate, "EEEE"),
                  short: getLocaleDay(eventDate, "EEE"),
                },
                events: [],
              };
            }

            groupedEvents[eventDay].events.push(eventItem);

            return groupedEvents;
          },
          []
        );

        return {
          id: Number(itemMonth),
          name: monthLabel,
          events: groupedEvents.filter(Boolean),
        };
      }
    );

    setCalendarEvents(eventsGroupedByDay);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentData]);

  useEffect(() => {
    const { months } = monthsFilterOptions.find(
      ({ id }) => id === monthsFilter
    );

    setMonthsIncluded(months);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthsFilter]);

  useEffect(() => {
    const monthsUnselected = monthsFilterOptions.filter(
      ({ id }) => id !== monthsFilter
    );

    setMonthsExcluded(monthsUnselected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthsFilter]);

  useEffect(() => {
    if (showArchive) {
      setCalendarSeason(false);
    }
  }, [showArchive]);

  function getLocaleMonth(monthId, monthFormat) {
    const fakeDate = new Date(1970, monthId - 1, 1);

    return getDate(fakeDate, monthFormat, params.locale);
  }

  function getLocaleDay(fullDate, dayFormat) {
    const fakeDate = new Date(fullDate);

    return getDate(fakeDate, dayFormat, params.locale);
  }

  function initMonthFiltersOptions() {
    const currentMonth = new Date().getMonth() + 1;

    const monthsFilterOptionsUpdated = monthsFilterOptions.map(
      ({ id, name, months }) => {
        const allMonthsPassed = months.every((month) => month < currentMonth);

        return {
          id,
          name,
          months,
          past: allMonthsPassed,
        };
      }
    );

    setMonthsFilterOptions(monthsFilterOptionsUpdated);
  }

  if (languageData.centurion) {
    return (
      <>
        <Helmet>
          <title>Calendar</title>
        </Helmet>

        <div
          data-module="calendar"
          style={{
            backgroundImage: `url(${assetPath}/calendar/bg-light.webp)`,
            backgroundPosition: "center top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% auto",
            backgroundColor: "#fff",
          }}
        >
          <div>
            <div className="container">
              <header className="position-relative">
                <HomeLink rootModule="calendar" />
              </header>
            </div>

            <div className={style.pageBody}>
              <div className="container">
                <PageHero params={params} />
              </div>

              <CalendarSeasons
                options={availableSeasons}
                season={calendarSeason}
                handleClick={setCalendarSeason}
              >
                {/* <button
                  onClick={() => setShowArchive(true)}
                  className={`bigcaslonitalic ${style.tabLink} ${
                    style.archiveLink
                  } ${showArchive ? style.isActive : ""}`}
                >
                  <FormattedMessage id={"living.archive"} />
                </button> */}
              </CalendarSeasons>

              {!showArchive ? (
                <>
                  <div className={style.calendarControls}>
                    <CalendarTabs
                      options={monthsFilterOptions}
                      month={monthsFilter}
                      handleClick={setMonthsFilter}
                    />

                    <CalendarTalent
                      season={calendarSeason}
                      options={talentFilterOptions}
                      handleSelect={setTalentFilter}
                    />

                    <CalendarRegion
                      options={countryFilterOptions}
                      handleSelect={setCountryFilter}
                    />
                  </div>

                  <div className={style.calendarContainer}>
                    <CalendarView
                      season={calendarSeason}
                      events={calendarEvents}
                      months={monthsIncluded}
                      country={countryFilter}
                      talent={talentFilter}
                      error={[monthsExcluded]}
                    />
                  </div>
                </>
              ) : (
                <CalendarArchives />
              )}
            </div>

            <SiteFooter />
        </div>
      </div>
    </>
    )
  } else {
    return null;
  }
};

Calendar.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
