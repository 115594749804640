import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import style from "./Cards.module.scss";
import debounce from "lodash.debounce";
import CardDetail from "./CardDetail";
import CardHeader from "./CardHeader";
import ArtistLink from "./CardArtistLink";
import CardCarousel from "../CardCarousel/CardCarousel";
import LineSet from "../LineSet/LineSet";
import cardFaqs from "../../static/cardFaqs";

const Cards = ({ handleModalData, handleModalType, handleIsModalOpen }) => {
  const blackRef = useRef(null);
  const params = useParams();
  const intl = useIntl();
  const { locale } = intl;
  const userLocale = locale.toLocaleLowerCase();

  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const artCard1Image = `${assetPath}/card/${userLocale}/kehinde-wiley-card.png`;
  const artCard1ImageLarge = `${assetPath}/card/${userLocale}/kehinde-wiley-card-large.png`;
  const artCard2Image = `${assetPath}/card/${userLocale}/rem-koolhaas-card.png`;
  const artCard2ImageLarge = `${assetPath}/card/${userLocale}/rem-koolhaas-card-large.png`;
  const artist1ProfileImage = `${assetPath}/card/kehinde-wiley.png`;
  const artist1SignatureImage = `${assetPath}/card/kehinde-wiley-signature.png`;
  const artist2ProfileImage = `${assetPath}/card/rem-koolhaas.png`;
  const artist2SignatureImage = `${assetPath}/card/rem-koolhaas-signature.png`;
  const blackCardImage = `${assetPath}/card/${userLocale}/black-card.png`;
  const blackCardImageLarge = `${assetPath}/card/${userLocale}/black-card-large.png`;

  const chevronImage = `${assetPath}/card/down-shallow.svg`;
  const chevronImageWhite = `${assetPath}/card/down-shallow_white.svg`;

  const [pageRes, setPageRes] = useState(null);

  const faqs = cardFaqs({ locale, pageRes });

  const handleChevronClick = (id) => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  const resetPageRes = () => {
    let res = window.innerWidth;
    setPageRes(res < 768 ? "small" : "large"); // catch state change with useEffect (below)
  };

  useEffect(() => {
    setTimeout(() => {
      const chevrons = document.querySelectorAll("[data-navchevron]");
      chevrons.forEach((chevron) => {
        const id = chevron.getAttribute("data-navchevronto");
        chevron.addEventListener("click", () => handleChevronClick(id));
      });
    }, 2000);
  }, []);

  useEffect(() => {
    if (!window) {
      return;
    }
    const debFunc = debounce(() => {
      resetPageRes();
    }, 25);
    resetPageRes(); // initial call...
    window.addEventListener("resize", debFunc);
    return () => {
      window.removeEventListener("resize", debFunc);
    };
  }, []);

  return (
    <>
      <div
        className={`dls-white ${style.cardWrapper} ${style.cardBlack}`}
        ref={blackRef}
      >
        <CardDetail
          cardId="classicBlack"
          cardImageUrl={blackCardImage}
          cardImageUrlLarge={blackCardImageLarge}
          handleModalData={handleModalData}
          handleModalType={handleModalType}
          handleIsModalOpen={handleIsModalOpen}
        >
          <CardHeader title="blackCardTitle" id="classicBlackCard" />
          <div className={`row ${style.blurbWrapper}`}>
            <div className="col-sm-10 col-sm-offset-1 col-md-12 col-md-offset-0">
              <p
                className={`dls-white body-1 ${style.blurb} bentonsansthin text-justify`}
              >
                <LineSet id={`card.blackCardBlurb1`} />
              </p>

              <p
                className={`dls-white body-1 ${style.blurb} bentonsansthin text-justify`}
              >
                <LineSet id={`card.blackCardBlurb2`} />
              </p>
            </div>
          </div>
        </CardDetail>
        <div
          data-navchevron
          data-navchevronto="remKoolhaas"
          className={`${style.chevronDown} invisible`}
          style={{ backgroundImage: `url(${chevronImageWhite})` }}
        ></div>
      </div>
      <div className={`dls-black ${style.cardWrapper} ${style.cardKoolhaas}`}>
        <CardDetail
          cardId="remKoolhaas"
          cardImageUrl={artCard2Image}
          cardImageUrlLarge={artCard2ImageLarge}
          flipHorizontal={true}
          signatureImageUrl={artist2SignatureImage}
          handleModalData={handleModalData}
          handleModalType={handleModalType}
          handleIsModalOpen={handleIsModalOpen}
        >
          <div>
            <CardHeader title="remKoolhaas" id="koolhaasCard" />
          </div>
          <div className={`row ${style.blurbWrapper}`}>
            <div className="col-sm-10 col-sm-offset-1 col-md-12 col-md-offset-0">
              <p
                className={`body-1 ${style.blurb} bentonsansthin text-justify`}
              >
                <LineSet
                  id={`card.artCard2Blurb`}
                  desktop={[]}
                  tablet={[]}
                  mobile={[]}
                  values={{
                    lineBreak: <br />,
                    span: (str) => <span>{str}</span>,
                  }}
                />
              </p>
            </div>
          </div>
          <ArtistLink
            imageUrl={artist2ProfileImage}
            name={intl.formatMessage({ id: `card.remKoolhaas` })}
            to={`/${params.locale}/${params.card}/${params.centurion}/rem-koolhaas`}
            flipHorizontal={true}
          />
        </CardDetail>
        <div
          data-navchevron
          data-navchevronto="kehindeWiley"
          className={`${style.chevronDown} invisible`}
          style={{ backgroundImage: `url(${chevronImage})` }}
        ></div>
      </div>
      <div className={`dls-black ${style.cardWrapper} ${style.cardWiley}`}>
        <CardDetail
          cardId="kehindeWiley"
          cardImageUrl={artCard1Image}
          cardImageUrlLarge={artCard1ImageLarge}
          signatureImageUrl={artist1SignatureImage}
          handleModalData={handleModalData}
          handleModalType={handleModalType}
          handleIsModalOpen={handleIsModalOpen}
        >
          <div>
            <CardHeader title="kehindeWiley" id="wileyCard" />
          </div>
          <div className={`row ${style.blurbWrapper}`}>
            <div className="col-sm-10 col-sm-offset-1 col-md-12 col-md-offset-0">
              <p
                className={`body-1 ${style.blurb} bentonsansthin text-justify`}
              >
                <LineSet id={`card.artCard1Blurb`} />
              </p>
            </div>
          </div>
          <ArtistLink
            imageUrl={artist1ProfileImage}
            name={intl.formatMessage({ id: `card.kehindeWiley` })}
            to={`/${params.locale}/${params.card}/${params.centurion}/kehinde-wiley`}
          />
        </CardDetail>
      </div>
      <CardCarousel
        items={faqs}
        toggleButtonId="cardFaqs"
        carouselContainerId="cardFaqsContainer"
        buttonTextId="centurionCardFaqs"
        rootModule="card"
      />
    </>
  );
};

Cards.propTypes = {
  handleModalData: PropTypes.func.isRequired,
  handleModalType: PropTypes.func.isRequired,
  handleIsModalOpen: PropTypes.func.isRequired,
};

export default Cards;
