import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import debounce from "lodash.debounce";
import gsap from "gsap/dist/gsap";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import HomeLink from "../../components/HomeLink/HomeLink";
import ArtistLink from "../../components/Art/ArtistLink";
import InView from "../../components/InView/InView";
import Logotype from "../../components/Logotype/Logotype";
import LineSet from "../../components/LineSet/LineSet";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import style from "./Art.module.scss";

const Art = ({ updateRoot }) => {
  const { messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();
  const params = useParams();

  useEffect(() => {
    updateRoot("art");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const fallingCardsSmTopSrc = `${assetPath}/art/falling-cards_sm-top.png`;
  const fallingCardsSmBottomSrc = `${assetPath}/art/falling-cards_sm-bottom.png`;
  const fallingCardsSrc = `${assetPath}/art/falling-cards.png`;
  const fallingCardsLgSrc = `${assetPath}/art/falling-cards_lg.png`;
  const fallingCardsOnBlueSrc = `${assetPath}/art/falling-cards-on-blue.png`;

  const [pageRes, setPageRes] = useState(null);

  const resetPageRes = () => {
    let res = window.innerWidth;
    setPageRes(res < 768 ? "small" : "large"); // catch state change with useEffect (below)
  };

  useEffect(() => {
    if (!window) {
      return;
    }
    const debFunc = debounce(function () {
      resetPageRes();
    }, 25);
    resetPageRes(); // initial call...
    window.addEventListener("resize", debFunc);
    return () => {
      window.removeEventListener("resize", debFunc);
    };
  }, []);

  useEffect(() => {
    const animatables = document.querySelectorAll("[data-animate-delay]");

    animatables.forEach((item) => {
      const endOpacity = item.getAttribute("data-opacity-end");
      item.style.opacity = 0;

      gsap.fromTo(
        item,
        {
          opacity: 0,
        },
        {
          opacity: endOpacity || 1,
          duration: 1,
          delay: parseInt(item.getAttribute("data-animate-delay")) / 1000,
        }
      );
    });
  }, []);

  const artistLinks = [
    {
      id: "remKoolhaas",
      classes: `${style.profileFirst}`,
      imageUrl: "rem-koolhaas.png",
      textId: "viewProfileKoolhaas",
      to: `/${params.locale}/${params.card}/${params.centurion}/rem-koolhaas`,
    },
    {
      id: "kehindeWiley",
      classes: `${style.profileSecond}`,
      imageUrl: "kehinde-wiley.png",
      textId: "viewProfileWiley",
      to: `/${params.locale}/${params.card}/${params.centurion}/kehinde-wiley`,
    },
  ];

  if (languageData.centurion) {
    return (
      <>
        <Helmet>
          <title>{languageData["art.title"]}</title>
        </Helmet>

        <div data-module="art" className="container bentonsansthin">
          <div className={style.page}>
            <header className={style.header}>
              <HomeLink rootModule="art" />

              <div className="row">
                <div className="col-xs-12 col-md-6 col-md-offset-3">
                  <Logotype id="artxcenturion" title="title" rootModule="art" />
                </div>
              </div>
            </header>

            <div className="row">
              <InView
                duration={1000}
                delay={100}
                y={0}
                className={`${style.summary} col-xs-10 col-xs-offset-1 col-md-6 col-md-offset-0`}
              >
                <p className="bigcaslonregular text-justify">
                  <LineSet
                    id="art.summary"
                    desktop={[7, 6, 8, 8]}
                    tablet={[5, 5, 6, 6, 6]}
                    mobile={[5, 5, 6, 6, 6]}
                  />
                </p>
              </InView>

              <InView
                duration={1000}
                delay={pageRes === "small" ? 300 : 900}
                y={0}
                className={`col-xs-4 col-xs-offset-4 ${style.diagonalLine} ${style.diagonalLineSummary}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 106.25 47.25"
                >
                  <path
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".1"
                    d="M.13.13l106 47"
                  />
                </svg>
              </InView>

              <div
                className={`${style.intro} col-xs-10 col-xs-offset-1 col-md-7 col-lg-6`}
              >
                <InView
                  duration={1000}
                  delay={pageRes === "small" ? 600 : 900}
                  y={0}
                  className={`${style.content} ${style.content1} text-justify`}
                >
                  <p>
                    <LineSet
                      id="art.content1"
                      desktop={[10, 9, 11, 11]}
                      tablet={[10, 9, 11, 11]}
                      mobile={[]}
                    />
                  </p>
                </InView>

                <InView
                  duration={1000}
                  delay={pageRes === "small" ? 900 : 1500}
                  y={0}
                  className={`${style.content} ${style.content2} text-justify col-md-10 col-md-push-4`}
                >
                  <p>
                    <LineSet
                      id="art.content2"
                      desktop={[10, 12]}
                      tablet={[7, 9, 7]}
                      mobile={[]}
                    />
                  </p>
                </InView>
              </div>

              <InView
                duration={1000}
                delay={pageRes === "small" ? 100 : 2100}
                y={0}
                className={`${style.remProfile} col-xs-8 col-xs-offset-2 col-md-3 col-md-offset-1 col-lg-offset-2`}
              >
                <ArtistLink
                  imageUrl={artistLinks[0].imageUrl}
                  text={
                    <FormattedMessage
                      id={`art.${artistLinks[0].textId}`}
                      values={{ bold: (str) => <strong>{str}</strong> }}
                    />
                  }
                  to={artistLinks[0].to}
                />
              </InView>

              <InView
                duration={1000}
                delay={pageRes === "small" ? 200 : 2100}
                y={0}
                className={`col-xs-4 col-xs-offset-4 ${style.diagonalLine} ${style.diagonalLineProfile}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 106.25 47.25"
                >
                  <path
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".1"
                    d="M.13.13l106 47"
                  />
                </svg>
              </InView>

              <InView
                duration={1000}
                delay={pageRes === "small" ? 300 : 2700} // 2700
                y={0}
                className={`${style.wileyProfile} col-xs-8 col-xs-offset-2 col-md-3 col-md-offset-1 col-lg-offset-2`}
              >
                <ArtistLink
                  imageUrl={artistLinks[1].imageUrl}
                  text={
                    <FormattedMessage
                      id={`art.${artistLinks[1].textId}`}
                      values={{ bold: (str) => <strong>{str}</strong> }}
                    />
                  }
                  to={artistLinks[1].to}
                />
                <InView
                  duration={1000}
                  delay={pageRes === "small" ? 500 : 2100}
                  y={0}
                  className={`${style.artistLinkText}`}
                >
                  <Link
                    className={`display-block dls-black ${style.artistLink}`}
                    to={artistLinks[0].to}
                  >
                    <p className={`bentonsansthin`}>
                      <FormattedMessage
                        id={`art.${artistLinks[0].textId}`}
                        values={{ bold: (str) => <strong>{str}</strong> }}
                      />
                    </p>
                  </Link>
                  <Link
                    className={`display-block dls-black ${style.artistLink}`}
                    to={artistLinks[1].to}
                  >
                    <p className={`bentonsansthin`}>
                      <FormattedMessage
                        id={`art.${artistLinks[1].textId}`}
                        values={{ bold: (str) => <strong>{str}</strong> }}
                      />
                    </p>
                  </Link>
                </InView>
              </InView>
            </div>

            <InView
              duration={1000}
              delay={pageRes === "small" ? 500 : 3300}
              y={0}
              className={`${style.fallingCardsSmBackground}`}
            >
              <img
                className={`${style.fallingCardsSm}`}
                alt=""
                src={fallingCardsOnBlueSrc}
              />
            </InView>

            <div className="row">
              <InView
                duration={1000}
                delay={pageRes === "small" ? 500 : 3300}
                y={0}
                className="col-xs-12"
              >
                <h2
                  className={`${style.newCardsTitle} bigcaslonregular text-align-center`}
                >
                  <FormattedMessage
                    id={`art.theNewCardDesigns`}
                    values={{
                      em: (str) => <em className="bigcaslonitalic">{str}</em>,
                    }}
                  />
                </h2>
              </InView>

              <InView
                duration={1000}
                delay={pageRes === "small" ? 500 : 3300}
                y={0}
                className={`${style.remCard} col-xs-8 col-xs-offset-2 col-md-3 col-md-offset-0`}
              >
                <Link
                  to={{
                    pathname: `/${params.locale}/${params.card}/${params.centurion}/card`,
                    hash: "#remKoolhaas",
                  }}
                  className={`display-block ${style.cardLink} bentonsansthin`}
                >
                  <div className={style.cardLinkImageWrapper}>
                    <img
                      src={`${assetPath}/art/card-thumbnail-koolhaas.png`}
                      alt=""
                      className="display-block"
                    />
                  </div>

                  <p>
                    <FormattedMessage
                      id="art.viewCardKoolhaas"
                      values={{
                        bold: (str) => <strong>{str}</strong>,
                        lineBreak: <br />,
                      }}
                    />
                  </p>
                </Link>
              </InView>

              <InView
                duration={1000}
                delay={pageRes === "small" ? 500 : 3900}
                y={0}
                className={`col-xs-4 col-xs-offset-4 ${style.diagonalLine} ${style.diagonalLineCards}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 106.25 47.25"
                >
                  <path
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".1"
                    d="M.13.13l106 47"
                  />
                </svg>
              </InView>

              <InView
                duration={1000}
                delay={pageRes === "small" ? 500 : 3900}
                y={0}
                className={`${style.wileyCard} col-xs-8 col-xs-offset-2 col-md-3 col-md-offset-0 col-lg-pull-1`}
              >
                <Link
                  to={{
                    pathname: `/${params.locale}/${params.card}/${params.centurion}/card`,
                    hash: "#kehindeWiley",
                  }}
                  className={`display-block ${style.cardLink} bentonsansthin`}
                >
                  <div className={style.cardLinkImageWrapper}>
                    <img
                      src={`${assetPath}/art/card-thumbnail-wiley.svg`}
                      alt=""
                      className="display-block"
                    />
                  </div>

                  <p>
                    <FormattedMessage
                      id="art.viewCardWiley"
                      values={{
                        bold: (str) => <strong>{str}</strong>,
                        lineBreak: <br />,
                      }}
                    />
                  </p>
                </Link>
              </InView>

              <InView
                duration={1000}
                delay={4500}
                y={0}
                className={`${style.fallingCards} ${style.fallingCardsMd}`}
              >
                <img
                  // data-animate-delay="4500"
                  // data-opacity-end="0.1"
                  src={fallingCardsSrc}
                  alt=""
                />
              </InView>

              <InView
                duration={1000}
                delay={4500}
                y={0}
                className={`${style.fallingCards} ${style.fallingCardsLg}`}
              >
                <img
                  // data-animate-delay="4500"
                  // data-opacity-end="0.1"
                  src={fallingCardsLgSrc}
                  alt=""
                />
              </InView>

              <InView
                duration={1000}
                delay={pageRes === "small" ? 500 : 4500}
                y={0}
                className={`${style.fallingCardsSmEnd} ${style.fallingCardsSmEndTop}`}
              >
                <img
                  // data-animate-delay="4500"
                  // data-opacity-end="0.05"
                  src={fallingCardsSmTopSrc}
                  alt=""
                />
              </InView>
              <InView
                duration={1000}
                delay={pageRes === "small" ? 500 : 4500}
                y={0}
                className={`${style.fallingCardsSmEnd} ${style.fallingCardsSmEndBottom}`}
              >
                <img
                  // data-animate-delay="4500"
                  // data-opacity-end="0.05"
                  src={fallingCardsSmBottomSrc}
                  alt=""
                />
              </InView>
            </div>
          </div>

          <SiteFooter />
        </div>
      </>
    );
  } else {
    return null;
  }
};

Art.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
  };
};

export default connect(null, mapDispatchToProps)(Art);
