import React from "react";

// uk
const harvey = "harvey-nichols";
const clos = "clos19";
const lounge = "centurion-lounge-at-heathrow";
const airport = "airport-fast-track-lanes";
const limo = "airport-limousine-service";
const concierge = "centurion-concierge-team";
const events = "centurion-events";

// australia
const relationshipManager = "relationship-manager";
const diningProgram = "dining-program";
const centurionExperiences = "centurion-experiences";

// spain
const fastTrack = "fast-track-y-fast-lane-de-aena";
const servicioPremium = "servicio-premium-de-aena";
const salasVip = "salas-vip-riority-pass";
const heathrow = "centurion-lounge-en-heathrow";
// const viajes = "american-express-viajes-online";
const seguros = "coberturas-adicionales-en-el-seguro-de-viajes-de-tu-tarjeta";
const corteIngles = "el-corte-ingles";
const experiencias = "app-amex-experiences";

export const slugs = {
  // english
  harvey,
  clos,
  lounge,
  airport,
  limo,
  concierge,
  events,
  relationshipManager,
  diningProgram,
  centurionExperiences,
  // spanish
  fastTrack,
  servicioPremium,
  salasVip,
  heathrow,
  // viajes,
  seguros,
  corteIngles,
  experiencias,
};

export const benefitsMenuItems = ({ basePath, localeName }) => {
  const introductionItem = {
    title: "benefitsIntroduction",
    to: {
      pathname: `/${basePath}/benefits`,
      hash: "#introduction",
    },
  };

  const defaultItems = [
    introductionItem,
    {
      title: "benefitsLifestyle",
      to: {
        pathname: `/${basePath}/benefits`,
        hash: "#lifestyle",
      },
    },
    {
      title: "benefitsTravel",
      to: {
        pathname: `/${basePath}/benefits`,
        hash: "#travel",
      },
    },
    {
      title: "benefitsServiceAndExperiences",
      to: {
        pathname: `/${basePath}/benefits`,
        hash: "#service",
      },
    },
  ];

  const config = {
    "en-in": [introductionItem],
    "en-gb": defaultItems,
    "es-es": [
      introductionItem,
      {
        title: "benefitsTravel",
        to: {
          pathname: `/${basePath}/benefits`,
          hash: "#travel",
        },
      },
      // {
      //   title: "benefitsCompras",
      //   to: {
      //     pathname: `/${basePath}/benefits`,
      //     hash: "#compras",
      //   },
      // },
      {
        title: "benefitsExperiencias",
        to: {
          pathname: `/${basePath}/benefits`,
          hash: "#experiencias",
        },
      },
    ],
    "en-au": [
      introductionItem,
      {
        title: "benefitsDining",
        to: {
          pathname: `/${basePath}/benefits/dining-program`,
          hash: "",
        },
      },
      {
        title: "benefitsService",
        to: {
          pathname: `/${basePath}/benefits/relationship-manager`,
          hash: "",
        },
      },
      {
        title: "benefitsExperiences",
        to: {
          pathname: `/${basePath}/benefits/centurion-experiences`,
          hash: "",
        },
      },
    ],
  };

  return config[localeName] || defaultItems;
};

export const blueLogos = [
  // en-gb
  harvey,
  limo,
  concierge,
  // es-es
  fastTrack,
  // viajes,
  experiencias,
  // en-au
  diningProgram,
];

export const ids = Object.keys(slugs).reduce(
  (res, key) => ({ ...res, [slugs[key]]: key }),
  {}
);

export const benefits = {
  "en-in": [],
  "en-gb": [
    {
      group: "lifestyle",
      items: ["harvey", "clos"],
    },
    {
      group: "travel",
      items: ["lounge", "limo", "airport"],
    },
    {
      group: "service",
      items: ["concierge"],
    },
    {
      group: "experiences",
      items: ["events"],
    },
  ],
  "es-es": [
    {
      group: "travel",
      items: [
        "fastTrack",
        "servicioPremium",
        "salasVip",
        "heathrow",
        // "viajes",
        "seguros",
      ],
    },
    // {
    //   group: "compras",
    //   items: ["corteIngles"],
    // },
    {
      group: "experiencias",
      items: ["experiencias"],
    },
  ],
  "en-au": [
    {
      group: "dining",
      items: ["diningProgram"],
    },
    {
      group: "service",
      items: ["relationshipManager"],
    },
    {
      group: "experiences",
      items: ["centurionExperiences"],
    },
  ],
};

export const benefitGroupIndex = (market, benefitIndex) =>
  (benefits[market] || [])
    .reduce(
      ({ res, count }, { items }) => {
        const sum = count + items.length;
        return {
          res: [...res, sum],
          count: sum,
        };
      },
      { res: [], count: 0 }
    )
    .res.findIndex((count) => benefitIndex < count);

export const marketBenefits = Object.keys(benefits).reduce(
  (res, market) => ({
    ...res,
    [market]: benefits[market].reduce(
      (acc, { items }) => [...acc, ...items],
      []
    ),
  }),
  {}
);

export const marketHasBenefit = (market, benefit) =>
  (marketBenefits[market.toLowerCase()] || []).includes(benefit);

// 20px gutters on 1240px layout width = 20 * 100 / 1240 = 2000 / 1240 = 50 / 31 <- prime number, stop fraction simplification
export const gutterWidth = 50 / 31; // = ~ 1.61 %

// 85px columns on 1240px layout width = 85 * 100 / 1240 = 8500 / 1240 = 425 / 62 <- cannot be simplified any further
export const columnWidth = 425 / 62; // = ~ 6.85 %

// 12px gutters on 348px layout width = 12 * 100 / 348 = 1200 / 348 = 100 / 29 <- cannot be simplified any further
export const smallGutterWidth = 100 / 29; // = ~ 3.44 %

// 18px columns on 348px layout width = 18 * 100 / 348 = 1800 / 348 = 150 / 29 <- cannot be simplified any further
export const smallColumnWidth = 150 / 29; // = ~ 3.44 %

export const largeColumns = (n, guttersCount) =>
  n * columnWidth + (isNaN(guttersCount) ? n - 1 : guttersCount) * gutterWidth;

export const smallColumns = (n, guttersCount) =>
  n * smallColumnWidth +
  (isNaN(guttersCount) ? n - 1 : guttersCount) * smallGutterWidth;

export const blends = {
  // "en-in": {},
  "en-gb": {
    home: {
      large: [
        {
          width: largeColumns(4),
          top: 25,
          left: largeColumns(4, 4),
          src: "airport.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 37.5,
          right: largeColumns(1, 1),
          src: "concierge.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 30,
          right: largeColumns(2.5, 2),
          src: "clos.jpg",
        },
        {
          width: largeColumns(3, 3),
          top: 22.5,
          left: largeColumns(2, 2),
          src: "limo.jpg",
        },
        {
          width: 20,
          top: 51.25,
          left: 21,
          src: "harvey.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 45,
          left: largeColumns(1, 1),
          src: "lounge.jpg",
        },
        {
          width: largeColumns(3, 3),
          top: 45,
          right: largeColumns(2.33, 2),
          src: "events.jpg",
        },
      ],
      small: [
        {
          width: largeColumns(4.5),
          top: 25,
          left: largeColumns(3.75, 4),
          src: "airport.jpg",
        },
        {
          width: largeColumns(2.5, 2),
          top: 37.5,
          right: 0,
          src: "concierge.jpg",
        },
        {
          width: largeColumns(2, 3),
          top: 30,
          right: largeColumns(2),
          src: "clos.jpg",
        },
        {
          width: largeColumns(3.25, 3),
          top: 22.5,
          left: largeColumns(1.25, 2),
          src: "limo.jpg",
        },
        {
          width: largeColumns(2.5, 2),
          top: 51.25,
          left: largeColumns(2, 2),
          src: "harvey.jpg",
        },
        {
          width: largeColumns(2.5, 2),
          top: 45,
          left: 0,
          src: "lounge.jpg",
        },
        {
          width: largeColumns(3, 4),
          top: 45,
          right: largeColumns(2),
          src: "events.jpg",
        },
      ],
    },
    harvey: {
      large: [
        {
          width: 40,
          top: 30,
          left: 30,
          src: "1.jpg",
          halo: true,
        },
        {
          width: 16.66,
          top: 55,
          left: 0,
          src: "2.jpg",
        },
        {
          width: 20,
          top: 35,
          left: 10,
          src: "3.jpg",
        },
        {
          width: 20,
          top: 60,
          right: largeColumns(2), // /!\ aligning to the right /!\
          src: "4.jpg",
        },
        {
          width: 28,
          top: 25,
          right: 0, // /!\ aligning to the right /!\
          src: "5.jpg",
        },
        {
          width: 30,
          top: 55,
          left: 8.33,
          src: "6.jpg",
        },
      ],
      small: [
        {
          width: 60,
          top: 25,
          left: 20,
          src: "1.jpg",
          halo: true,
        },
        {
          width: 25,
          top: 22,
          left: 8.33,
          src: "2.jpg",
        },
        {
          width: 25 - smallGutterWidth,
          top: 64,
          right: 8.33, // /!\ aligning to the right /!\
          src: "4.jpg",
        },
        {
          width: 33.33,
          top: 15,
          right: 0, // /!\ aligning to the right /!\
          src: "5.jpg",
        },
        {
          width: 36,
          top: 60,
          left: 0,
          src: "6.jpg",
        },
      ],
    },
    clos: {
      large: [
        {
          width: 40,
          top: 30,
          left: 30,
          src: "1.jpg",
          halo: true,
        },
        {
          width: 33.33,
          top: 50,
          right: 0, // /!\ aligning to the right /!\
          src: "2.jpg",
        },
        {
          width: 25 + gutterWidth,
          top: 30,
          left: 8.33,
          src: "3.jpg",
        },
        {
          width: 20,
          top: 25,
          left: largeColumns(8, 8), // /!\ aligning to the right /!\
          src: "4.jpg",
        },
        {
          width: largeColumns(3),
          top: 62.5,
          left: 5,
          src: "5.jpg",
        },
        // {
        //   width: 12.5,
        //   top: 60,
        //   left: 0,
        //   src: "6.jpg",
        // },
      ],
      small: [
        {
          width: 60,
          top: 25,
          left: 20,
          src: "1.jpg",
          halo: true,
        },
        {
          width: 25 - smallGutterWidth,
          top: 64,
          right: 8.33, // /!\ aligning to the right /!\
          src: "2.jpg",
        },
        {
          width: 25,
          top: 22,
          left: 8.33,
          src: "3.jpg",
        },
        {
          width: 33.33,
          top: 15,
          right: 0, // /!\ aligning to the right /!\
          src: "4.jpg",
        },
        {
          width: smallColumns(4),
          top: 60,
          left: 0,
          src: "5.jpg",
        },
      ],
    },
    lounge: {
      large: [
        {
          width: 40,
          top: 30,
          left: 30,
          src: "1.jpg",
          halo: true,
        },
        // {
        //   width: 12.5,
        //   top: 75,
        //   left: 66.66,
        //   src: "2.jpg",
        // },
        {
          width: 33.33,
          top: 45,
          left: 0,
          src: "3.jpg",
        },
        {
          width: 33.33,
          top: 20,
          left: 58.33,
          src: "4.jpg",
        },
        {
          width: 19.5,
          top: 30,
          left: 12,
          src: "5.jpg",
        },
        {
          width: 25 - gutterWidth,
          top: 45,
          left: 75,
          src: "6.jpg",
        },
      ],
      small: [
        {
          width: 60,
          top: 20,
          left: 20,
          src: "1.jpg",
          halo: true,
        },
        {
          width: smallColumns(4),
          top: 55,
          left: 0,
          src: "3.jpg",
        },
        {
          width: 33.33,
          top: 15,
          left: 66.67,
          src: "4.jpg",
        },
        {
          width: smallColumns(3, 3),
          top: 20,
          left: smallColumnWidth,
          src: "5.jpg",
        },
        {
          width: smallColumns(3, 3),
          top: 58.33,
          right: smallColumns(1, 1), // /!\ aligning to the right /!\
          src: "6.jpg",
        },
      ],
    },
    airport: {
      large: [
        {
          width: 40,
          top: 30,
          left: 30,
          src: "1.jpg",
          halo: true,
        },
        {
          width: 30,
          top: 47.5,
          right: 8.33 - gutterWidth, // /!\ aligning to the right /!\
          src: "2.jpg",
        },
        {
          width: 15,
          top: 35,
          left: 17.5,
          src: "3.jpg",
        },
        {
          width: 25 - gutterWidth,
          top: 35,
          right: 0, // /!\ aligning to the right /!\
          src: "4.jpg",
        },
        {
          width: 35,
          top: 45,
          left: 0,
          src: "5.jpg",
        },
      ],
      small: [
        {
          width: 60,
          top: 15,
          left: 20,
          src: "1.jpg",
          halo: true,
        },
        {
          width: smallColumns(3, 3),
          top: 55,
          right: 5, // /!\ aligning to the right /!\
          src: "2.jpg",
        },
        {
          width: smallColumns(3),
          top: 15,
          left: smallColumns(1, 0.5),
          src: "3.jpg",
        },
        {
          width: smallColumns(3, 3),
          top: 15,
          right: smallColumnWidth, // /!\ aligning to the right /!\
          src: "4.jpg",
        },
        {
          width: smallColumns(4),
          top: 55,
          left: 0,
          src: "5.jpg",
        },
      ],
    },
    limo: {
      large: [
        {
          width: 40,
          top: 30,
          left: 30,
          src: "1.jpg",
          halo: true,
        },
        // {
        //   width: 25,
        //   top: 45,
        //   left: 0,
        //   src: "2.jpg",
        // },
        {
          width: largeColumns(3),
          top: 55,
          right: largeColumns(2, 0), // /!\ aligning to the right
          src: "3.jpg",
        },
        {
          width: largeColumns(4, 2),
          top: 15,
          left: largeColumns(8, 6),
          src: "4.jpg",
        },
        {
          width: largeColumns(3),
          top: 25,
          left: largeColumns(2, 0),
          src: "5.jpg",
        },
        {
          width: 30,
          top: 50,
          left: 4.16,
          src: "6.jpg",
        },
      ],
      small: [
        {
          width: 60,
          top: 25,
          left: 20,
          src: "1.jpg",
          halo: true,
        },
        {
          width: smallColumns(4),
          top: 58.33,
          right: 1.5, // /!\ aligning to the right /!\
          src: "3.jpg",
        },
        {
          width: smallColumns(3, 3),
          top: 20,
          right: 0, // /!\ aligning to the right /!\
          src: "4.jpg",
        },
        {
          width: smallColumns(3),
          top: 30,
          left: smallColumnWidth,
          src: "5.jpg",
        },
        {
          width: smallColumns(4),
          top: 55,
          left: 0,
          src: "6.jpg",
        },
      ],
    },
    concierge: {
      large: [
        {
          width: 40,
          top: 25,
          left: 30,
          src: "1.jpg",
          halo: true,
        },
        {
          width: 22,
          top: 55,
          left: 66.66,
          src: "2.jpg",
        },
        {
          width: 20,
          top: 25,
          left: 16.66,
          src: "3.jpg",
        },
        {
          width: 33.33 - 2 * gutterWidth,
          top: 20,
          right: 0, // /!\ aligning to the right /!\
          src: "4.jpg",
        },
        {
          width: 33.33 - gutterWidth,
          top: 40,
          left: 0,
          src: "5.jpg",
        },
      ],
      small: [
        {
          width: 60,
          top: 25,
          left: 20,
          src: "1.jpg",
          halo: true,
        },
        {
          width: 25,
          top: 22,
          left: 8.33,
          src: "2.jpg",
        },
        {
          width: 25 - smallGutterWidth,
          top: 64,
          right: 8.33, // /!\ aligning to the right /!\
          src: "3.jpg",
        },
        {
          width: 33.33,
          top: 15,
          right: 0, // /!\ aligning to the right /!\
          src: "4.jpg",
        },
        {
          width: 36,
          top: 60,
          left: 0,
          src: "5.jpg",
        },
      ],
    },
    events: {
      large: [
        {
          width: 40,
          top: 30,
          left: 30,
          src: "1.jpg",
          halo: true,
        },
        {
          width: 25,
          top: 55,
          left: 75,
          src: "2.jpg",
        },
        {
          width: 25 - gutterWidth,
          top: 25,
          left: 8.33 - gutterWidth,
          src: "3.jpg",
        },
        {
          width: 30,
          top: 15,
          right: columnWidth - gutterWidth,
          src: "4.jpg",
          blend: "soft-light",
          // opacity: 0.8,
        },
        {
          width: 33.33,
          top: 50,
          left: 0,
          src: "5.jpg",
        },
      ],
      small: [
        {
          width: 60,
          top: 25,
          left: 20,
          src: "1.jpg",
          halo: true,
        },
        {
          width: 25,
          top: 22,
          left: 8.33,
          src: "2.jpg",
        },
        {
          width: 25 - smallGutterWidth,
          top: 64,
          right: 8.33, // /!\ aligning to the right /!\
          src: "3.jpg",
        },
        {
          width: 33.33,
          top: 15,
          right: 0, // /!\ aligning to the right /!\
          src: "4.jpg",
        },
        {
          width: 36,
          top: 60,
          left: 0,
          src: "5.jpg",
        },
      ],
    },
  },
  "es-es": {
    home: {
      large: [
        {
          width: largeColumns(4),
          top: 25,
          left: largeColumns(3.5, 4),
          src: "aena.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 37.5,
          right: largeColumns(1.5, 1),
          src: "seguros.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 30,
          right: largeColumns(3, 2),
          src: "experiences.jpg",
        },
        {
          width: largeColumns(3, 3),
          top: 24,
          left: largeColumns(1.5, 2),
          src: "fasttrack.jpg",
        },
        {
          width: 20,
          top: 50.5,
          left: 21,
          src: "ato.jpg",
        },
        // {
        //   width: largeColumns(2, 2),
        //   top: 45,
        //   left: largeColumns(1, 1),
        //   src: "corte.jpg",
        // },
        {
          width: largeColumns(3, 3),
          top: 45,
          right: largeColumns(2.83, 2),
          src: "heathrow.jpg",
        },
      ],
      small: [
        {
          width: largeColumns(4),
          top: 25,
          left: largeColumns(3.5, 4),
          src: "aena.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 37.5,
          right: largeColumns(1.5, 1),
          src: "seguros.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 30,
          right: largeColumns(3, 2),
          src: "experiences.jpg",
        },
        {
          width: largeColumns(3, 3),
          top: 24,
          left: largeColumns(1.5, 2),
          src: "fasttrack.jpg",
        },
        {
          width: 20,
          top: 50.5,
          left: 18,
          src: "ato.jpg",
        },
        // {
        //   width: largeColumns(2, 2),
        //   top: 45,
        //   left: largeColumns(1, 1),
        //   src: "corte.jpg",
        // },
        {
          width: largeColumns(3, 3),
          top: 45,
          right: largeColumns(2.83, 2),
          src: "heathrow.jpg",
        },
      ],
    },
    fastTrack: [
      {
        width: 40,
        top: 30,
        left: 30,
        src: "1.jpg",
        halo: true,
      },
      {
        width: 35,
        top: 45,
        left: 0,
        src: "2.jpg",
      },
      {
        width: 30,
        top: 47.5,
        right: 8.33 - gutterWidth, // /!\ aligning to the right /!\
        src: "3.jpg",
      },
      {
        width: largeColumns(3),
        top: 35,
        right: 0, // /!\ aligning to the right /!\
        src: "4.jpg",
      },
      {
        width: largeColumns(3),
        top: 25,
        left: largeColumns(1, 1),
        src: "5.jpg",
      },
    ],
    servicioPremium: [
      {
        width: 40,
        top: 30,
        left: 30,
        src: "1.jpg",
        halo: true,
      },
      {
        width: largeColumns(3.5, 3),
        top: 30,
        right: columnWidth, // /!\ aligning to the right /!\
        src: "2.jpg",
      },
      {
        width: largeColumns(4),
        top: 40,
        left: 6,
        src: "3.jpg",
      },
      {
        width: largeColumns(3),
        top: 25,
        left: 0,
        src: "4.jpg",
      },
      {
        width: largeColumns(3),
        top: 57.5,
        right: 0, // /!\ aligning to the right /!\
        src: "5.jpg",
      },
    ],
    heathrow: [
      {
        width: 40,
        top: 30,
        left: 30,
        src: "1.jpg",
        halo: true,
      },
      {
        width: 33.33,
        top: 20,
        left: 58.33,
        src: "2.jpg",
      },
      {
        width: 33.33,
        top: 45,
        left: 0,
        src: "3.jpg",
      },
      {
        width: 25 - gutterWidth,
        top: 45,
        left: 75,
        src: "4.jpg",
      },
      {
        width: 19.5,
        top: 25,
        left: 12,
        src: "5.jpg",
      },
    ],
    salasVip: [
      {
        width: 40,
        top: 30,
        left: 30,
        src: "1.jpg",
        halo: true,
      },
      {
        width: largeColumns(3),
        top: 55,
        left: largeColumns(2, 0),
        src: "2.jpg",
      },
      {
        width: largeColumns(4, 4),
        top: 15,
        right: 6, // /!\ aligning to the right /!\
        src: "3.jpg",
      },
      {
        width: largeColumns(3, 3),
        top: 35,
        left: 0,
        src: "4.jpg",
      },
      {
        width: 20,
        top: 60,
        right: 0, // /!\ aligning to the right /!\
        src: "5.jpg",
      },
    ],
    // viajes: [
    //   {
    //     width: 40,
    //     top: 30,
    //     left: 30,
    //     src: "1.jpg",
    //     halo: true,
    //   },
    //   {
    //     width: largeColumns(4, 2),
    //     top: 45,
    //     right: columnWidth, // /!\ aligning to the right /!\
    //     src: "2.jpg",
    //   },
    //   {
    //     width: largeColumns(4),
    //     top: 17.5,
    //     left: 6,
    //     src: "3.jpg",
    //   },
    //   {
    //     width: largeColumns(3),
    //     top: 25,
    //     right: 0, // /!\ aligning to the right /!\
    //     src: "4.jpg",
    //   },
    //   {
    //     width: largeColumns(3, 1),
    //     top: 60,
    //     left: largeColumns(2),
    //     src: "5.jpg",
    //   },
    // ],
    seguros: [
      {
        width: 40,
        top: 30,
        left: 30,
        src: "1.jpg",
        halo: true,
      },
      {
        width: 20,
        top: 42.5,
        right: 0, // /!\ aligning to the right /!\
        src: "2.jpg",
      },
      {
        width: largeColumns(3, 0),
        top: 25,
        left: 0,
        src: "3.jpg",
      },
      {
        width: largeColumns(4, 2),
        top: 15,
        right: largeColumns(1, 1), // /!\ aligning to the right /!\
        src: "4.jpg",
      },
      {
        width: largeColumns(4),
        top: 40,
        left: largeColumns(0, 1),
        src: "5.jpg",
      },
    ],
    experiencias: [
      {
        width: 40,
        top: 20,
        left: 30,
        src: "1.jpg",
        halo: true,
      },
      {
        width: largeColumns(3),
        top: 45,
        right: 0, // /!\ aligning to the right /!\
        src: "2.jpg",
      },
      {
        width: largeColumns(3, 4),
        top: 47.5,
        left: largeColumns(1, 1),
        src: "3.jpg",
      },
      {
        width: largeColumns(2),
        top: 22.5,
        left: largeColumns(3, 0.5),
        src: "4.jpg",
      },
      {
        width: largeColumns(3.5, 3),
        top: 20,
        right: largeColumns(1, 1), // /!\ aligning to the right /!\
        src: "5.jpg",
      },
      {
        width: largeColumns(3, 0),
        top: 35,
        left: 0,
        src: "6.jpg",
      },
    ],
    corteIngles: [
      {
        width: 40,
        top: 30,
        left: 30,
        src: "1.jpg",
        halo: true,
      },
      {
        width: largeColumns(4),
        top: 20,
        left: largeColumns(1, 1),
        src: "2.jpg",
      },
      {
        width: largeColumns(3, 3),
        top: 52.5,
        left: 0,
        src: "3.jpg",
      },
      {
        width: largeColumns(4),
        top: 52.5,
        right: largeColumns(1, 0.5), // /!\ aligning to the right /!\
        src: "4.jpg",
      },
      {
        width: 20,
        top: 40,
        right: 0, // /!\ aligning to the right /!\
        src: "5.jpg",
      },
    ],
  },
  "en-au": {
    home: {
      large: [
        {
          width: largeColumns(4),
          top: 21.8,
          left: largeColumns(1, 1),
          src: "experiences.jpg",
        },
        {
          width: largeColumns(4),
          top: 30,
          right: largeColumns(4, 4),
          src: "relationship.jpg",
        },
        {
          width: largeColumns(4),
          top: 38.2,
          left: largeColumns(7, 7),
          src: "dining.jpg",
        },
      ],
      small: [
        {
          width: largeColumns(4),
          top: 21.8,
          left: largeColumns(1, 1),
          src: "experiences.jpg",
        },
        {
          width: largeColumns(4),
          top: 30,
          right: largeColumns(4, 4),
          src: "relationship.jpg",
        },
        {
          width: largeColumns(4),
          top: 38.2,
          left: largeColumns(7, 7),
          src: "dining.jpg",
        },
      ],
    },
    centurionExperiences: [
      {
        width: 40,
        top: 30,
        left: 30,
        src: "1.jpg",
        halo: true,
      },
      {
        width: largeColumns(4),
        top: 20,
        left: largeColumns(1, 1),
        src: "2.jpg",
      },
      {
        width: largeColumns(2.33, 2),
        top: 50,
        left: 0,
        src: "3.jpg",
      },
      {
        width: largeColumns(4),
        top: 52.5,
        right: largeColumns(1, 0.5), // /!\ aligning to the right /!\
        src: "4.jpg",
      },
      {
        width: largeColumns(3),
        top: 35,
        right: 0, // /!\ aligning to the right /!\
        src: "5.jpg",
      },
    ],
    diningProgram: [
      {
        width: 40,
        top: 30,
        left: 30,
        src: "1.jpg",
        halo: true,
      },
      {
        width: largeColumns(3.5, 3),
        top: 20,
        right: 10, // /!\ aligning to the right /!\
        src: "2.jpg",
      },
      {
        width: largeColumns(3),
        top: 20,
        left: largeColumns(2),
        src: "3.jpg",
      },
      {
        width: largeColumns(4),
        top: 45,
        left: 5,
        src: "4.jpg",
      },
      {
        width: largeColumns(3),
        top: 47.5,
        right: 0, // /!\ aligning to the right /!\
        src: "5.jpg",
      },
      {
        width: largeColumns(2),
        top: 40,
        left: 0,
        src: "6.jpg",
      },
    ],
    relationshipManager: [
      {
        width: 40,
        top: 30,
        left: 30,
        src: "1.jpg",
        halo: true,
      },
      {
        width: largeColumns(4),
        top: 41.5,
        right: 0, // /!\ aligning to the right /!\
        src: "2.jpg",
      },
      {
        width: largeColumns(3, 3),
        top: 35,
        left: 0,
        src: "3.jpg",
      },
      {
        width: largeColumns(3, 3.2),
        top: 21,
        right: largeColumns(2, 0.8), // /!\ aligning to the right /!\
        src: "4.jpg",
      },
      {
        width: largeColumns(3.75, 4),
        top: 52.2,
        left: largeColumns(1),
        src: "5.jpg",
      },
    ],
  },
  "en-in": {
    landing: {
      large: [
        {
          width: largeColumns(6, 6),
          top: 10,
          left: largeColumns(3, 3),
          src: "1.png",
        },
        {
          width: largeColumns(3, 3),
          top: 15,
          right: largeColumns(0.5, 0),
          src: "3.jpg",
        },
        {
          width: largeColumns(3.5, 3.5),
          top: 40,
          right: largeColumns(1, 1),
          src: "2.jpg",
        },
        {
          width: largeColumns(3.5, 3.5),
          top: 15,
          left: largeColumns(1, 2),
          src: "4.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 44.25,
          left: 7,
          src: "5.jpg",
        },
      ],
      small: [
        {
          width: largeColumns(4),
          top: 25,
          left: largeColumns(4, 4),
          src: "1.jpg",
        },
        {
          width: largeColumns(3, 3),
          top: 37.5,
          right: 18,
          src: "2.jpg",
        },
        {
          width: largeColumns(1.5, 1.5),
          top: 32,
          right: largeColumns(1, 3),
          src: "3.jpg",
        },
        {
          width: largeColumns(3, 3),
          top: 22.5,
          left: largeColumns(2, 2),
          src: "4.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 32,
          left: 10,
          src: "5.jpg",
        },
      ],
    },
    home: {
      large: [
        {
          width: largeColumns(6, 6),
          top: 10,
          left: largeColumns(3, 3),
          src: "1.png",
        },
        {
          width: largeColumns(3, 3),
          top: 15,
          right: largeColumns(0.5, 0),
          src: "3.jpg",
        },
        {
          width: largeColumns(3.5, 3.5),
          top: 40,
          right: largeColumns(1, 1),
          src: "2.jpg",
        },
        {
          width: largeColumns(3.5, 3.5),
          top: 15,
          left: largeColumns(1, 2),
          src: "4.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 44.25,
          left: 7,
          src: "5.jpg",
        },
      ],
      small: [
        {
          width: largeColumns(4),
          top: 25,
          left: largeColumns(4, 4),
          src: "1.jpg",
        },
        {
          width: largeColumns(3, 3),
          top: 37.5,
          right: 18,
          src: "2.jpg",
        },
        {
          width: largeColumns(1.5, 1.5),
          top: 32,
          right: largeColumns(1, 3),
          src: "3.jpg",
        },
        {
          width: largeColumns(3, 3),
          top: 22.5,
          left: largeColumns(2, 2),
          src: "4.jpg",
        },
        {
          width: largeColumns(2, 2),
          top: 32,
          left: 10,
          src: "5.jpg",
        },
      ],
    },
  },
};

const uuid = () => Math.floor(Date.now() * Math.random()).toString(32);

export const signatures = {
  centurionExperiences: {
    "en-au": [
      {
        type: `title`,
        text: `benefits.centurionExperiences.signatures.title`,
      },
      {
        type: `text`,
        text: `benefits.centurionExperiences.signatures.text1`,
      },
      {
        type: `text`,
        text: `benefits.centurionExperiences.signatures.text2`,
      },
      {
        type: `text`,
        text: `benefits.centurionExperiences.signatures.text3`,
      },
      {
        type: `image`,
        id: `centurion-experiences-signature.jpg`,
        text: `benefits.centurionExperiences.signatures.imageAlt`,
      },
      {
        type: `text`,
        text: `benefits.centurionExperiences.signatures.text4`,
      },
      {
        type: `cta`,
        text: `benefits.centurionExperiences.signatures.cta`,
        href: "https://www.americanexpress.com/en-au/credit-cards/membership-benefits/centurion/centurionsignatures/",
      },
      {
        type: `text`,
        text: `benefits.centurionExperiences.signatures.text5`,
      },
    ],
  },
};

export const faqs = {
  // lounge, harvey, clos, limo, airport, concierge, events
  lounge: {
    "en-gb": [
      {
        title: `benefits.lounge.faq1Title`,
        text: `benefits.lounge.faq1Text`,
      },
      {
        title: `benefits.lounge.faq2Title`,
        text: `benefits.lounge.faq2Text`,
      },
      {
        title: `benefits.lounge.faq3Title`,
        text: `benefits.lounge.faq3Text`,
      },
      {
        title: `benefits.lounge.faq4Title`,
        text: `benefits.lounge.faq4Text`,
        lastItem: true,
      },
    ],
    "es-es": [
      {
        title: `benefits.lounge.faq1Title`,
        text: `benefits.lounge.faq1Text`,
      },
      {
        title: `benefits.lounge.faq2Title`,
        text: `benefits.lounge.faq2Text`,
      },
      {
        title: `benefits.lounge.faq3Title`,
        text: `benefits.lounge.faq3Text`,
      },
      {
        title: `benefits.lounge.faq4Title`,
        text: `benefits.lounge.faq4Text`,
        lastItem: true,
      },
    ],
  },
  harvey: {
    "en-gb": [
      {
        title: `benefits.harvey.faq1Title`,
        text: `benefits.harvey.faq1Text`,
      },
      {
        title: `benefits.harvey.faq2Title`,
        text: `benefits.harvey.faq2Text`,
      },
      {
        title: `benefits.harvey.faq3Title`,
        text: `benefits.harvey.faq3Text`,
      },
      {
        title: `benefits.harvey.faq4Title`,
        text: `benefits.harvey.faq4Text`,
        // titleCleared: true,
        lastItem: true,
      },
    ],
    "es-es": [
      {
        title: `benefits.harvey.faq1Title`,
        text: `benefits.harvey.faq1Text`,
      },
      {
        title: `benefits.harvey.faq2Title`,
        text: `benefits.harvey.faq2Text`,
      },
      {
        title: `benefits.harvey.faq3Title`,
        text: `benefits.harvey.faq3Text`,
      },
      {
        title: `benefits.harvey.faq4Title`,
        text: `benefits.harvey.faq4Text`,
        // titleCleared: true,
        lastItem: true,
      },
    ],
  },
  clos: {
    "en-gb": [
      {
        title: `benefits.clos.faq1Title`,
        text: `benefits.clos.faq1Text`,
      },
      {
        title: `benefits.clos.faq2Title`,
        text: `benefits.clos.faq2Text`,
        lastItem: true,
      },
    ],
    "es-es": [
      {
        title: `benefits.clos.faq1Title`,
        text: `benefits.clos.faq1Text`,
      },
      {
        title: `benefits.clos.faq2Title`,
        text: `benefits.clos.faq2Text`,
        lastItem: true,
      },
    ],
  },
  limo: {
    "en-gb": [
      {
        title: `benefits.limo.faq1Title`,
        text: `benefits.limo.faq1Text`,
      },
      {
        title: `benefits.limo.faq2Title`,
        text: `benefits.limo.faq2Text`,
      },
      {
        title: `benefits.limo.faq3Title`,
        text: `benefits.limo.faq3Text`,
      },
      {
        title: `benefits.limo.faq4Title`,
        text: `benefits.limo.faq4Text`,
        lastItem: true,
      },
    ],
    "es-es": [
      {
        title: `benefits.limo.faq1Title`,
        text: `benefits.limo.faq1Text`,
      },
      {
        title: `benefits.limo.faq2Title`,
        text: `benefits.limo.faq2Text`,
      },
      {
        title: `benefits.limo.faq3Title`,
        text: `benefits.limo.faq3Text`,
      },
      {
        title: `benefits.limo.faq4Title`,
        text: `benefits.limo.faq4Text`,
        lastItem: true,
      },
    ],
  },
  airport: {
    "en-gb": [
      {
        title: `benefits.airport.faq1Title`,
        text: `benefits.airport.faq1Text`,
      },
    ],
  },
  concierge: {
    "en-gb": [
      {
        title: `benefits.concierge.faq1Title`,
        text: `benefits.concierge.faq1Text`,
      },
      {
        title: `benefits.concierge.faq2Title`,
        text: `benefits.concierge.faq2Text`,
      },
      {
        title: `benefits.concierge.faq3Title`,
        text: `benefits.concierge.faq3Text`,
      },
    ],
    "es-es": [
      {
        title: `benefits.concierge.faq1Title`,
        text: `benefits.concierge.faq1Text`,
      },
      {
        title: `benefits.concierge.faq2Title`,
        text: `benefits.concierge.faq2Text`,
      },
      {
        title: `benefits.concierge.faq3Title`,
        text: `benefits.concierge.faq3Text`,
      },
    ],
  },
  events: {
    "en-gb": [
      {
        title: `benefits.events.faq1Title`,
        text: `benefits.events.faq1Text`,
      },
      {
        title: `benefits.events.faq2Title`,
        text: `benefits.events.faq2Text`,
        formatValues: {
          eventsLinkFaq1: (str) => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              key={uuid()}
              className="bentonsanslight"
              href="https://c00.adobe.com/v3/c2d836f7d582438b6399d609f2ba16bb1dbbce467f679e4a4c6f21f84371bad1/start?a_dl=60070329e9254ff20f270a64"
            >
              {str}
            </a>
          ),
        },
      },
      {
        title: `benefits.events.faq3Title`,
        text: `benefits.events.faq3Text`,
      },
    ],
    "es-es": [
      {
        title: `benefits.events.faq1Title`,
        text: `benefits.events.faq1Text`,
      },
      {
        title: `benefits.events.faq2Title`,
        text: `benefits.events.faq2Text`,
        formatValues: {
          eventsLinkFaq1: (str) => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              key={uuid()}
              className="bentonsanslight"
              href="https://c00.adobe.com/v3/c2d836f7d582438b6399d609f2ba16bb1dbbce467f679e4a4c6f21f84371bad1/start?a_dl=60070329e9254ff20f270a64"
            >
              {str}
            </a>
          ),
        },
      },
      {
        title: `benefits.events.faq3Title`,
        text: `benefits.events.faq3Text`,
      },
    ],
  },
  relationshipManager: {
    "en-au": [
      {
        title: `benefits.relationshipManager.faq1Title`,
        text: `benefits.relationshipManager.faq1Text`,
      },
      {
        title: `benefits.relationshipManager.faq2Title`,
        text: `benefits.relationshipManager.faq2Text`,
      },
      {
        title: `benefits.relationshipManager.faq3Title`,
        text: `benefits.relationshipManager.faq3Text`,
      },
    ],
  },
  diningProgram: {
    "en-au": [
      {
        title: `benefits.diningProgram.faq1Title`,
        text: `benefits.diningProgram.faq1Text`,
      },
    ],
  },
  centurionExperiences: {
    "en-au": [
      {
        title: `benefits.centurionExperiences.faq1Title`,
        text: `benefits.centurionExperiences.faq1Text`,
      },
      {
        title: `benefits.centurionExperiences.faq2Title`,
        text: `benefits.centurionExperiences.faq2Text`,
      },
      {
        title: `benefits.centurionExperiences.faq3Title`,
        text: `benefits.centurionExperiences.faq3Text`,
      },
    ],
  },
  fastTrack: {
    "es-es": [
      {
        title: `benefits.fastTrack.faq1Title`,
        text: `benefits.fastTrack.faq1Text`,
      },
      {
        title: `benefits.fastTrack.faq2Title`,
        text: `benefits.fastTrack.faq2Text`,
      },
      {
        title: `benefits.fastTrack.faq3Title`,
        text: `benefits.fastTrack.faq3Text`,
      },
      {
        title: `benefits.fastTrack.faq4Title`,
        text: `benefits.fastTrack.faq4Text`,
      },
      {
        title: `benefits.fastTrack.faq5Title`,
        text: `benefits.fastTrack.faq5Text`,
      },
      {
        title: `benefits.fastTrack.faq6Title`,
        text: `benefits.fastTrack.faq6Text`,
      },
    ],
  },
  servicioPremium: {
    "es-es": [
      {
        title: `benefits.servicioPremium.faq1Title`,
        text: `benefits.servicioPremium.faq1Text`,
      },
      {
        title: `benefits.servicioPremium.faq2Title`,
        text: `benefits.servicioPremium.faq2Text`,
      },
      {
        title: `benefits.servicioPremium.faq3Title`,
        text: `benefits.servicioPremium.faq3Text`,
      },
      {
        title: `benefits.servicioPremium.faq4Title`,
        text: `benefits.servicioPremium.faq4Text`,
      },
    ],
  },
  salasVip: {
    "es-es": [
      {
        title: `benefits.salasVip.faq1Title`,
        text: `benefits.salasVip.faq1Text`,
      },
      {
        title: `benefits.salasVip.faq2Title`,
        text: `benefits.salasVip.faq2Text`,
      },
      {
        title: `benefits.salasVip.faq3Title`,
        text: `benefits.salasVip.faq3Text`,
      },
    ],
  },
  heathrow: {
    "es-es": [
      {
        title: `benefits.heathrow.faq1Title`,
        text: `benefits.heathrow.faq1Text`,
      },
      {
        title: `benefits.heathrow.faq2Title`,
        text: `benefits.heathrow.faq2Text`,
      },
      {
        title: `benefits.heathrow.faq3Title`,
        text: `benefits.heathrow.faq3Text`,
      },
      {
        title: `benefits.heathrow.faq4Title`,
        text: `benefits.heathrow.faq4Text`,
      },
    ],
  },
  // viajes: {
  //   "es-es": [
  //     {
  //       title: `benefits.viajes.faq1Title`,
  //       text: `benefits.viajes.faq1Text`,
  //     },
  //     {
  //       title: `benefits.viajes.faq2Title`,
  //       text: `benefits.viajes.faq2Text`,
  //     },
  //     {
  //       title: `benefits.viajes.faq3Title`,
  //       text: `benefits.viajes.faq3Text`,
  //       formatValues: {},
  //     },
  //   ]
  // },
  corteIngles: {
    "es-es": [
      {
        title: `benefits.corteIngles.faq1Title`,
        text: `benefits.corteIngles.faq1Text`,
      },
      {
        title: `benefits.corteIngles.faq2Title`,
        text: `benefits.corteIngles.faq2Text`,
      },
      {
        title: `benefits.corteIngles.faq3Title`,
        text: `benefits.corteIngles.faq3Text`,
      },
      {
        title: `benefits.corteIngles.faq4Title`,
        text: `benefits.corteIngles.faq4Text`,
      },
      {
        title: `benefits.corteIngles.faq5Title`,
        text: `benefits.corteIngles.faq5Text`,
      },
    ],
  },
};
