// const isProd = process.env.REACT_APP_CONFIG_ENV === "production";

const seasonsForLocale = (localeName) => {

  const defaultAvailableSeasons = ["2022", "2023", "2024"];
  const defaultCurrentSeason = "2024";
  const defaultConfig = {
    availableSeasons: defaultAvailableSeasons,
    currentSeason: defaultCurrentSeason,
  };

  // override defaults as required
  const config = {
    "en-US": {
      availableSeasons: ["2022", "2023"],
      currentSeason: "2023",
    },
    "en-ID": {
      availableSeasons: ["2023"],
      currentSeason: "2023",
    },
    "es-ID": {
      availableSeasons: ["2023"],
      currentSeason: "2023",
    },
    "en-SG": {
      availableSeasons: ["2023", "2024"],
    },
    "ja-JP": {
      availableSeasons: ["2023", "2024"],
    },
    "de-AT": {
      availableSeasons: ["2023", "2024"],
    },
  };

  return {...defaultConfig, ...config[localeName]} || defaultConfig;

};

export default seasonsForLocale;
