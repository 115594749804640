import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getConfigForEnv } from "../../appConfig";
import CenturionLogo from "../CenturionLogo/CenturionLogo";
import style from "./HomeLink.module.scss";

const HomeLink = ({ rootModule, basePath }) => {
  const params = useParams();

  const [positionLeft, setPositionLeft] = useState(-80);
  const [initialised, setInitialised] = useState(false);

  const isDarkPage = rootModule === "living";

  useEffect(() => {
    if (!window) {
      return;
    }
    const updatePosition = () => {
      if (document.getElementById("centurionLogo")) {
        let pos = 0;
        const w = window.innerWidth;
        const rect = document
          .getElementById("centurionLogo")
          .parentNode.getBoundingClientRect();

        if (w < 375) {
          pos = -rect.left + 12;
        }
        if (w >= 375 && w < 768) {
          pos = -rect.left + 18;
        }
        if (w >= 768 && w < 1080) {
          pos = -rect.left + 20;
        }
        if (w >= 1080) {
          pos = -80;
        }
        setPositionLeft(pos);

        if (!initialised) {
          setInitialised(true);
        }
      }
    };
    window.addEventListener("resize", updatePosition);
    setTimeout(() => {
      updatePosition();
    }, 0);
    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, [initialised]);

  const lowerCaseLocale = params.locale.toLowerCase();
  const { locales } = getConfigForEnv(); // All enabled locales
  const localeIsAvailable = locales.some(
    (key) => key.toLowerCase() === lowerCaseLocale
  );

  return rootModule === "living" ? (
    <Link
      id="centurionLogo"
      className={style.link}
      to={{ pathname: localeIsAvailable ? `/${basePath}/` : "/" }}
    >
      <CenturionLogo isDarkPage={isDarkPage} />
    </Link>
  ) : (
    <Link
      to={{ pathname: localeIsAvailable ? `/${basePath}/` : "/" }}
      id="centurionLogo"
      className={style.link}
      style={{
        left: `${positionLeft}px`,
        display: `${initialised ? "block" : "none"}`,
      }}
    >
      <CenturionLogo isDarkPage={isDarkPage} />
    </Link>
  );
};

HomeLink.propTypes = {
  rootModule: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    basePath: state.get("basePath")?.path || "/",
  };
};

export default connect(mapStateToProps)(HomeLink);
