import React from "react";
import { Link, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from '@react-spring/web';
import BenefitsBlend from "../../components/Benefits/BenefitsBlend";
import style from "./FeatureBenefits.module.scss";

const FeatureBenefits = ({ animation }) => {
  const params = useParams();

  const [moreRef, moreInView] = useInView();
  const animMore = useSpring({
    from: {
      opacity: moreInView ? 0 : 1,
      transform: moreInView ? "translate3d(0,32px,0)" : "translate3d(0,0px,0)",
    },
    to: {
      opacity: moreInView ? 1 : 0,
      transform: moreInView ? "translate3d(0,0,0)" : "translate3d(0,32px,0)",
    },
  });

  const { locale } = useIntl();
  const localeName = locale.toLowerCase();
  const isIndia = localeName === "en-in";

  return (
    <>
      <div className={`row flex-justify-center ${style.blurbWrapper}`}>
        <div className={`col-sm-10 col-md-6 ${ isIndia ? 'margin-4-b' : ''}`}>
          <animated.div style={animation}>
            <p className={`${style.benefitsBlurb} text-justify bentonsansthin`}>
              <FormattedMessage id="home.benefitsBlurb" values={{ lineBreak: <br /> }} />
            </p>
          </animated.div>
        </div>
      </div>

      <div className={style.benefitBlendsContainer}>
        <BenefitsBlend
          id="home"
          containerRatio={64.5}
          imageContainerProps={{ aspectRatio: 100 }}
          className="margin-4-tb"
        />

        <div ref={moreRef} className="pad-4-tb text-align-center">
          <animated.div className="text-align-center" style={animMore}>
            <Link
              className={`btn btn-centurion-black moreButton bentonsanslight`}
              to={{
                pathname: `/${params.locale}/${params.card}/${params.centurion}/benefits`,
              }}
            >
              <FormattedMessage id={`home.findOutMore`} />
            </Link>
          </animated.div>
        </div>
      </div>
    </>
  );
};

export default FeatureBenefits;
