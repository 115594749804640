import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import style from "./CalendarCta.module.scss";

import { activeSeasons as calendarSeasons } from "../../static/living/calendar";

const CalendarCta = ({ basePath, text, season = 2024 }) => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;

  const [calendarSeason, setCalendarSeason] = useState();

  useEffect(() => {
    const trueSeason = Number(season);
    const newSeason = calendarSeasons.includes(trueSeason)
      ? trueSeason
      : calendarSeasons[0];

    setCalendarSeason(newSeason);
  }, [season]);

  return (
    <div className={`${style.wrapper} row`}>
      <Link
        to={{
          pathname: `/${basePath}/living/calendar`,
          search: `?season=${calendarSeason}`,
        }}
        className={`${style.ctaButton} btn btn-centurion-black bentonsanslight`}
        style={{
          backgroundImage: `url(${assetPath}/icons/calendar-outline.svg)`,
        }}
      >
        <FormattedMessage id={text} />
      </Link>
    </div>
  );
};

CalendarCta.propTypes = {
  text: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    basePath: state.get("basePath")?.path || "/",
  };
};

export default connect(mapStateToProps)(CalendarCta);
