import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import slidesData from "../../static/wileySlides";
import HomeLink from "../../components/HomeLink/HomeLink";
import SlideshowAlt from "../../components/Slideshow/SlideshowAlt";
import InViewProfile from "../../components/InView/InViewProfile";
import ChevronDown from "../../components/ChevronDown/ChevronDown";
import Logotype from "../../components/Logotype/Logotype";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import LineSet from "../../components/LineSet/LineSet";
import offset from "../../services/elementOffset";
import style from "./Wiley.module.scss";

const Wiley = ({ updateRoot }) => {
  const { locale: localeName, messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();
  const params = useParams();

  useEffect(() => {
    updateRoot("wiley");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const locale = localeName.toLowerCase();

  const profileImage1 = `${assetPath}/wiley/kehinde-wiley-3.png`;
  const profileImage2 = `${assetPath}/wiley/kehinde-wiley-art.png`;
  const botanicalsImage = `${assetPath}/wiley/botanicals.png`;

  const botLrg = `${assetPath}/wiley/bot-lrg.png`;
  const botLrgQTop = `${assetPath}/wiley/${locale}/bot-lrg-quote-top.png`;
  const botLrgQBtm = `${assetPath}/wiley/${locale}/bot-lrg-quote-btm.png`;

  const botMed = `${assetPath}/wiley/bot-med.png`;
  const botMedQTop = `${assetPath}/wiley/${locale}/bot-med-quote-top.png`;
  const botMedQBtm = `${assetPath}/wiley/${locale}/bot-med-quote-btm.png`;

  const botSml = `${assetPath}/wiley/bot-sml.png`;
  const botSmlQTop = `${assetPath}/wiley/${locale}/bot-sml-quote-top.png`;
  const botSmlQBtm = `${assetPath}/wiley/${locale}/bot-sml-quote-btm.png`;

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--botanicalsBg",
      `url(${botanicalsImage})`
    );
  });

  function handleChevronClick(id) {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    window.scrollTo({
      left: 0,
      top: offset(element).top,
      behavior: "smooth",
    });
  }

  // Top section diagonal background
  useEffect(() => {
    if (!window) {
      return;
    }
    let dimCache = window.innerWidth;

    const updateBg = () => {
      if (!document.getElementById("pageTop")) {
        return;
      }

      if (document.getElementById("pageTopBg")) {
        const current = document.getElementById("pageTopBg");
        current.parentNode.removeChild(current);
      }

      // const rad = 0.3804818; // 21.8 deg to radian
      const rad = 0.3; // Manual radian

      const minWidth = window.innerWidth;
      const c = minWidth / Math.cos(rad);

      const minHeight = Math.round(
        Math.sqrt(Math.abs(Math.pow(c, 2) - Math.pow(minWidth, 2)))
      );

      const el = document.getElementById("pageTop");
      const bgEl = document.createElement("div");
      bgEl.innerHTML = "<span></span><span></span>";
      bgEl.id = "pageTopBg";
      bgEl.style.opacity = "0.75";

      const bg = bgEl.querySelectorAll("span")[0];
      const overlay = bgEl.querySelectorAll("span")[1];

      const elTop = el.offsetTop;
      const elLeft = 0;
      const elHeight = el.offsetHeight;
      let offsetFactor = 24.5;
      if (window.innerWidth >= 768) {
        offsetFactor = 18;
      }
      if (window.innerWidth >= 1024) {
        offsetFactor = 7;
      }
      const offsetTop = (elHeight / 100) * offsetFactor;
      const bgHeight = Math.round(elHeight / 2 + minHeight / 2 + offsetTop);

      bg.style.width = minWidth + "px";
      bg.style.height = bgHeight + "px";
      bg.style.position = "absolute";
      bg.style.zIndex = "-2";
      bg.style.top = elTop;
      bg.style.left = elLeft + "px";
      bg.style.pointerEvents = "none";
      bg.style.background =
        "linear-gradient(0deg, #E8E9E3 0%, #dbded5 100%), var(--botanicalsBg)";
      bg.style.backgroundBlendMode = "screen";
      bg.style.backgroundRepeat = "no-repeat";
      bg.style.backgroundPosition = "80% 85%";
      bg.style.backgroundSize = "cover";

      overlay.style.position = "absolute";
      overlay.style.zIndex = "-1";
      overlay.style.top =
        Math.round(elTop + elHeight / 2 - minHeight / 2 + offsetTop) - 1 + "px";
      overlay.style.left = "0";
      overlay.style.width = "100%";
      overlay.style.height = minHeight + 2 + "px";
      overlay.style.backgroundColor = "#fff";
      overlay.style.clipPath = "polygon(100% 0, 0% 100%, 100% 100%)";

      el.parentNode.insertBefore(bgEl, el);
    };

    const debFunc = debounce(function () {
      updateBg();
    }, 250);

    const onResize = () => {
      // If the window width has not changed,
      // do not update the background.
      //
      // (This is prevent background flash on iOS)
      //
      if (window.innerWidth !== dimCache) {
        // Window width has changed; so update
        dimCache = window.innerWidth;

        // Run the debounced update background
        debFunc();
      }
    };
    window.addEventListener("resize", onResize);
    window.addEventListener("orientationchange", debFunc);

    updateBg();
    return () => {
      const pageTopBg = document.getElementById("pageTopBg");
      if (pageTopBg) {
        pageTopBg.parentNode.removeChild(pageTopBg);
      }
      window.removeEventListener("resize", onResize);
      window.removeEventListener("orientationchange", debFunc);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (!languageData.centurion) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{languageData["wiley.title"]}</title>
      </Helmet>
      <div data-module="wiley" id="pageTop">
        <div className={`container ${style.notFullpage}`}>
          <header className={`position-relative ${style.header}`}>
            <HomeLink rootModule="wiley" /> {/* location={location} */}
            <div className="row">
              <div className="col-xs-12 col-xs-offset-0 col-md-10 col-md-offset-1 col-lg-offset-3 col-lg-6">
                <div className={`${style.titleWrapper}`}>
                  <Logotype
                    id="artWileyProfile"
                    title="title"
                    rootModule="wiley"
                  />
                </div>
              </div>
            </div>
            <div className={`row ${style.mobileOnly}`}>
              <div className="col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-1 col-lg-6 col-lg-offset-2">
                <InViewProfile y={0} duration={600}>
                  <p
                    className={`heading-5 ${style.summary} bentonsansthin text-justify`}
                  >
                    <FormattedMessage
                      id="wiley.summary"
                      values={{ sup: (str) => <sup>{str}</sup> }}
                    />
                  </p>
                </InViewProfile>
              </div>
            </div>
          </header>
          <div className={`row ${style.profileAndCopy}`}>
            <div
              className={`col-sm-8 col-sm-offset-2 col-md-5 col-md-offset-0 col-lg-5`}
            >
              {" "}
              <InViewProfile y={0} duration={600} delay={600}>
                <div className={style.profileImage}>
                  <img
                    className={style.profileImage2}
                    src={profileImage2}
                    alt=""
                  />
                  <img
                    className={style.profileImage1}
                    src={profileImage1}
                    alt={languageData.title}
                  />
                </div>
              </InViewProfile>
            </div>
            <div className="col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-1">
              <InViewProfile y={0} duration={600} delay={1200}>
                <p
                  className={`heading-5 ${style.summary} ${style.tabletUp} bentonsansthin text-justify`}
                >
                  <LineSet
                    id="wiley.summary"
                    desktop={[11, 11, 10, 11]}
                    values={{ sup: (str) => <sup>{str}</sup> }}
                  />
                </p>
                <p
                  className={`${style.content} ${style.content1} bentonsansthin text-justify`}
                >
                  <LineSet
                    id="wiley.content1"
                    desktop={[13, 7, 11]}
                    tablet={[10, 6, 7, 8, 7, 8]}
                    mobile={[8, 7, 5, 6, 8, 6, 7]}
                  />
                </p>
              </InViewProfile>
            </div>
            <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1 col-lg-6 col-lg-offset-3">
              <InViewProfile y={0} duration={600} delay={1800}>
                <p
                  className={`${style.contentPullOut} ${style.content} ${style.content2} bigcaslonregular text-justify`}
                >
                  <FormattedMessage
                    id="wiley.content2"
                    values={{
                      bold: (str) => <strong>{str}</strong>,
                      cardLink: (
                        <Link
                          to={{
                            pathname: `/${params.locale}/${params.card}/${params.centurion}/card`,
                            hash: "#kehindeWiley",
                          }}
                        >
                          <FormattedMessage id="wiley.cardLinkText" />
                        </Link>
                      ),
                    }}
                  />
                </p>
              </InViewProfile>
            </div>
          </div>
        </div>
      </div>
      <ChevronDown
        onClick={() => handleChevronClick("slideshow")}
        id="chevronToSlideshow"
        rootModule="wiley"
      />
      <div className="container" id="slideshow">
        <InViewProfile y={100} duration={1000}>
          <section className={style.slideshowWrapper}>
            <SlideshowAlt
              slides={slidesData}
              title="wiley.kehindeWileyWork"
              rootModule="wiley"
            />
          </section>
        </InViewProfile>
      </div>
      <ChevronDown
        onClick={() => handleChevronClick("pageBottom")}
        id="chevronToBotanicals"
        rootModule="wiley"
      />
      <div className={style.container}>
        <div className={`container ${style.bottomContainer}`}>
          <div className="container">
            <InViewProfile y={100} duration={1000}>
              <div id="pageBottom" className={style.behind}>
                <div className="row">
                  <h2
                    data-localeid="quotesTitle"
                    className={`${style.quotesTitle} col-xs-12 bigcaslonregular`}
                  >
                    <FormattedMessage
                      id="wiley.quotesTitle"
                      values={{
                        i: (str) => <i>{str}</i>,
                        lineBreak: <br />,
                      }}
                    />
                  </h2>
                </div>
              </div>
            </InViewProfile>

            <InViewProfile y={300} duration={1000} delay={300}>
              <img
                src={botLrgQTop}
                alt=""
                className={`${style.botLrg} ${style.botQTop}`}
              />
              <img
                src={botMedQTop}
                alt=""
                className={`${style.botMed} ${style.botQTop}`}
              />
              <img
                src={botSmlQTop}
                alt=""
                className={`${style.botSml} ${style.botQTop}`}
              />
            </InViewProfile>

            <InViewProfile y={300} duration={1000} delay={450}>
              <img src={botLrg} alt="" className={style.botLrg} />
              <img src={botMed} alt="" className={style.botMed} />
              <img src={botSml} alt="" className={style.botSml} />
            </InViewProfile>

            <InViewProfile y={300} duration={1000} delay={600}>
              <img
                src={botLrgQBtm}
                alt=""
                className={`${style.botLrg} ${style.botQBtm}`}
              />
              <img
                src={botMedQBtm}
                alt=""
                className={`${style.botMed} ${style.botQBtm}`}
              />
              <img
                src={botSmlQBtm}
                alt=""
                className={`${style.botSml} ${style.botQBtm}`}
              />
            </InViewProfile>
          </div>
        </div>
      </div>

      <div className={style.footer}>
        <div className="container">
          <SiteFooter />
        </div>
      </div>
    </>
  );
};

Wiley.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(null, mapDispatchToProps)(Wiley);
