import React, { useEffect, useState } from "react";
import style from "./Tiles.module.scss";

const Tiles = () => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const GridStrapCombo = `${assetPath}/howtowear/gridstrapcombo.png`;
  const GridStrapComboDesktop = `${assetPath}/howtowear/gridstrapcombo_desktop.png`;

  const [isDesktop, setIsDesktop] = useState(null);

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.screen.width > 810);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={style.tiles}>
      <img src={isDesktop ? GridStrapComboDesktop : GridStrapCombo} alt="" />
    </div>
  );
};

export default Tiles;
