/* eslint-disable no-unused-vars */
import {
  // smallColumns,
  // smallGutterWidth,
  // smallColumnWidth,
  largeColumns,
  // gutterWidth,
  // columnWidth,
} from "../benefits";
/* eslint-enable no-unused-vars */

const default2023Config = {
  containerRatio: 0.645,
  items: [
    {
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(2.5, 3),
      src: "1.jpg",
      aspectRatio: 357 / 268,
    },
    {
      // top: 20,
      centerVertically: true,
      width: largeColumns(1.9),
      left: 8.4,
      src: "2.jpg",
      aspectRatio: 1,
    },
    {
      top: 26.33,
      width: largeColumns(2, 2.5),
      left: 15.66,
      src: "3.jpg",
      aspectRatio: 1,
    },
    {
      top: 16,
      width: largeColumns(2, 1.8),
      left: 25.75,
      src: "4.jpg",
      aspectRatio: 275 / 206,
    },
    {
      top: 42.9,
      width: largeColumns(2, 2.45),
      left: 25.15,
      src: "5.jpg",
      aspectRatio: 275 / 224
    },
    {
      top: 21.9,
      width: largeColumns(1.5),
      left: 52.275,
      src: "6.jpg",
      aspectRatio: 1,
    },
    {
      top: 17.5,
      width: largeColumns(3.125, 3),
      left: 54.8,
      src: "7.jpg",
      aspectRatio: 1,
    },
    {
      top: 42.33,
      width: largeColumns(2.75, 2),
      right: 8.5,
      src: "8.jpg",
      aspectRatio: 186 / 278.55,
    },
    {
      top: 50.5,
      width: largeColumns(2.2),
      right: 28.65,
      src: "9.jpg",
      aspectRatio: 205 / 252,
    },
  ]
};
const default2024Config = {
  // containerRatio: 0.50,
  containerRatio: 0.55,
  items: [
    {
      top: 34.75,
      left: 10,
      width: largeColumns(1.9, 2),
      src: "1.webp",
      aspectRatio: 264 / 204, // height / width
    },
    {
      top: 46.33,
      left: 19,
      width: largeColumns(2.7, 2),
      src: "2.webp",
      aspectRatio: 205 / 219.36,
    },
    {
      top: 23.75,
      left: 24.75,
      width: largeColumns(2, 1.25),
      src: "3.webp",
      aspectRatio: 260 / 199,
    },
    {
      centerVertically: true,
      left: 39.4,
      width: largeColumns(2.25, 2),
      src: "4.webp",
      aspectRatio: 300 / 228,
    },
    {
      top: 51.5,
      width: largeColumns(2.2, 2),
      right: 25.75,
      src: "5.webp",
      aspectRatio: 1
    },
    {
      top: 22.375,
      width: largeColumns(2, 3.25),
      right: 23.85,
      src: "6.webp",
      aspectRatio: 264 / 233,
    },
    {
      top: 29.33,
      width: largeColumns(2.25, 1),
      right: 9.85,
      src: "7.webp",
      aspectRatio: 240 / 213,
    },
  ],
};

// Homepage Living section blends config
const blends = {
  2023: { ...default2023Config },
  2024: {
    ...default2024Config,
    overrides: [
      {
        id: 'en-gl',
        config: {
          ...default2023Config,
          items: [
            ...default2024Config.items.slice(0, -3),
            {
              top: 51.5,
              width: largeColumns(2.5, 2),
              right: 26,
              src: "5.webp",
              aspectRatio: 1
            },
            {
              top: 22.375,
              width: largeColumns(2.25, 3.5),
              right: 24,
              src: "6.webp",
              aspectRatio: 264 / 233,
            },
            {
              top: 29.33,
              width: largeColumns(2.33, 1),
              right: 9.825,
              src: "7-gl.webp",
              aspectRatio: 294 / 197,
            },
          ],
        },
      },
    ],
  },
};

export default blends;
