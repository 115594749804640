import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useParams, useOutletContext } from "react-router-dom";
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import Logotype from "../../components/Logotype/Logotype";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import MarketPrivacy from "../../components/Privacy/MarketPrivacy";
import MainPrivacy from "../../components/Privacy/MainPrivacy";
import style from "./Privacy.module.scss";

const Privacy = ({ location, basePath, updateRoot }) => {
  const { messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();

  useEffect(() => {
    updateRoot("privacy");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  const params = useParams();
  const subpage = params.subpage || "landing";

  const Subpage = () => {
    if (params.subpage && params.subpage === "market") {
      return <MarketPrivacy />;
    }
    if (params.subpage && params.subpage === "Privacy") {
      return <MainPrivacy location={location} basePath={basePath} />;
    }
    return <MarketPrivacy />;
  };

  if (!languageData.centurion) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{languageData["privacy.title"]}</title>
      </Helmet>
      <div className={`${style.container}`} data-subpage={subpage}>
        <div className={`flex flex-justify-between ${style.titleWrapper}`}>
          <Logotype
            id="centurion"
            title="logoTitle"
            rootModule="privacy"
          />

        </div>

        <Subpage />
      </div>
      <SiteFooter />
    </>
  );
};

Privacy.propTypes = {
  updateRoot: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
});

export default connect(null, mapDispatchToProps)(Privacy);
