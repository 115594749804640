import React from "react";
import ReactDOM from "react-dom/client";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./duck";
import { loadLanguagePack } from "./features/intl";
import { HelmetProvider } from "react-helmet-async";
import "./eulocale";

if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "archive") {
  disableReactDevTools();

  if (process.env.PUBLIC_URL === "https://www.americanexpress.com") {
    Sentry.init({
      dsn: "https://a6d1ecf925804ee0920e3388e9cfb777@o1056788.ingest.sentry.io/6311076",
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        })
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5, // 1.0,
      ignoreErrors: [
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications.",
      ],
      ignoreUrls: [
        /https?:\/\/nexus\.ensighten\.com/
      ],
    });
  }
} else if (process.env.NODE_ENV === "development") {
  /* eslint-disable no-console */
  const consoleError = console.error.bind(console);
  console.error = (error, ...args) => {
    if (
      error.code === "MISSING_TRANSLATION" ||
      args.some((arg) => arg.code === "MISSING_TRANSLATION")
    ) {
      return false;
    }
    consoleError(error, ...args);
  };
  /* eslint-enable no-console */
}

if (process.env.NODE_ENV === "archive") {
  disableReactDevTools();
}

// Set the language data for the app here
store.dispatch(
  loadLanguagePack("axp-centurion", {
    locale: store.getState().getIn(["intl", "activeLocale"]),
    force: true,
    fallbackLocale: "en-GB",
  })
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
