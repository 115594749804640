import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import style from "./CenturionLogo.module.scss";

const CenturionLogo = ({ height, width, isDarkPage, cdnUrl }) => {
  const intl = useIntl();

  return (
    <span
      className={`display-block ${style.wrapper}`}
      style={{ height, width }}
    >
      <img
        alt={intl.formatMessage({ id: "centurion" })}
        className="display-block"
        src={`${cdnUrl}static/${isDarkPage ? "logoWhite" : "logo"}.svg`}
      />
    </span>
  );
};

const mapStateToProps = (state) => {
  const config = state.get("config");
  const cdnUrl = config?.assets;

  return {
    cdnUrl,
  };
};

CenturionLogo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  isDarkPage: PropTypes.bool,
  cdnUrl: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(CenturionLogo);
