import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import style from './WearableCarouselSlide.module.scss';

const WearableCarouselSlide = ({ slides, slide, basePath }) => {

  const intl = useIntl();

  const formatLink = (linktext) => {
    return linktext.indexOf("http") === 0 ? linktext : `http://${linktext}`;
  }

  const mailtoLink = (email) => {
    return (email = `mailto:${email}`);
  }

  const uuid = () => {
    return Math.floor(Date.now() * Math.random()).toString(32);
  };

  const formatTel = (str) => {
    if (!str[0]) {
      return "";
    }
    let s = str[0].toString();
    let n = s.replace(/\s+/g, "");
    n = n.replace(/-/g, "");
    return n;
  };

  const faqsLinkHref = intl.formatMessage({ id: "wearable.faqsLink"}) ? intl.formatMessage({ id: "wearable.faqsLink"}) : '';

  const locatorLinkHref = intl.formatMessage({ id: "wearable.locatorLink"}) ? intl.formatMessage({ id: "wearable.locatorLink"}) : '';

  const formatHTMLMessage = {
    h4: (...str) => <h4 key={uuid()}>{str}</h4>,
    p: (...str) => <p key={uuid()}>{str}</p>,
    b: (...str) => (
      <strong key={uuid()} className="bentonsanslight">
        {str}
      </strong>
    ),
    bold: (...str) => (
      <strong key={uuid()} className="bentonsanslight">
        {str}
      </strong>
    ),
    italic: (...str) => <em key={uuid()}>{str}</em>,
    subheading: (...str) => <span key={uuid()}>{str}</span>,
    link: (...str) => (
      <a
        href={formatLink(str)}
        target="_blank"
        rel="noopener noreferrer"
        key={uuid()}
        className="bentonsanslight"
      >
        {str}
      </a>
    ),
    monoLink: (str) => (
      <Link
        to={`/${basePath}/monogramming-viewings/monogramming/`}
        target="_blank"
        rel="noopener noreferrer"
        key={uuid()}
        className="bentonsanslight"
      >
        {str}
      </Link>
    ),
    mailto: (str) => (
      <a
        href={mailtoLink(str)}
        target="_blank"
        rel="noopener noreferrer"
        key={uuid()}
        className="bentonsanslight"
      >
        {str}
      </a>
    ),
    tel: (str) => (
      <a
        href={`tel:${formatTel(str)}`}
        rel="noopener noreferrer"
        key={uuid()}
        className="bentonsanslight"
      >
        {str}
      </a>
    ),
    faqsLink: (
      <a
        href={formatLink(faqsLinkHref)}
        target="_blank"
        rel="noopener noreferrer"
        key={uuid()}
        className="bentonsanslight"
        style={{wordBreak: "break-word"}}
      >
        <FormattedMessage id="wearable.faqsLinkText" />
      </a>
    ),
    locatorLink: (
      <a
        href={formatLink(locatorLinkHref)}
        target="_blank"
        rel="noopener noreferrer"
        key={uuid()}
        className="bentonsanslight"
        style={{wordBreak: "break-word"}}
      >
        <FormattedMessage id="wearable.locatorLinkText" />
      </a>
    ),
    lineBreak: (
      <span key={uuid()}>
        <br />
      </span>
    ),
    lineBreakMedium: (
      <span key={uuid()} data-bp="medium">
        <br />
      </span>
    ),
    nbsp: String.fromCharCode(160),
  };

  function textClassName(slide) {
    if (typeof slide.title == 'undefined') {
      return `${style.slideText} ${style.slidePadding} ${slide.subtitleAsTitle ? style.subtitleAsTitle : ''} bentonsansthin`;
    }
    return `${style.slideText} bentonsansthin`;
  }

  return (
    <Fragment>
    { slide && (
      <div 
        className={`${style.slide} ${slide.subtitleAsTitle ? style.subtitleAsTitle : ''}`}
        data-slide-type="wearable" data-slide-id={slide.id}
        role="group"
        aira-roledescription="slide"
        aria-label={slide.title ? intl.formatMessage({ id:slide.title }) : ''}
      >
        {slide.title && 
          <h3 className={
            `${style.title} ${(slide.titleCleared && !slide.lastItem) ? style.titleCleared : ''} 
            ${slide.lastItem ? style.lastItem : ''} 
            bigcaslonitalic`
          }><FormattedMessage id={slide.title} values={formatHTMLMessage} /></h3>
        }
        {slide.sectionTitle && 
          <h3 className={`${style.sectionTitle} bigcaslonitalic`}>
            <FormattedMessage id={slide.sectionTitle} values={formatHTMLMessage} />
          </h3>
        }
        <div className={textClassName(slide)}>
          <FormattedMessage id={ slide.text } values={formatHTMLMessage} />
        </div>
      </div>
    )}
    { slides && (
      <div
        className={`${style.slide}`}
        data-slide-type="wearable"
        data-slide-id={slides[0].id}
        role="group"
        aira-roledescription="slide"
        aria-label={slide.title ? intl.formatMessage({ id:slide.title }) : ''}
      >
        {slides.map((slide, index) => (
          <Fragment key={`col-${index}`}>
            {slide.title && 
              <h3 className={
                `${style.title} ${(slide.titleCleared && !slide.lastItem) ? style.titleCleared : ''} 
                ${slide.lastItem ? style.lastItem : ''} 
                ${slide.subtitleAsTitle ? style.subtitleAsTitle : ''}
                bigcaslonitalic`
              }><FormattedMessage id={slide.title} values={formatHTMLMessage} /></h3>
            }
            {index === 0 && slide.sectionTitle && 
              <h3 className={`${style.sectionTitle} ${slide.subtitleAsTitle ? style.subtitleAsTitle : ''} bigcaslonitalic`}>
                <FormattedMessage id={slide.sectionTitle} values={formatHTMLMessage} />
              </h3>
            }
            <div className={textClassName(slide)}>
              <FormattedMessage id={ slide.text } values={formatHTMLMessage} />
            </div>
          </Fragment>
        ))}
      </div>
    )} 
    </Fragment>
  )
};

WearableCarouselSlide.propTypes = {
  slides: PropTypes.array, // TODO: Use PropTypes.arrayOf(PropTypes.shape({}) ?
  slide: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
  }).isRequired,
  basePath: PropTypes.string.isRequired,
};

export default WearableCarouselSlide;
