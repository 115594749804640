/* eslint-disable no-unused-vars */
import {
  smallColumns,
  smallGutterWidth,
  smallColumnWidth,
  largeColumns,
  gutterWidth,
  columnWidth,
} from "../benefits";
/* eslint-enable no-unused-vars */

const defaultTalent = {
  hasQuoteImage: true,
  hasImageSignature: false,
  showAudioPlayer: false,
  audioSrc: [],
  hasArticle: false,
  articleData: {},
  hasProgrammeNotes: false,
  showProgrammeNotes: false,
  showVideo: false,
  showVideo2: false,
  videoSrc: "static/.mp4",
  posterSrc: "_poster.png",
  showCitiesAndDates: true,
  bookingOpens: false, // hides buttons when true (&& !tbc)
  events: [],
};

// Generate names, category etc (as translations) based on ID
const artists = {
  2022: [
    {
      id: "aakashodedra",
      path: "aakash-odedra",
      avatar: "aakashOdedra.png",
      hasImageSignature: false,
      showAudioPlayer: false,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "6Y6bZLyGD0b9JvdUcNjZAP",
          title: "aakashodedra_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: true,
      showProgrammeNotes: false,
      showVideo: true,
      videoSrc: "static/aakashodedra_video.mp4",
      posterSrc: "aakashodedra_poster.png",
      showCitiesAndDates: true,
      events: [
        { id: "aakashodedra_event1", map: "" },
        { id: "aakashodedra_event2", map: "" },
        { id: "aakashodedra_event3", map: "" },
        { id: "aakashodedra_event4", map: "" },
        { id: "aakashodedra_event5", map: "" },
        // { id: "aakashodedra_event6", map: "" },
      ],
      hasBlends: true, // 'Old' blends rendering
    },
    {
      id: "elianeelias",
      path: "eliane-elias",
      avatar: "elianeElias.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "17abPJKeFmfUTtg2bE1bWF",
          title: "elianeelias_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: true,
      showProgrammeNotes: false,
      showVideo: true,
      videoSrc: "static/eliane_elias-chega_de_saudade.mp4",
      posterSrc: "elianeelias_poster.png",
      showCitiesAndDates: true,
      // citiesAndDates: [
      //   {
      //     id: 1,
      //     venueId: 'NYC'
      //   },
      // ],
      events: [
        { id: "elianeelias_event1", map: "" },
        { id: "elianeelias_event2", map: "" },
        { id: "elianeelias_event3", map: "" },
        { id: "elianeelias_event4", map: "" },
        { id: "elianeelias_event5", map: "" },
        { id: "elianeelias_event6", map: "" },
        { id: "elianeelias_event7", map: "" },
        { id: "elianeelias_event8", map: "" },
        { id: "elianeelias_event9", map: "" },
        { id: "elianeelias_event10", map: "" },
      ],
      hasBlends: true, // 'Old' blends rendering
    },
    {
      id: "yoyoma",
      path: "yo-yo-ma",
      categoryColour: "gold",
      avatar: "yoyoma-avatar.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        // {
        //   id: 0,
        //   type: 'file',
        //   src: 'yoyoma_audio_0.mp3',
        //   title: 'yoyoma_audioTitle0',
        //   player: 'bespoke',
        // },
        // {
        //   id: 1,
        //   type: 'file',
        //   src: 'yoyoma_audio_0.mp3',
        //   title: 'yoyoma_audioTitle0',
        //   player: 'bespoke',
        // },
        // {
        //   id: 2,
        //   type: 'file',
        //   src: 'yoyoma_audio_0.mp3',
        //   title: 'yoyoma_audioTitle0',
        //   player: 'bespoke',
        // },
        // {
        //   id: 3,
        //   type: 'file',
        //   src: 'yoyoma_audio_0.mp3',
        //   title: 'yoyoma_audioTitle0',
        //   player: 'bespoke',
        // },
        // {
        //   id: 4,
        //   type: 'file',
        //   src: 'yoyoma_audio_0.mp3',
        //   title: 'yoyoma_audioTitle0',
        //   player: 'bespoke',
        // },
        // {
        //   id: 5,
        //   type: 'file',
        //   src: 'yoyoma_audio_0.mp3',
        //   title: 'yoyoma_audioTitle0',
        //   player: 'bespoke',
        // },
        {
          id: 6,
          title: "yoyoma_listenTo1",
          player: "spotify",
          type: "podcast",
          src: "1SpkoZVKjKfNeC2IIJqxFu",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: true,
      showProgrammeNotes: false,
      showVideo: true,
      videoSrc: "static/yoyoma_video.mp4",
      posterSrc: "yoyoma_poster.png",
      showCitiesAndDates: true,
      // citiesAndDates: [
      //   {
      //     id: 1,
      //     venueId: 'NYC',
      //   }
      // ],
      events: [
        { id: "yoyoma_event1", map: "" }, // https://www.google.com/maps/place/Lincoln+Center+for+the+Performing+Arts/@40.7724681,-73.9856829,17z/data=!3m1!4b1!4m12!1m6!3m5!1s0x89c258f55fbea537:0x49d5f074061cac4e!2sLincoln+Center+for+the+Performing+Arts!8m2!3d40.7724641!4d-73.9834889!3m4!1s0x89c258f55fbea537:0x49d5f074061cac4e!8m2!3d40.7724641!4d-73.9834889
        { id: "yoyoma_event2", map: "" },
        { id: "yoyoma_event3", map: "" },
        { id: "yoyoma_event4", map: "" },
        { id: "yoyoma_event5", map: "" },
        { id: "yoyoma_event6", map: "" },
        { id: "yoyoma_event7", map: "" },
        // { id: "yoyoma_event8", map: "" },
      ],
      hasBlends: true, // 'Old' blends rendering
    },
    {
      id: "salmanrushdie",
      path: "salman-rushdie",
      avatar: "salmanRushdie.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        // {
        //   id: 0,
        //   type: 'file',
        //   src: 'yoyoma_audio_0.mp3',
        //   title: 'yoyoma_audioTitle0',
        //   player: 'bespoke',
        // },
        {
          player: "spotify",
          type: "podcast",
          src: "3bVwNA9x6uORWI9BzQEGjY?si=ec5MD0gqTVed4biEu8otuQ",
          title: "salmanrushdie_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: true,
      showProgrammeNotes: false,
      showVideo: true,
      videoSrc: "static/salman_rushdie.mp4",
      posterSrc: "salmanrushdie_poster.png",
      showCitiesAndDates: true,
      // citiesAndDates: [
      //   {
      //     id: 1,
      //     venueId: 'NYC'
      //   },
      // ],
      events: [
        { id: "salmanrushdie_event1", map: "" },
        { id: "salmanrushdie_event2", map: "" },
        { id: "salmanrushdie_event3", map: "" },
      ],
      hasBlends: true, // 'Old' blends rendering
    },
    {
      id: "salifkeita",
      path: "salif-keita",
      categoryColour: "gold",
      avatar: "salifKeita.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "5T2rzhUFXpY35x12GMDYii",
          title: "salifkeita_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: true,
      showProgrammeNotes: false,
      showVideo: true,
      videoSrc: "static/salifkeita_video.mp4",
      posterSrc: "salifkeita_poster.png",
      showCitiesAndDates: true,
      // citiesAndDates: [
      //   {
      //     id: 1,
      //     venueId: 'NYC'
      //   },
      // ],
      events: [
        { id: "salifkeita_event1", map: "", tbc: true },
        { id: "salifkeita_event2", map: "" },
        { id: "salifkeita_event3", map: "" },
        { id: "salifkeita_event4", map: "" },
      ],
      hasBlends: true, // 'Old' blends rendering
    },
    {
      id: "aliciakeys",
      path: "alicia-keys",
      categoryColour: "gold",
      avatar: "aliciaKeys.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "73E08jF1urQQSO0oTzCPpP",
          title: "aliciakeys_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: false,
      videoSrc: "static/looping_waves_edit_long_fade_medium_bitrate.mp4",
      showCitiesAndDates: true,
      // citiesAndDates: [
      //   {
      //     id: 1,
      //     venueId: 'NYC'
      //   },
      // ],
      events: [
        { id: "aliciakeys_event1", map: "" },
        { id: "aliciakeys_event2", map: "" },
      ],
      hasBlends: true, // 'Old' blends rendering
    },
    // {
    //   id: "ikebana",
    //   path: "ikebana",
    //   avatar: "ikebana.png",
    //   hasImageSignature: false,
    //   showAudioPlayer: true,
    //   audioSrc: "533TZP8RSOvLW5aydnvfRp",
    //   showProgrammeNotes: false,
    //   showVideo: false,
    //   videoSrc: "",
    //   showCitiesAndDates: true,
    //   citiesAndDates: [
    //     {
    //       id: 1,
    //       venueId: 'NYC'
    //     },
    //   ]
    // },
    {
      id: "wongkarwai",
      path: "wong-kar-wai",
      avatar: "wongKarWai.png",
      hasImageSignature: false,
      showAudioPlayer: false,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "0yVq58uQ2Bp2OVADYlLHNk",
          title: "wongkarwai_listenTo1",
        },
      ],
      hasArticle: true,
      articleData: {
        id: "inTheMoodForLoveReview",
        logo: "nyt",
        hasImage: true,
        imageLeft: false,
        background: true,
      },
      hasProgrammeNotes: true,
      showProgrammeNotes: false,
      hasMedia: true, // Testing
      showVideo: false,
      videoSrc: "static/wongkarwai_video.mp4",
      posterSrc: "wongkarwai_poster.png",
      showCitiesAndDates: true,
      // citiesAndDates: [
      //   {
      //     id: 1,
      //     venueId: 'NYC'
      //   },
      // ],
      events: [
        { id: "wongkarwai_event1", map: "" },
        { id: "wongkarwai_event2", map: "" },
        { id: "wongkarwai_event3", map: "" },
      ],
      hasBlends: true, // 'Old' blends rendering
    },
    {
      id: "diannereeves",
      path: "dianne-reeves",
      categoryColour: "gold",
      avatar: "dianneReeves.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "445RkLvjQbo4cnAXz7Ku8s",
          title: "diannereeves_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: true,
      showProgrammeNotes: false,
      showVideo: true,
      videoSrc: "static/dianne_reeves-define_your_voice.mp4",
      posterSrc: "diannereeves_poster.png",
      showCitiesAndDates: true,
      // citiesAndDates: [
      //   {
      //     id: 1,
      //     venueId: 'NYC'
      //   },
      // ],
      events: [
        { id: "diannereeves_event1", map: "" },
        { id: "diannereeves_event2", map: "" },
        { id: "diannereeves_event3", map: "" },
        { id: "diannereeves_event4", map: "" },
        { id: "diannereeves_event5", map: "" },
        { id: "diannereeves_event6", map: "" },
        { id: "diannereeves_event7", map: "" },
        { id: "diannereeves_event8", map: "" },
        { id: "diannereeves_event9", map: "" },
        { id: "diannereeves_event10", map: "" },
        { id: "diannereeves_event11", map: "" },
        { id: "diannereeves_event12", map: "" },
      ],
      hasBlends: true, // 'Old' blends rendering
    },
  ],
  2023: [
    {
      id: "johnlegend",
      path: "john-legend",
      categoryColour: "gold",
      avatar: "johnlegend.png",
      hasQuoteImage: true,
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "6nxQdXa1uAL0rY72wPZu89?si=8346b560836247ef",
          title: "johnlegend_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: false,
      videoSrc: "static/.mp4",
      posterSrc: "_poster.png",
      showCitiesAndDates: true,
      // bookingOpens: false, // hides buttons when true (&& !tbc)
      // events: [
      //   { id: "johnlegend_event1", map: "", tbc: null }, // tbc: null
      //   { id: "johnlegend_event2", map: "", tbc: null },
      // ],
      eventsOverrides: {
        "en-ID": ["johnlegend_event2"],
        "es-ID":  ["johnlegend_event2"],
      },
    },
    {
      id: "lemepris",
      path: "le-mepris",
      avatar: "lemepris.png",
      avatarOverrides: {
        "en-gl": "lemepris-en-gl.png",
      },
      hasQuoteImage: true,
      hasImageSignature: false,
      showAudioPlayer: false,
      audioSrc: [],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: false,
      videoSrc: "static/.mp4",
      posterSrc: "_poster.png",
      showCitiesAndDates: true,
      // bookingOpens: false, // hides buttons when true (&& !tbc)
      // events: [
      //   { id: "lemepris_event1", map: "" }, // tbc: null
      //   { id: "lemepris_event2", map: "" },
      //   { id: "lemepris_event3", map: "" },
      //   { id: "lemepris_event4", map: "" },
      //   { id: "lemepris_event5", map: "" },
      //   { id: "lemepris_event6", map: "" },
      //   { id: "lemepris_event7", map: "" },
      // ],
    },
    {
      id: "bebelgilberto",
      path: "bebel-gilberto",
      categoryColour: "gold",
      avatar: "bebelgilberto.png",
      hasQuoteImage: false,
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "0EWQOijH3Cc4jHJUnKdFG2?si=b714a5203c14468d",
          title: "bebelgilberto_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: false,
      videoSrc: "static/.mp4",
      posterSrc: "_poster.png",
      showCitiesAndDates: true,
      // bookingOpens: false, // hides buttons when true (&& !tbc)
      // events: [
      //   { id: "bebelgilberto_event1", map: "" }, // tbc: null
      //   { id: "bebelgilberto_event2", map: "" },
      //   { id: "bebelgilberto_event3", map: "" },
      //   { id: "bebelgilberto_event4", map: "" },
      //   { id: "bebelgilberto_event5", map: "" },
      //   { id: "bebelgilberto_event6", map: "" },
      //   { id: "bebelgilberto_event7", map: "" },
      //   { id: "bebelgilberto_event8", map: "" },
      //   { id: "bebelgilberto_event9", map: "" },
      //   { id: "bebelgilberto_event10", map: "" },
      //   { id: "bebelgilberto_event11", map: "" },
      // ],
    },
    {
      id: "alvinailey",
      path: "alvin-ailey",
      categoryColour: "gold",
      avatar: "alvinailey.png",
      hasQuoteImage: true,
      hasImageSignature: false,
      showAudioPlayer: false,
      audioSrc: [],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: false,
      videoSrc: "static/.mp4",
      posterSrc: "_poster.png",
      showCitiesAndDates: true,
      // bookingOpens: false, // hides buttons when true (&& !tbc)
      // events: [
      //   { id: "alvinailey_event1", map: "" }, // tbc: null
      // ],
    },
    {
      id: "reneefleming",
      path: "renee-fleming",
      avatar: "reneefleming.png",
      hasQuoteImage: true,
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "4GnVpbnWjHFrpXjAcPHvqM?si=147d99a8fe6145c4",
          title: "reneefleming_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: false,
      videoSrc: "static/.mp4",
      posterSrc: "_poster.png",
      showCitiesAndDates: true,
      // bookingOpens: false, // hides buttons when true (&& !tbc)
      // events: [
      //   { id: "reneefleming_event1", map: "" }, // tbc: null
      //   { id: "reneefleming_event2", map: "" },
      //   { id: "reneefleming_event3", map: "" },
      //   { id: "reneefleming_event4", map: "" },
      //   { id: "reneefleming_event5", map: "" },
      //   { id: "reneefleming_event6", map: "" },
      //   { id: "reneefleming_event7", map: "" },
      //   { id: "reneefleming_event8", map: "" },
      // ],
      eventsOverrides: {
        "en-ID": [
          "reneefleming_event3",
          "reneefleming_event4",
          "reneefleming_event5",
          "reneefleming_event6",
          "reneefleming_event7",
          "reneefleming_event8",
        ],
        "es-ID": [
          "reneefleming_event3",
          "reneefleming_event4",
          "reneefleming_event5",
          "reneefleming_event6",
          "reneefleming_event7",
          "reneefleming_event8",
        ],
      },
    },
    {
      id: "chuchovaldes",
      path: "chucho-valdes",
      avatar: "chuchovaldes.png",
      categoryColour: "gold",
      hasQuoteImage: true,
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "0cWrotZwqQAUrYpEeliVuR?si=4916b238bdf84a9f",
          title: "chuchovaldes_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: false,
      videoSrc: "static/.mp4",
      posterSrc: "_poster.png",
      showCitiesAndDates: true,
      // bookingOpens: false, // hides buttons when true (&& !tbc)
      // events: [
      //   { id: "chuchovaldes_event1", map: "" }, // tbc: null
      //   { id: "chuchovaldes_event2", map: "" },
      //   { id: "chuchovaldes_event3", map: "" },
      //   { id: "chuchovaldes_event4", map: "" },
      //   { id: "chuchovaldes_event5", map: "" },
      //   { id: "chuchovaldes_event6", map: "" },
      //   { id: "chuchovaldes_event7", map: "" },
      //   { id: "chuchovaldes_event8", map: "" },
      //   { id: "chuchovaldes_event9", map: "" },
      //   { id: "chuchovaldes_event10", map: "" },
      // ],
    },
    {
      id: "aakashodedra",
      path: "aakash-odedra",
      avatar: "aakashodedra.png",
      hasImageSignature: false,
      showAudioPlayer: false,
      audioSrc: [
        {
          player: "spotify",
          type: "track",
          src: "6Y6bZLyGD0b9JvdUcNjZAP",
          title: "aakashodedra_listenTo1",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: true,
      videoSrc: "static/aakashodedra_video.mp4",
      posterSrc: "aakashodedra_poster.png",
      showCitiesAndDates: true,
      // bookingOpens: false, // hides buttons when true (&& !tbc)
      // events: [
      //   { id: "aakashodedra_event1", map: "" }, // tbc: null
      //   { id: "aakashodedra_event2", map: "" },
      // ],
      hasBlends: true, // 'Old' blends rendering
    },
    {
      id: "yoyoma",
      path: "yo-yo-ma",
      categoryColour: "gold",
      avatar: "yoyoma-avatar.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          id: 6,
          title: "yoyoma_listenTo1",
          player: "spotify",
          type: "track",
          src: "5RMmI12HWgdBYtJHE1wEgY?si=982837ccfa0a4df4",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: true,
      videoSrc: "static/yoyoma_video.mp4",
      posterSrc: "yoyoma_poster.png",
      showCitiesAndDates: true,
      // bookingOpens: false, // hides buttons when true (&& !tbc)
      // events: [
      //   { id: "yoyoma_event1", map: "" }, // tbc: null
      //   // { id: "yoyoma_event2", map: "" },
      //   // { id: "yoyoma_event3", map: "" },
      // ],
      hasBlends: true, // 'Old' blends rendering
      disabledLocales: ["en-id", "es-id"],
    },
  ],
  2024: [
    {
      ...defaultTalent,
      id: "johnlegend",
      path: "john-legend",
      categoryColour: "gold",
      avatar: "johnlegend.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          id: 6,
          title: "johnlegend_listenTo1",
          player: "spotify",
          type: "track",
          src: "3BLWGYOcy1svvsBByeFxLh",
        },
        {
          id: 6,
          title: "johnlegend_listenTo2",
          player: "spotify",
          type: "track",
          src: "1XNAH3h63O1hWFcgyHGbjf", // 7Mt4yp5VWlYlXiam5yn4t8",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: false,
      videoSrc: "static/yoyoma_video.mp4",
      posterSrc: "yoyoma_poster.png",
    },
    {
      ...defaultTalent,
      id: "marisamonte",
      path: "marisa-monte",
      avatar: "marisamonte.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          id: 1,
          title: "marisamonte_listenTo1",
          player: "spotify",
          type: "track",
          src: "43BCxznTSBLcXzu4lJ8KW5",
        },
        {
          id: 2,
          title: "marisamonte_listenTo2",
          player: "spotify",
          type: "track",
          src: "5nwT95l5rMdQPaydjGh32q",
        },
        // {
        //   id: 6,
        //   title: "marisamonte_listenTo3",
        //   player: "spotify",
        //   type: "track",
        //   src: "5RMmI12HWgdBYtJHE1wEgY?si=982837ccfa0a4df4",
        // },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: false,
      showVideo2: false,
      videoSrc: "static/yoyoma_video.mp4",
      videoAlign: "left",
      posterSrc: "yoyoma_poster.png",
      videoSrc1: "static/yoyoma_video.mp4",
      posterSrc1: "yoyoma_poster.png",
    },
    {
      ...defaultTalent,
      id: "carlosacosta",
      path: "carlos-acosta",
      avatar: "carlosacosta.png",
      hasImageSignature: false,
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
    },
    {
      ...defaultTalent,
      id: "mitsukouchida",
      path: "mitsuko-uchida",
      categoryColour: "gold",
      avatar: "mitsukouchida.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        // {
        //   id: 6,
        //   title: "mitsukouchida_listenTo1",
        //   player: "spotify",
        //   type: "track",
        //   src: "",
        // },
        // {
        //   id: 6,
        //   title: "mitsukouchida_listenTo2",
        //   player: "spotify",
        //   type: "track",
        //   src: "",
        // },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
    },
    {
      ...defaultTalent,
      id: "ladysmithblackmambazo",
      path: "ladysmith-black-mambazo",
      avatar: "ladysmithblackmambazo.png",
      hasQuoteImage: false,
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          id: 1,
          title: "ladysmithblackmambazo_listenTo1",
          player: "spotify",
          type: "track",
          src: "0sGbDq8dssHTaXryYykdEa?si=r7fTT2w-T8GMBdjFqMab8A&context=spotify:album:7E9IY8XpEVPycKNk5egsfF",
        },
        {
          id: 2,
          title: "ladysmithblackmambazo_listenTo2",
          player: "spotify",
          type: "track",
          src: "02K73bX64Gkj38GH7QSSTs?si=ee0d743e20a0404f",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
    },
    {
      ...defaultTalent,
      id: "kandacesprings",
      path: "kandace-springs",
      avatar: "kandacesprings.png",
      hasImageSignature: false,
      showAudioPlayer: true,
      audioSrc: [
        {
          id: 1,
          title: "kandacesprings_listenTo1",
          player: "spotify",
          type: "track",
          src: "7Mt4yp5VWlYlXiam5yn4t8",
        },
        {
          id: 2,
          title: "kandacesprings_listenTo2",
          player: "spotify",
          type: "track",
          src: "2rNVuESpnqDyCni2tfw4st",
        },
      ],
      hasArticle: false,
      articleData: {},
      hasProgrammeNotes: false,
      showProgrammeNotes: false,
      showVideo: false,
      videoSrc: "static/yoyoma_video.mp4",
      posterSrc: "yoyoma_poster.png",
    },
  ],
};

export const overrideContainerRatios = {
  "en-sg": {
    2023: {
      // shakespeare: 100,
    },
  },
};

const default2023Blends = {
  // introductions
  middleeasternart: [
    {
      backgroundColor: "FFD6AF",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 460 / 1032, // height / width,
    },
    {
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(5),
      src: "1.jpg",
      aspectRatio: 714 / 500, // height / width
    },
    {
      top: 20,
      width: largeColumns(3, 3),
      left: 0,
      src: "2.jpg",
      aspectRatio: 362 / 298, // height / width
    },
    {
      top: 5,
      width: largeColumns(3, 3),
      right: 0, // /!\ aligning to the right /!\
      src: "3.jpg",
      aspectRatio: 1.5,
    },
    {
      top: 55,
      width: largeColumns(3),
      right: largeColumns(1, 2), // /!\ aligning to the right /!\
      src: "4.jpg",
      aspectRatio: 428 / 300, // height / width
    },
  ],
  psychoanalysis: [
    {
      backgroundColor: "FBE5DE",
      centerVertically: true,
      centerHorizontally: true,
      // top: 10, // used in combination with `centerVertically` for offset
      width: largeColumns(10),
      aspectRatio: 460 / 1032, // height / width,
    },
    {
      centerVertically: true,
      centerHorizontally: true,
      top: 10, // used in combination with `centerVertically` for offset
      width: largeColumns(4, 4),
      src: "1.jpg",
      aspectRatio: 567 / 420, // height / width
    },
    {
      top: 17.25,
      width: largeColumns(4.5, 4),
      left: 0,
      src: "2.jpg",
      aspectRatio: 1, // square
    },
    {
      top: 19.5,
      width: largeColumns(4.5, 4),
      right: 0.1, // /!\ aligning to the right /!\
      src: "3.jpg",
      aspectRatio: 627 / 470, // height / width
    },
    // {
    //   top: 55,
    //   width: largeColumns(5),
    //   right: largeColumns(0, 1), // /!\ aligning to the right /!\
    //   src: "4.jpg",
    //   aspectRatio: 596 / 504, // height / width
    // },
  ],
  ceremonialjapan: null,
  shakespeare: [
    {
      backgroundColor: "F6E39A",
      centerVertically: true,
      // centerHorizontally: true,
      left: largeColumns(0.9),
      width: largeColumns(9.95),
      aspectRatio: 458.54 / 1033.67, // height / width,
    },
    {
      centerVertically: true,
      width: largeColumns(5.25, 6),
      left: largeColumns(2.8, 2),
      src: "1.jpg",
      aspectRatio: 573.52 / 570, // height / width
    },
    {
      top: 15.05,
      width: largeColumns(3.15),
      left: largeColumns(0.475),
      src: "2.jpg",
      aspectRatio: 470.55 / 314, // height / width
    },
    {
      top: 10.75,
      width: largeColumns(4, 4),
      right: largeColumns(0.285, 0), // /!\ aligning to the right /!\
      src: "3.jpg",
      aspectRatio: 482.92 / 409.38, // height / width
    },
    {
      top: 49.33,
      width: largeColumns(4.25, 4),
      right: largeColumns(1.05, 1), // /!\ aligning to the right /!\
      src: "4.jpg",
      aspectRatio: 286.12 / 441.09, // height / width
    },
  ],
  selflove: [
    {
      backgroundColor: "E2E5EE",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 460 / 1032, // height / width,
    },
    {
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(5),
      src: "1.jpg",
      aspectRatio: 781 / 593, // height / width
    },
    {
      top: 30,
      width: largeColumns(5),
      left: 0,
      src: "2.jpg",
      aspectRatio: 1, // square
    },
    {
      top: 10,
      width: largeColumns(4.5, 4),
      right: 0, // /!\ aligning to the right /!\
      src: "3.jpg",
      aspectRatio: 627 / 470, // height / width
    },
  ],
  // oceans: [
  //   {
  //     backgroundColor: "C2DEFF",
  //     centerVertically: true,
  //     centerHorizontally: true,
  //     width: largeColumns(10),
  //     aspectRatio: 460 / 1032, // height / width,
  //   },
  //   {
  //     centerVertically: true,
  //     centerHorizontally: true,
  //     width: largeColumns(8, 8),
  //     src: "1.jpg",
  //     aspectRatio: 559 / 839, // height / width
  //   },
  //   {
  //     top: 35.25,
  //     width: largeColumns(4, 4),
  //     left: largeColumns(0.65),
  //     src: "2.jpg",
  //     aspectRatio: 280 / 420, // height / width
  //   },
  //   {
  //     top: 45,
  //     width: largeColumns(6.5, 6),
  //     left: 0,
  //     src: "3.jpg",
  //     aspectRatio: 452 / 679, // height / width
  //   },
  //   {
  //     top: 8,
  //     width: largeColumns(5, 6),
  //     right: largeColumns(1, 1), // /!\ aligning to the right /!\
  //     src: "4.jpg",
  //     aspectRatio: 1, // square
  //   },
  //   {
  //     top: 56,
  //     width: largeColumns(4.5, 4),
  //     right: 0, // /!\ aligning to the right /!\
  //     src: "6.jpg",
  //     aspectRatio: 254 / 481, // height / width
  //   },
  // ],
  finewine: null,
  africanart: [
    {
      backgroundColor: "FFE1C9",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 459 / 1031, // height / width,
    },
    {
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(5),
      src: "1.jpg",
      aspectRatio: 339 / 509, // height / width
    },
    {
      top: 27.5,
      width: largeColumns(4),
      left: 0,
      src: "2.jpg",
      aspectRatio: 505 / 402, // height / width
    },
    {
      top: 15,
      width: largeColumns(4),
      right: 0, // /!\ aligning to the right /!\
      src: "3.jpg",
      aspectRatio: 526 / 392, // height / width
    },
  ],

  // performances
  chuchovaldes: [
    {
      backgroundColor: "FDCCAA",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 460 / 1032, // height / width,
    },
    {
      top: 15,
      centerHorizontally: true,
      width: largeColumns(7, 7),
      src: "1.jpg",
      aspectRatio: 1, // square
    },
    {
      top: 20,
      width: largeColumns(4, 4),
      left: 0,
      src: "2.jpg",
      aspectRatio: 286 / 426, // height / width
    },
    {
      top: 55,
      width: largeColumns(4, 4),
      left: largeColumns(1.5, 1),
      src: "3.jpg",
      aspectRatio: 286 / 428, // height / width
    },
    {
      top: 40,
      width: largeColumns(5, 5),
      right: 0, // /!\ aligning to the right /!\
      src: "4.jpg",
      aspectRatio: 1, // square
    },
    // {
    //   top: 65,
    //   width: largeColumns(4.5, 4),
    //   right: 0, // /!\ aligning to the right /!\
    //   src: "6.jpg",
    //   aspectRatio: 254 / 481, // height / width
    // },
  ],
  alvinailey: [
    {
      backgroundColor: "FFCBD8",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 460 / 1032, // height / width,
    },
    {
      centerVertically: true,
      width: largeColumns(5, 5),
      left: largeColumns(4),
      src: "1.jpg",
      aspectRatio: 775 / 500, // height / width
    },
    {
      top: 31.5,
      width: largeColumns(4),
      right: 0, // /!\ aligning to the right /!\
      src: "2.jpg",
      aspectRatio: 490 / 398, // height / width
    },
    {
      top: 21,
      width: largeColumns(3),
      left: 0,
      src: "3.jpg",
      aspectRatio: 366 / 298, // height / width
    },
    {
      top: 41,
      width: largeColumns(3),
      left: largeColumns(1.5, 0),
      src: "4.jpg",
      aspectRatio: 362 / 298, // height / width
    },
  ],
  aakashodedra: null,
  lemepris: [
    {
      backgroundColor: "DCEBDD",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 460 / 1032, // height / width,
    },
    {
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(5),
      src: "1.jpg",
      aspectRatio: 694 / 520, // height / width
    },
    {
      top: 40,
      width: largeColumns(4, 4),
      left: 0,
      src: "2an.jpg",
      aspectRatio: 660 / 902, // 315 / 420, // height / width
    },
    {
      top: 25,
      width: largeColumns(4, 4),
      right: 0, // /!\ aligning to the right /!\
      src: "3an.jpg",
      aspectRatio: 662 / 902, // 276 / 420, // height / width
    },
  ],
  johnlegend: [
    {
      backgroundColor: "FFCBB7",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 460 / 1032, // height / width,
    },
    {
      top: 5,
      centerHorizontally: true,
      width: largeColumns(4, 5),
      src: "1.jpg",
      aspectRatio: 638 / 450, // height / width
    },
    {
      top: 25,
      width: largeColumns(4, 5),
      left: 0,
      src: "2.jpg",
      aspectRatio: 615 / 440, // height / width
    },
    {
      top: 20,
      width: largeColumns(4, 5),
      right: 0, // /!\ aligning to the right /!\
      src: "3.jpg",
      aspectRatio: 602 / 450, // height / width
    },
  ],
  bebelgilberto: [
    {
      backgroundColor: "FFEBC6",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 460 / 1032, // height / width,
    },
    {
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(5),
      src: "1.jpg",
      aspectRatio: 694 / 520, // height / width
    },
    {
      top: 40,
      width: largeColumns(4, 4),
      left: 0,
      src: "2.jpg",
      aspectRatio: 315 / 420, // height / width
    },
    {
      top: 25,
      width: largeColumns(4, 4),
      right: 0, // /!\ aligning to the right /!\
      src: "3.jpg",
      aspectRatio: 276 / 420, // height / width
    },
  ],
  reneefleming: [
    {
      backgroundColor: "D7DBBE",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 460 / 1032, // height / width,
    },
    {
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(5, 6),
      src: "1.jpg",
      aspectRatio: 772 / 545, // height / width
    },
    {
      top: 20,
      width: largeColumns(5, 5),
      left: 0,
      src: "2.jpg",
      aspectRatio: 1, // square
    },
    {
      top: 5,
      width: largeColumns(4.5, 4.5),
      right: 0, // /!\ aligning to the right /!\
      src: "3.jpg",
      aspectRatio: 577 / 399, // height / width
    },
  ],
  yoyoma: null,
};

const default2024Blends = {
  photography: [
    {
      backgroundColor: "E5DDD4",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 371.37 / 1032, // height / width,
    },
    {
      left: 7,
      top: 30,
      width: largeColumns(3, 3), //first arg is the offset to the left ( 4 columns ) second arg is the width ( 5 columns ) (edited)
      src: "1.webp",
      aspectRatio: 244 / 334, // height / width
    },
    {
      width: largeColumns(4.5, 5),
      centerHorizontally: true,
      centerVertically: true,
      src: "2.webp",
      aspectRatio: 509 / 428, // height / width
    },
    {
      top: 27,
      width: largeColumns(2, 3),
      right: 10,
      src: "3.webp",
      aspectRatio: 252.02 / 220, // height / width
    },
    {
      top: 50,
      width: largeColumns(2, 3),
      left: 10,
      src: "4.webp",
      aspectRatio: 187.86 / 202, // height / width
    },
    {
      top: 51,
      width: largeColumns(3.5, 2),
      right: 6,
      src: "5.webp",
      aspectRatio: 276 / 402, // height / width
    },
  ],
  southeastasianart: [
    {
      backgroundColor: "FFE5DF",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 400 / 1032, // height / width,
    },
    {
      left: 7,
      top: 20,
      width: largeColumns(3, 3), //first arg is the offset to the left ( 4 columns ) second arg is the width ( 5 columns ) (edited)
      src: "1.webp",
      aspectRatio: 314 / 314, // height / width
    },
    {
      width: largeColumns(3.5, 5),
      centerHorizontally: true,
      centerVertically: true,
      src: "2.webp",
      aspectRatio: 560 / 372, // height / width
    },
    {
      top: 27,
      width: largeColumns(3.5, 2),
      right: 5,
      src: "3.webp",
      aspectRatio: 296 / 347, // height / width
    },
    {
      top: 50,
      width: largeColumns(2, 3),
      left: 14,
      src: "4.webp",
      aspectRatio: 204 / 204, // height / width
    },
    {
      top: 52,
      width: largeColumns(2.5, 3),
      right: 10,
      src: "5.webp",
      aspectRatio: 215.03 / 278.74, // height / width
    },
  ],
  finejewellery: [
    {
      backgroundColor: "CCE1E1",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 400 / 1032, // height / width,
    },
    {
      left: 14,
      top: 29,
      width: largeColumns(2, 3), //first arg is the offset to the left ( 4 columns ) second arg is the width ( 5 columns ) (edited)
      src: "1.webp",
      aspectRatio: 197 / 197, // height / width
    },
    {
      width: largeColumns(4.5, 5),
      centerHorizontally: true,
      centerVertically: true,
      src: "2.webp",
      aspectRatio: 480 / 480, // height / width
    },
    {
      top: 24,
      width: largeColumns(2.5, 3),
      right: 5,
      src: "3.webp",
      aspectRatio: 381.53 / 276, // height / width
    },
    {
      top: 42,
      width: largeColumns(2.6, 3),
      left: 5,
      src: "4.webp",
      aspectRatio: 346 / 276, // height / width
    },
    {
      top: 52,
      width: largeColumns(2, 3),
      right: 15,
      src: "5.webp",
      aspectRatio: 228 / 228, // height / width
    },
  ],
  italianfoodandwine: [
    {
      backgroundColor: "FFE6C3",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 408.1 / 1032, // height / width,
    },
    {
      left: 18.25,
      top: 19.75,
      width: largeColumns(1.975), //first arg is the offset to the left ( 4 columns ) second arg is the width ( 5 columns ) (edited)
      src: "1.webp",
      aspectRatio: 274.94 / 189, // height / width
    },
    {
      width: largeColumns(3.475, 4),
      centerHorizontally: true,
      centerVertically: true,
      src: "2.webp",
      aspectRatio: 581 / 380, // 581.88 / 380, // height / width
    },
    {
      top: 18.725,
      width: largeColumns(3, 3.425),
      right: 7.325,
      src: "3.webp",
      aspectRatio: 275.47 / 326, // height / width
    },
    {
      top: 43.75,
      width: largeColumns(3.1, 3),
      left: 7.25,
      src: "4.webp",
      aspectRatio: 382.92 / 326, // height / width
    },
    {
      top: 43.925,
      width: largeColumns(2, 1),
      right: 18,
      src: "5.webp",
      aspectRatio: 275 / 190, // height / width
    },
  ],
  // ...
  // performances
  johnlegend: [
    {
      backgroundColor: "FFD2DC",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 400 / 1000, // height / width,
    },
    {
      top: 22,
      left: 0,
      width: largeColumns(5, 5), //first arg is the offset to the left ( 4 columns ) second arg is the width ( 5 columns ) (edited)
      src: "1.webp",
      aspectRatio: 540 / 540, // height / width
    },
    {
      top: 27,
      width: largeColumns(3.5, 5),
      centerHorizontally: true,
      rotate: "-15deg",
      src: "2.webp",
      aspectRatio: 234 / 406, // height / width
    },
    {
      top: 45,
      width: largeColumns(3, 3),
      left: 40,
      src: "3.webp",
      aspectRatio: 202.15 / 269, // height / width
    },
    {
      top: 33,
      width: largeColumns(3.5, 5),
      right: 5, // /!\ aligning to the right /!\
      src: "4.webp",
      aspectRatio: 400 / 400, // height / width
    },
  ],
  marisamonte: [
    {
      backgroundColor: "FAEBFF",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 360 / 1032, // height / width,
    },
    {
      top: 27,
      left: 5,
      width: largeColumns(3, 3), //first arg is the offset to the left ( 4 columns ) second arg is the width ( 5 columns ) (edited)
      src: "1.webp",
      aspectRatio: 424 / 310, // height / width
    },
    {
      top: 24,
      width: largeColumns(5, 5),
      centerHorizontally: true,
      src: "2.webp",
      aspectRatio: 490 / 540, // height / width
    },
    {
      width: largeColumns(3, 3),
      centerVertically: true,
      right: 5,
      src: "3.webp",
      aspectRatio: 202.15 / 269, // height / width
    },
  ],

  carlosacosta: [
    {
      backgroundColor: "C1E2E9",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 380 / 1032, // height / width,
    },
    {
      left: 5,
      centerVertically: true,
      width: largeColumns(3, 3), //first arg is the offset to the left ( 4 columns ) second arg is the width ( 5 columns ) (edited)
      src: "1.webp",
      aspectRatio: 384 / 384, // height / width
    },
    {
      top: 24,
      width: largeColumns(5, 5),
      centerHorizontally: true,
      src: "2.webp",
      aspectRatio: 576 / 576, // height / width
    },
    {
      top: 40,
      width: largeColumns(3, 3),
      right: 5,
      src: "3.webp",
      aspectRatio: 228 / 228, // height / width
    },
  ],
  kandacesprings: [
    {
      backgroundColor: "BFE2E2",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 395 / 1032, // height / width,
    },
    {
      left: 5,
      centerVertically: true,
      width: largeColumns(3, 3), //first arg is the offset to the left ( 4 columns ) second arg is the width ( 5 columns ) (edited)
      src: "1.webp",
      aspectRatio: 314.02 / 318, // height / width
    },
    {
      width: largeColumns(5, 5),
      centerHorizontally: true,
      centerVertically: true,
      src: "2.webp",
      aspectRatio: 632 / 427.47, // height / width
    },
    {
      top: 25,
      width: largeColumns(3.5, 3),
      right: 6,
      src: "3.webp",
      aspectRatio: 265 / 400, // height / width
    },
    {
      top: 50,
      width: largeColumns(3, 3),
      right: 5,
      src: "4.webp",
      aspectRatio: 239.47 / 320, // height / width
    },
  ],
  ladysmithblackmambazo: [
    {
      backgroundColor: "DAE2CE",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 400 / 1032, // height / width,
    },
    {
      left: 7,
      centerVertically: true,
      width: largeColumns(3, 3), //first arg is the offset to the left ( 4 columns ) second arg is the width ( 5 columns ) (edited)
      src: "1.webp",
      aspectRatio: 274 / 360, // height / width
    },
    {
      width: largeColumns(4.5, 5),
      centerHorizontally: true,
      centerVertically: true,
      src: "2.webp",
      aspectRatio: 432 / 432, // height / width
    },
    {
      top: 26,
      width: largeColumns(3, 3),
      right: 7,
      src: "3.webp",
      aspectRatio: 242.53 / 365, // height / width
    },
    {
      top: 45,
      width: largeColumns(2.3, 2),
      right: 10,
      src: "4.webp",
      aspectRatio: 241 / 241, // height / width
    },
  ],
  mitsukouchida: [
    {
      background: "linear-gradient(#F0D3B7, #F2EADE)",
      centerVertically: true,
      centerHorizontally: true,
      width: largeColumns(10),
      aspectRatio: 400 / 1032, // height / width,
    },
    {
      left: 7,
      centerVertically: true,
      width: largeColumns(3, 3), //first arg is the offset to the left ( 4 columns ) second arg is the width ( 5 columns ) (edited)
      src: "1.webp",
      aspectRatio: 229.63 / 391, // height / width
    },
    {
      width: largeColumns(5, 5),
      centerHorizontally: true,
      centerVertically: true,
      src: "2.webp",
      aspectRatio: 542 / 542, // height / width
    },
    {
      top: 30,
      width: largeColumns(2.1, 5),
      right: 8,
      src: "3.webp",
      aspectRatio: 224 / 224, // height / width
    },
    {
      top: 52,
      width: largeColumns(2, 2),
      left: 13,
      src: "4.webp",
      aspectRatio: 224 / 224, // height / width
    },
  ],
};

export const blends = (localeName, season, talent) => {
  const defaults = {
    2023: default2023Blends,
    2024: default2024Blends,
  };

  const config = {
    2023: {
      "en-gl": {
        // Override
        ...default2023Blends,
        ...{
          lemepris: [
            {
              backgroundColor: "DCEBDD",
              centerVertically: true,
              centerHorizontally: true,
              width: largeColumns(10, 9),
              aspectRatio: 460 / 1034, // height / width,
            },
            {
              top: 34,
              width: largeColumns(6.5, 6),
              left: 2.8,
              rotate: "-8deg",
              src: "2an.jpg",
              aspectRatio: 660 / 902, // height / width
            },
            {
              top: 16,
              width: largeColumns(6.5, 6),
              right: 3, // /!\ aligning to the right /!\
              rotate: "9deg",
              src: "3an.jpg",
              aspectRatio: 662 / 902, // height / width
            },
          ],
        },
      },
    },
    2024: {
      // 2024 overrides go here
    },
  };

  return config[season]?.[localeName]?.[talent] || defaults[season]?.[talent];
};

export default artists;
