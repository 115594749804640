import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import style from "./SlideNotes.module.scss";

function formattedText(prefix, id, rootModule, intl) {
  const text = intl.formatMessage({
    id: `${rootModule}.${prefix}${id}Text`
  });
  return <div dangerouslySetInnerHTML={{__html: text}} />
}

const SlideNotes = ({
  slide: { id, hasMask } = {},
  isMaskVisible,
  inverted,
  prefix,
  rootModule,
}) => {
  const intl = useIntl();
  const title = intl.messages[`${rootModule}.${prefix}${id}Title`];
  const text = intl.messages[`${rootModule}.${prefix}${id}Text`];

  const notesWrapperEl = useRef(null);
  const notesEl = useRef(null);
  const [notesOverflowing, setNotesOverflowing] = useState(false);

  useEffect(() => {
    setNotesOverflowing(
      notesEl.current.scrollHeight > notesEl.current.clientHeight
    );
  }, [id]);

  useEffect(() => {
    function scrollFocus() {
      window.addEventListener("keydown", (e) => {
        const down =
          e.key === "ArrowDown" ||
          e.keyCode === 40 ||
          e.key === " " ||
          e.keyCode === 32;
        const up = e.key === "ArrowUp" || e.keyCode === 38;

        if (down) {
          e.preventDefault();
          scrollDown();
        }

        if (up) {
          e.preventDefault();
          scrollUp();
        }
      });
    }
    if (!(notesWrapperEl && notesWrapperEl.current && notesOverflowing)) {
      return;
    }
    const el = notesWrapperEl.current;
    el.current.addEventListener("focus", scrollFocus);
    el.current.addEventListener("blur", () => {
      notesWrapperEl.el.removeEventListener("focus", scrollFocus);
    });

    return () => {
      el.removeEventListener("focus", scrollFocus);
    };
  }, [notesOverflowing]);

  function scrollUp() {
    if (notesEl.current.scrollTop > 0) {
      notesEl.current.scrollTo({
        top: notesEl.current.scrollTop - 200,
        behavior: "smooth",
      });
    }
  }

  function scrollDown() {
    if (
      notesEl.current.scrollTop <
      notesEl.current.scrollHeight - notesEl.current.clientHeight
    ) {
      notesEl.current.scrollTo({
        top: notesEl.current.scrollTop + 200,
        behavior: "smooth",
      });
    }
  }

  return (
    <div
      ref={notesWrapperEl}
      tabIndex={notesOverflowing ? 0 : -1}
      className={`${style.notes} ${notesOverflowing && style.overflowing} ${
        hasMask && isMaskVisible && style.hasQuote
      } ${inverted ? style.wrapperInverted : ""}`}
    >
      {notesOverflowing && (
        <div className={style.scrollButtons}>
          <button onClick={scrollUp}>
            <span className="dls-icon-up-filled icon icon-sm" />
          </button>
          <button onClick={scrollDown}>
            <span className="dls-icon-down-filled icon icon-sm" />
          </button>
        </div>
      )}
      <div className={`${style.wrapper}`} ref={notesEl}>
        <h3 className={`heading-4 ${style.title} bentonsansthin`}>
          {title && (
            <FormattedMessage
              id={`${rootModule}.${prefix}${id}Title`}
              values={{ lineBreak: <br />, cite: (str) => <cite>{str}</cite> }}
            />
          )}
        </h3>
        <div className={`body-1 ${style.text} bentonsansthin`}>
          {text && formattedText(prefix, id, rootModule, intl)}
        </div>
      </div>
    </div>
  );
};

SlideNotes.propTypes = {
  slide: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  isMaskVisible: PropTypes.bool,
  inverted: PropTypes.bool,
  rootModule: PropTypes.string.isRequired,
};

SlideNotes.defaultProps = {
  prefix: "slide",
  inverted: false,
};

export default SlideNotes;
