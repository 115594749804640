import React from 'react';
import PropTypes from 'prop-types';
import style from './CarouselSlide.module.scss';

const CarouselSlide = ({ slide }) => (
  <div className={`${style.slide}`} role="group" aira-roledescription="slide" aria-label={slide.title}>
    { slide.title && (
      <h3 className={`${style.title} bentonsanslight`}>{slide.title}</h3>
    )}
    <p className={`${style.slideText} bentonsansthin`}>{ slide.text }</p>
  </div>
);

CarouselSlide.propTypes = {
  slide: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default CarouselSlide;
