import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { animated, useSpring, useTrail } from '@react-spring/web';
import livingImages from "../../static/living/livingImages";
import ImageContainer from "../ImageContainer/ImageContainer";
import LineSet from "../LineSet/LineSet";
import style from "./FeatureLiving.module.scss";

const FeatureLiving = () => {
  const intl = useIntl();
  const params = useParams();
  const images = livingImages();

  const [ref, inView] = useInView();

  const springs = useTrail(images.length, {
    from: {
      opacity: 0,
      transform: inView ? "translate3d(0,100px,0)" : "translate3d(0,0px,0)",
    },
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translate3d(0,0,0)" : "translate3d(0,100px,0)",
    },
  });

  const [moreRef, moreInView] = useInView({ triggerOnce: true });
  const animMore = useSpring({
    from: {
      opacity: moreInView ? 0 : 1,
      transform: moreInView ? "translate3d(0,32px,0)" : "translate3d(0,0px,0)",
    },
    to: {
      opacity: moreInView ? 1 : 0,
      transform: moreInView ? "translate3d(0,0,0)" : "translate3d(0,32px,0)",
    },
  });

  return (
    <div className={style.wrapper}>
      <div className="row">
        <div className="col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3">
          <p
            className={`heading-5 ${style.blurb} text-justify bentonsansthin`}
            data-localeid="livingBlurb"
          >
            <LineSet
              id={`home.livingBlurb`}
              desktop={intl.locale !== "en-US" ? [12, 10, 11] : []}
              tablet={[]}
              mobile={[]}
              values={{
                em: (str) => <em>{str}</em>,
                bold: (str) => <>{str}</>,
                lineBreak: <br />,
                livingLink: <FormattedMessage id={`home.livingTitle`} />,
              }}
            />
          </p>
        </div>
      </div>
      <div className={style.images}>
        <div ref={ref} className="flex-no-wrap row">
          {springs.map((props, i) => (
            <animated.div
              key={images[i].src}
              className="col"
              style={{
                flex: `0 0 ${images[i].width}`,
                maxWidth: images[i].width,
                padding: `${images[i].top} 0 0`,
                right: images[i].right,
                mixBlendMode: images[i].mixBlendMode,
                transitionTimingFunction: "ease-out",
                ...props,
              }}
            >
              <ImageContainer
                imageStyle={{}}
                ratio={images[i].ratio}
                src={images[i].src}
              />
            </animated.div>
          ))}
        </div>
      </div>
      <div ref={moreRef} className="text-align-center">
        <animated.div className="text-align-center" style={animMore}>
          <Link
            className={`btn btn-centurion-black moreButton bentonsanslight`}
            to={{
              pathname: `/${params.locale}/${params.card}/${params.centurion}/living`,
            }}
          >
            <FormattedMessage id={`home.findOutMore`} />
          </Link>
        </animated.div>
      </div>
    </div>
  );
};

export default FeatureLiving;
