import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import style from "./MarketPrivacy.module.scss";

const MarketPrivacy = () => {
  const intl = useIntl();
  const marketLocale = intl.locale.toLowerCase();
  const [market, setMarket] = useState("");
  const marketOptions = [
    [
      {
        val: "",
        text: intl.formatMessage({ id: "privacy.pleaseSelect" }),
      },
    ],
    [
      {
        market: "belgium",
        val: "https://www.americanexpress.com/be/fr/service-clientele/privacy-policy.html",
        text: intl.formatMessage({ id: "privacy.belgium" }),
      },
      {
        market: "germany",
        val: "https://www.americanexpress.com/de-de/firma/legal/datenschutz-center/online-datenschutzerklarung/?inav=de_legalfooter_dataprivacy",
        text: intl.formatMessage({ id: "privacy.germany" }),
      },
      {
        market: "austria",
        val: "https://www.americanexpress.com/amex/de-at/firma/legal/datenschutz-center/?inav=at_legalfooter_privacycentre",
        text: intl.formatMessage({ id: "privacy.austria" }),
      },
      {
        market: "finland",
        val: "https://www.americanexpress.com/fi/legal/yksityisyys/?inav=fi_footer_about_privacy",
        text: intl.formatMessage({ id: "privacy.finland" }),
      },
      {
        market: "hongkong",
        val: "https://www.americanexpress.com/en-hk/company/legal/privacy-centre/internet-privacy-statement/?inav=hk_footer_privacy",
        text: intl.formatMessage({ id: "privacy.hongkong" }),
      },
      {
        market: "italy",
        val: "https://www.americanexpress.com/it-it/chi-siamo/legale/centro-di-privacy/dichiarazione-sulla-privacy/",
        text: intl.formatMessage({ id: "privacy.italy" }),
      },
      {
        market: "netherlands",
        val: "https://www.americanexpress.com/nl-nl/bedrijf/legaal/privacy-centrum/online-privacyverklaring/",
        text: intl.formatMessage({ id: "privacy.netherlands" }),
      },
      {
        market: "norway",
        val: "https://www.americanexpress.com/no/legal/personvern/?inav=no_footer_about_privacy",
        text: intl.formatMessage({ id: "privacy.norway" }),
      },
      {
        market: "sweden",
        val: "https://www.americanexpress.com/se/legal/sekretess/sekretesspolicy/index.html?intlink=se-se-privacy-privacypolicy",
        text: intl.formatMessage({ id: "privacy.sweden" }),
      },
      {
        market: "switzerland",
        val: "https://www.americanexpress.com/us/company/privacy-center/online-privacy-disclosures/",
        text: intl.formatMessage({ id: "privacy.switzerland" }),
      },
      {
        market: "saudiarabia",
        val: "https://www.americanexpress.com/us/company/privacy-center/online-privacy-disclosures/",
        text: intl.formatMessage({ id: "privacy.saudiarabia" }),
      },
      {
        market: "middleeast",
        val: "https://www.americanexpress.com/us/company/privacy-center/online-privacy-disclosures/",
        text: intl.formatMessage({ id: "privacy.middleeast" }),
      },
      {
        market: "france",
        val: "https://www.americanexpress.com/fr/legal/politique-de-protection-des-donnees-personnelles.html",
        text: intl.formatMessage({ id: "privacy.france" }),
      },
      {
        market: "singapore",
        val: "https://www.americanexpress.com/en-sg/company/legal/privacy-centre/online-privacy-statement/index.html?inav=sg_legalfooter_privacy",
        text: intl.formatMessage({ id: "privacy.singapore" }),
      },
      {
        market: "japan",
        val: "https://www.americanexpress.com/ja-jp/company/legal/privacy-centre/online-privacy-statement/index.html",
        text: intl.formatMessage({ id: "privacy.japan" }),
      },
      {
        market: "uk",
        val: "https://www.americanexpress.com/en-gb/company/legal/privacy-centre/online-privacy-statement/#q-7",
        text: intl.formatMessage({ id: "privacy.uk" }),
      },
      {
        market: "au",
        val: "https://www.americanexpress.com/content/dam/amex/au/about-us/disclosures/AmericanExpressAustralia-PrivacyPolicy_2022.pdf",
        text: intl.formatMessage({ id: "privacy.au" }),
      },
      // {
      //   market: "idcEN",
      //   val: "https://www.americanexpress.com/idc/en/company/legal/amex-experiences/terms-and-conditions/index.html",
      //   text: intl.formatMessage({ id: "privacy.idcEN" }),
      // },
      // {
      //   market: "idcES",
      //   val: "https://www.americanexpress.com/idc/es/empresa/legal/amex-experiences/terminos-y-condiciones/index.html",
      //   text: intl.formatMessage({ id: "privacy.idcES" }),
      // },
    ].sort((a, b) => (a.text > b.text ? 1 : -1)),
  ].flat();

  const filteredOptions = marketOptions.filter(
    (opt) => !opt.hiddenFor || !opt.hiddenFor.includes(marketLocale)
  );

  function handleChange(event) {
    const market = event.target.value;
    const marketData = marketOptions.find((d) => d.market === market);
    const val = (marketData && marketData.market) || market;
    setMarket(val);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (market) {
      const marketData = marketOptions.find((d) => d.market === market);
      if (marketData && marketData.val) {
        window.location.href = marketData.val;
      }
    }
  }

  return (
    <div className="container position-relative">
      {/* Back button */}
      {/* <div className={style.backButton}>
        <Link
          className={`${style.backLink} flex flex-align-items-center`}
          to={{ pathname: `/${params.locale}/${staticParams}` }}
        >
          <span className="backLinkIcon icon dls-icon-left" />
          <span className={style.backText}>
            <FormattedMessage id="back" />
          </span>
        </Link>
      </div> */}
      <h1
        className={`${style.marketsTitle} bigcaslonregular col-md-10 col-md-offset-1`}
        data-localeid="marketsTitle"
      >
        <span>
          <FormattedMessage id="privacy.marketsTitle" />
        </span>

        <span>
          <FormattedMessage id="privacy.marketsTitleTranslation1" />
        </span>

        <span>
          <FormattedMessage id="privacy.marketsTitleTranslation2" />
        </span>

        <span>
          <FormattedMessage id="privacy.marketsTitleTranslation3" />
        </span>

        <span>
          <FormattedMessage id="privacy.marketsTitleTranslation4" />
        </span>

        <span>
          <FormattedMessage id="privacy.marketsTitleTranslation5" />
        </span>

        <span>
          <FormattedMessage id="privacy.marketsTitleTranslation6" />
        </span>
      </h1>
      <form
        className={`flex flex-align-items-center flex-justify-center ${style.marketForm}`}
        onSubmit={handleSubmit}
      >
        <fieldset>
          <div className="position-relative">
            <label
              className="sr-only"
              data-localeid="marketsTitle"
              htmlFor="market"
            >
              <FormattedMessage id="privacy.marketsTitle" />
            </label>
            <select
              className={`${style.marketSelector} bentonsanslight`}
              value={market}
              name="market"
              onChange={handleChange}
            >
              {filteredOptions.map((opt) => (
                <option value={opt.market} key={opt.text}>
                  {opt.text}
                </option>
              ))}
            </select>
            <div className={`position-absolute ${style.selectChevronWrapper}`}>
              <span className={`icon dls-icon-down ${style.selectChevron}`} />
            </div>
          </div>
        </fieldset>
        <input
          type="submit"
          className={`${style.goButton} bentonsanslight`}
          value={intl.formatMessage({ id: "privacy.go" })}
        />
      </form>
    </div>
  );
};

export default MarketPrivacy;
