import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import debounce from "lodash.debounce";
import { getConfigForEnv } from "../../appConfig";
// import { useHistory } from "react-router-dom";
import style from "./LangSelect.module.scss";
// import { current } from "@reduxjs/toolkit";

const Select = ({ formats, locale, module }) => {
  // const history = useHistory();
  const [lang, setLang] = useState(locale);

  // console.log('LangSelect:', module);

  const defaultValue = formats.filter(({ val }) => val === locale).length
    ? locale
    : "en-gb";

  const handleSelectChange = ({ target: { value } }) => {
    setLang(value);
  };

  const calcPadding = () => {
    if (module === "howtowear") {
      if (locale.toLocaleLowerCase() === "it-it") {
        return "26px";
      } else {
        return "32px";
      }
    } else {
      return "13px";
    }
  };

  useEffect(() => {
    const href = window.location.href.split("/");
    // "/:locale?/:card?/:centurion?/:page?/:subPage?/:pageId?/:id?"
    const currentLang = href[3];
    const page = href[6];
    const subPage = href[7] || null;
    const pageId = href[8] || null;
    const id = href[9] || null;

    if (currentLang === lang) {
      return;
    }

    // handle variations to en-GB paths
    const { langCard, langCenturion } =
      {
        // "de-at": {
        //   langCard: "vorteile",
        //   langCenturion: "neue-centurion",
        // },
        "de-de": {
          langCard: "vorteile",
          langCenturion: "neue-centurion",
        },
        "it-it": {
          langCard: "benefit",
          langCenturion: "nuova-centurion",
        },
      }[lang] || {};

    const baseUrl =
      `${lang || "en-gb"}` +
      `/${langCard || "benefits"}` +
      `/${langCenturion || "new-centurion"}`;

    window.location.href = `/${baseUrl}${
      page
        ? `/${page}${subPage ? `/${subPage}${pageId ? `/${pageId}${id ? `/${id}` : ""}` : ""}` : ""}`
        : ""
    }`;
  }, [lang]);

  return (
    <select
      style={{ paddingRight: lang === "zh-hk" ? "20px" : calcPadding() }}
      className={`${style.select} bentonsansthin`}
      defaultValue={defaultValue}
      onChange={handleSelectChange}
    >
      {formats.map(({ val, display }) => (
        <option key={val} value={val}>
          {display}
        </option>
      ))}
    </select>
  );
};

const LangSelect = ({ module }) => {
  const { locale } = useIntl();

  const [fade, setFade] = useState(false);

  useEffect(() => {
    const debounced = debounce(
      (e) => {
        setFade(window.scrollY > 0);
      },
      50,
      { maxWait: 100 }
    );

    window.addEventListener("scroll", debounced);
    return () => {
      window.removeEventListener("scroll", debounced);
    };
  }, []);

  const isHowToWear = module === "howtowear";

  const formats = isHowToWear
    ? [
        { val: "es-es", display: "ES" },
        { val: "es-id", display: "IDC (ES)" },
        { val: "de-at", display: "DE" },
        { val: "en-gb", display: "EN" },
        // { val: "it-it", display: "IT" },
        // { val: "fr-fr", display: "FR" },
        { val: "zh-tw", display: "中文" },
        { val: "ja-jp", display: "日本" },
      ]
    : [
        { val: "en-hk", display: "EN" },
        { val: "zh-hk", display: "中文" },
      ];

  const disabled = isHowToWear ? false : !["en-HK", "zh-HK"].includes(locale);

  const { locales } = getConfigForEnv();

  const filterFormats = () => {
    return formats.filter((item) => {
      const split = item.val.split("-");
      return locales.includes(`${split[0]}-${split[1].toUpperCase()}`);
    });
  };

  if (disabled) {
    return null;
  }

  const contents = isHowToWear ? (
    <Select
      formats={filterFormats()}
      locale={locale.toLocaleLowerCase()}
      module={module}
    />
  ) : (
    <Select
      formats={filterFormats()}
      locale={locale.toLocaleLowerCase()}
      module={module}
    />
  );

  return (
    <div
      className={`${style.langSelect} ${fade ? style.fade : ""} ${
        style[module]
      }`}
    >
      {contents}
    </div>
  );
};

LangSelect.propTypes = {
  module: PropTypes.string.isRequired,
};

export default LangSelect;
