import React, { useEffect } from "react";
import gsap from "gsap";
import style from "./ChevronDown.module.scss";

const ChevronDown = ({ onClick, id }) => {
  useEffect(() => {
    const chevron = document.querySelector(`[data-chevron="${id}"]`);

    const tl = gsap.timeline({ defaults: {duration:0.25}, paused: true });

    tl.to(`[data-chevron="${id}"] .path`, {
      attr: { d:"M 7, 0, 48.41, 17.75, 89.82, 0" },
    }, 0);

    tl.to(`[data-chevron="${id}"] .path`, {
      attr: { d:"M 7, 0, 48.41, 17.75, 89.82, 0" },
    }, 0);

    if (!chevron) {
      return;
    }

    const animateOver = () => {
      tl.play();
    };

    const animateOut = () => {
      tl.reverse();
    };

    const resetAfterClick = () => {
      setTimeout(() => {
        animateOut();
      }, 100);
    };

    chevron.addEventListener("mouseover", animateOver);
    chevron.addEventListener("mouseout", animateOut);
    chevron.addEventListener("click", resetAfterClick);
    return () => {
      chevron.removeEventListener("mouseover", animateOver);
      chevron.removeEventListener("mouseout", animateOut);
      chevron.removeEventListener("click", resetAfterClick);
    };
  }, [id]);

  return (
    <div data-chevron={id} className={style.chevronDown} onClick={onClick}>
      <div className={style.svgWrapper}>
        <svg
          id={id}
          className={style.svg}
          xmlns="http://www.w3.org/2000/svg"
        >
        <path className="path" d="M 28, 0, 49, 9.5, 70, 0" />
        </svg>
      </div>
    </div>
  );
};

export default ChevronDown;
