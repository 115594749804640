import React, { useEffect, useState, useRef } from "react";

import CalendarGroup from "./CalendarGroup";

import { useMeasure } from "react-use";
import { animated, useSpring } from '@react-spring/web';

import style from "../Calendar.module.scss";

export default function CalendarArchive({ year, events }) {
  const archiveRef = useRef(null);

  const [showExpanded, setShowExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const [innerContent, { height }] = useMeasure();

  const contentToggleAnim = useSpring({
    height: showExpanded ? `${contentHeight}px` : "0px",
  });

  useEffect(() => setContentHeight(height), [height]);

  function scrollTo() {
    archiveRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  return (
    <div className={style.archiveAccordion} ref={archiveRef}>
      <button
        className={style.accordionToggle}
        onClick={() => setShowExpanded(!showExpanded)}
        aria-expanded={showExpanded}
        aria-controls={`accordion-content-${year}`}
        aria-label={`Calendar archive for ${year}, ${
          showExpanded ? "Hide this section" : "Show this section"
        }`}
      >
        <h2
          className={`bigcaslonitalic ${style.calendarYear}`}
          id={`accordion-heading-${year}`}
        >
          {year}
        </h2>

        <span
          className={`${style.accordionIcon} ${
            showExpanded ? style.accordionIconExpanded : ""
          }`}
        />
      </button>

      <animated.div className="no-scroll" style={contentToggleAnim}>
        <div
          ref={innerContent}
          id={`accordion-content-${year}`}
          aria-labelledby={`accordion-heading-${year}`}
        >
          <div className={style.calendarContainer}>
            {events.map(({ id, name, events }) => {
              return (
                <div key={`${year}-${id}`}>
                  <h2 className={`bigcaslonitalic ${style.calendarMonth}`}>
                    {name}
                  </h2>

                  <ul className={style.calendarGrid}>
                    {events.map(({ id, name, weekday, events }) => (
                      <CalendarGroup
                        key={id}
                        name={name}
                        weekday={weekday}
                        events={events}
                        country={false}
                      />
                    ))}
                  </ul>
                </div>
              );
            })}

            {showExpanded && (
              <button
                className={`${style.accordionToggle} ${style.accordionToggleClose}`}
                onClick={() => scrollTo()}
                aria-label={`Scroll to top of ${year} calendar`}
              >
                <span
                  className={`${style.accordionIcon} ${
                    showExpanded ? style.accordionIconExpanded : ""
                  }`}
                />
              </button>
            )}
          </div>
        </div>
      </animated.div>
    </div>
  );
}
