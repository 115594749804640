import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { animated, useTrail} from '@react-spring/web';
import style from './ImageList.module.scss';
import ImageContainer from '../ImageContainer/ImageContainer';

const ImageList = ({ items }) => {

  const [ref, inView] = useInView(); // { triggerOnce: true }

  const anim = useTrail(items.length, {
    from: { opacity: inView ? 0 : 1, transform: inView ? 'translate3d(0,64px,0)' : 'translate3d(0,0px,0)'},
    to: { opacity: inView ? 1 : 0, transform: inView ? 'translate3d(0,0,0)' : 'translate3d(0,64px,0)'},
    delay: 300,
  });

  return (

    <ul ref={ref} className={`flex flex-wrap ${style.list}`}>{
      anim.map((props, index) => {
        return (
          <animated.li
            key={index}
            style={props}
            className={`${style.item}`}
          >
            <ImageContainer alt="" ratio={1} src={items[index].src} />
          </animated.li>
        );
      })}
    </ul>

  );
};

ImageList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string.isRequired,
  })).isRequired,
};

export default ImageList;
