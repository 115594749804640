import style from "../Calendar.module.scss";

import { activeSeasons as calendarSeasons } from "../../../static/living/calendar";

export default function CalendarSeasons({ options, season, handleClick, children }) {
  const trueSeason = Number(season);

  return (
    <div className={style.calendarYears}>
      {calendarSeasons.map((season) => {
        return options.includes(season.toString())
        ? (
          <button
            key={season}
            onClick={() => handleClick(season)}
            className={`bigcaslonitalic ${style.tabLink} ${
              trueSeason === season ? style.isActive : ""
            }`}
          >
            {season}
          </button>
        )
        : null
      })}

      {children}
    </div>
  );
}
