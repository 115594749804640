import { redirect } from "react-router-dom";
import { getConfigForEnv } from "../../appConfig";
import { default as artistData } from "../../static/living/artists";
import seasonsForLocale from "../../static/living/seasons.js";

const PerformancesLoader = ({ params }) => {
  const { id, season, locale, card, centurion } = params;
  const { features } = getConfigForEnv();
  const localeCased =
    `${locale.split('-')[0]}-${locale.split('-')[1].toUpperCase()}`;
  if (features[localeCased]?.hiddenSections?.['living']) {
    return redirect(`/${locale}/${card}/${centurion}/404`);
  }

  const isValidSeason = seasonsForLocale(locale)?.availableSeasons.includes(
    season
  );
  let artist = null;
  if (isValidSeason) {
    const pageId = id.split("-").join("") || null;
    const artists = artistData?.[season];
    artist = artists.find(
      (item) => item.id === pageId && item.path === id
    );
  }
  if (!isValidSeason || !artist) {
    return redirect(`/${locale}/${card}/${centurion}/404`);
    // OR: throw new Response("Not Found", { status: 404 });
  }
  return { artist };
};

export default PerformancesLoader;
