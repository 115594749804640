import React from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import PropTypes from "prop-types";
import style from "./SiteFooter.module.scss";

const SiteFooter = ({ eventsNotice }) => {
  const year = new Date().getFullYear();
  const intl = useIntl();
  const userLocale = intl.locale.toLocaleLowerCase();
  const showDisclaimer = ["en-gl", "en-bh"].includes(userLocale);
  const usLink =
    userLocale === "en-us"
      ? "https://www.americanexpress.com/en-us/account/centurion/login?DestPage=https%3A%2F%2Fwww.americanexpress.com%2Fus%2Fbenefits%2Fcenturion"
      : null;
  const hasLegal = userLocale === "fr-fr";

  const uuid = () => {
    return Math.floor(Date.now() * Math.random()).toString(32);
  };

  return (
    <footer role="contentinfo">
      <div
        className={`position-relative ${style.wrapper} ${
          usLink ? "" : style.stackBelowLarge
        }`}
      >
        <div className="container">
          {userLocale === "zh-tw" && (
            <div className={style.twFooter}>
              <FormattedMessage
                id="taiwanFooter"
                values={{
                  h3: (str) => (
                    <h3 key={uuid()} className="bentonsanslight">
                      {str}
                    </h3>
                  ),
                  p: (str) => <p key={uuid()}>{str}</p>,
                }}
              />
            </div>
          )}
          {eventsNotice.length && hasLegal ? (
            <div className={style.eventsNotice}>
              <FormattedMessage
                id="living.eventsNotice"
                values={{
                  lineBreak: <br />,
                }}
              />
            </div>
          ) : null}
          <div className={`flex ${style.content} ${style.position}`}>
            <div className={`${style.spaced}`}>
              <span
                className={`${userLocale !== "fr-fr" && "text-uppercase"} ${
                  style.copyright
                }`}
              >
                <FormattedMessage
                  id="copyrightNotice"
                  values={{
                    year: <span>{year}</span>,
                  }}
                />
              </span>
            </div>
            {showDisclaimer && (
              <div className={`${style.content} ${style.disclaimer}`}>
                <FormattedMessage id="disclaimer" />
              </div>
            )}
            {usLink && (
              <div className={`${style.content} ${style.visit}`}>
                <a href={usLink}>Visit Centurion.com</a>
              </div>
            )}
          </div>
          {hasLegal && (
            <div className={style.legalText}>
              <p>
                <FormattedMessage id="legalText1" />
              </p>
              <p>
                <FormattedMessage id="legalText2" />
              </p>
              <p>
                <FormattedMessage id="legalText3" />
              </p>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

SiteFooter.defaultProps = {
  // fullpage: false,
  eventsNotice: "",
};

SiteFooter.propTypes = {
  // intl: PropTypes.shape({
  //   formatMessage: PropTypes.func.isRequired,
  // }).isRequired,
  // fullpage: PropTypes.bool,
  eventsNotice: PropTypes.string,
};

export default injectIntl(SiteFooter);
