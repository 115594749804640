import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import style from "./TopBar.module.scss";

const TopBar = ({ rootModule }) => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const Logo = `${assetPath}/${rootModule}/centurion-logo.svg`;
  return (
    <div className={style.bar}>
      <h1>
        <span className={style.srOnly}>
          <FormattedMessage id={`${rootModule}.barTitle`} />
        </span>
        <img className={style.logo} src={Logo} alt="" />
      </h1>
    </div>
  );
};

TopBar.propTypes = {
  rootModule: PropTypes.string.isRequired,
};

export default TopBar;
