import React, { useEffect, useState } from "react";
import { animated, useSpring } from '@react-spring/web';
import { useInView } from "react-intersection-observer";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import ImageContainer from "../ImageContainer/ImageContainer";
import LineSet from "../LineSet/LineSet";
import style from "./CardIntro.module.scss";

const CardIntro = () => {
  const { locale } = useIntl();
  const params = useParams();

  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const chevronImage = `${assetPath}/card/down-shallow.svg`;
  const fallingCardsImage = `${assetPath}/card/falling-cards-background.png`;

  const [pageRes, setPageRes] = useState(null);

  const [introRef, inView] = useInView();

  const resetPageRes = () => {
    let res = window.innerWidth;
    setPageRes(res < 768 ? "small" : "large"); // catch state change with useEffect (below)
  };

  const useFadeInBlurb = (index) =>
    useSpring({
      from: { opacity: !inView ? 1 : 0 },
      to: { opacity: !inView ? 0 : 1 },
      delay: !inView ? 0 : index * 500,
      immediate: pageRes === "small" ? false : !inView,
    });

  const fadeInBg = useSpring({
    from: { opacity: inView ? 0 : 1 },
    to: { opacity: inView ? 1 : 0 },
    delay: inView ? 25 : 0,
    immediate: pageRes === "small" ? false : !inView,
  });

  const fadeInDiagonal = useSpring({
    from: { opacity: inView ? 0 : 1 },
    to: { opacity: inView ? 1 : 0 },
    delay: inView ? 150 : 0,
    immediate: pageRes === "small" ? false : !inView,
  });

  const fadeInWiley = useSpring({
    from: { opacity: inView ? 0 : 1 },
    to: { opacity: inView ? 1 : 0 },
    delay: inView ? 2000 : 0,
    immediate: pageRes === "small" ? false : !inView,
  });

  const fadeInRem = useSpring({
    from: { opacity: inView ? 0 : 1 },
    to: { opacity: inView ? 1 : 0 },
    delay: inView ? 2500 : 0,
    immediate: pageRes === "small" ? false : !inView,
  });

  useEffect(() => {
    if (!window) {
      return;
    }
    const debFunc = debounce(resetPageRes, 25);
    resetPageRes();
    window.addEventListener("resize", debFunc);
    return () => {
      window.removeEventListener("resize", debFunc);
    };
  }, []);

  return (
    <>
      <div className="position-relative" ref={introRef}>
        <div className="row">
          <animated.div
            style={useFadeInBlurb(0)}
            className="col-sm-10 col-sm-offset-1 col-md-offset-0 col-md-5"
          >
            <p className={`${style.blurb} ${style.blurbFirst} lineSetOverride bigcaslonregular`}>
              <FormattedMessage
                id="card.blurb1"
                values={{
                  em: (...str) => <em className="bigcaslonitalic">{str}</em>,
                  lineBreak: <br />,
                }}
              />
            </p>
          </animated.div>

          <animated.div
            style={fadeInDiagonal}
            className={`${style.diagonalLine} ${style.diagonalLineSummary}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.44 24.25">
              <path
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth=".1"
                d="M.13.13l51.19 24"
              />
            </svg>
          </animated.div>

          {/* @desktop */}
          <div className={`col-lg-6 col-lg-offset-1 ${style.largeUp}`}>
            <div className="row">
              <div className={`${style.introText} col-lg-8 col-lg-offset-0`}>
                <animated.div style={useFadeInBlurb(1)}>
                  <p
                    className={`${style.blurb} ${style.blurbOne} text-justify bentonsansthin`}
                  >
                    <FormattedMessage
                      id="card.blurb2"
                      values={{ lineBreak: <br /> }}
                    />
                  </p>
                </animated.div>
              </div>
              <div className="col-lg-8 col-lg-offset-2">
                <animated.div style={useFadeInBlurb(2)}>
                  <p
                    className={`${style.blurb} ${style.blurbTwo} text-justify bentonsansthin`}
                  >
                    <LineSet
                      id="card.blurb3"
                      desktop={[8, 7]}
                      values={{ lineBreak: <br /> }}
                    />
                  </p>
                </animated.div>
              </div>
              <div className="col-lg-8 col-lg-offset-4">
                <animated.div style={useFadeInBlurb(3)}>
                  <p
                    className={`${style.blurb} ${style.blurbThree} text-justify bentonsansthin`}
                  >
                    <LineSet
                      id="card.blurb4"
                      desktop={
                        // locale === "en-US" ? [8, 9, 8] : [8, 9, 9]
                        (() => {
                          switch (locale) {
                            case "en-US":
                              return [8, 9, 8];
                            case "en-ID":
                              return [8, 9, 8];
                            case "en-GB":
                              return [8, 8, 10];
                            case "en-SG":
                              return [8, 9, 10];
                            case "en-AU":
                              return [8, 9, 9];
                            case "en-IN":
                              return [8, 8, 10];
                            default:
                              return [8, 9, 9];
                          }
                        })()
                      }
                      values={{ lineBreak: <br /> }}
                    />
                  </p>
                </animated.div>
              </div>
            </div>

            <animated.div
              className={`${style.introCircle} ${style.introCircleWiley}`}
              style={fadeInWiley}
            >
              <figure className={style.introCard1}>
                <ImageContainer
                  ratio={1}
                  src={`${assetPath}/card/kehinde-wiley-card-detail-rounded.png`}
                  round={true}
                  maxWidth="18.75rem"
                />
                <figcaption className={`${style.caption} bentonsansthin`}>
                  <FormattedMessage
                    id="card.artCard1Caption"
                    values={{
                      lineBreak: <br />,
                      wileyLink: (str) => (
                        <Link
                          to={`/${params.locale}/${params.card}/${params.centurion}/kehinde-wiley`}
                        >
                          {str}
                        </Link>
                      ),
                    }}
                  />
                </figcaption>
              </figure>
            </animated.div>
            <animated.div
              className={`${style.introCircle} ${style.introCircleRem}`}
              style={fadeInRem}
            >
              <figure className={style.introCard2}>
                <ImageContainer
                  ratio={1}
                  src={`${assetPath}/card/rem-koolhaas-card-detail-rounded.png`}
                  maxWidth="18.75rem"
                />
                <figcaption className={`${style.caption} bentonsansthin`}>
                  <FormattedMessage
                    id="card.artCard2Caption"
                    values={{
                      lineBreak: <br />,
                      remLink: (str) => (
                        <Link
                          to={`/${params.locale}/${params.card}/${params.centurion}/rem-koolhaas`}
                        >
                          {str}
                        </Link>
                      ),
                      // remLink: (str) => <span>{str}</span>
                    }}
                  />
                </figcaption>
              </figure>
            </animated.div>
          </div>
        </div>

        {/* @mobile / @tablet */}
        <div className={`${style.upToLarge} position-relative`}>
          <div className="row">
            <animated.div
              style={useFadeInBlurb(1)}
              className="col-sm-10 col-sm-offset-1 col-md-offset-5 col-md-4"
            >
              <p
                className={`${style.blurb} ${style.blurbOne} text-justify bentonsansthin lineSetOverride`}
              >
                {pageRes === "small" ? (
                  <FormattedMessage
                    id="card.blurb2_mobile"
                    values={{
                      bold: (str) => <strong>{str}</strong>,
                      em: (str) => <em>{str}</em>,
                      kehindeCardLink: (
                        <span>
                          <FormattedMessage id="card.kehindeCardLinkText" />
                        </span>
                      ),
                      remCardLink: (
                        <span>
                          <FormattedMessage id="card.remCardLinkText" />
                        </span>
                      ),
                      blackCardLink: (
                        <span>
                          <FormattedMessage id="card.blackCardLinkText" />
                        </span>
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id={
                      locale === "en-US" ? "card.blurb2_tablet" : "card.blurb2"
                    }
                    values={{
                      bold: (str) => <strong>{str}</strong>,
                      em: (str) => <em>{str}</em>,
                      kehindeCardLink: (
                        <span>
                          <FormattedMessage id="card.kehindeCardLinkText" />
                        </span>
                      ),
                      remCardLink: (
                        <span>
                          <FormattedMessage id="card.remCardLinkText" />
                        </span>
                      ),
                      blackCardLink: (
                        <span>
                          <FormattedMessage id="card.blackCardLinkText" />
                        </span>
                      ),
                    }}
                  />
                )}
              </p>
            </animated.div>
          </div>
          <div className="row">
            <animated.div
              style={useFadeInBlurb(2)}
              className="col-sm-10 col-sm-offset-1 col-md-offset-7 col-md-4"
            >
              <p
                className={`${style.blurb} ${style.blurbTwo} text-justify bentonsansthin lineSetOverride`}
              >
                {pageRes === "small" ? (
                  <LineSet id="card.blurb3" mobile={[8]} />
                ) : (
                  <FormattedMessage id="card.blurb3_tablet" />
                )}
              </p>
            </animated.div>
          </div>
          <div className="row">
            <animated.div
              style={useFadeInBlurb(3)}
              className="col-sm-10 col-sm-offset-1 col-md-offset-8 col-md-4"
            >
              <p
                className={`${style.blurb} ${style.blurbThree} text-justify bentonsansthin lineSetOverride`}
              >
                <LineSet id="card.blurb4" mobile={[8, 9]} />
              </p>
            </animated.div>
          </div>

          <div className="row">
            <animated.div
              className={`${style.introCircle} ${style.introCircleWiley} col-sm-6 col-md-4`}
              style={fadeInWiley}
            >
              <figure className={style.introCard1}>
                <ImageContainer
                  ratio={1}
                  src={`${assetPath}/card/kehinde-wiley-card-detail-rounded.png`}
                  round={true}
                  maxWidth="18.75rem"
                />
                <figcaption className={`${style.caption} bentonsansthin`}>
                  <FormattedMessage
                    id="card.artCard1Caption"
                    values={{
                      lineBreak: <br />,
                      wileyLink: (str) => (
                        <Link
                          to={`/${params.locale}/${params.card}/${params.centurion}/kehinde-wiley`}
                        >
                          {str}
                        </Link>
                      ),
                    }}
                  />
                </figcaption>
              </figure>
            </animated.div>
            <animated.div
              className={`${style.introCircle} ${style.introCircleRem} col-sm-6 col-md-4 col-md-offset-2`}
              style={fadeInRem}
            >
              <figure className={style.introCard2}>
                <ImageContainer
                  ratio={1}
                  src={`${assetPath}/card/rem-koolhaas-card-detail-rounded.png`}
                  maxWidth="18.75rem"
                />
                <figcaption className={`${style.caption} bentonsansthin`}>
                  <FormattedMessage
                    id="card.artCard2Caption"
                    values={{
                      lineBreak: <br />,
                      remLink: (str) => (
                        <Link
                          to={`/${params.locale}/${params.card}/${params.centurion}/rem-koolhaas`}
                        >
                          {str}
                        </Link>
                      ),
                    }}
                  />
                </figcaption>
              </figure>
            </animated.div>
          </div>
        </div>
      </div>

      <animated.div style={fadeInBg}>
        <img
          src={fallingCardsImage}
          className={style.fallingCardsImage}
          alt=""
        />
      </animated.div>

      <div
        data-navchevron
        data-navchevronto={`/${params.locale}/${params.card}/${params.centurion}/classicBlack`}
        className={`${style.chevronDown} invisible`}
        style={{ backgroundImage: `url(${chevronImage})` }}
      ></div>
    </>
  );
};

export default CardIntro;
