import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import style from './ModalImage.module.scss';

const ModalImage = ({ data: { description, src, title } }) => (
  <Fragment>
  <div className={style.imageBG} style={{ backgroundImage: `url(${src})` }} />
  <div >
    {(title || description) && (
      <div className="margin-2-t">
        {title && (
          <h3 className={`bentonsansthin heading-4 ${style.title}`}>
            {title}
          </h3>
        )}
        {description && (
          <p className={`bentonsansthin body-1 ${style.description}`}>
            {description}
          </p>
        )}
      </div>
    )}
  </div>
  </Fragment>
);

ModalImage.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    src: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
};

export default ModalImage;
