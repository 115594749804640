import React, { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ViewingAccordion from "./ViewingAccordion";
import viewings from "../../static/viewings";
import { getConfigForEnv } from "../../appConfig";
import style from "./Viewings.module.scss";

const Viewings = ({ basePath }) => {
  const { locale: localeName, messages: languageData } = useIntl();
  const userLocale = localeName.toLocaleLowerCase();
  const { dates, countries } = viewings;
  const hideDates = ["it-it", "en-hk", "zh-hk"];

  const locationSwitch = {
    "en-hk": "https://www.prada.com/hk/en/store-locator.html",
    "zh-hk": "https://www.prada.com/hk/hk/store-locator.html",
  };

  const locationFallback =
    "https://www.prada.com/geored?urlNew=store-locator.html";

  const locationURL = Object.keys(locationSwitch).includes(userLocale)
    ? locationSwitch[userLocale]
    : locationFallback;

  // countries.sort((a, b) => {
  //   if (a.countryId < b.countryId) {
  //     return -1;
  //   }
  //   if (a.countryId > b.countryId) {
  //     return 1;
  //   }
  //   return 0;
  // });

  countries.sort((a, b) => {
    const valA = languageData[`viewmono.${a.countryId}`].replace('É', 'E').replace('Ö', 'O');
    const valB = languageData[`viewmono.${b.countryId}`].replace('É', 'E').replace('Ö', 'O');
    if (valA < valB) {
      return -1;
    }
    if (valA > valB) {
      return 1;
    }
    return 0;
  });

  const { locales } = getConfigForEnv(); // All enabled locales

  return (
    <div>
      {/* viewings by date/country */}
      <section id="viewings" className={style.viewingsWrapper}>
        <div className="container position-relative">
          <div className={style.backButton}>
            <Link
              className={`${style.backLink} flex flex-align-items-center`}
              to={{ pathname: `/${basePath}/monogramming-viewings/` }}
            >
              <span className="backLinkIcon icon dls-icon-left" />
              <span className={style.backText}>
                <FormattedMessage id="viewmono.back" />
              </span>
            </Link>
          </div>

          <h1
            className={`text-align-center bigcaslonitalic ${style.sectionTitle}`}
          >
            <FormattedMessage id="viewmono.viewingsTitle" />
          </h1>

          {dates.map((date) => {
            const itemsByDate = countries.filter((country) => country.from === date);

            // Filter by available locales
            const items = itemsByDate.filter(item => {
              let includeLocale = false;
              item.locales.forEach(loc => {
                const split = loc.split("-");
                if (locales.includes(`${split[0]}-${split[1].toUpperCase()}`)) {
                  includeLocale = true;
                }
              });
              return includeLocale;
            });

            const dateId =
              hideDates.indexOf(userLocale) > -1 ? "availableFrom" : date;

            return (
              <div key={date}>
                <h3
                  className={`text-align-center ${style.titleFrom} bentonsansthin`}
                  dangerouslySetInnerHTML={{ __html: languageData[`viewmono.${dateId}`] }}
                />
                <div
                  className={`flex flex-column ${style.viewingsContainer}`}
                  data-locale={localeName}
                >
                  {items.map((item) => {
                    return (
                      <ViewingAccordion key={item.countryId} item={item} />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <div className={style.locator}>
        <div className="container">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
              <p className="text-align-center bentonsansthin">
                <FormattedMessage
                  id="viewmono.bookingAnAppointment"
                  values={{
                    lineBreak: (
                      <Fragment>
                        <br />
                        <br />
                      </Fragment>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <div className={`text-align-center ${style.locatorLink}`}>
            <a
              href={locationURL}
              target="_blank"
              rel="noopener noreferrer"
              className="btn bentonsanslight"
            >
              <FormattedMessage id="viewmono.storeLocator" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Viewings.propTypes = {
  basePath: PropTypes.string.isRequired,
};

export default Viewings;
