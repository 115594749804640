import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { animated, useSpring } from '@react-spring/web';
import { useDrag } from "@use-gesture/react";
import { useMeasure } from "react-use";
import debounce from "lodash.debounce";
import style from "./WearableCarousel.module.scss";
import WearableCarouselSlide from "./WearableCarouselSlide";

const WearableCarousel = ({
  intl,
  items = [],
  toggleButtonId,
  carouselContainerId,
  buttonTextId,
  buttonTextIdSmall,
  defaultOpen,
  toggleCallback,
  rootModule,
  basePath,
}) => {
  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;
  const faqsImage = `${assetPath}/${rootModule}/faqsImage.svg`;

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [pageRes, setPageRes] = useState("small"); // 'small' or 'large'
  const [contentHeight, setContentHeight] = useState(0);
  const [transformAmount, setTransformAmount] = useState(0);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(null);

  const [measureRef, { height }] = useMeasure();

  const calculateAvailableHeight = () => {
    const wh = window.innerHeight;
    const th = window.innerWidth >= 1024 ? 107 : 93; // toggle
    const fh = 75; // footer
    const res = wh - (th + fh);
    return `${Math.max(res, contentHeight)}`;
  };

  const handlePrev = () => {
    const newPage = Math.max(page - 1, 0);
    setPage(newPage);
  };

  const handleNext = () => {
    const newPage = Math.min(page + 1, maxPage);
    setPage(newPage);
  };

  const wearableFaqsToggleAnim = useSpring({
    height: isOpen
      ? `${pageRes === "small" ? contentHeight : calculateAvailableHeight()}px`
      : "0px",
    onRest: () => {
      if (scrolled || !isOpen) {
        return;
      }
      setScrolled(true);
      document
        .getElementById("wearableFaqs")
        .scrollIntoView({ behavior: "smooth" });
    },
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setScrolled(false);
    }
    if (toggleCallback) {
      toggleCallback();
    }
  };

  const slide = useSpring({
    transform: `translateX(-${transformAmount}%)`,
  });

  const bind = useDrag(({ down, event, direction: [xDir] }) => {
    if (down) {
      return;
    }
    if (xDir < 0 && atEnd) {
      return;
    }
    if (xDir > 0 && atStart) {
      return;
    }
    if (xDir === 0) {
      return;
    }
    xDir < 0 && handleNext();
    xDir > 0 && handlePrev();
    event.stopPropagation();
  });

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const resetPageRes = () => {
    let res = window.innerWidth;
    setPageRes(res < 768 ? "small" : "large"); // catch state change with useEffect (below)
  };

  useEffect(() => {
    if (!window) {
      return;
    }
    const debFunc = debounce(() => {
      resetPageRes();
    }, 25);
    resetPageRes();
    window.addEventListener("resize", debFunc);
    return () => {
      window.removeEventListener("resize", debFunc);
    };
  }, []);

  useEffect(() => {
    setContentHeight(height);
  }, [height]);

  const handleResize = useCallback(() => {
    const slidesInView = window.innerWidth < 768 ? 1 : 3;

    setMaxPage(Math.ceil(items.length / slidesInView) - 1);
  }, [items.length]);

  useEffect(() => {
    if (!window) {
      return;
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (items && items.length) {
      handleResize();
    }
  }, [handleResize, items]);

  useEffect(() => {
    if (maxPage === null) {
      return;
    }
    setAtStart(page <= 0);
    setAtEnd(page >= maxPage);
    setTransformAmount(page * 100);
  }, [page, maxPage]);

  useEffect(() => {
    if (maxPage === null) {
      return;
    }
    if (maxPage === 0) {
      setAtEnd(true);
    }
  }, [maxPage]);

  return (
    <div
      id="wearableFaqs"
      className={`position-relative ${style.accordionEnabled}`}
    >
      <div role="heading" aria-level="1">
        <div className={style.accordionToggleWrapper}>
          <div
            id={`${toggleButtonId}Toggle`}
            tabIndex="0"
            role="button"
            className={style.accordionToggle}
            aria-controls={carouselContainerId}
            aria-expanded={isOpen}
            onClick={handleToggle}
            onKeyUp={(e) => {
              if (e.keyCode === 13 || e.keyCode === 32) {
                handleToggle();
              }
            }}
          >
            <div className={`position-relative container`}>
              <span className={style.accordionToggleIcon}>
                <svg xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="none"
                    stroke="#000"
                    strokeWidth=".25"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    d="M28 0l21 9.5L70 0"
                  />
                </svg>
              </span>
              <span className={`${style.accordionToggleText} bigcaslonregular`}>
                <FormattedMessage
                  id={
                    pageRes === "small" && buttonTextIdSmall
                      ? `${rootModule}.${buttonTextIdSmall}`
                      : `${rootModule}.${buttonTextId}`
                  }
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        id={carouselContainerId}
        className={`position-relative ${style.slidesWrapper}`}
        role="region"
        aria-labelledby={`${toggleButtonId}Toggle`}
      >
        <animated.div
          aria-roledescription="carousel"
          aria-label={intl.formatMessage({
            id: `${rootModule}.${buttonTextId}`,
          })}
          className={`no-scroll ${style.carousel}`}
          data-test="carousel"
          style={wearableFaqsToggleAnim}
        >
          <div ref={measureRef} className="container">
            <img className={style.faqsBG} src={faqsImage} alt="" />
            <div
              {...(!(atStart && atEnd) ? bind() : false)}
              className={`no-scroll ${style.noTouch}`}
            >
              <animated.div
                className={`flex-no-wrap row ${style.carouselCols}`}
                style={slide}
                aria-atomic="false"
                aria-live="polite"
              >
                {items.map((item, index) => (
                  <div
                    key={index}
                    className={`${item.lastItem ? "col-md-6" : "col-md-4"} ${
                      style.slideWrapper
                    }`}
                  >
                    <div className={`pad-responsive-tb ${style.slide}`}>
                      <WearableCarouselSlide slide={item} basePath={basePath} />
                    </div>
                  </div>
                ))}
              </animated.div>
            </div>
            <div className="pad-4-t">
              {!(atStart && atEnd) ? (
                <nav className="flex flex-justify-center">
                  <button
                    aria-label={intl.formatMessage({
                      id: `${rootModule}.previousFaq`,
                    })}
                    className={`dls-black margin-1-r transparent ${style.navButton}`}
                    data-test="previous-slide"
                    disabled={atStart}
                    type="button"
                    onClick={handlePrev}
                  >
                    <span className="dls-icon-left-filled icon icon-lg" />
                  </button>
                  <button
                    aria-label={intl.formatMessage({
                      id: `${rootModule}.nextFaq`,
                    })}
                    className={`dls-black transparent ${style.navButton}`}
                    data-test="next-slide"
                    disabled={atEnd}
                    type="button"
                    onClick={handleNext}
                  >
                    <span className="dls-icon-right-filled icon icon-lg" />
                  </button>
                </nav>
              ) : null}
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

WearableCarousel.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      text: PropTypes.string.isRequired,
    })
  ),
  toggleButtonId: PropTypes.string.isRequired,
  carouselContainerId: PropTypes.string.isRequired,
  buttonTextId: PropTypes.string.isRequired,
  buttonTextIdSmall: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool.isRequired,
};

WearableCarousel.defaultProps = {
  defaultOpen: false,
};

export default injectIntl(WearableCarousel);
