import React, { Fragment, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { connect } from "react-redux";
import { useInView } from "react-intersection-observer";
import { animated, useSpring, useTrail } from '@react-spring/web';
import { updateRootModule } from "../../features/rootModule/rootModuleSlice";
import seasonsForLocale from "../../static/living/seasons.js";
import ChevronDown from "../../components/ChevronDown/ChevronDown";
import Hero from "../../components/Hero/Hero";
import Logo from "../../components/Logotype/Logotype";
import FeatureCard from "../../components/Home/FeatureCard";
import FeatureArt from "../../components/Home/FeatureArt";
import FeatureLiving from "../../components/Home/FeatureLiving";
import FeatureLivingNew from "../../components/Home/FeatureLivingNew";
import FeatureWearable from "../../components/Home/FeatureWearable";
import FeatureBenefits from "../../components/Home/FeatureBenefits";
import LineSet from "../../components/LineSet/LineSet";
import SiteFooter from "../../components/SiteFooter/SiteFooter";
import style from "./Home.module.scss";

const Home = ({ isMenuOpen, features, updateRoot }) => {
  const { locale: localeName, messages: languageData } = useIntl();
  const { handleRouteChange } = useOutletContext();

  useEffect(() => {
    updateRoot("home");
    handleRouteChange();
  }, [updateRoot, handleRouteChange]);

  const intl = useIntl();
  const seasons = seasonsForLocale(intl.locale);
  const currentSeason = seasons.currentSeason;

  const assetPath = `${process.env.REACT_APP_ASSETS_URL || ""}assets`;

  const broLeft = `${assetPath}/home/bro-left.jpg`;
  const broRight = `${assetPath}/home/bro-right.jpg`;

  const showVideo = !features?.hiddenSections?.heroVideo;

  const blurbs = [
    {
      id: "home.heroBlurb",
      styles: style.heroBlurb,
      tablet: [],
      mobile: [],
    },
    {
      id: "home.featuresBlurb1",
      styles: style.blurb2,
      tablet: (() => {
        switch (localeName) {
          case "en-US":
            return [11, 11, 10];
          case "en-GB":
            return [11, 10, 10];
          default:
            return [11, 11, 11];
        }
      })(),
      mobile: [],
    },
  ];

  if (languageData["home.featuresBlurb2"]) {
    blurbs.push({
      id: "home.featuresBlurb2",
      styles: `${style.blurb3} ${style.overrideLineSet}`,
      tablet: [],
      mobile: [7, localeName === "en-GB" ? 8 : 6, 8],
    });
  }

  const animConfig = (
    ref,
    offset = 32,
    delay = 80,
    tension = 200,
    friction = 20
  ) => ({
    from: {
      opacity: ref ? 0 : 1,
      transform: ref ? `translate3d(0,${offset}px,0)` : "translate3d(0,0px,0)",
    },
    to: {
      opacity: ref ? 1 : 0,
      transform: ref ? "translate3d(0,0,0)" : `translate3d(0,${offset}px,0)`,
    },
    delay,
    config: { tension, friction },
  });

  const [blurbRef, blurbsInView] = useInView({
    initialInView: features?.hiddenSections?.heroVideo
  });
  const blurbAnim = useTrail(blurbs.length, animConfig(blurbsInView));

  const [titleRef, titleInView] = useInView({
    initialInView: features?.hiddenSections?.heroVideo
  });
  const titleAnim = useSpring(animConfig(titleInView));

  const [cardRef, cardInView] = useInView();
  const cardAnim = useSpring(animConfig(cardInView));

  const [artRef, artInView] = useInView();
  const artAnim = useSpring(animConfig(artInView));

  const [wearableRef, wearableInView] = useInView();
  const wearableAnim = useSpring(animConfig(wearableInView));

  const [livingRef, livingInView] = useInView();
  const livingAnim = useSpring(animConfig(livingInView));

  const [brochureRef, brochureInView] = useInView();
  const brochureAnim = useSpring(animConfig(brochureInView, 100, 300, 50, 15));

  const [brochureRefRight, brochureInViewRight] = useInView();
  const brochureAnimRight = useSpring(
    animConfig(brochureInViewRight, 100, 800, 50, 15)
  );

  const [benefitsRef, benefitsInView] = useInView();
  const benefitsAnim = useSpring(animConfig(benefitsInView));

  const cardRootRef = useRef();
  const artRootRef = useRef();
  const wearableRootRef = useRef();
  const livingRootRef = useRef();
  const brochureRootRef = useRef();
  const benefitsRootRef = useRef();
  const refMap = {
    card: cardRootRef,
    art: artRootRef,
    wearable: wearableRootRef,
    living: livingRootRef,
    brochure: brochureRootRef,
    benefits: benefitsRootRef,
  };

  function handleChevronClick(id) {
    const element = refMap[id] && refMap[id].current;

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  if (!languageData.centurion) {
    return null;
  }

  const art = (
    <div ref={artRootRef} id="art" className={`section ${style.anchorArt}`}>
      <div className="container">
        <div data-section="art" ref={artRef}>
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3">
              <animated.div style={artAnim}>
                <div className={style.featureHeader}>
                  <Logo id="artxcenturion" title="artTitle" rootModule="home" />
                </div>
              </animated.div>
            </div>
          </div>

          <FeatureArt />

          {!features?.hiddenSections?.wearable &&
            <ChevronDown
              onClick={() => handleChevronClick("wearable")}
              id="chevronToWearable"
              rootModule="home"
            />
          }

          {features?.hiddenSections?.wearable && !features?.hiddenSections?.living && (
            <ChevronDown
              onClick={() => {
                handleChevronClick("living");
              }}
              id="chevronToLiving"
              rootModule="home"
            />
          )}
        </div>
      </div>
    </div>
  );

  const card = (
    <div
      ref={cardRootRef}
      id="card"
      className={`section ${style.anchorCard} ${!showVideo ? "" : ""}`}
    >
      <div className={`container`}>
        <div data-section="card" ref={cardRef}>
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3">
              <animated.div style={cardAnim}>
                <div className={style.featureHeader}>
                  <Logo
                    id="newCenturionCard"
                    title="cardTitle"
                    rootModule="home"
                  />
                </div>
              </animated.div>
            </div>
          </div>

          <FeatureCard />

          <ChevronDown
            onClick={() => handleChevronClick("art")}
            id="chevronToArt"
            rootModule="home"
          />
        </div>
      </div>
    </div>
  );

  const hero = (
    <div id="hero" className={`section hero`} data-section="hero">
      <div className={style.heroWrapper}>
        <div className="container">
          <Hero
            showVideo={showVideo}
            fullPage={handleChevronClick}
            isMenuOpen={isMenuOpen}
            // pageRes={pageRes}
          />
        </div>
      </div>
      <div className="container">
        <div id="bar" className={style.blurbsWrapper} ref={blurbRef}>
          {blurbAnim.map((props, index) => {
            return (
              <animated.div
                key={blurbs[index].id}
                style={props}
                className={`${blurbs[index].styles} ${style.blurbRow}`}
              >
                <div className="row">
                  <div
                    className={`
                      col-sm-10 col-sm-offset-1 col-md-7
                      ${
                        index > 0
                          ? `col-md-offset-${1 + 2 * index}`
                          : "col-md-offset-0"
                      }
                      ${index > 0 ? `col-lg-6` : `col-lg-7`}
                      ${
                        index > 0
                          ? `col-lg-offset-${3 * index}`
                          : "col-lg-offset-0"
                      }`}
                  >
                    <p
                      className={`body-1 ${style.blurb} ${style.lineSetDefault} text-justify bentonsansthin`}
                    >
                      <LineSet
                        id={blurbs[index].id}
                        tablet={blurbs[index].tablet}
                        mobile={blurbs[index].mobile}
                        values={{
                          em: (str) => <em>{str}</em>,
                          lineBreak: <br />,
                        }}
                      />
                    </p>
                    <p
                      className={`body-1 ${style.blurb} ${style.lineSetOverride} text-justify bentonsansthin`}
                    >
                      <FormattedMessage
                        id={blurbs[index].id}
                        values={{
                          em: (str) => <em>{str}</em>,
                          lineBreak: <br />,
                        }}
                      />
                    </p>
                  </div>
                </div>
              </animated.div>
            );
          })}
        </div>

        <div className="row">
          <div
            ref={titleRef}
            className="col-md-10 col-md-offset-1 text-align-center"
          >
            <animated.div style={titleAnim}>
              <h2
                className={`display-inline-block text-align-center ${style.title} bigcaslonregular`}
              >
                <FormattedMessage
                  id="home.featuresTitle"
                  values={{
                    lineBreak: (
                      <Fragment>
                        <br></br>
                      </Fragment>
                    ),
                    em: (str) => <em className="bigcaslonitalic">{str}</em>,
                  }}
                />
              </h2>
            </animated.div>
          </div>
        </div>

        <ChevronDown
          onClick={() => handleChevronClick("card")}
          id="chevronToCard"
          rootModule="home"
        />
      </div>
    </div>
  );

  const wearable = !features?.hiddenSections?.wearable ? (
    <div
      ref={wearableRootRef}
      id="wearable"
      className={`section ${style.anchorPrada} ${
          features?.hiddenSections?.living ? style.wearableNoLiving : ""
        }
        ${
          features?.hiddenSections?.benefits ? style.wearableNoBenefits : ""
        }`
      }
    >
      <div className="container">
        <div data-section="wearable" ref={wearableRef}>
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3">
              <animated.div style={wearableAnim}>
                <div className={style.featureHeader}>
                  <Logo
                    id="pradaxcenturion"
                    title="wearableTitle"
                    rootModule="home"
                  />
                </div>
              </animated.div>
            </div>
          </div>

          <FeatureWearable localeName={localeName} />

          {!features?.hiddenSections?.living && (
            <ChevronDown
              onClick={() => {
                handleChevronClick("living");
              }}
              id="chevronToLiving"
              rootModule="home"
            />
          )}
        </div>
      </div>
    </div>
  ) : null;

  const brochure = currentSeason === "2022" && !features?.hiddenSections?.living ? (
    <div
      id="brochure"
      ref={brochureRootRef}
      className={`section ${style.anchorBrochure}`}
    >
      <div className={`container`}>
        <div id="booklet" className={style.brochure}>
          <div className="row">
            <div className={`col-md-10 col-md-offset-1 ${style.brochureWrap}`}>
              <div ref={brochureRef}>
                <animated.div style={brochureAnim}>
                  <img src={broLeft} alt="" />
                </animated.div>
              </div>
              <div ref={brochureRefRight}>
                <animated.div style={brochureAnimRight}>
                  <img src={broRight} alt="" />
                </animated.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  const living = !features?.hiddenSections?.living ? (
    <div
      ref={livingRootRef}
      id="living"
      className={`section ${style.anchorLiving}`}
      data-section="living"
    >
      <div className="container">
        <div data-section="living">
          <div ref={livingRef}>
            <div className="row">
              <div className="col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3">
                <animated.div style={livingAnim}>
                  <div className={style.featureHeader}>
                    <Logo
                      id="centurionLiving"
                      title="livingTitle"
                      rootModule="home"
                    />
                  </div>
                </animated.div>
              </div>
            </div>
          </div>
        </div>

        { currentSeason === "2022"
          ? <FeatureLiving localeName={localeName} />
          : <FeatureLivingNew animation={livingAnim} currentSeason={currentSeason} hasBrochure={!!brochure} />
        }

        { brochure &&
          <ChevronDown
            onClick={() => handleChevronClick("brochure")}
            id="chevronToBrochure"
            rootModule="home"
          />
        }
      </div>
    </div>
  ) : null;

  const benefits = !features?.hiddenSections?.benefits ? (
    <>
      <ChevronDown
        onClick={() => {
          handleChevronClick("benefits");
        }}
        id="chevronToBenefits"
        rootModule="home"
      />
      <div ref={benefitsRootRef} id="benefits" className={`section ${style.anchorBenefits}`}>
        <div className="container">
          <div ref={benefitsRef}>
            <div className="row flex-justify-center">
              <div className="col-sm-10 col-md-6">
                <animated.div style={benefitsAnim}>
                  <div className={style.featureHeader}>
                    <Logo
                      id="centurionBenefits"
                      title="benefitsTitle"
                      rootModule="benefits"
                    />
                  </div>
                </animated.div>
              </div>
            </div>
            <FeatureBenefits animation={benefitsAnim} />
          </div>
        </div>
      </div>
    </>
  ) : null;

  return (
    <>
      <div
        data-module="home"
        data-season={currentSeason}
        className={`
          ${ !showVideo ? style.noVideo : "" }
          ${ !benefits ? style.noBenefits : "" }`
        }>
        <div id="fullpage-wrapper" className={style.pageBackground}>
          {hero}
          {card}
          {art}
          {wearable}
          {living}
          {brochure}
          {benefits}
          <SiteFooter />
        </div>
      </div>
    </>
  );
};

Home.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const localeName = state.getIn(["intl", "activeLocale"]);
  const config = state.get("config");
  const trackingLink = config?.trackingLink;
  const features = config?.features[localeName];
  const isMenuOpen = state.get("menu")?.isOpen;

  return {
    trackingLink,
    features,
    isMenuOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRoot: (moduleName) => dispatch(updateRootModule(moduleName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
