import Logotype from "../../../components/Logotype/Logotype";

export default function PageHero({ params }) {
  return (
    <div className="row">
      <div className="col-xs-12 col-md-8 col-md-offset-2">
        <Logotype
          id="calendar"
          title="title"
          rootModule="calendar"
        />
      </div>
    </div>
  );
}
